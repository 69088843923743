import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, Subscription) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();

    formData.append("CoreSubscriptionUID", "");
    formData.append("Subscription", Subscription);
    formData.append("ActionBy", user.empUID);
    formData.append("DeviceIP", ipaddress);

    formDataConsole(formData);

    let url = '/api/core/coresubscription/list';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// core subscription list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const New = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
   
    formDataConsole(formData);
    let url = '/api/core/coresubscription/new'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// core subscription new response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    
    formDataConsole(formData)
    let url = '/api/core/coresubscription/update'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// core account update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Delete = (user, CoreSubscriptionUID, Subscription) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();

    formData.append("CoreSubscriptionUID", CoreSubscriptionUID);
    formData.append("Subscription", Subscription);
    formData.append("ActionBy", user.empUID);
    formData.append("DeviceIP", ipaddress);

    formDataConsole(formData);

    let url = '/api/core/coresubscription/delete';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// core subscription delete response", res)
            return res;
        })
        .catch(checkAPIError);
}
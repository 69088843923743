import React from 'react'
import { Route } from 'react-router-dom'
import Menu from "../../../../Components/Menu/Menu"
import BasicInfo from "../../EmployeeInfo/MyInformation/ProfileInfo/BasicInfo"
import ContactDetails from "../../EmployeeInfo/MyInformation/ContactDetails"
import FamilyDetails from "../../EmployeeInfo/MyInformation/FamilyDetails"
import EducationDetails from "../../EmployeeInfo/MyInformation/EducationDetails"
import WorkHistory from "../../EmployeeInfo/MyInformation/WorkHistory"
import MyOrgChart from "../../EmployeeInfo/MyInformation/OrganisationalChart/MyOrgChart"
import Role from "../../EmployeeInfo/MyInformation/Role"
import CTCInfo from "../../EmployeeInfo/MyInformation/CTCInfo/CTCInfo"
import IdCardInfo from "../../EmployeeInfo/MyInformation/IdCardInfo"
import ValidLocInfo from "../../EmployeeInfo/MyInformation/ValidLocInfo"
import OtherInfo from "../../EmployeeInfo/MyInformation/OtherInfo/OtherInfo"

class Menus extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route path={`${match.path}`} component={Menu} />
                <Route path={`${match.path}/basicinfo`} component={BasicInfo} />
                <Route path={`${match.path}/contactdetails`} component={ContactDetails} />
                <Route path={`${match.path}/familydetails`} component={FamilyDetails} />
                <Route path={`${match.path}/educationdetails`} component={EducationDetails} />
                <Route path={`${match.path}/workhistory`} component={WorkHistory} />
                <Route path={`${match.path}/orgchart`} component={MyOrgChart} />
                <Route path={`${match.path}/validrole`} component={Role} />
                <Route path={`${match.path}/ctcInfo`} component={CTCInfo} />
                <Route path={`${match.path}/idcardinfo`} component={IdCardInfo} />
                <Route path={`${match.path}/validloc`} component={ValidLocInfo} />
                <Route path={`${match.path}/otherinfo`} component={OtherInfo} />
            </React.Fragment>
        );
    }
}

export default Menus;
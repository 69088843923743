export const LOGIN_SUCCESS = 'user/login';
export const LOGOUT = 'user/logout';
export const MASTER_DATA = 'user/master';
export const ORG_UNIT_LIST_DATA = 'user/orgList';
export const ORG_UNIT_SELECT_DATA = 'user/selecteOrgUnit';
export const LOAD_MASTER = 'user/loadmaster';

const initialState = {
    user: {},
    master: {},
    orgList: [],
    selectedOrgUnit: null,
    loggedin: false,
    masterLoading: {
        load: false,
        path: ''
    }
}
export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                user: action.user,
                selectedOrgUnit: action.user.orgUnitUID,
                loggedin: true
            }
        case ORG_UNIT_LIST_DATA:
            return {
                ...state,
                orgList: action.orgList,
            }
        case ORG_UNIT_SELECT_DATA:
            return {
                ...state,
                selectedOrgUnit: action.data,
            }
        case MASTER_DATA:
            return {
                ...state,
                master: action.master,
                masterLoading: {
                    ...state.masterLoading,
                    load: false
                },
            }
        case LOAD_MASTER:
            return {
                ...state,
                masterLoading: action.masterLoading
            }
        case LOGOUT:
            return initialState
        default:
            return state
    }
}
export const storeMaster = (res) => {
    return dispatch => {
        dispatch({
            type: MASTER_DATA,
            master: res
        })
    }
}
export const storeOrgList = (res) => {
    return dispatch => {
        dispatch({
            type: ORG_UNIT_LIST_DATA,
            orgList: res
        })
    }
}
export const updateSelectedOrgUnit = (selectedOrgUnit) => {
    return dispatch => {
        dispatch({
            type: ORG_UNIT_SELECT_DATA,
            data: selectedOrgUnit
        })
    }
}

export const storeUser = (res) => {
    return dispatch => {
        dispatch({
            type: LOGIN_SUCCESS,
            user: res,
        })
    }
}

export const removeUser = () => {
    return dispatch => {
        dispatch({
            type: LOGOUT,
            user: {},
            orgList: [],
            selectedOrgUnit: null,
        })
    }
}




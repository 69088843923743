import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Menus from "./Menus";
import AddEmployee from "./AddEmployee";
import EmployeeList from "./EmployeeList";
import OrgUnit from "./Orgunit";

class AllEmployee extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Sarvam - Manage Employee Information</title>
                </Helmet>
                <Route path={`${match.path}`} component={OrgUnit} />
                <Route path={`${match.path}/allemployee`} component={EmployeeList} />                
                <Route path={`${match.path}/allemployee/menu`} component={Menus} />
                <Route path={`${match.path}/allemployee/employeeadd`} component={AddEmployee} />
            </React.Fragment>
        );
    }
}

export default AllEmployee;
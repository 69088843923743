import React, { Component } from "react";

import BladeToolbar from "./BladeToolbar";
import { connect } from 'react-redux';

class Blade extends Component {
    static getDerivedStateFromProps(next) {
        return {
            max: next.max
        }
        
    }
    state = {
        name: this.props.id,
        max: this.props.max || false,
    }

    // checkPathBeforeShowLoading = (masterload) => {
    //     let { match } = this.props;
    //     if (match.path === masterload.path && masterload.load) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    getIcon = (item) => {
        if (item) {
            return item.replace('_black.png', '.png');
        } else {
            return '#';
        }
    }
    minmax = () => {
        if (this.state.max) {
            return (
                <li onClick={() => this.setState({ max: false })} className="menu-item __minimize">
                    Minimize
                        </li>
            )
        } else {
            return (
                <li onClick={() => {
                    this.setState({ max: true })
                }} className="menu-item __maximize">
                    Maximize
                        </li>
            )
        }
    }
    render() {
        let { max } = this.state;
        let { toolbar, masterLoading } = this.props;
        return (
            <div className="blade" style={max
                ? max === 'max'
                    ? { width: 900 }
                    : max === 'dash'
                        ? { width: '100%' }
                        :max === 'report' ? {width:'80%'} :
                         { width: 600 }
                : { width: 300 }
            }>
                <header className="blade-head" style={toolbar ? {} : { height: 55 }}>
                    <div className={" blade-status"}>

                    </div>
                    <div className="blade-nav" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className='menu'>
                            <div style={{ position: 'absolute', display: 'flex' }}>
                                {this.props.icon && <img alt='title' style={{ margin: '10px 10px 0px 10px', height: 40, width: 40 }} src={`${this.getIcon(this.props.icon)}`} />}
                                <div style={{ margin: '10px 0 0 0' }}>
                                    <p className="blade-t_subhead" style={{ marginBottom: 0, fontSize: 15 }}> {this.props.head}</p>
                                    <p className="blade-t_subhead" style={{ marginBottom: 0 }}>{this.props.subhead}</p>
                                </div>
                            </div>
                        </div>
                        <ul className="menu __inline">
                            {this.minmax()}
                            {!this.props.disable ?
                                <li onClick={() => this.props.close(this.state.name)} className="menu-item __close">
                                    Close
                                        </li> :
                                <li className="menu-item __close __disabled">
                                    Close
                            </li>}
                        </ul>
                    </div>
                    {/* <BladeBand icon={this.props.icon} head={this.props.head}
                        subhead={this.props.subhead} /> */}
                    {this.props.toolbar
                        && <BladeToolbar handleclick={this.props.handleclick} toolbar={this.props.toolbar} />}
                </header>
                <div>
                    {/* <div>
                        <img alt='loading' style={{ height: 100, width: 100 }} src={'/images/sarvamlogogreen.png'} />
                    </div> */}
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                {this.props.children}
            </div >
        )
    }
}
const mapStateToProps = state => ({
    masterLoading: state.user.masterLoading,
})
export default connect(
    mapStateToProps
)(Blade);
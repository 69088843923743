import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, type, moduleUID, isActive) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('IsActive', isActive);
    formData.append('type', type);
    formData.append('ModuleUID', moduleUID);

    formDataConsole(formData);

    let url = '/api/hrms/master/role/list';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// role list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (formData, user, isActive) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('roleName').toLowerCase() +
        user.empUID.toLowerCase()
        + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('isActive', isActive)
    formDataConsole(formData);

    let url = '/api/hrms/master/role/add';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// role add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user, isActive) => {
    let signature = formData.get('UID').toLowerCase() + user.empUID.toLowerCase()
        + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('isActive', isActive);

    formDataConsole(formData);

    let url = '/api/hrms/master/role/update';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// role update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Detail = (datauid, user) => {
    let signature = user.subscription.toLowerCase() + datauid.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    let formData = new FormData();

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('DataUID', datauid);

    let url = '/api/hrms/master/role/detail';

    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// role detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const MenuList = (datauid, user) => {
    let signature = user.subscription.toLowerCase() + datauid.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    let formData = new FormData();

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ModuleUID', datauid);

    let url = '/api/hrms/master/role/menu/list';

    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// menu list response", res)
            return res;
        })
        .catch(checkAPIError);
}



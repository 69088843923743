
import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';

class CValidator extends ValidatorComponent {

    render() {
        const { children, label, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        return (
            <div className="form-group">
                <label className="form-label">{label}</label>
                <div className="form-input">
                    <input
                        {...rest}
                        ref={(r) => { this.input = r; }}
                    />
                    {children}
                </div>
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'red', fontSize: 12 }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default CValidator;
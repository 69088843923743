import { formDataConsole, devLog,getSignature,DEV_URL, checkAPIError } from '../../../_helpers/helper.methods';
import store from '../../../_helpers/helper.store';

export const Detail = (user, ReimbursementUID) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/detail'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Submit = (user, ReimbursementUID) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/submit'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement submit response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Prepare = (user, ReimbursementUID, PeriodDate, TransferDate, Message) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
    formData.append('PeriodDate', PeriodDate);
    formData.append('TransferDate', TransferDate);
    formData.append('Message', Message);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/prepare'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement prepare response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Cancel = (user, ReimbursementUID, message) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
    formData.append('Message', message);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/cancel'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement cancel response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Approve = (user, ReimbursementUID, PeriodDate, TransferDate, Message) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
    formData.append('PeriodDate', PeriodDate);
    formData.append('TransferDate', TransferDate);
    formData.append('Message', Message);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/approve'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement approve response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Reject = (user, ReimbursementUID, message) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
    formData.append('Message', message);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/reject'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement reject response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const HeaderList = (user, EmployeeUID) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmployeeUID', EmployeeUID);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/header/list'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement header list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const HeaderPrepare = (user, EmployeeUID) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmployeeUID', EmployeeUID);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/header/prepare'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement header prepare response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const HeaderApprove = (user, EmployeeUID) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmployeeUID', EmployeeUID);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/header/approve'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement header approve response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const HeaderNew = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    
    formDataConsole(formData)
    let url = '/api/hrms/reimbursement/header/new'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement header new response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const HeaderUpdate = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    
    formDataConsole(formData)
    let url = '/api/hrms/reimbursement/header/update'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement header update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const LineList = (user, ReimbursementUID) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/line/list'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement line list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const LineDetail = (user, ReimbursementUID, ReimbursementLineUID) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
    formData.append('ReimbursementLineUID', ReimbursementLineUID);
   
    formDataConsole(formData);
    let url = '/api/hrms/reimbursement/line/detail'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement line detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const LineNew = (formData, user, ReimbursementUID) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
    
    formDataConsole(formData)
    let url = '/api/hrms/reimbursement/line/new'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement line new response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const LineUpdate = (formData, user, ReimbursementUID) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
    
    formDataConsole(formData)
    let url = '/api/hrms/reimbursement/line/update'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// reimbursement line update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const LineDelete = (user, ReimbursementUID, ReimbursementLineUID) => {
    let formData = new FormData();      
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ReimbursementUID', ReimbursementUID);
    formData.append('ReimbursementLineUID', ReimbursementLineUID);

    let url = '/api/hrms/reimbursement/line/delete';    
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('//reimbursement line delete response', res)
            return res;
        })
        .catch(checkAPIError);
}
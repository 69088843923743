import React from 'react'

class SideBarItem extends React.Component {
    render() {
        return (
            <a className="list-link" onClick={this.props.onClick}>
                <i><img alt='sidebar' title={this.props.value} style={{height:25,width:25,marginRight:5}} src={this.props.icon} /></i>
                <span className="list-name" style={{fontSize:11}}>
                    {this.props.value}
                </span>
            </a >
        )
    }
}

export default SideBarItem;
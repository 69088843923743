import React from 'react';
import Api from "../../_apis";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../../Components/Validators/CValidator";
import TextAreaValidator from "../../Components/Validators/CTextareaValidator";
//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//methods
import { createNotification } from '../../_reducers/reducer.notification';
import { handleFormErrors } from '../../_helpers/helper.methods';
//import ReCAPTCHA from "react-google-recaptcha";
//const grecaptchaObject = window.grecaptcha;
class SignUp extends React.Component {    
    state = {
        requestor: '',
        company: '',
        email: '',
        retypeEmail: '',
        phone: '',
        note: '',
        submitted: false,
        captchaResponse: null,
        errorcaptcha: ''
    }  
    componentDidMount() {     
    }
    
    Register = async (e) => {              
        //if(this.state.captchaResponse === null) {
          //this.setState({errorcaptcha:"Captcha is required"})
          //return false;
        if(this.state.email !== this.state.retypeEmail) {
            this.setState({errorcaptcha:"Confirm email must be same as email"})
            return false;
        } else {
            this.setState({
                error: '',
                errorcaptcha: '',
                submitted: true
            }) 
            let res = await Api.Login.Signup(this.state.requestor, this.state.company, this.state.email, this.state.phone, this.state.note);
            this.setState({ submitted: false })
            if (res.status !== '1') {
                this.props.createNotification('Success', 'Thank you for signing up!');
                this.props.history.push("/");
            } else {
                this.setState({ error: res.message })
            }  
        }
    }
    render() {     
        let { submitted, requestor, company, email, retypeEmail, phone, note, error } = this.state;
        return (
            <div className='window'>
                {submitted && <div className="window-status">
                    <div className={"progress-bar"}></div>
                </div>}
                <header className='window-head'>
                    <span className='window-t'>Sign Up</span>
                </header>
                <div className='window-cnt'>
                    <ValidatorForm
                        ref="form"
                        onError={handleFormErrors}
                        onSubmit={this.Register}
                    >    
                        <div className="row">
                            <div className="col-md-6">
                                <TextValidator
                                    label={"Name"}
                                    value={requestor}
                                    onChange={(e) => this.setState({ requestor: e.target.value })}
                                    name="requestor"
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextValidator
                                    label={"Company"}
                                    value={company}
                                    onChange={(e) => this.setState({ company: e.target.value })}
                                    name="company"
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                />
                            </div>
                        </div>        
                        <div className="row">
                            <div className="col-md-6">
                                <TextValidator
                                    label={"Email"}
                                    value={email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                    name="email"
                                    validators={['required', 'isEmail']}
                                    errorMessages={['This field is required', 'Email is Invalid']}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextValidator
                                    label={"Phone"}
                                    value={phone}
                                    onChange={(e) => this.setState({ phone: e.target.value })}
                                    name="phone"
                                    validators={['required']}
                                    errorMessages={['This field is required']}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <TextValidator
                                    label={"Confirm Email"}
                                    value={retypeEmail}
                                    onChange={(e) => this.setState({ retypeEmail: e.target.value })}
                                    name="retypeEmail"
                                    validators={['required', 'isEmail']}
                                    errorMessages={['This field is required', 'Email is Invalid']}
                                />
                                <div style={{ color: 'red', fontSize: 12 }}>{this.state.errorcaptcha}</div>
                            </div>                            
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <TextAreaValidator
                                    label={"Note"}
                                    value={note}
                                    onChange={(e) => this.setState({ note: e.target.value })}
                                    name="note"  
                                    validators={['required']}
                                    errorMessages={['This field is required']}                                
                                />
                            </div>                            
                        </div>
                        {/*<br />
                        <div className="row">
                            <div className="col-md-12">                              
                                <ReCAPTCHA
                                    id="recaptcha"
                                    //sitekey="6Lds49AUAAAAAH7BgVWWbOt0lvusN2CbYhSJtrb3" //localhost
                                    sitekey="6LfJO9EUAAAAAEGvELigg9MrPHoL-VjzepEcXu33" //dev-ftp
									//sitekey="6LdloNEUAAAAABtG2gYYY5pwCwYl6eC3y0KpmDj2" //sarvam.sta
                                    //sitekey="6LcK6NEUAAAAAPjDdEgubsw9PKXOqNkfW3qlzb9X" //sarvam.firstindo.com
                                    onChange={(response) => { this.setState({ captchaResponse: response, errorcaptcha: "" }); }}
                                />
                                <div style={{ color: 'red', fontSize: 12 }}>{this.state.errorcaptcha}</div>
                            </div>
                        </div>*/}
                        
                        <div className="clearfix">
                            <div className="column">
                                <div className="form-group">
                                    <button type='button' onClick={() => this.props.history.push("/")} className="btn __cancel">
                                        <span>Back</span>
                                    </button>
                                </div>
                            </div>
                            <div className="column">
                                <div className="form-group __right">
                                    <button id="submit" style={{ display: "none" }} type="submit"></button>
                                    <button style={submitted ? { minWidth: 100 } : {}} disabled={submitted ? true : false} className={submitted ? "btn __loading" : "btn"}>
                                        <span>Submit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="clearfix">&nbsp;</div>
                    </ValidatorForm>
                    {error &&
                        <div style={{ color: 'red' }}>{error}</div>
                    }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification   
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    routing: state.routing
})
export default connect(
    mapStateToProps, mapDispatchToProps 
)(SignUp);
import React from 'react'
import { Route } from 'react-router-dom'
//components
import RoleInfo from "./RoleInfo"

class Role extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route path={`${match.path}`} component={RoleInfo} />
            </React.Fragment>
        );
    }
}

export default Role;


import React, { Component } from "react";

import { withTheme } from 'theming';

class BladeToolbar extends Component {
    getIcon = (item) => {
        let { bladetoolbar } = this.props.theme;
        if (bladetoolbar.color === 'white') {
            return item.replace('_black.png', '.png');
        } else if (bladetoolbar.color === 'black') {
            return item
        } else {
            return '#';
        }
    }
    render() {
        let { theme } = this.props;
        return (
            <div className="blade-toolbar">
                <ul className={this.props.disabled ? 'menu __inline __disabled' : "menu __inline"} style={theme.bladetoolbar}>
                    {this.props.children}
                    {this.props.toolbar && this.props.toolbar.map((item, i) => (
                        <li key={i} className={item.disabled ? 'menu-item __disabled' : "menu-item"} onClick={() => this.props.handleclick(item.value)}>
                            <a className="menu-btn" style={{ color: theme.bladetoolbar.color }}>
                                <span style={{ padding: 5 }} title={item.title || ''} >
                                    {item.icon && <img alt={item.name || ''} style={{ height: 20, width: 20 }} src={`${this.getIcon(item.icon)}`} />}
                                </span>
                                {item.name ?
                                <span style={{ padding: 5 }}> {item.name}</span>: ''}</a>                                
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
export default withTheme(BladeToolbar);
import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";
import TextValidator from "../../../../../Components/Validators/TextValidator";
import { ValidatorForm } from 'react-form-validator-core';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { errorNotification } from '../../../../../_reducers/reducer.notification';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';
//api
import API from '../../../../../_apis/hrms/master';

class MyOrgChart extends Component {
    async componentDidMount() {
        let { history: { location: { state } }, match } = this.props;

        let orgChartList = state.item.currentCompanyWorkList.filter((r) => {
            return !r.endDate
        })
        this.setState({
            icon: state.icon,
            orgChartList
        })
        if (match.path.includes('allemployee')) {
            let url = '../../../../';
            let res = await API.Lists.ReportingStaffList(this.props.user, url, 'all', state.item.empUID);
            if (res.status !== '1') {
                this.setState({
                    directStaffList: res.filter((r) => (r.listType === 'DirectReportingStaff')),
                    inDirectStaffList: res.filter((r) => (r.listType === 'IndirectReportingStaff'))
                })
            } else {
                this.props.errorNotification(res.message);
            }
        } else {
            let url = '../../../../';
            let res = await API.Lists.ReportingStaffList(this.props.user, url, 'all');
            if (res.status !== '1') {
                this.setState({
                    directStaffList: res.filter((r) => (r.listType === 'DirectReportingStaff')),
                    inDirectStaffList: res.filter((r) => (r.listType === 'IndirectReportingStaff'))
                })
            } else {
                this.props.errorNotification(res.message);
            }
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en"); 
        this.state = {
            orgChartList: [],
            directStaffList: [],
            inDirectStaffList: [],
            selected: -1,
            orgChart: {
                positionName: '',
                directManagerName: '',
                indirectManagerName: '',
                divisionName: '',
                jobDesc: '',
                dirRepStaff: '',
                inDirRepStaff: ''
            }
        }
    }
    handleChange = (index) => {
        let { selected } = this.state;
        if (selected >= 0) {
            document.getElementById(`org${selected}`).classList.remove('__opened');
            this.setState({
                selected: -1
            })
        }
        if (index !== selected) {
            document.getElementById(`org${index}`).classList.add('__opened');
            this.setState({
                selected: index
            })
        }
    }

    close = () => {
        let { match } = this.props;
        if (match.path.includes('allemployee')) {
            this.props.history.push('/home/hrms/allemporgunit/allemployee/menu')
        } else {
            this.props.history.push('/home/hrms/myinfo')
        }
    }
    render() {
        let { orgChartList, directStaffList, inDirectStaffList } = this.state;
        let toolbar = [];
        return (
            <Blade match={this.props.match}
                close={this.close}
                icon={this.state.icon || '/images/myorgchart.png'}
                head={<Translate id="hrms.employeeInfo.myInformation.myOrgChart" />}
                subhead={<Translate id="hrms.employeeInfo.myInformation.myOrgChart" />}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match}>

                    {Inputs(orgChartList,
                        this.handleChange, directStaffList,
                        inDirectStaffList)}

                </BladeBody>
            </Blade >
        )
    }
}

const Inputs = (orgChartList, handleChange,
    directStaffList, inDirectStaffList) => (

        <ValidatorForm
            ref="form"
        >
            {orgChartList.length > 1
                ? orgChartList.map((orgChart, index) => (
                    <div className='list __items' key={index}>
                        <div onClick={() => handleChange(index)} id={`org${index}`} className="list-group">
                            {'Postion: '}{orgChart.positionName}
                        </div>
                        <div className="list __sub">
                            <TextValidator
                                disabled
                                label={<Translate id="hrms.employeeInfo.myInformation.positionTitle" />}
                                name="positionName"
                                value={orgChart.positionName}
                            />
                            <TextValidator
                                disabled
                                label={<Translate id="hrms.employeeInfo.myInformation.directManager" />}
                                name="directManagerName"
                                value={orgChart.directManagerName}
                            />
                            <TextValidator
                                disabled
                                label={<Translate id="hrms.employeeInfo.myInformation.indirectManager" />}
                                name="indirectManager"
                                value={orgChart.indirectManager}
                            />
                            <TextValidator
                                disabled
                                label={<Translate id="hrms.employeeInfo.myInformation.division" />}
                                name="divisionName"
                                value={orgChart.divisionName}
                            />
                            <TextValidator
                                disabled
                                label={<Translate id="hrms.employeeInfo.myInformation.organisationName" />}
                                name="orgUnitName"
                                value={orgChart.orgUnitName}
                            />
                            <div className="form-group">
                                <label className="form-label"><Translate id="hrms.employeeInfo.myInformation.positionDescription" /></label>
                                <div className="form-input">
                                    <textarea
                                        disabled
                                        name="jobDesc"
                                        value={orgChart.jobDesc}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                ))
                : orgChartList.length === 1
                && orgChartList.map((orgChart, index) => (
                    <React.Fragment>
                        <TextValidator
                            disabled
                            label={<Translate id="hrms.employeeInfo.myInformation.positionTitle" />}
                            name="positionName"
                            value={orgChart.positionName}
                        />
                        <TextValidator
                            disabled
                            label={<Translate id="hrms.employeeInfo.myInformation.directManager" />}
                            name="directManagerName"
                            value={orgChart.directManagerName}
                        />
                        <TextValidator
                            disabled
                            label={<Translate id="hrms.employeeInfo.myInformation.indirectManager" />}
                            name="indirectManagerName"
                            value={orgChart.indirectManager}
                        />
                        <TextValidator
                            disabled
                            label={<Translate id="hrms.employeeInfo.myInformation.division" />}
                            name="divisionName"
                            value={orgChart.divisionName}
                        />
                        <TextValidator
                            disabled
                            label={<Translate id="hrms.employeeInfo.myInformation.organisationUnit" />}
                            name="orgUnitName"
                            value={orgChart.orgUnitName}
                        />
                        < div className="form-group">
                            <label className="form-label"><Translate id="hrms.employeeInfo.myInformation.positionDescription" /></label>
                            <div className="form-input">
                                <textarea
                                    disabled
                                    name="jobDesc"
                                    value={orgChart.jobDesc}
                                />
                            </div>
                        </div>
                    </React.Fragment>))}
            <div className="form-group">
                <label className="form-label"><Translate id="hrms.employeeInfo.myInformation.myDirectReportingStaff" /></label>
                <div className="form-input">
                    {directStaffList.length > 0
                        ? directStaffList.map((item) => (
                            <React.Fragment>
                                <input
                                    disabled
                                    value={item.addInfo +' - '+item.text}
                                />
                            </React.Fragment>
                        )) : <p><Translate id="hrms.employeeInfo.myInformation.noReportingStaff" /></p>}
                </div>
            </div>
            <div className="form-group">
                <label className="form-label"><Translate id="hrms.employeeInfo.myInformation.myIndirectReportingStaff" /></label>
                <div className="form-input">
                    {inDirectStaffList.length > 0
                        ? inDirectStaffList.map((item) => (
                            <React.Fragment>
                                <input
                                    disabled
                                    value={item.addInfo +' - '+item.text}
                                />
                            </React.Fragment>
                        )) : <p><Translate id="hrms.employeeInfo.myInformation.noReportingStaff" /></p>}
                </div>
            </div>
        </ValidatorForm>
    );


const mapDispatchToProps = dispatch => bindActionCreators({
    errorNotification
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(MyOrgChart))
import { formDataConsole, devLog, getSignature, DEV_URL } from "../../_helpers/helper.methods";
import store from '../../_helpers/helper.store';
//var ip = require("ip");

export const DashBoardAPI = (user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    let formData = new FormData();
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);

    let url = '/api/hrms/dashboard';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// dashboard response", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
}

export const AttendanceDashboardAPI = (user, OrgUnitUID, type) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    let formData = new FormData();
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('OrgUnitUID', OrgUnitUID);
    formData.append('DashType', type);

    formDataConsole(formData);
    
    let url = '/api/hrms/attendance/dashboard';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// attendance dashboard response : "+type , res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
}

export const LeaveDashboardAPI = (user, OrgUnitUID, type) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    let formData = new FormData();
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('OrgUnitUID', OrgUnitUID);
    formData.append('DashType', type);

    formDataConsole(formData);
    
    let url = '/api/hrms/leave/dashboard';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// leave dashboard response : "+type , res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
}

export const MonthlyRecap = (user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    let formData = new FormData();
    formData.append('EmployeeUID', user.empUID);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);
    
    let url = '/api/hrms/attendance/monthly/recap';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// monthly recap response : ", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
}

export const MonthlyStaff = (user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    let formData = new FormData();
    formData.append('EmployeeUID', user.empUID);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);
    
    let url = '/api/hrms/attendance/monthly/staff';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// monthly staff response : ", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
}

export const AbsentStaff = (user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    let formData = new FormData();
    formData.append('EmployeeUID', user.empUID);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);
    
    let url = '/api/hrms/attendance/absent/staff';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// absent staff response : ", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
}
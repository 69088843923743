import * as OrgUnit from './orgunit';
import * as Division from './division';
import * as Position from './position';
import * as Lists from './Lists';
import * as Role from './role';
import * as Holiday from './Holiday';
import * as MySubscription from './mysubscription';
import * as Documents from './documents';
import * as Department from './department';
import * as SubDepartment from './subdepartment';
import * as Location from './location';
import * as EmailTemplate from './emailtemplate';
import * as ActionType from './actiontype';
import * as CoreAccount from './coreaccount';
import * as SubModule from './submodule';

export default {
    Lists,
    Division,
    OrgUnit,
    Position,
    Role,
    Holiday,
    MySubscription,
    Documents,
    Department,
    SubDepartment,
    Location,
    EmailTemplate,
    ActionType,
    CoreAccount,
    SubModule
}


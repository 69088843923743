import React, { useRef } from "react";
import Cropper from "react-cropper";
import Api from "../_apis";

import HrmsApi from "../_apis/hrms/master";

import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../Components/Validators/CValidator";
import TextAreaValidator from "../Components/Validators/CTextareaValidator";
import Select2 from "react-select2-wrapper";
import AttachFile from "../Components/AttachFile";

import Webcam from "react-webcam";

//redux
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  storeUser,
  storeMaster,
  storeOrgList,
} from "../_reducers/reducer.user";

import { setMenu } from "../_reducers/reducer.menu";

import { withLocalize, Translate } from "react-localize-redux";

import Tree, { TreeNode } from "rc-tree";
//methods
import {
  errorNotification,
  createNotification,
  dataChanged,
} from "../_reducers/reducer.notification";
import {
  devLog,
  handleFormErrors,
  formDataConsole,
} from "../_helpers/helper.methods";
import ReCAPTCHA from "react-google-recaptcha";

import API from "../_apis/Core/index";
import "../Components/Theme/tree.css";

const grecaptchaObject = window.grecaptcha;

//200kb
const MAX_IMAGE_CROP_SIZE = 200000;
class SignupPage extends React.Component {
  state = {
    requestor: "",
    subscriptionName: "",
    address: "",
    country: "",
    city: "",
    province: "",
    contactEmail: "",
    contactFirstName: "",
    contactLastName: "",
    fax: "",
    taxNo: "",
    phone: "",
    notes: "",
    submitted: false,
    captchaResponse: null,
    errorcaptcha: "",
    halfchecked: [],
    moduledata: {},
    menuList: [],
    modifiedarray: [],
    checkedKeys: [],
    moduleList: {
      SubModuleName: "",
    },
    selectedModuleUID: [],
    load: false,
  };

  async componentDidMount() {
    this.setState({ load: false });
    let {
      history: {
        location: { state },
      },
    } = this.props;
    this.setState({ moduledata: this.state.module });
    let res = await API.SubModuleList("");
    if (res.status !== "1") {
      this.frameMenuList(res);
      this.getCheckedMenu(res);
      this.setState({
        moduleList: res,
      });
      this.setState({ load: false });
    } else {
      this.props.errorNotification(res.message);
    }
  }

  frameMenuList = (data) => {
    let modifiedarray = []; //{moduleName:"hrms",subModule:["RMS","HRMS Core"]}
    data.forEach((item) => {
      if (modifiedarray.length === 0) {
        modifiedarray.push({
          moduleName: item.moduleName,
          subModule: [item.subModuleName],
        });
      } else {
        modifiedarray.forEach((mod, index) => {
          if (item.moduleName === mod.moduleName) {
            modifiedarray[index].subModule.push(item.subModuleName);
            //return true;
          } else {
            modifiedarray.push({
              moduleName: item.moduleName,
              subModule: [item.subModuleName],
            });
            //return true;
          }
          //return false;
        });
      }
    });

    this.setState({
      modifiedarray: modifiedarray,
    });
  };
  getCheckedMenu = (data) => {
    let array = [];
    function getSelected(data) {
      data.forEach((item) => {
        //array.push(item.moduleName);
        if (item.subModuleName === "HRMS Core") {
          array.push(item.subModuleName);
        }
      });
    }
    getSelected(data);
    devLog("array", array);
    this.setState({
      checkedKeys: array,
    });
  };

  onCheck = (checkedKeys, e) => {
    this.state.selectedModuleUID = [];
    devLog("onCheck", checkedKeys, "half", e);
    this.setState({
      checkedKeys,
      halfchecked: e.halfCheckedKeys,
    });

    devLog("Modules", this.state.moduleList);
    let subModuleUid = [];
    let finalData;
    this.state.moduleList.forEach((moduleElement) => {
      checkedKeys.forEach((element) => {
        if (moduleElement.subModuleName === element) {
          devLog("------Success------");
          subModuleUid.push(moduleElement.subModuleUID);
          finalData = [...new Set(subModuleUid)];
          devLog("-----------selected modules-------", finalData);
        }
      });
    });
    this.state.selectedModuleUID = finalData;
    devLog(
      "-----------Updated selected modules-------",
      this.state.selectedModuleUID
    );

    // let joinedData = this.state.selectedModuleUID.join(",")
    // this.state.selectedModuleUID = joinedData;
    // devLog('-----------Join Data-------', this.state.selectedModuleUID);
  };

  resetImageUploadButton = () => {
    document.getElementById("subscriptionLogoFile").value = "";
  };

  Register = async (e) => {
    this.setState({ load: true });
    let { user, match } = this.props;
    let { file } = this.state;
    let formData = new FormData(e.target);

    formData.append("SubscriptionName", this.state.subscriptionName);
    formData.append("Address", this.state.address);
    formData.append("City", this.state.city);
    formData.append("Province", this.state.province);
    formData.append("Country", this.state.country);
    formData.append("Phone", this.state.phone);
    formData.append("Fax", this.state.fax);
    formData.append("WebsiteUrl", this.state.websiteURL);
    formData.append("TaxNo", this.state.taxNo);
    formData.append("ContactFirstName", this.state.contactFirstName);
    formData.append("ContactLastName", this.state.contactLastName);
    formData.append("ContactPhone", this.state.contactPhone);
    formData.append("ContactEmail", this.state.contactEmail);
    formData.append("Notes", this.state.notes);
    formData.append("SubModuleUIDList", this.state.selectedModuleUID);

    if (file) {
      let croppedImage = await this.getCroppedImage();
      if (croppedImage.size > MAX_IMAGE_CROP_SIZE) {
        this.resetImageUploadButton();
        this.props.createNotification(
          "Alert",
          "Picture size is greater than 200kb"
        );
        this.setState({ load: false });
        return;
      }
      formData.append("SubscriptionLogoFile", croppedImage);
    }

    if (this.state.captchaResponse === null) {
      this.setState({ errorcaptcha: "Captcha is required" });
      return false;
    } else {
      formDataConsole(formData);
      this.setState({
        error: "",
        errorcaptcha: "",
        submitted: true,
      });
      let res = await Api.Login.AccountSignup(formData);
      this.setState({ submitted: false });
      devLog("Signup res ----------------", res);
      if (res.status !== "1") {
        this.props.createNotification("Success", "Thank you for signing up!");
        localStorage.setItem("user", JSON.stringify(res.login));
        this.props.storeUser(res.login);
        this.props.setMenu(res.menu);
        this.getMaster(res.login);
      } else {
        this.setState({ error: res.message });
      }
    }
  };

  getMaster = async (res) => {
    let masterlist = await Api.Core.Master.List(res, null, "");
    let orgList = await HrmsApi.OrgUnit.List(res, "", true);
    // setSubmitted(false)
    if (orgList.status !== "1") {
      this.props.storeOrgList(orgList);
    }
    if (masterlist.status !== "1") {
      this.props.storeMaster(masterlist);
      this.props.history.push("/home");
    }
  };

  loadImage = (event) => {
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      this.setState({
        modal: true,
        imagePreviewUrl: reader.result,
        is_fromwebcam: false,
      });
    };
    reader.readAsDataURL(file);
    event.target.value = "";
  };

  uploadImage = async () => {
    this.setState({
      modal: false,
      file: this.refs.cropper.getCroppedCanvas(),
      is_fromwebcam: false,
      cropimage: this.refs.cropper.getCroppedCanvas().toDataURL(),
    });
  };

  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    this.setState({ using_webcam: false, imagePreviewUrl: imageSrc });
  };

  getCroppedImage = () => {
    return new Promise((resolve, reject) => {
      this.state.file.toBlob((blob) => {
        resolve(blob);
      }, "image/jpeg");
    });
  };

  setRef = (webcam) => {
    this.webcam = webcam;
  };

  closeCropper = () => {
    this.setState({
      modal: false,
    });
  };

  render() {
    const {
      add,
      theme,
      label,
      errorMessages,
      validators,
      requiredError,
      validatorListener,
      ...rest
    } = this.props;

    let {
      submitted,
      subscriptionName,
      address,
      country,
      province,
      city,
      phone,
      fax,
      websiteURL,
      taxNo,
      contactFirstName,
      contactLastName,
      contactPhone,
      contactEmail,
      notes,
      imageFile,
      error,
    } = this.state;
    let defaultimage = "/images/subscription_default.png";
    let {
      modal,
      cropimage,
      imagePreviewUrl,
      toolbar,
      load,
      disableField,
      photoUrl,
      modifiedarray,
      selectedModuleUID,
    } = this.state;

    const loop = (data) => {
      return data.map((item, index) => {
        return item.subModule.map((element) => {
          return (
            <TreeNode
              className={{ marginRight: "20px" }}
              key={element}
              title={element}
              disableCheckbox={element !== "HRMS Core" ? false : true}
            />
          );
        });
      });
    };

    let treeNodes;
    treeNodes = loop(this.state.modifiedarray);
    this.treeNodes = treeNodes;

    return (
      <React.Fragment>
        <img className="logo-signup" src="/images/sarvamnamegreen.png" alt="" />
        <div className="window-signup">
          {submitted && (
            <div className="window-status">
              <div className={"progress-bar"}></div>
            </div>
          )}
          <header className="window-head">
            <span className="window-t">Signup Page</span>
          </header>
          <div className="window-cnt">
            <ValidatorForm
              ref="form"
              onError={handleFormErrors}
              onSubmit={this.Register}
            >
              <div className="row">
                <div className="col-md-12">
                  <TextValidator
                    label={"Company Name *"}
                    value={subscriptionName}
                    onChange={(e) =>
                      this.setState({ subscriptionName: e.target.value })
                    }
                    name="subscriptionName"
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className="col-md-6"
                  style={
                    add ? { display: "flex", width: 190 } : { width: "100%" }
                  }
                >
                  <TextAreaValidator
                    label={"Address *"}
                    value={address}
                    onChange={(e) => this.setState({ address: e.target.value })}
                    name="address"
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </div>
                <div className="col-md-6">
                  <TextValidator
                    label={"Country"}
                    value={country}
                    onChange={(e) => this.setState({ country: e.target.value })}
                    name="country"
                  />
                </div>
                {/* <div className='col-md-6' style={add ? { display: 'flex', width: 190 } : { width: '100%' }}>
                                <div className="form-group">
                                    <label className="form-label">Country</label>
                                    <div className="form-input" style={add ? { display: 'flex', width: 190 } : { width: '100%' }}>
                                        <Select2
                                            label='title'
                                            value={country}
                                            name="country"
                                            style={{ color: 'white' }}
                                            className='mySelect'
                                            // onSelect={this.handleChange}
                                            // data='data'
                                            options={{ placeholder: 'Select Country' }}
                                        />
                                    </div>
                                </div>
                            </div> */}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextValidator
                    label={"Province"}
                    value={province}
                    onChange={(e) =>
                      this.setState({ province: e.target.value })
                    }
                    name="province"
                  />
                  {/* <div className="form-group">
                                    <label className="form-label">Province</label>
                                    <div className="form-input" style={add ? { display: 'flex', width: 190 } : { width: '100%' }}>
                                        <Select2
                                            label='title'
                                            value={province}
                                            name="province"
                                            style={{ color: 'white' }}
                                            className='mySelect'
                                            // onSelect={this.handleChange}
                                            // data='data'
                                            options={{ placeholder: 'Select Province' }}
                                        />
                                    </div>
                                </div> */}
                </div>
                <div className="col-md-6">
                  <TextValidator
                    label={"City"}
                    value={city}
                    onChange={(e) => this.setState({ city: e.target.value })}
                    name="city"
                  />
                  {/* <div className="form-group">
                                    <label className="form-label">City</label>
                                    <div className="form-input" style={add ? { display: 'flex', width: 190 } : { width: '100%' }}>
                                        <Select2
                                            label='title'
                                            value={city}
                                            name="city"
                                            style={{ color: 'white' }}
                                            className='mySelect'
                                            // onSelect={this.handleChange}
                                            // data='data'
                                            options={{ placeholder: 'Select City' }}
                                        />
                                    </div>
                                </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextValidator
                    label={"Phone *"}
                    value={phone}
                    onChange={(e) => this.setState({ phone: e.target.value })}
                    name="phone"
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </div>
                <div className="col-md-6">
                  <TextValidator
                    label={"Fax"}
                    value={fax}
                    onChange={(e) => this.setState({ fax: e.target.value })}
                    name="fax"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextValidator
                    label={"Website URL"}
                    value={websiteURL}
                    onChange={(e) =>
                      this.setState({ websiteURL: e.target.value })
                    }
                    name="websiteURL"
                  />
                </div>
                <div className="col-md-6">
                  <TextValidator
                    label={"Tax No"}
                    value={taxNo}
                    onChange={(e) => this.setState({ taxNo: e.target.value })}
                    name="taxNo"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextValidator
                    label={"Contact First Name *"}
                    value={contactFirstName}
                    onChange={(e) =>
                      this.setState({ contactFirstName: e.target.value })
                    }
                    name="contactFirstName"
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  />
                </div>
                <div className="col-md-6">
                  <TextValidator
                    label={"Contact Last Name"}
                    value={contactLastName}
                    onChange={(e) =>
                      this.setState({ contactLastName: e.target.value })
                    }
                    name="contactLastName"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <TextValidator
                    label={"Contact Phone"}
                    value={contactPhone}
                    onChange={(e) =>
                      this.setState({ contactPhone: e.target.value })
                    }
                    name="contactPhone"
                  />
                </div>
                <div className="col-md-6">
                  <TextValidator
                    label={"Contact Email *"}
                    value={contactEmail}
                    onChange={(e) =>
                      this.setState({ contactEmail: e.target.value })
                    }
                    name="contactEmail"
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "This field is required",
                      "Email is Invalid",
                    ]}
                  />
                </div>
              </div>
              <div className="row">
                <div style={{ padding: "0 20px" }}>
                  <div className="form-group">
                    {modifiedarray.length ? (
                      <label className="form-label">Choose Modules</label>
                    ) : null}
                    {modifiedarray.length ? (
                      <div style={{ display: "flex" }}>
                        <div style={{ marginRight: 20 }}>
                          {/* <h3>{<Translate id="hrms.master.mySubscription.myModules" />}</h3> */}
                          <Tree
                            multiple
                            checkable
                            showIcon={false}
                            selectable={false}
                            defaultExpandAll={true}
                            onCheck={this.onCheck}
                            checkedKeys={this.state.checkedKeys}
                          >
                            {treeNodes}
                          </Tree>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <TextAreaValidator
                    label={"Note"}
                    value={notes}
                    onChange={(e) => this.setState({ notes: e.target.value })}
                    name="notes"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {modal && (
                      <React.Fragment>
                        <div
                          className="modal-backdrop fade in"
                          style={{ zIndex: 1040 }}
                        ></div>
                        <div
                          modal-render="true"
                          tabIndex="-1"
                          role="dialog"
                          className="modal fade in"
                          style={{
                            zIndex: 1050,
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                            opacity: 1,
                          }}
                        >
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h3 className="modal-title"></h3>
                              </div>
                              <div className="modal-body">
                                {this.state.using_webcam ? (
                                  <Webcam
                                    ref={this.setRef}
                                    screenshotFormat="image/jpeg"
                                    style={{ height: 300, width: "100%" }}
                                  />
                                ) : (
                                  imagePreviewUrl && (
                                    <Cropper
                                      style={{ height: 400, width: "100%" }}
                                      ref="cropper"
                                      aspectRatio={10 / 8}
                                      src={imagePreviewUrl}
                                    />
                                  )
                                )}
                              </div>
                              <div className="modal-footer">
                                <button
                                  onClick={(state) => this.closeCropper(state)}
                                  className="btn btn-default"
                                >
                                  Close
                                </button>
                                {this.state.using_webcam ? (
                                  <button
                                    onClick={this.capture}
                                    className="btn"
                                  >
                                    Capture
                                  </button>
                                ) : (
                                  <button
                                    onClick={this.uploadImage}
                                    className="btn"
                                  >
                                    Upload
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                    <div style={{ display: "flex" }}>
                      <div style={{ flex: 1 }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flex: 1,
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "100%" }}>
                            <div>
                              <AttachFile
                                label="Company Logo"
                                name="subscriptionLogoFile"
                                accepts=".jpg,.png,.jpeg"
                                value={photoUrl}
                                onChange={this.loadImage}
                                placeholder="Choose file"
                                validators={["required"]}
                                errorMessages={["This field is required"]}
                              />
                            </div>
                            {cropimage || photoUrl ? (
                              <img
                                alt="user"
                                style={{ height: 250, width: "100%" }}
                                src={
                                  cropimage
                                    ? cropimage
                                    : photoUrl
                                    ? photoUrl
                                    : defaultimage
                                }
                              />
                            ) : (
                              <img
                                alt="user"
                                style={{ height: 250, width: "100%" }}
                                src={defaultimage}
                              />
                            )}
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {/* <input name='profilePhoto' type="file" id='profilePhoto' onChange={this.loadImage} style={{ display: 'none' }} /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-md-6">
                  <ReCAPTCHA
                    id="recaptcha"
                    //sitekey="6Lds49AUAAAAAH7BgVWWbOt0lvusN2CbYhSJtrb3" //localhost
                    sitekey="6LcPVvYfAAAAAM04EXYm45afCL8raCPC1S88Sr7j" //dev-ftp
                    //sitekey="6LdloNEUAAAAABtG2gYYY5pwCwYl6eC3y0KpmDj2" //sarvam.sta
                    //sitekey="6LcK6NEUAAAAAPjDdEgubsw9PKXOqNkfW3qlzb9X" //sarvam.firstindo.com
                    onChange={(response) => {
                      this.setState({
                        captchaResponse: response,
                        errorcaptcha: "",
                      });
                    }}
                  />
                  <div style={{ color: "red", fontSize: 12 }}>
                    {this.state.errorcaptcha}
                  </div>
                </div>
              </div>

              <div className="clearfix">
                <div className="column">
                  {
                    <div className="form-group">
                      {/* <button
                        type="button"
                        onClick={() => this.props.history.push("/")}
                        className="btn __cancel"
                      >
                        <span>Back</span>
                      </button> */}
                      <a onClick={() => this.props.history.push("/")} href="">
                        Have an account? Sign In
                      </a>
                    </div>
                  }
                </div>
                <div className="column">
                  <div className="form-group __right">
                    <button
                      id="submit"
                      style={{ display: "none" }}
                      type="submit"
                    ></button>
                    <button
                      style={submitted ? { minWidth: 100 } : {}}
                      disabled={submitted ? true : false}
                      className={submitted ? "btn __loading" : "btn"}
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="clearfix">&nbsp;</div>
            </ValidatorForm>
            {error && <div style={{ color: "red" }}>{error}</div>}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createNotification,
      errorNotification,
      dataChanged,
      storeUser,
      storeMaster,
      storeOrgList,
      setMenu,
    },
    dispatch
  );
const mapStateToProps = (state) => ({
  user: state.user.user,
  routing: state.routing,
});
export default connect(mapStateToProps, mapDispatchToProps)(SignupPage);

import React from 'react';
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'

import store, { history } from './_helpers/helper.store'
import {unregister} from './registerServiceWorker';
import App from './App';

require('bootstrap');
require('bootstrap/dist/css/bootstrap.min.css');
require('font-awesome/css/font-awesome.min.css');
require('./index.css');
require('./Components/Theme/select2.css');
require('./Components/Theme/datepicker.css');
require('cropperjs/dist/cropper.css');
require('./Components/Theme/dash.css');

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>        
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root'));
unregister();

import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, DivisionUID, isActive) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('DivisionUID', DivisionUID);
    formData.append('Type', 'List');
    formData.append('isActive', isActive || true);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);  
    formDataConsole(formData);
    let url = '/api/hrms/master/department/list';

    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
           
            devLog("//department list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const ListDropdown = (user, DivisionUID, isActive) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('DivisionUID', DivisionUID);
    formData.append('Type', 'Dropdown');
    formData.append('IsActive', isActive || true);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);  
    formDataConsole(formData);
    let url = '/api/hrms/master/department/list';

    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
           
            devLog("//department list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (formData, user,DivisionUID) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('DepartmentName').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('DivisionUID', DivisionUID);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formDataConsole(formData);
    let url = '/api/hrms/master/department/add'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// department add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user) => {
    let signature = formData.get('UID').toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/hrms/master/department/update'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// department update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Detail = (DataUID, user) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + DataUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('DataUID', DataUID);

    let url = '/api/hrms/master/department/detail';

    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// department detail response", res)
            return res;
        })
        .catch(checkAPIError);
}



// import Signature from "../../Utils/Signature";
// import Const from "../../Utils/Const";
import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError, GetApi } from "../../_helpers/helper.methods";
import { setIP } from '../../_reducers/reducer.language';
import store from '../../_helpers/helper.store';
//var ip = require("ip");

export const Email = (formData) => {
    return GetApi().then(function (result) {
        store.dispatch(setIP(result));
        formData.append('DeviceIP', result);
        formDataConsole(formData);
        return fetch(DEV_URL.concat("/api/login/email"), {
            method: "POST",
            body: formData
        })
            .then((res) => res.json())
            .then((res) => {
                devLog("// email response", res)
                return res;
            })
            .catch(checkAPIError);
    })

}

export const Password = (state, formData) => {
    let { language: { ipaddress } } = store.getState();
    let signature = state.UserId.toLowerCase() + formData.get('UserPassword') + state.res.token;
    devLog("raw sig", signature);
    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', state.res.subscription);
    formData.append("UserID", state.UserId);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);

    return fetch(DEV_URL.concat("/api/login/password"), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// password response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Remove = (user) => {
    let { language: { ipaddress } } = store.getState();
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);

    return fetch(DEV_URL.concat("/api/login/remove"), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// remove response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const changePassword = (user, formData) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);

    return fetch(DEV_URL.concat("/api/login/change"), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer ' + user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// change password response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Forget = (user, formData) => {
    return GetApi().then(function (result) {
        store.dispatch(setIP(result));
        let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
        devLog("raw sig", signature);

        signature = getSignature(signature);
        formData.append('empUID', user.empUID);
        formData.append('Signature', signature);
        formData.append('Subscription', user.subscription);
        formData.append("ActionBy", user.empUID);
        formData.append('DeviceIP', result);

        formDataConsole(formData);

        return fetch(DEV_URL.concat("/api/login/forget"), {
            method: "POST",
            body: formData
        })
            .then((res) => res.json())
            .then((res) => {
                devLog("// forget response", res)
                return res;
            })
            .catch(checkAPIError);
    })
}

export const Signup = (Requestor, Company, Email, Phone, Note) => {
    let formData = new FormData();
    formData.append('Requestor', Requestor);
    formData.append('Company', Company);
    formData.append("Email", Email);
    formData.append('Phone', Phone);
    formData.append('Note', Note);

    formDataConsole(formData);
    devLog('url', '/api/signup/send');
    return fetch(DEV_URL.concat("/api/signup/send"), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// signup response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const AccountSignup = (formData,) => {
    // let formData = new FormData();
    let { language: { ipaddress } } = store.getState();    
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);
    devLog('url', '/api/signup/account/new');
    return fetch(DEV_URL.concat("/api/signup/account/new"), {
        method: "POST",
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// signup response", res)
            return res;
        })
        .catch(checkAPIError);
}
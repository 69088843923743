export const SET_MENU = "menu/menu";
export const SET_subMenu = "menu/subMenu";

const initialState = {
  menu: [],
  subMenu: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MENU:
      return {
        ...state,
        menu: action.menu,
      };
    case SET_subMenu:
      return {
        ...state,
        subMenu: action.subMenu,
      };
    default:
      return state;
  }
};

export const setMenu = (res) => {
  return (dispatch) => {
    dispatch({
      type: SET_MENU,
      menu: res,
    });
  };
};

export const setsubMenu = (res) => {
  return (dispatch) => {
    dispatch({
      type: SET_subMenu,
      subMenu: res,
    });
  };
};

export function getMenu() {
  let menulist = [];
  return menulist;
}

export function getBreadcrumbs() {
  return {
    home: "Home",
    hrms: "Hrms",
    allemployee: "All Employee",
    menu: "Employee Info",
    basicinfo: "Basic",
    contactdetails: "Contact",
    educationdetails: "Education",
    edit: "Update",
    add: "Add",
    workhistory: "Work History",
    current: "Current Company",
    view: "View",
    previous: "Previous Company",
    familydetails: "Family",
    employeeadd: "New Employee",
    myinfo: "My Info",
    orgchart: "Organization Chart",
    CTCInfo: "CTC Info",
    ctcinfo: "CTC Info",
    leave: "Leave",
    leavesettings: "Leave Settings",
    adjustment: "Leave Adjustment",
    myleaveinfo: "My Leave Info",
    details: "Details",
    leaverequest: "My Leave Info",
    leaveapprove: "Approve",
    leavedetails: "Leave Request Detail",
    holidaycalendar: "Holiday Calendar",
    mycompanychart: "My Company Chart",
    master: "Master",
    holiday: "Holiday",
    city: "City",
    province: "Province",
    idcard: "ID Card Type",
    country: "Country",
    employeestatus: "Employee Status",
    emailtemplate: "Email Template",
    orgunit: "Organization Unit",
    division: "Division",
    position: "Position",
    absence: "Absence Management",
    myrole: "My Role",
    roles: "Roles",
    positionlist: "List",
    shift: "Shift",
    idcardinfo: "Id Card",
    idcardInfo: "Id Card",
    mypresence: "My Presence",
    checkin: "Checkin",
    checkout: "Checkout",
    Checkout: "Checkout",
    CalendarView: "Calendar View",
    calendarview: "Calendar View",
    allpresence: "All Presence",
    mystaff: "My Staff Presence",
    Mystaff: "My Staff Presence",
    orgnizationlist: "Orgnization",
    employeelist: "All Employee Presence",
    approveadjustment: "Approve Adjustment",
    ApproveAdjustment: "Approve Adjustment",
    checkoutapproved: "Checkout Approve",
    checkinapproved: "Checkin Approve",
    CheckinApproved: "Checkin Approve",
    swapdayapproved: "Swap Day Approve",
    shiftmanage: "Shift Management",
    existshift: "Shift Details",
    allotshift: "Allot Shift",
    securitymanage: "Security Management",
    modulelist: "Module List",
    rolelist: "Role List",
    rms: "RMS",
    RMS: "RMS",
    jobposition: "Job Position",
    JobPosition: "Job Position",
    dashboard: "Dashboard",
    newsdetails: "NewsDetails",
    quicklinks: "QuickLinks",
    news: "News",
    candidates: "Candidates",
    applied: "Applied",
    shortlisted: "Shortlisted",
    interview: "Interview",
    interviewresult: "Interview Result",
    selected: "Selected",
    onhold: "On Hold",
    rejected: "Rejected",
    profileupload: "Profile Upload",
    profile: "Profile",
    candidateeducation: "Education",
    candidatecertificate: "Certificate",
    candidateexperience: "Experence",
    candidatereferee: "Referee",
    vote: "Vote",
    voterremarks: "Voter Remarks",
    scheduleinterview: "Schedule Interview",
    onholdremark: "On Hold Remark",
    rejectremark: "Reject Remark",
    interviewfeedback: "Interview Feedback",
    viewinterviewfeedback: "View Interview Feedback",
    viewfeedback: "View Feedback",
    feedback: "Feedback",
    rollback: "Rollback",
    movejobposition: "Move Job Position",
    shortlistremark: "Shortlist Remark",
    finalresult: "Final Result",
    industry: "Industry",
    jobfunction: "Job Function",
    interviewduration: "Interview Duration",
    yearsofworkexp: "Years Of Work Experience",
    mysubscription: "My Subscription",
    candidateprofile: "Profile",
    showlocation: "Show Location",
    knowledgebase: "Knowledge Base",
    knowledgebaselist: "List",
    organizationchart: "Organization Chart",
    allemporgunit: "Organization Unit",
    locationlist: "Location List",
    organizationdashboard: "Organization Dashboard",
    reports: "Reports",
    employmentstatus: "Employment Status",
    subdepartment: "Sub Department",
    departmentlist: "Department List",
    subdepartmentlist: "Sub Department List",
    department: "Department",
    location: "Location",
    report: "Report",
    exportimport: "Export / Import",
    leaveperiod: "Leave Period",
    LeavePeriod: "Leave Period",
    termination: "Termination",
    discipline: "Disciplinary Actions",
    award: "Awards",
    person: "Award and Discipline Management",
    awardlist: "Award List",
    awarddetail: "Award Detail",
    awardemployeelist: "Employee List",
    disciplineemployeelist: "Employee List",
    terminationemployeelist: "Employee List",
    disciplinelist: "Discipline List",
    disciplinedetail: "Discipline Detail",
    terminationlist: "Termination List",
    terminationdetail: "Termination Detail",
    validloc: "Assigned Location",
    employeebasic: "Employee Basic",
    basic: "Basic",
    otherinfo: "Other Info",
    attendancedashboard: "Attendance Dashboard",
    leavedashboard: "Leave Dashborad",
    update: "Update",
    ws: "Workflow Approval",
    list: "List",
    acttype: "Action Type",
    existsh: "Shift List",
    epms: "EPMS",
    modsetup: "Module Setup",
    okr: "OKR",
    companyokr: "Company OKR",
    createcompanyokr: "Create Company OKR",
    editcompanyokr: "Edit Company OKR",
    viewcompanyokr: "View Company OKR",
    divisionokr: "Division OKR",
    createdivisionokr: "Create Division OKR",
    viewdivisionokr: "View Division OKR",
    departmentokr: "Department OKR",
    createdepartmentokr: "Create Department OKR",
    viewdepartmentokr: "View Department OKR",
    subdepartmentokr: "Sub Department OKR",
    createsubdepartmentokr: "Create Sub Department OKR",
    viewsubdepartmentokr: "View SUb Department OKR",
    divisionlist: "Division List",
    editdivisionokr: "Edit Division OKR",
    editdepartmentokr: "Edit Department OKR",
    editsubdepartmentokr: "Edit Sub Department OKR",
    myteamsokr: "My Teams OKR",
    createmyteam: "Create My Team",
    mypersonalokr: "My Personal OKR",
    allteamsokr: "All Teams OKR",
    renew: "Renew Module Setup",
    viewmember: "Team Members",
    task: "Task Management",
    pem: "PEM",
    ownerlist: "Owner List",
    createmypersonaltokr: "Create My Personal Team OKR",
    mypersonaltasklist: "My Personal Task",
    createweeklytask: "Create Weekly Task",
    perdsetup: "Period Setup",
    newperdsetup: "New Period Setup",
    editperdsetup: "Edit Period Setup",
    reviewtype: "Review Type",
    nreviewtype: "Review Type",
    newreview: "New Questioner Setup",
    orgunitokr: "Organization Unit OKR",
    orgunitokrlist: "List",
    addokr: "Add Objective OKR",
    editokr: "Edit Objective OKR",
    selectlinkedokr: "Select Linked OKR",
    selectlinkedkeyokr: "Select Linked Key OKR",
    addkeyokr: "Add Key Result OKR",
    editkeyokr: "Edit Key Result OKR",
    viewprogressokr: "View Progress OKR",
    updateprogressokr: "Update Progress OKR",
    teamsetup: "Team Setup",
    addteam: "Create New Team",
    editteam: "Edit Team",
    addmember: "Add Team Member",
    editmember: "Edit Team Member",
    staff: "Staff",
    team: "Team",
    mytask: "Personal",
    tasklist: "List Tasks",
    createtask: "Create Task",
    edittask: "Edit Task",
    edittaskdetail: "Edit Task Detail",
    addtaskdetail: "Add Task Detail",
    viewprogresstask: "View Task Progress",
    updatestatus: "Update Task Status",
    EmployeeList: "Employee List",
    teamlist: "Team List",
    teammember: "Team Member",
    stafflist: "Staff List",
    addmassadj: "Add Mass Adjustment",
    questionlist: "List Questions",
    addquestion: "Add Question",
    editquestion: "Edit Question",
    revpersetup: "Review",
    reviewlist: "Review List",
    createreview: "Create Period Review",
    editreview: "Edit Period Review",
    assignreviewer: "Assign Reviewer",
    assignreviewee: "Assign Reviewee",
    setquestion: "Set Question",
    editsetquestion: "Set Question",
    detailobjective: "Detail Objective",
    reviewerlist: "Reviewer List",
    revieweelist: "Reviewee List",
    reviewquestion: "Review Question",
    reviewanswer: "Review Answer",
    review: "Review",
    analysis: "Analysis",
    Reports: "Reports",
    movetoemployee: "Move to Employee",
    employed: "Employed",
    persondocs: "Personal Documents",
    manage: "Manage",
    payslip: "Payslip",
    incometax: "Income Tax",
    massleave: "Mass Leave",
    period: "Period",
    reimbursement: "Reimbursement",
    mylist: "My Reimbursement",
    prepare: "Prepare",
    approve: "Approve",
    validrole: "Valid Role",
    lineadd: "Add Line",
    lineupdate: "Edit Line",
    security: "Security",
    submodule: "Sub Module",
    coreaccount: "Core Account",
    coreaccountlist: "Core Account List",
    subscriptionlist: "Subscription List",
    submodulelist: "Sub Module List",
    sectiongroup: "Section and Group",
    createsection: "Create Section",
    editsection: "Edit Section",
    creategroup: "Create Group",
    editgroup: "Edit Group",
    grouplist: "Group List",
    eval: "My Evaluation",
    answerlist: "Answer List",
    copyquestionaire: "Copy Questionaire",
  };
}

var encr = require("crypto-js");
var moment = require("moment");

export const DEV_URL = process.env.REACT_APP_API_URL; //'https://dev-ftp.azurewebsites.net/sarvamapi/';
export const DEV_LOG = process.env.REACT_APP_NODE_ENV;
export const SESS_STORAGE_KEY = "@sarvam:data";
export const WHITE_TEXT_COLOR = ["red", "green", "blue", "orange"];

export const formDataConsole = (formData) => {
  if (process.env.NODE_ENV === "development") {
    devLog("// API Request Body Data");
    for (var pair of formData.entries()) {
      devLog(pair[0] + " : " + pair[1]);
    }
    devLog("// End Request");
  }
};

export const GetApi = async () => {
  let url =
    "https://sarvamlogtrigger.azurewebsites.net/api/GetRemoteIPAddress?format=json";
  devLog("url", url);
  var response = await fetch(url);
  var data = await response.json();

  return data.ip;
};

export const checkAPIError = (err) => {
  return { status: "1", message: err.message };
};
export const devLog = (...msgs) => {
  if (DEV_LOG === "development") console.log(...msgs);
};

export const showLastBlade = () => {
  setTimeout(() => {
    animatethis();
  }, 200);
  function animatethis() {
    var elem = document.getElementById("viewcontainer");
    if (elem) {
      var scrollWidth = elem.scrollWidth;
      var clientWidth = elem.clientWidth;
      var end = scrollWidth - clientWidth - 90;
      var pos = end;
      if (elem !== null && end !== null) {
        var id = setInterval(frame, 2);
      }
      function frame() {
        if (pos >= end) {
          elem.scrollLeft = end;
          clearInterval(id);
        } else {
          pos += 1;
          elem.scrollLeft = pos;
        }
      }
    }
  }
};

export const getSignature = (sig) => {
  var wordArray = encr.SHA1(sig);
  var words = wordArray.words;
  var sigBytes = wordArray.sigBytes;
  var hexChars = [];
  for (var i = 0; i < sigBytes; i++) {
    var bite = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff;
    hexChars.push((bite >>> 4).toString(16));
    hexChars.push((bite & 0x0f).toString(16));
  }
  return hexChars.join("").toUpperCase();
};

export const getDropdownUID = (list, value) => {
  let array = list || [];
  array = array.find((item) => item.text === value);
  if (array) {
    return array.id;
  } else {
    return value;
  }
};

export const getCalenderView = (cdate, sys) => {
  if (sys) {
    if (cdate && cdate.length === 10) {
      return `${cdate.slice(6)}/${cdate.slice(3, 5)}/${cdate.slice(0, 2)}`;
    } else {
      return cdate;
    }
  } else {
    if (cdate && cdate.length === 10) {
      return `${cdate.slice(6)}/${cdate.slice(3, 5)}/${cdate.slice(0, 2)}`;
    } else {
      return moment().format("YYYY/MM/DD");
    }
  }
};

export const formatDate = (date) => {
  if (date) {
    let fdate = new Date(date);
    let y = fdate.getFullYear();
    let m = fdate.getMonth() + 1;
    m = m <= 9 ? "0" + m : m;
    let d = fdate.getDate() <= 9 ? "0" + fdate.getDate() : fdate.getDate();
    let ndate = d + "/" + m + "/" + y;
    return ndate;
  } else {
    return "";
  }
};

export const handleFormErrors = (errors) => {
  let err = errors[0];
  if (err.Select2) {
    err.Select2.el.focus();
  } else if (err.input) {
    err.input.focus();
  } else if (err.textarea) {
    err.textarea.focus();
  }
};

import React, { Component } from "react";

import Blade from "../../../Components/Blade";
import BladeBody from "../../../Components/Blade/BladeBody";

import TableList from "../../../Components/Lists/TableList";
import Pagination from '../../../Components/Pagination';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createNotification } from "../../../_reducers/reducer.notification";
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../_language/en.json';
import id from '../../../_language/id.json';

//methods
import { showLastBlade } from "../../../_helpers/helper.methods";
//api
import API from "../../../_apis/hrms/master";
//import APIMaster from '../../../_apis/Core';

class Orgunit extends Component {
    async componentDidMount() {
        // let url = '../../../../../';
        let url = '../../../../../';
        let res = await API.OrgUnit.List(this.props.user, url, true);
        this.setState({ load: false })
        if (res.status !== '1') {
            let selectedOrgUnit = this.props.selectedOrgUnit;
            let foundIndex = res.findIndex((e) => e.uid === selectedOrgUnit);
            this.setState({
                orgUnitList: res,
                selectedIndex: foundIndex,
            }, () => {
            });
        } else {
            this.props.createNotification(res.message);
        }
        this.setState({ load: false })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.history.location.pathname === '/home/hrms/allemporgunit') {
            if (this.state.orgUnitList !== null && this.state.orgUnitList.length !== 0) {
                if (this.state.selectedIndex !== null && this.state.selectedIndex > -1) {
                    this.selectedData(this.state.orgUnitList[this.state.selectedIndex]);
                }
            }
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            pageOfItems: [],
            toolbar: [],
            protectedToolbar: [{
                icon: '/images/add_black.png',
                name: <Translate id="hrms.allEmployee.add" />,
                value: "add"
            }],
            dataLoad: '',
            subtitle: '',
            load: false,
            list: [],
            orgUnitList: [],
            menu: this.props.history.location.state.menu,
        }
    }
    selectedData = (item, index) => {
        let { match } = this.props;
        // this.setState({ index })
        this.props.history.push({
            pathname: `${match.path}/allemployee`,
            state: {
                selectedOrgUnitUID: item.uid,
                menu: this.state.menu,
                item
            }
        });
        showLastBlade();
    }
    close = () => {
        let { history, match } = this.props;
        if (match.path.includes('positionlist')) {
            history.push('/home/hrms/master/position')
        } else {
            history.push('/home')
        }

    }
    toolbarclick = (e) => {
        let { match, history } = this.props;
        if (e === "add") {
            let elem = document.getElementById(`list${'positionlist'}${this.state.index}`);
            if (elem) {
                elem.classList.remove('__selected');
            }
            this.setState({
                dataLoad: 'positionlist/'
            }, () => {
                history.push({
                    pathname: `${match.path}/add`,
                    state: {
                        orguid: this.state.org,
                        list: this.state.list,

                    }
                });
            })
        }
        showLastBlade();
    }
    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems: pageOfItems });
    }

    render() {
        //let { master } = this.props;
        let { toolbar, pageOfItems, orgUnitList, selectedIndex } = this.state;

        //let { match } = this.props;
        return (
            // Hide organisation unit
            <div></div>

            // Organisation unit blade

            /*    <Blade match={this.props.match}
                    max={false}
                    load={this.state.load}
                    close={() => this.props.history.push("/home")}
                    icon={this.state.icon || '/images/allemployee.png'}
                    head={<Translate id="hrms.allEmployee.allEmployee" />}
                    subhead={this.state.subtitle}
                    toolbar={toolbar}
                    handleclick={this.toolbarclick}>
                    <BladeBody match={this.props.match} load={this.state.load}>
                        <React.Fragment>
                            <Pagination
                                mainFilter={{ jsonName: 'orgUnitName', disName: 'Org Unit' }}
                                filters={[{ jsonName: 'orgUnitName', disName: 'Org Unit' }]}
                                //items={master.modulemaster.orgUnitList}
                                items={orgUnitList}
                                onChangePage={(e) => { this.onChangePage(e) }}
                                onRef={(onSort) => { this.onSort = onSort }} />
    
                            <TableList
                                id='position'
                                returnSelected={this.selectedData}
                                fields={['orgUnitName']}
                                list={pageOfItems || []}
                                header={[<Translate id="hrms.allEmployee.organisationUnit" />]}
                                onHeaderClick={this.onSort}>
                            </TableList>
                        </React.Fragment>
                    </BladeBody>
                </Blade> */
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    dataChanged: state.notification.dataChanged,
    master: state.user.master,
    selectedOrgUnit: state.user.selectedOrgUnit,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(Orgunit))

import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";
//custom components
import TextValidator from "../../../../../Components/Validators/TextValidator";
import { ValidatorForm } from 'react-form-validator-core';
import SelectValidator from "../../../../../Components/Validators/SelectValidator";
import DatetimePicker from "../../../../../Components/DateTimePicker/DatePicker";

//methods
import { formatDate, getCalenderView, handleFormErrors } from '../../../../../_helpers/helper.methods';

//api
import API from "../../../../../_apis/hrms/employee";
import moment from "moment";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createNotification, dataChanged } from '../../../../../_reducers/reducer.notification';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';

class MyEducationDetails extends Component {

    componentDidUpdate(prevProps, prevState) {
        let { history: { location } } = this.props;
        if (prevProps !== this.props) {
            if (location.pathname.includes("edit")) {
                this.setState({
                    subtitle: location.state.item.schoolName,
                    educationDetails: {
                        ...location.state.item,
                        startDate: formatDate(location.state.item.startDate || ''),
                        endDate: formatDate(location.state.item.endDate || '')
    
                    }
                })
            }
        }        
    }
    getinitialState = () => {
        return {
            schoolName: '',
            degree: '',
            startDate: '',
            endDate: '',
            fieldStudy: '',
            grade: '',
            attachFiles: ''
        }
    }
    componentDidMount() {
        let { history, match } = this.props;
        if (match.path.includes("edit")) {
            this.setState({
                subtitle: history.location.state.item.schoolName,
                educationDetails: {
                    ...history.location.state.item,
                    startDate: formatDate(history.location.state.item.startDate || ''),
                    endDate: formatDate(history.location.state.item.endDate || '')
                }
            })
        }
    }

    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en"); 
        this.state = {
            subtitle:<Translate id="hrms.employeeInfo.myInformation.myEducationDetails" />,
            load: false,
            educationDetails: this.getinitialState()
        }
    }
    handleChange = (event) => {
        let educationDetails = this.state.educationDetails;
        if (event.target.type === 'file') {
            let reader = new FileReader();
            let name = event.target.name;
            let file = event.target.files[0];
            reader.onloadend = () => {
                this.setState({
                    educationDetails: {
                        ...educationDetails,
                        file: file,
                        [name]: file.name,
                        imagePreviewUrl: reader.result
                    }
                });
            }

            reader.readAsDataURL(file)

        } else if (event.target.type === "checkbox") {
            this.setState({
                educationDetails: {
                    ...educationDetails,
                    [event.target.name]: !educationDetails[event.target.name]
                }
            })
        } else {
            this.setState({
                educationDetails: {
                    ...educationDetails,
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    handleCalendar = (moment, name) => {
        let educationDetails = this.state.educationDetails;
        if (typeof moment !== "string") {
            this.setState({
                educationDetails: {
                    ...educationDetails,
                    [name]: moment.format("DD/MM/YYYY")
                }
            })
        } else {
            this.setState({
                educationDetails: {
                    ...educationDetails,
                    [name]: moment
                }
            })
        }
    }
    handleSubmit = async (e) => {
        let formData = new FormData(e.target);
        formData.set('startDate', getCalenderView(this.state.educationDetails.startDate, 'server'));
        formData.set('endDate', getCalenderView(this.state.educationDetails.endDate, 'server'));

        let { match, history: { location: { state } } } = this.props;
        this.setState({ load: true })
        if (match.path.includes('add')) {
            let url = '../../../../../../';
            if (match.path.includes("allemployee")) {
                let res = await API.Education.Add(formData, this.props.user, url, state.item);
                this.setState({ load: false })
                if (res.status === "0") {
                    this.props.dataChanged();
                    this.close();
                    this.props.createNotification('Success', res.message);
                } else {
                    this.props.createNotification('Operation Failed', res.message);
                }
            } else {
                let res = await API.Education.Add(formData, this.props.user, url);
                this.setState({ load: false })
                if (res.status === "0") {
                    this.props.dataChanged();
                    this.close();
                    this.props.createNotification('Success', res.message);
                } else {
                    this.props.createNotification('Operation Failed', res.message);
                }
            }

        } else {
            let url = "../../../../../";
            let res = await API.Education.Update(formData, this.props.user, url, this.state.educationDetails);
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.props.createNotification('Success', res.message);
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }
        }
    }
    deleteAPI = async () => {
        this.setState({ load: true })
        let { educationUID } = this.state.educationDetails;
        let url = '../../../../../';
        let res = await API.Education.Delete(this.props.user, educationUID, url)
        this.setState({ load: false })
        if (res.status === "0") {
            this.props.dataChanged();
            this.props.createNotification('Success', res.message);
            this.close();
        } else {
            this.props.createNotification('Operation Failed', res.message);
        }
    }
    reset = () => {
        let { history: { location: { state } }, match } = this.props;
        if (match.path.includes('edit')) {
            this.setState({
                educationDetails: {
                    ...state.item,
                    startDate: formatDate(state.item.startDate),
                    endDate: formatDate(state.item.endDate)
                }
            });
        } else if (match.path.includes('add')) {
            this.setState({ educationDetails: this.getinitialState() });
        }
    }
    toolbarclick = (e) => {
        if (e === "save") {
            document.getElementById("submit").click();
        } else if (e === 'delete') {
            this.deleteAPI();
        } else if (e === 'reset') {
            this.reset();
        }
    }
    close = () => {
        let { match, history } = this.props;
        if (match.path.includes("/hrms/myinfo")) {
            history.push("/home/hrms/myinfo/educationdetails")
        } else if (match.path.includes("allemployee/menu/educationdetails")) {
            history.push("/home/hrms/allemporgunit/allemployee/menu/educationdetails")
        } else {
            history.push("/home");
        }
    }
    render() {
        let defaultimage = "https://www.1plusx.com/app/mu-plugins/all-in-one-seo-pack-pro/images/default-user-image.png";
        let { educationDetails } = this.state;
        let toolbar = [{
            icon: '/images/save_black.png',
            name: <Translate id="hrms.employeeInfo.myInformation.save" />,
            value: "save"
        }, {
            icon: '/images/reset_black.png',
            name: "Reset",
            value: "reset"
        }];
        let { match } = this.props;
        if (match.path.includes("edit")) {
            toolbar.push({
                icon: '/images/trash_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.delete" />,
                value: "delete"
            })
        }
        return (
            <Blade match={this.props.match}
                max={false}
                close={this.close}
                load={this.state.load}
                icon={this.state.icon || '/images/education.png'}
                head={<Translate id="hrms.employeeInfo.myInformation.myEducationDetails" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={this.state.load}>

                    {Inputs(this.props.master, educationDetails, this.handleSubmit, this.handleChange, this.handleCalendar,defaultimage)}

                </BladeBody>
            </Blade >
        )
    }
}

const Inputs = (master, educationDetails, handleSubmit, handleChange, handleCalendar,defaultimage) => (
    <ValidatorForm
        ref="form"
        onError={handleFormErrors}
        onSubmit={handleSubmit}
    >

        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.school" />}
            onChange={handleChange}
            name="schoolName"
            validators={['required']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
            value={educationDetails.schoolName || ''}
        >
        </TextValidator>

        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.degree" />}
            onChange={handleChange}
            name="degree"
            value={educationDetails.degree || ''}
            data={master.educationDegreeList}
            options={{ placeholder: "Choose one" }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
        />

        <DatetimePicker
            closeOnSelect
            name='startDate'
            value={educationDetails.startDate || ''}
            viewDate={getCalenderView(educationDetails.startDate)}
            onChange={(m) => handleCalendar(m, "startDate")}
            inputProps={{
                placeholder: 'DD/MM/YYYY',
                label: <Translate id="hrms.employeeInfo.myInformation.from" />
            }}
            validators={['matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidDate" />]}
            dateFormat="DD/MM/YYYY"
            timeFormat={false} />

        <DatetimePicker
            closeOnSelect
            name='endDate'
            isValidDate={(current) => { return current.isAfter(moment(educationDetails.startDate, 'DD/MM/YYYY')) }}
            value={educationDetails.endDate || ''}
            viewDate={getCalenderView(educationDetails.endDate)}
            onChange={(m) => handleCalendar(m, "endDate")}
            inputProps={{
                placeholder: 'DD/MM/YYYY',
                label: <Translate id="hrms.employeeInfo.myInformation.to" />
            }}
            validators={['matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidDate" />]}
            dateFormat="DD/MM/YYYY"
            timeFormat={false} />

        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.fieldOfStudy" />}
            onChange={handleChange}
            name="fieldStudy"
            value={educationDetails.fieldStudy || ''}
            validators={['required']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.grade" />}
            onChange={handleChange}
            name="grade"
            value={educationDetails.grade || ''}
        />
        {/* <AttachFile
            label='Attach File'
            id='degreefilename'
            value={educationDetails.degreefilename || ''}
            onChange={handleChange}
            placeholder='Choose one file'
        /> */}
        <div className="form-group">
            <button id="submit" style={{ display: "none" }} type="submit"></button>
        </div>
    </ValidatorForm>
);

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    dataChanged
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(MyEducationDetails))
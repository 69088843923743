import { devLog, formDataConsole, getSignature, DEV_URL, checkAPIError } from "../../_helpers/helper.methods";
import store from '../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, uid, lastFetchDate) => {
    let { language: { ipaddress } } = store.getState();
    let formData = new FormData();
    let listName = 'color,HolidayList,employeestatus,orgunit,division,WorkReasonType,CoreModule,City,Country,Years of Work Exp,Job Function,Industry,ID Card Type,rmscandidatefields,gender,marital,salutation,CreditFrequency,Timezone,Download APK,Relation,EducationDegree,Religion,BloodType,Province,LocationType,AwardType,DisciplinaryType,ActionType,TerminationType,Personal Docs,Reimbursement Type,currency';
    if(!user) {
        user = JSON.parse(localStorage.getItem("user"))
    }
    let signature;

    if (uid) {
        signature = user.subscription.toLowerCase() + listName.toLowerCase() + user.empUID.toLowerCase() + user.token;
    } else {
        signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    }

    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('lastfetchdate', lastFetchDate);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ListName', listName);
    formData.append('IsActive', true);
    formDataConsole(formData);
    let url;
    //let directory = '../../../';
    if (uid) {
        url = '/api/hrms/master/list';
    } else {
        url = '/api/core/master/list';
    }
    devLog('url', url)
    //return fetch(directory.concat(DEV_URL, url), {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            if(res.status!=='1')
            {
                localStorage.setItem("coremaster",JSON.stringify(res));
            }            
            devLog("// master list response", res)
            return res;
        })
        .catch(checkAPIError);    
}

export const Add = (user, formData) => {
    //let formData = new FormData();   
    let { language: { ipaddress } } = store.getState(); 
    let signature = user.subscription.toLowerCase() + formData.get('ListName').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formDataConsole(formData);
    let url;
    //let directory = '../../../';
    url = '/api/hrms/master/add';
    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// master add list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (user, formData) => {
    //let formData = new FormData();  
    let { language: { ipaddress } } = store.getState();  
    let signature = user.subscription.toLowerCase() + formData.get('ListName').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formDataConsole(formData);
    let url;
    //let directory = '../../../';
    url = '/api/hrms/master/update';
    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// master update list response", res)
            return res;
        })
        .catch(checkAPIError);
}
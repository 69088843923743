import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, directory,isActive) => {
    let { language: { ipaddress } } = store.getState();
    let formData = new FormData();
    if(!user) {
        user = JSON.parse(localStorage.getItem("user"))
    }
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('IsActive', isActive || true);
    formData.append('Type', 'List');

    formDataConsole(formData);
    let url = '/api/hrms/master/orgunit/list';
    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//OrgUnit List response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (formData, user, directory) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('orgUnitName').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);
    let url = '/api/hrms/master/orgunit/add';
    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//OrgUnit add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user, directory) => {
    let signature = formData.get('uid').toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/hrms/master/orgunit/update';
    return fetch(DEV_URL.concat(url), {
        //return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// org update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Details = (formData, user, directory) => {
    let signature = user.subscription.toLowerCase() + formData.get('DataUID').toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/hrms/master/orgunit/detail';
    return fetch(DEV_URL.concat(url), {
        //return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// org update response", res)
            return res;
        })
        .catch(checkAPIError);
}

import React from 'react';
import { withTheme } from 'theming'

var _ = require('lodash');
const defaultProps = {
    initialPage: 1
}

class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainFilter: '',
            filters: {},
            filternames: [],
            opened: false,
            pager: {},
            pageno: 10
        };
    }

    componentDidMount() {

        // set page if items array isn't empty
        this.props.onRef(this.onSort);
        let filters = {};
        if (this.props.filters) {
            this.props.filters.map((filt) => {
                filters[filt.jsonName] = '';
                return null;
            })
        }
        this.setState({
            items: [...this.props.items],
            filters,
            filternames: this.props.filters || []
        }, () => {
            if (this.props.items && this.props.items.length) {
                this.setPage(this.props.initialPage);
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        // reset page if items array has changed
        if (this.props.items !== prevProps.items) {
            this.setState({
                items: [...this.props.items],
                pager: {}
            }
                , () => {
                    this.setPage(this.props.initialPage);
                })
        }
    }

    setPage(page) {
        var items = this.state.items;
        var pager = this.state.pager;
        var pageno = this.state.pageno;

        if (page < 1 || page > pager.totalPages) {
            return;
        }

        // get new pager object for specified page
        pager = this.getPager(items.length, page, pageno);

        // get new page of items from items array
        var pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

        // update state
        this.setState({
            pager: pager,
        });

        // call change page function in parent component
        this.props.onChangePage(pageOfItems);
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 10;

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages = _.range(startPage, endPage + 1);

        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
    change = (event) => {
        this.setState({
            pageno: parseInt(event.target.value,10)
        }, () => {
            this.setPage(1);
        })
    }
    toggleClass = (e) => {

        let filters = {};
        this.state.filternames.map((item) => {
            filters[item.jsonName] = '';
            return null;
        })
        this.setState({
            opened: !this.state.opened,
            filters
        }, () => {
            let event = {
                target: {
                    name: this.props.mainFilter.jsonName,
                    value: ''
                }
            }
            this.handleChange(event, true)
        })
    }
    handleChange = (e, main) => {
        let { name, value } = e.target;
        if (this.props.mainFilter.jsonName === name && main) {
            this.setState({
                mainFilter: value
            }, () => {

                let filteredItems = this.props.items.filter((data) => {
                    let filterdata = true;

                    this.state.filternames.map((item) => {
                        if (data[item.jsonName] && !data[item.jsonName].toString().toLowerCase().includes(this.state.filters[item.jsonName].toString().toLowerCase())) {
                            filterdata = false;
                        }
                        return null;
                    })
                    if (filterdata) {
                        return true
                    }
                    return false;
                })
                filteredItems = filteredItems.filter((rec) =>
                    rec[this.props.mainFilter.jsonName].toString().toLowerCase().includes(this.state.mainFilter.toString().toLowerCase())
                )
                this.setState({
                    pager: {},
                    items: filteredItems,
                }, () => {
                    this.setPage(1);
                })
            })

        } else {
            this.setState({
                filters: {
                    ...this.state.filters,
                    [name]: value
                }
            }, () => {

                let filteredItems = this.props.items.filter((data) => {
                    let filterdata = true;

                    this.state.filternames.map((item) => {
                        if (data[item.jsonName] && !data[item.jsonName].toString().toLowerCase().includes(this.state.filters[item.jsonName].toString().toLowerCase())) {
                            filterdata = false;
                        }
                        return null;
                    })
                    if (filterdata) {
                        return true
                    }
                    return false;
                })


                filteredItems = filteredItems.filter((rec) =>
                    rec[this.props.mainFilter.jsonName].toString().toLowerCase().includes(this.state.mainFilter.toString().toLowerCase())
                )
                this.setState({
                    pager: {},
                    items: filteredItems,
                }, () => {
                    this.setPage(1);
                })
            })

        }
    }

    clearFilter = (e, main) => {
        let { title } = e.target;
        let event = {
            target: {
                name: title,
                value: ''
            }
        }
        if (main) {
            this.handleChange(event, true);
        } else {
            this.handleChange(event);
        }

    }
    checkTheme = () => {
        let { theme } = this.props;
        if (theme.pagination.color === "white") {
            return '/images/advancesearch.png';
        } else {
            return '/images/advancesearch_black.png';
        }
    }

    onSort = (headerName) => {
        var headerField = headerName.toString().split('-');
        var pageOfItems = this.state.items.sort(function (a, b) {
            var aval = a[headerField[0]];
            var bval = b[headerField[0]];
            if (aval === null) {
                aval = '0';
            }
            if (bval === null) {
                bval = '0';
            }
            if (aval.toString().toLowerCase() < bval.toString().toLowerCase()) {
                return -1;
            } else if (aval.toString().toLowerCase() > bval.toString().toLowerCase()) {
                return 1;
            } else {
                return 0;
            }
        });
        if (headerField[1] === "desc") {
            pageOfItems.reverse();
        }
        //var pageOfItems = this.state.items.sort((a, b) => (a[headerName].toString().toLowerCase() < b[headerName].toString().toLowerCase()) ? -1 : ((b[headerName].toString().toLowerCase() > a[headerName].toString().toLowerCase()) ? 1 : 0));
        this.setState({
            items: pageOfItems,
            pager: {}
        }, () => {
            this.setPage(this.props.initialPage);
        })
    }
    render() {
        var pager = this.state.pager;

        return (
            <div>
                {/* {this.props.mainFilter && <div className='list __items' style={{ flex: 1, margin: 2 }}>

                    {this.state.opened ?
                        <div style={{ position: "relative", textAlign: 'right' }}>
                            <button onClick={this.toggleClass} style={{ background: 'transparent', color: '#43b0e6' }} className="btn __other">
                                <i className="btn-ico fa fa-remove"></i>
                            </button>
                        </div>
                        : <div style={{ flexDirection: 'row', alignItems: 'center', background: "none", borderBottom: 'none', padding: 0 }} className={this.state.opened ? "list-group __opened" : "list-group"}>
                            <div style={{ flex: 1 }}>
                                <div className="form-group" style={{ marginBottom: 0, margin: 10 }}>
                                    <div className="form-input __search">
                                        <input
                                            style={this.props.theme.inputs}
                                            value={this.state.mainFilter}
                                            name={this.props.mainFilter.jsonName}
                                            placeholder={this.props.mainFilter.disName || 'Search...'}
                                            onChange={(e) => this.handleChange(e, true)} />
                                        <button onClick={(e) => this.clearFilter(e, true)} title={this.props.mainFilter.jsonName}
                                            className="btn __other">
                                            <i className="btn-ico fa fa-remove" title={this.props.mainFilter.jsonName}></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div onClick={this.toggleClass} style={{ flex: 1 }}>
                                <div className="tooltip" style={{ width: 25 }}>
                                    <img src={this.checkTheme()} alt='adv ser' title="Advance Search" style={{ height: 25, width: 25 }} />
                                    <span className="tooltiptext">Advance Search</span>
                                </div>
                            </div>

                        </div>}
                    <div className="list __sub">
                        <div style={{ display: "flex", flexWrap: 'wrap' }}>
                            {this.state.filternames.map((item, index) => (
                                <div className="form-group" key={index} style={{ marginBottom: 0, margin: 0 }}>
                                    <label className="form-label" style={{ fontSize: 12 }}>{item.disName}</label>
                                    <div className="form-input __search">
                                        <input
                                            style={this.props.theme.inputs}
                                            value={this.state.filters[item.jsonName]}
                                            name={item.jsonName}
                                            placeholder={item.disName || 'Search...'}
                                            onChange={this.handleChange} />
                                        <button onClick={this.clearFilter} title={item.jsonName} className="btn __other">
                                            <i className="btn-ico fa fa-remove" title={item.jsonName}></i>
                                        </button>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>} */}
                {pager.pages && pager.pages.length > 1
                    && <div className="pagination" style={{ flex: 1, display: 'flex' }}>
                        <ul className="menu __inline" style={{ marginBottom: 0, flex: 1 }}>
                            <li className={pager.currentPage === 1 ? 'menu-item __disabled' : 'menu-item'}>
                                <a className="menu-link" style={{ padding: 3 }} onClick={() => this.setPage(1)}><img alt="first" style={{ height: 15, width: 15 }} src={"/images/first_black.png"} /></a>
                            </li>
                            <li className={pager.currentPage === 1 ? 'menu-item __disabled' : 'menu-item'} >
                                <a className="menu-link" style={{ padding: 3 }} onClick={() => this.setPage(pager.currentPage - 1)}><img alt="prev" style={{ height: 15, width: 15 }} src={"/images/previous.png"} /></a>
                            </li>
                            {pager.pages.length > 4 &&
                                pager.pages.map((page, index) => {
                                    if (page <= pager.currentPage + 3 && page >= pager.currentPage - 3) {
                                        return <li key={index} className={pager.currentPage === page ? 'menu-item __selected' : 'menu-item'}>
                                            <a className="menu-link" onClick={() => this.setPage(page)}>{page}</a>
                                        </li>
                                    } else {
                                        return null
                                    }
                                }
                                )}
                            {pager.pages.length < 4 && pager.pages.map((page, index) =>
                                <li key={index} className={pager.currentPage === page ? 'menu-item __selected' : 'menu-item'}>
                                    <a className="menu-link" tabIndex={index} onClick={() => this.setPage(page)}>{page}</a>
                                </li>
                            )}
                            <li className={pager.currentPage === pager.totalPages ? 'menu-item __disabled' : 'menu-item'}>
                                <a className="menu-link" style={{ padding: 3 }} onClick={() => this.setPage(pager.currentPage + 1)}><img alt="next" style={{ height: 15, width: 15 }} src={"/images/next.png"} /></a>
                            </li>
                            <li className={pager.currentPage === pager.totalPages ? 'menu-item __disabled' : 'menu-item'}>
                                <a className="menu-link" style={{ padding: 3 }} onClick={() => this.setPage(pager.totalPages)}><img alt="last" style={{ height: 15, width: 15 }} src={"/images/last_black.png"} /></a>
                            </li>
                        </ul>
                        <div>
                            <span className="details"> Showing {pager.startIndex + 1} to {pager.endIndex + 1} of {pager.totalItems} entires</span>
                            <span style={{ marginLeft: 10, marginRight: 10 }}>
                                <select style={{ padding: 3, margin: 3 }} onChange={(e) => this.change(e)}>
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                </select>
                            </span>
                            Items per Page
                </div>
                    </div>}
            </div >
        );
    }
}

Pagination.defaultProps = defaultProps;

export default withTheme(Pagination);
import * as Master from "./master";
import * as Adjustment from "./adjustment";
import * as Request from "./request";
import * as LeavePeriod from "./LeavePeriod";
import * as MassLeave from "./mass";
export default {
    Master,
    Adjustment,
    Request,
    LeavePeriod,
    MassLeave
}
import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";

import TableList from "../../../../../Components/Lists/TableList";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateComponent, errorNotification } from '../../../../../_reducers/reducer.notification';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';
//methods
import { showLastBlade } from "../../../../../_helpers/helper.methods";
//api
import API from "../../../../../_apis/hrms/employee";

import Pagination from '../../../../../Components/Pagination';

class IdCardInfoList extends Component {

    async componentDidUpdate(prevProps, prevState) {
        let { dataChanged, history: { location: { pathname } } } = this.props;

        if (dataChanged && prevProps !== this.props) {
            if (pathname.includes("allemployee/menu/idcardinfo/")) {
                let url = "../../../../../";
                let res = await API.Employee.Detail(this.props.user, url, this.state.employee);
                if (res.status !== '1') {
                    this.props.updateComponent(res);
                    this.setState({
                        load: false,
                        list: res.idCardList,
                    })
                } else {
                    this.props.errorNotification(res.message);
                }
            } else if (pathname.toLowerCase().includes('myinfo/idcardinfo/')) {
                let url = "../../../../../";
                let res = await API.Employee.Detail(this.props.user, url);
                if (res.status !== '1') {
                    this.props.updateComponent(res);
                    this.setState({
                        load: false,
                        list: res.idCardList,
                    })
                } else {
                    this.props.errorNotification(res.message);
                }
            }
        }
    }
    componentDidMount() {
        let { match, history } = this.props;
        let { item, icon } = this.props.history.location.state;
        this.setState({ icon });
        if (match.path.includes("allemployee")) {
            setTimeout(() => {
                this.setState({
                    subtitle: item.employeeCode,
                    employee: item,
                    list: item.idCardList
                })
            }, 1000);
        } else {
            setTimeout(() => {
                this.setState({
                    subtitle: item.employeeCode,
                    employee: item,
                    list: item.idCardList
                })
            }, 1000);
        }
        if (item.idCardList.length === 0) {
            history.push({
                pathname: `${match.path}/add`,
                state: { employee: item }
            });
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            pageOfItems: [],
            subtitle: '',
            employee: {},
            load: false,
            list: []
        }
    }
    selectedData = (item, index) => {
        let { history, match } = this.props;
        this.setState({
            index
        })
        history.push({
            pathname: `${match.path}/edit`,
            state: { item, employee: this.state.employee }
        });
        showLastBlade();
    }
    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems: pageOfItems });
    }
    Change = (item) => {
        let items = this.state.pageOfItems;
        let data = items.findIndex(i => i.id === item.id)
        items[data].name = "Changed";
        this.setState({ pageOfItems: items })
    }

    close = () => {
        let { match, history } = this.props;
        if (match.path.includes("/hrms/myinfo")) {
            history.push("/home/hrms/myinfo")
        } else if (match.path.includes("allemployee/menu")) {
            history.push("/home/hrms/allemporgunit/allemployee/menu")
        } else {
            history.push("/home");
        }
    }
    toolbarclick = (e) => {
        let { match, history } = this.props;
        if (e === "add") {
            let elem = document.getElementById(`list${'idcard'}${this.state.index}`);
            if (elem) {
                elem.classList.remove('__selected');
            }
            history.push({
                pathname: `${match.path}/add`,
                state: {
                    employee: this.state.employee
                }
            });
            showLastBlade();
        }
    }
    render() {
        let toolbar = [{
            icon: '/images/add_black.png',
            name: <Translate id="hrms.employeeInfo.myInformation.add" />,
            value: "add"
        }];
        return (
            <Blade match={this.props.match}
                max
                load={this.state.load}
                close={this.close}
                icon={this.state.icon || '/images/idcard.png'}
                head={<Translate id="hrms.employeeInfo.myInformation.idCard" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={this.state.load}>

                    <Pagination
                        mainFilter={{ jsonName: 'cardNo', disName: 'Card No' }}
                        filters={[{ jsonName: 'cardNo', disName: 'Card No' },
                        { jsonName: 'cardType', disName: 'Card Type' },
                        { jsonName: 'city', disName: 'City' }]}
                        items={this.state.list}
                        onChangePage={(e) => { this.onChangePage(e) }}
                        onRef={(onSort) => { this.onSort = onSort }} />

                    <TableList
                        id='idcard'
                        returnSelected={this.selectedData}
                        fields={["cardNo", 'cardType', 'country', 'city']}
                        list={this.state.pageOfItems || []}
                        header={[<Translate id="hrms.employeeInfo.myInformation.cardNo" />, <Translate id="hrms.employeeInfo.myInformation.cardType" />, <Translate id="hrms.employeeInfo.myInformation.country" />, <Translate id="hrms.employeeInfo.myInformation.city" />]}
                        onHeaderClick={this.onSort}>
                    </TableList>
                </BladeBody>
            </Blade >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateComponent,
    errorNotification
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    dataChanged: state.notification.dataChanged
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(IdCardInfoList))
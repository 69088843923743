import React, { Component } from "react";

import Blade from "../../../Components/Blade";
import BladeBody from "../../../Components/Blade/BladeBody";
import CheckBox from "../../../Components/Validators/CheckBox";
import TableList from "../../../Components/Lists/TableList";

import Pagination from '../../../Components/Pagination';

//methods
import { devLog, showLastBlade } from "../../../_helpers/helper.methods";

//redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    updateSelectedOrgUnit,
} from "../../../_reducers/reducer.user";
import { errorNotification } from "../../../_reducers/reducer.notification";
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../_language/en.json';
import id from '../../../_language/id.json';

//api
import API from "../../../_apis/hrms/employee";

class EmployeeList extends Component {
    async componentDidUpdate(prevProps, prevState) {
        let { dataChanged, history } = this.props;
        let { history: { location } } = this.props;
        if (prevProps !== this.props) {
            if (history.location.state && history.location.state.from === 'back') return;
            if ((history.location.pathname === '/home/hrms/allemporgunit/allemployee') || (history.location.pathname === '/home/hrms/allemporgunit/allemployee/menu/basicinfo' && dataChanged)) {
                // this.setState({ load: true })   
                if (location.state) {
                    let { item } = location.state;
                    this.setState({
                        subtitle: item.text,
                        load: true
                    });
                    let res = await API.Employee.List(this.props.user, this.props.selectedOrgUnit, undefined, true);
                    if (res.status !== '1') {
                        this.props.updateSelectedOrgUnit(this.props.history.location.state.selectedOrgUnitUID);
                        this.setState({
                            load: false,
                            list: res
                        })
                    } else {
                        this.props.errorNotification(res.message);
                    }
                }
            }
        }
    }
    async componentDidMount() {
        this.setState({
            icon: this.props.history.location.state.icon,
            title: this.props.history.location.state.title || "",
            menu: this.props.history.location.state.menu || [],
            fullmenu: this.props.history.location.state.menu,
            selectedOrgUnitUID: this.props.history.location.state.selectedOrgUnitUID,
            load: true
        })
        let { match, history } = this.props;

        let res = await API.Employee.List(this.props.user, this.props.history.location.state.selectedOrgUnitUID, undefined, true);
        devLog(' --------------- selected org unit -------', this.props.history.location.state.selectedOrgUnitUID);
        if (res.status !== '1') {
            this.props.updateSelectedOrgUnit(this.props.history.location.state.selectedOrgUnitUID);
            this.setState({
                load: false,
                list: res
            })
            if (res.length === 0) {
                history.push({
                    pathname: `${match.path}/employeeadd`
                });
            }
        } else {
            this.props.errorNotification(res.message);
        }
    }

    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            subtitle: <Translate id="hrms.allEmployee.allEmployee" />,
            exampleItems: [],
            pageOfItems: [],
            menu: [],
            title: "",
            load: false,
            list: [],
            checkInactive: false
        }
    }
    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems: pageOfItems });
    }

    selectedData = (item, index) => {
        let { match } = this.props;
        this.setState({ index: index });
        this.props.history.push({
            pathname: `${match.path}/menu`,
            state: {
                menu: this.state.menu,
                item,
                icon: '/images/employeeinfo.png',
                title: "Edit Employee",
                subtitle: item.firstName + " " + item.lastName,
                selectedOrgUnitUID: this.state.selectedOrgUnitUID
            }
        });
        showLastBlade();
    }
    toolbarclick = (e) => {
        let { match } = this.props;
        if (e === "add") {
            let elem = document.getElementById(`list${'allemp'}${this.state.index}`);
            if (elem) {
                elem.classList.remove('__selected')
            }
            this.props.history.push({
                pathname: `${match.path}/employeeadd`,
                state: {}
            });
            showLastBlade();
        }
    }

    handlechange = async (e) => {
        if (e.target.type === "checkbox") {
            if (e.target.checked === true) {
                this.setState({ checkInactive: true });
                let res = await API.Employee.List(this.props.user, this.props.history.location.state.selectedOrgUnitUID, undefined, "");
                if (res.status !== '1') {
                    this.setState({
                        load: false,
                        list: res
                    })
                } else {
                    this.props.errorNotification(res.message);
                }
                return true;
            }
            else {
                this.setState({ checkInactive: false });
                let res = await API.Employee.List(this.props.user, this.props.history.location.state.selectedOrgUnitUID, undefined, true);
                if (res.status !== '1') {
                    this.setState({
                        load: false,
                        list: res
                    })
                } else {
                    this.props.errorNotification(res.message);
                }
                return true;
            }
            //return false;
        }
    }

    render() {
        let toolbar = [{
            icon: '/images/add_black.png',
            name: <Translate id="hrms.allEmployee.add" />,
            value: "add"
        }];
        return (
            <Blade match={this.props.match}
                max="max"
                load={this.state.load}
                close={() => this.props.history.push("/home")}
                icon={this.state.icon || '/images/allemployee.png'}
                head={<Translate id="hrms.allEmployee.allEmployee" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={this.state.load}>
                    <CheckBox style={{ paddingBottom: 15 }}
                        label={<Translate id="hrms.allEmployee.inactive" />}
                        onChange={this.handlechange}
                        checked={this.state.checkInactive}
                    />

                    <Pagination
                        mainFilter={{ jsonName: 'firstName', disName: 'First Name' }}
                        filters={[{ jsonName: 'firstName', disName: 'First Name' },
                        { jsonName: 'mobileNo', disName: 'Mobile Number' },
                        { jsonName: 'email', disName: 'Email' }]}
                        items={this.state.list}
                        onChangePage={(e) => { this.onChangePage(e) }}
                        onRef={(onSort) => { this.onSort = onSort }}
                    />

                    <TableList
                        id='allemp'
                        returnSelected={this.selectedData}
                        fields={['employeeCode', 'firstName', 'lastName', 'mobileNo', 'email', 'gender']}
                        list={this.state.pageOfItems || []}
                        header={[<Translate id="hrms.allEmployee.employeeCode" />, <Translate id="hrms.allEmployee.firstName" />, <Translate id="hrms.allEmployee.lastName" />, <Translate id="hrms.allEmployee.mobileNumber" />, <Translate id="hrms.allEmployee.email" />, <Translate id="hrms.allEmployee.gender" />]}
                        onHeaderClick={this.onSort}
                    >

                    </TableList>
                </BladeBody>
            </Blade >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    errorNotification,
    updateSelectedOrgUnit,
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    selectedOrgUnit: state.user.selectedOrgUnit,
    dataChanged: state.notification.dataChanged
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(EmployeeList))
import React from 'react';
import { withTheme } from 'theming';
import BreadcrumbsItem from './BreadcrumbItem';
import { Route } from 'react-router-dom';

class Breadcrumb extends React.Component {
    render() {
        let { theme } = this.props;
        return (
            <div style={theme.breadcrumb} id='breadcrumbs' className="breadcrumbs long">
                <ul className='container' style={{ display: 'flex', marginLeft: 5 }}>
                    <Route path='/:path' {...this.props} component={BreadcrumbsItem} />
                </ul>
            </div>
        )
    }
}
export default withTheme(Breadcrumb)

import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { withTheme } from 'theming';

class TextValidator extends ValidatorComponent {

    render() {
        const {add, theme, children, label, req, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        let required = validators[0] === 'required';
        return (
            <div className="form-group" style={label ? {} : { /*margin: 0*/ }}>
                {label && <label className="form-label">{label}
                    <span style={{ color: 'red' }}>{required && ' *'}</span>
                </label>}
                <div className="form-input">
                    <div style={add ? { display: 'flex', width: 190 } : {width:'100%'}}>
                    <input
                        style={theme.inputs}
                        {...rest}
                        ref={(r) => { this.input = r; }}
                    />
                    {children}
                    </div>
                    <div>
                        {add && <button
                            style={{ background: '#43b0e6', color: 'white' }}
                            type='button' onClick={() => add()} className='btn'>+</button>}
                    </div>
                </div>
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'red', fontSize: 12 }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default withTheme(TextValidator);
import React, { Component } from "react";

import DashboardGrid from '../../Components/Dashboard/DashboardGrid';
//import Dashboard from '../../../../Components/Dashboard';
import TableList from '../../Components/Lists/TableList';

//blades
import Blade from "../../Components/Blade";
import BladeBody from "../../Components/Blade/BladeBody";

import DateTime from 'react-datetime';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { errorNotification } from '../../_reducers/reducer.notification';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../_language/en.json';
import id from '../../_language/id.json';
import { withTheme } from 'theming';
// methods
import { formatDate, showLastBlade } from '../../_helpers/helper.methods';
// api
import API from "../../_apis/hrms/QuickLinks&News";
import APIMaster from '../../_apis/Core';
import APILeave from "../../_apis/hrms/leave";
import APIEmployee from "../../_apis/hrms/employee";
import { MonthlyRecap, MonthlyStaff, AbsentStaff } from "../../_apis/hrms/dashboard";
//import { DashBoardAPI } from '../../_apis/hrms/dashboard';
import $ from "jquery";
//import Select2 from 'react-select2-wrapper';
//import { Pie, Bar } from 'react-chartjs-2';
var moment = require('moment');

class Dashboard extends Component {
    state = {
        quickLinksList: [],
        newsList: [],
        holidayList: [],
        downloadAPKList: [],      
        selOrg: 'ALL',        
        recaps: [],
        staffs: [], 
        photoUrl: '',
        absents: [],
        requestList: []
    }

    constructor(props) {
        super(props);        
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");   
    }    
    getBlobFromURL = async (blob) => {
        let url = '../../../../';
        let res = await APIMaster.AccessBlob(this.props.user, blob, url);
        if (res.status !== '1') {
            this.setState({
                photoUrl: res.message
            })
        }
    }
    async componentDidMount() {
        // try {
        //     let dashres = await DashBoardAPI(this.props.user);
        //     this.setState({ dashBoardData: dashres }, () => {
        //         this.generateBarChartForOrgUnit();
        //         this.generatePieChartForTenure();
        //         this.generatePieChartForGender('ALL', true);
        //     })

        // } catch (error) {
        //     this.props.errorNotification(error.message);
        // }
        
        try {
            //let url = '../../'
            let masres = await APIMaster.Master.List(this.props.user, 'hrms', '')
            //let masleaveres = await APILeave.Master.List(this.props.user, url, this.props.user.empUID)
            let url = '../../../../'
            let startDate = moment().subtract(10000, 'day').format("YYYY-MM-DD");
            let endDate = moment().add(10000, 'day').format("YYYY-MM-DD");
            let leaveres = await APILeave.Request.List(this.props.user, url, this.props.user.empUID, startDate, endDate);
            if (leaveres.status !== '1') {
                let requestList = this.getLocDateFromArray(leaveres);
                this.setState({
                    requestList
                })
            }
            let recap = await MonthlyRecap(this.props.user);
            if (recap.status !== "1") {
                this.setState({
                    recaps: recap
                })
            }
            let staff = await MonthlyStaff(this.props.user);
            if (staff.status !== "1") {
                this.setState({
                    staffs: staff
                })
            }
            let absent = await AbsentStaff(this.props.user);
            if (absent.status !== "1") {
                for(var i=0;i<absent.length;i++) {
                    if (absent[i].photoURL) {
                        let res = await APIMaster.AccessBlob(this.props.user, absent[i].photoURL, '../../../../');
                        absent[i].photoURL = res.message;  
                    }                    
                }
                this.setState({
                    absents: absent
                })                
            }
            masres = JSON.parse(localStorage.getItem("coremaster"));
            if (masres !== null && masres !== undefined) {
                this.setState({                    
                    holidayList: masres.holidayList,
                    downloadAPKList: masres.downloadAPKList || [],
                }, () => $('[data-toggle="tooltip"]').tooltip());
            } else {
                this.props.errorNotification(masres.message);
            }

            let emp_url = "..";
            let empres = await APIEmployee.Employee.Detail(this.props.user, emp_url);
            if (empres.status !== "1") {
                if (empres.photoUrl) {
                    this.getBlobFromURL(empres.photoUrl);
                } 
            } else {
                this.props.errorNotification(empres.message);
            }

        } catch (error) {
            this.props.errorNotification(error.message);
        }
        try {
            let quickres = await API.QuickLinks.List(this.props.user, true)

            if (quickres.status !== "1") {
                this.setState({
                    quickLinksList: quickres
                })
            } else {
                this.props.errorNotification(quickres.message);
            }
        } catch (error) {
            this.props.errorNotification(error.message);
        }
        try {
            let newsres = await API.News.List(this.props.user, "")

            if (newsres.status !== "1") {
                this.setState({
                    newsList: newsres
                })
            } else {
                this.props.errorNotification(newsres.message);
            }
        } catch (error) {
            this.props.errorNotification(error.message);
        }
    }

    getLocDateFromArray = (res) => {
        let data = [...res];
        data.sort(function (a, b) { return moment(a.requestAt) - moment(b.requestAt) }).map((item, i) => {
            data[i] = {
                ...item,
                startDate: item.startDate ? formatDate(item.startDate) : '',
                endDate: item.endDate ? formatDate(item.endDate) : '',
                requestAt: item.requestAt ? formatDate(item.requestAt) : '',
                cancelAt: item.cancelAt ? formatDate(item.cancelAt) : ''
            };
            return null;
        })
        return data;
    }

    loadNewsSelectedData = (item) => {
        //let { match } = this.props;
        this.props.history.push({
            pathname: `/home/hrms/dashboard/details`,
            state: {
                item,
                subtitle: item.newsTitle
            }
        })
        showLastBlade();
    }

    openQuickLink = (item) => {
        var win = window.open(item.url, '_blank');
        win.focus();
    }

    downloadapk = () => {
        var win = window.open(this.state.downloadAPKList[0].text, '_blank');
        win.focus();
    }

    renderDay = (props, currentDate, selectedDate) => {
        let { holidayList, requestList } = this.state;
        let date = <td
            {...props}>
            <span style={{ padding: 0, margin: 0 }}>
                {currentDate.date()}</span>
        </td>;
        
        holidayList.some((item) => {
            if (moment(item.addInfo).isSame(currentDate)) {
                date = <td
                    style={item.addInfo2 === 'Fixed'
                        ? { backgroundColor: 'red', color: 'white' }
                        : item.addInfo2 === 'Optional'
                            ? { backgroundColor: 'yellow', color: 'black' } : {}}
                    {...props} data-toggle="tooltip"
                    data-placement="top" title={item.text}>
                    <p style={{ padding: 0, margin: 0 }}>
                        {currentDate.date()}</p>
                </td>
                return true;
            } else if((currentDate.days() === 0 || currentDate.days () ===6) && props.className !== "rdtDay rdtNew" && props.className !== "rdtDay rdtOld")
            {
                date = <td
                {...props}>
                <span style={{ padding: 0, margin: 0, color: 'red' }}>
                    {currentDate.date()}</span>
                </td>;        
            }
            return false;
        })
        requestList.forEach((item) => {
            let start = moment(item.startDate, "DD/MM/YYYY").toDate();
            let end = moment(item.endDate, "DD/MM/YYYY").toDate();
            let current = moment(currentDate).toDate();
             if (current >= start && current <= end) {
                date = <td
                    style={item.leaveStatus === 'Approved'
                        ? { backgroundColor: 'green', color: 'white' }
                        : {}}
                    {...props} data-toggle="tooltip"
                    data-placement="top" title={item.leaveName}>
                    <p style={{ padding: 0, margin: 0 }}>
                        {currentDate.date()}</p>
                </td>
            }        
        });        

        return date;
    }

    handleChange = (event) => {
        let { value } = event.target;
        if (value === 'ALL') {
            this.generatePieChartForGender(value, true);
        } else {
            this.generatePieChartForGender(value, false);
        }
        this.setState({ selOrg: value })
    }

    checkin = () => {
        let { history } = this.props;
        let menu = [
            {
                checked: false,
                icon: null,
                path: null,
                uid: null,
                value: "Absence Management",
                subMenu: [
                    {
                        checked: true,
                        icon: "/images/previouscompany.png",
                        path: "/home/hrms/absence/mypresence/checkin",
                        subMenu: [],
                        uid: "3907aa45-727f-4a50-b252-373df63a80cc",
                        value: "Checkin"
                    },
                    {
                        checked: true,
                        icon: "/images/currentcompany.png",
                        path: "/home/hrms/absence/mypresence/checkout",
                        subMenu: [],
                        uid: "44637ab7-3753-45f0-a285-69dc2745757b",
                        value: "Checkout"
                    },
                    {
                        checked: true,
                        icon: "/images/mypresence.png",
                        path: "/home/hrms/absence/mypresence/calendarview",
                        subMenu: [],
                        uid: "99717908-83a2-48b6-bebe-7a9de09f7af8",
                        value: "Calendar View"
                    }
                ]
            }
        ]
        history.push({
            pathname: "/home/hrms/absence/mypresence/checkin",
            state: {
                menu: menu || [],
                noapi: true,
                //item: state.item,
                location: 'mypresence/',
                title: "My Presence",
                icon: "/images/mypresence.png",
                subtitle: "My Presence"
            }
        })
    }

    checkout = () => {
        let { history } = this.props;
        let menu = [
            {
                checked: false,
                icon: null,
                path: null,
                uid: null,
                value: "Absence Management",
                subMenu: [
                    {
                        checked: true,
                        icon: "/images/previouscompany.png",
                        path: "/home/hrms/absence/mypresence/checkin",
                        subMenu: [],
                        uid: "3907aa45-727f-4a50-b252-373df63a80cc",
                        value: "Checkin"
                    },
                    {
                        checked: true,
                        icon: "/images/currentcompany.png",
                        path: "/home/hrms/absence/mypresence/checkout",
                        subMenu: [],
                        uid: "44637ab7-3753-45f0-a285-69dc2745757b",
                        value: "Checkout"
                    },
                    {
                        checked: true,
                        icon: "/images/mypresence.png",
                        path: "/home/hrms/absence/mypresence/calendarview",
                        subMenu: [],
                        uid: "99717908-83a2-48b6-bebe-7a9de09f7af8",
                        value: "Calendar View"
                    }
                ]
            }
        ]
        history.push({
            pathname: "/home/hrms/absence/mypresence/checkout",
            state: {
                menu: menu || [],
                noapi: true,
                //item: state.item,
                location: 'mypresence/',
                title: "My Presence",
                icon: "/images/mypresence.png",
                subtitle: "My Presence"
            }
        })
    }
    getDate = () => {
        let newdate = new Date();
        var month = [];
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";
        var n = month[newdate.getMonth()];
        let year = newdate.getFullYear();
        let date = n + " " + year;
        return date;
    }
    render() {
        let defaultimage = "/images/profile-default.jpg";
        let { history } = this.props;
        let { load, icon, recaps, staffs, photoUrl, absents, newsList, holidayList/*, tenuredata, orgUnitdata, orgUnitList, selOrg, genderdata*/ } = this.state;
        
        return (
            <Blade match={this.props.match}
                max='max'
                load={load}
                close={() => history.push("/home")}
                icon={icon || '/images/myinfo.png'}
                head={<Translate id="dashboard.dashboard" />}
                subhead={<Translate id="dashboard.dashboard" />}
                handleclick={this.load}>
                <BladeBody match={this.props.match} notoolbar load={load}>
                    <div className="row" style={{ minHeight: '90vh' }}>
                        <div className="col-md-4">
                            <div className="text-center">
                                <br />
                                <img
                                    alt='user'
                                    style={{ borderRadius: '50%', width: 200, height: 200, display: 'inline-block' }}
                                    src={photoUrl || defaultimage} />
                            </div>                                
                            <p style={{ margin: 10, fontSize: 20 }}><Translate id="dashboard.welcomeBack" />
                            <br /><strong>{this.props.user.empName}</strong></p>
                            <br />
                            <div className="row dashbanner">
                                <div className="col-md-12 text-center" style={{ background: '#ff4081' }} onClick={() => history.push("/home/hrms/leave/myleaveinfo")}><img alt="" src="/images/myleaveinfo.png" width="25" /> <span><Translate id="hrms.leave.requestLeave" /></span></div>
                                {staffs.numOfStaff > 0 ? <div className="col-md-12 text-center" style={{ background: '#ffab40' }} onClick={() => history.push("/home/hrms/leave/leaveapprove")}><img alt="" src="/images/approval.png" width="25" /> <span><Translate id="hrms.leave.approveLeave" /></span></div> : ""}
                                <div className="col-md-12 text-center" style={{ background: '#00e676' }} onClick={() => this.checkin()}><img alt="" src="/images/previouscompany_black.png" width="25" /> <span><Translate id="dashboard.checkin" /></span></div>
                                <div className="col-md-12 text-center" style={{ background: '#bdbdbd' }} onClick={() => this.checkout()}><img alt="" src="/images/currentcompany_black.png" width="25" /> <span><Translate id="dashboard.checkout" /></span></div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div style={{ display: 'flex' }}>
                                <p style={{ margin: 10, fontSize: 20 }}><Translate id="dashboard.recap" /> {this.getDate()}</p>
                            </div>
                            <div className="row dashbanner">
                                <div className="col-md-2" style={{ background: '#00796b' }}><Translate id="dashboard.numOfWorkDays" /><br /><br /><span>{recaps.numOfWorkDays}</span></div>
                                <div className="col-md-2" style={{ background: '#00c853' }}><Translate id="dashboard.present" /><br /><br /><br /><span>{recaps.numOfCheckIn}</span></div>
                                <div className="col-md-2" style={{ background: '#d32f2f' }}><Translate id="dashboard.numOFAbsent" /><br /><br /><br /><span>{recaps.numOFAbsent}</span></div>
                                <div className="col-md-2" style={{ background: '#388e3c' }}><Translate id="dashboard.numOfLeaves" /><br /><br /><br /><span>{recaps.numOfLeaves}</span></div>
                                <div className="col-md-2" style={{ background: '#FF7733' }}><Translate id="dashboard.numOfLateCheckInMin" /><br /><span>{recaps.lateCheckInMin}</span></div>
                                <div className="col-md-2" style={{ background: '#CC8800' }}><Translate id="dashboard.numOfEarlyCheckOutMin" /><br /><span>{recaps.earlyCheckOutMin}</span></div>
                            </div>
                            {staffs.numOfStaff > 0 ? 
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ margin: 10, fontSize: 20 }}><Translate id="dashboard.staffrecap" /> {this.getDate()}: {staffs.numOfStaff}</p>
                                </div>
                                <div className="row dashbanner">
                                    <div className="col-md-3" style={{ background: '#00796b' }}><Translate id="dashboard.numOfWorkDays" /><br /><span>{staffs.numOfWorkDays}</span></div>
                                    <div className="col-md-3" style={{ background: '#00c853' }}><Translate id="dashboard.present" /><br /><span>{staffs.numOfCheckIn}</span></div>
                                    <div className="col-md-3" style={{ background: '#d32f2f' }}><Translate id="dashboard.numOFAbsent" /><br /><span>{staffs.numOFAbsent}</span></div>
                                    <div className="col-md-3" style={{ background: '#388e3c' }}><Translate id="dashboard.numOfLeaves" /><br /><span>{staffs.numOfLeaves}</span></div>
                                </div>                                
                            </div>
                            : "" }
                            {absents.length > 0 ? 
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <p style={{ margin: 10, fontSize: 20, marginBottom: 20 }}><Translate id="dashboard.absent" /></p>
                                </div>
                                <div className="row">
                                    {absents.map((item, i) => (
                                        <div className="col-md-3 text-center" style={{ marginBottom: 15 }}>  
                                            <img
                                                alt='user'
                                                style={{ borderRadius: '50%', width: 50, height: 50, display: 'inline-block' }}
                                                src={item.photoURL || defaultimage} /><br />                                     
                                            {item.staffName}<br />
                                            {item.positionName}
                                        </div>
                                    ))}
                                </div>
                            </div>    
                            : ""}
                        </div>
                    </div>
                    
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1 }}>

                            <p style={{ marginBottom: 0, fontSize: 20, marginLeft: 10 }}><Translate id="dashboard.calendar" /></p>

                            <DashboardGrid
                                style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
                                {holidayList && <DateTime
                                    className='withoutaction'
                                    timeFormat={false}
                                    input={false}
                                    renderDay={(a, b, c) => this.renderDay(a, b, c)}
                                />}
                                <div style={{ display: 'flex', margin: 10 }}>
                                    <p style={{ fontSize: 12 }}><span style={{ padding: '0px 10px 0px 10px', height: 30, width: 35, border: '1px solid black', backgroundColor: "red", marginRight: 5 }}></span>Fixed</p>
                                    <p style={{ marginLeft: 10, fontSize: 12 }}><span style={{ padding: '0px 10px 0px 10px', height: 30, width: 35, border: '1px solid black', backgroundColor: "yellow", marginRight: 5 }}></span>Optional</p>
                                    <p style={{ marginLeft: 10, fontSize: 12 }}><span style={{ padding: '0px 10px 0px 10px', height: 30, width: 35, border: '1px solid black', backgroundColor: "green", marginRight: 5 }}></span>Leave</p>
                                </div>
                            </DashboardGrid>
                        </div>
                        <div style={{ flex: 1 }}>

                            <p style={{ marginBottom: 0, fontSize: 20, marginLeft: 10 }}><Translate id="dashboard.quicklinks" /></p>

                            <DashboardGrid
                                style={{ height: 250, overflowX: 'hidden', overflowY: 'auto' }} >

                                <TableList
                                    id='quicklinks'
                                    style={{ margin: 0 }}
                                    disabled
                                    returnSelected={this.openQuickLink}
                                    fields={['name', 'url']}
                                    list={this.state.quickLinksList || []}
                                    header={[<Translate id="dashboard.name" />, 'URL']} >
                                </TableList>
                            </DashboardGrid>
                        </div>

                    </div>
                    <div style={{ flex: 1, marginTop: 0 }}>

                        <p style={{ marginBottom: 0, fontSize: 20, marginLeft: 10 }}><Translate id="dashboard.newsannouncement" /></p>
                        <DashboardGrid
                            style={{ height: 80, overflowX: 'hidden', overflowY: 'auto', padding: 10 }} >

                            {newsList.map((item, key) => (
                                <React.Fragment key={key}>
                                    <a style={{ fontSize: 20, color: '#43b0e6' }} onClick={() => this.loadNewsSelectedData(item)}>{item.newsTitle}</a>
                                    <p className="newsdesc">
                                        {item.content}
                                    </p>
                                </React.Fragment>
                            ))}

                            {/* <TableList
         id='news'
         style={{ margin: 0 }}
         disabled
         returnSelected={this.loadNewsSelectedData}
         fields={['newsTitle', 'content']}
         list={this.state.newsList || []}
         header={['Title', 'Details']} >
     </TableList> */}
                        </DashboardGrid>
                    </div>
                    {this.state.downloadAPKList.length > 0 ?
                        <div style={{ flex: 1, marginTop: 10 }}>

                            <p style={{ marginBottom: 0, fontSize: 20, marginLeft: 10 }}><Translate id="dashboard.downloads" /></p>
                            <DashboardGrid
                                style={{ height: 'auto', overflowX: 'hidden', overflowY: 'auto' }} >
                                <div onClick={this.downloadapk} style={{ cursor: 'pointer'}}>
                                <img alt="icon" src={'/images/download_black.png'} tooltip={'Download this version'}  style={{ height: '25px', width: '25px' }} /> <Translate id="dashboard.downloadversion" />
                                </div>
     </DashboardGrid>
                        </div>
                        : ''}
                </BladeBody>
            </Blade>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    errorNotification
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(withTheme(Dashboard)))




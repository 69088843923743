import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const OrgUnitList = (user, directory) => {
    let formData = new FormData();
    if(!user) {
        user = JSON.parse(localStorage.getItem("user"))
    }
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('Type', 'Basic');
    formData.append('IsActive', true);

    formDataConsole(formData);
    let url = '/api/hrms/master/orgunit/list';
    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        //  return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//OrgUnit List response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const PositionList = (user, directory, OrgUnitUID, active) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + OrgUnitUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('Type', 'Dropdown');
    formData.append('isActive', active || true);
    formData.append('OrgUnitUID', OrgUnitUID);

    formDataConsole(formData);
    let url = '/api/hrms/master/position/list';
    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//positionlist response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const ReportingTo = (user, directory, PositionUID, type, active, empUID, orgUnitUID, divisionUID, departmentUID) => {
    let formData = new FormData();
    let signature = type.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('Type', type);
    formData.append('isActive', active || true);
    if (PositionUID !== null) {
        formData.append('PositionUID', PositionUID);
    }    
    if (empUID !== undefined && empUID !== null) {
        formData.append('EmployeeUID', empUID);
    }
    if (orgUnitUID !== undefined && orgUnitUID !== null) {
        formData.append('OrgUnitUID', orgUnitUID);
    }
    if (divisionUID !== undefined && divisionUID !== null) {
        formData.append('DivisionUID', divisionUID);
    }
    if (departmentUID !== undefined && divisionUID !== null) {
        formData.append('DepartmentUID', departmentUID);
    }    
    
    formDataConsole(formData);
    let url = '/api/hrms/master/reportingto/list';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//reportingto response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const ReportingStaffList = (user, directory, type, empUID, divisionUID, departmentUID) => {
    let formData = new FormData();
    let signature = type.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);
    if (!empUID) 
    {
        empUID = ''
    }
    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmpUID', empUID);
    formData.append('Type', type);
    if (divisionUID !== undefined && divisionUID !== null) {
        formData.append('DivisionUID', divisionUID);
    }
    if (departmentUID !== undefined && divisionUID !== null) {
        formData.append('DepartmentUID', departmentUID);
    } 
    formData.append('IsActive', true);
    formDataConsole(formData);

    let url = '/api/hrms/master/reportingstaff/list';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//reportingstafflist response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const ReportingStaffUnshiftList = (user, directory, type, empUID, startDate, endDate) => {
    let formData = new FormData();
    let signature = type.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmpUID', empUID || user.empUID);
    formData.append('StartDate', startDate);
    formData.append('EndDate', endDate)
    formData.append('Type', type);
    formDataConsole(formData);

    let url = '/api/hrms/master/reportingstaffunshift/list';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//reportingstaffunshiftlist response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const MasterList = (user, formData) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + formData.get('ListName').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    //formData.append('IsActive', "");
    //formData.append('IsActive', true);
    formDataConsole(formData);

    let url = '/api/hrms/master/list';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//hrms master list response", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
        .catch(checkAPIError);
}

export const MasterAdd = (user, formData) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + formData.get('ListName').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formDataConsole(formData);

    let url = '/api/hrms/master/add';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//hrms master add response", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
        .catch(checkAPIError);
}

export const MasterUpdate = (user, formData) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + formData.get('ListName').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formDataConsole(formData);

    let url = '/api/hrms/master/update';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//hrms master update response", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }

        })
        .catch(checkAPIError);
}

export const EmailTemplateList = (user) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Type', "List");
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('IsActive', true);
    formDataConsole(formData);

    let url = '/api/hrms/emailtemplate/list';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//emailtemplate list response", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
        .catch(checkAPIError);
}

export const DivisionList = (user, directory, OrgUnitUID) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('Type', 'Dropdown');
    formData.append('IsActive', true);
    //formData.append('Listname', '');
    formData.append('OrgUnitUID', OrgUnitUID);

    formDataConsole(formData);

    let url = '/api/hrms/master/division/list';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// divisionlist response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const DepartmentList = (user, directory, DivisionUID, active) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('Type', 'Dropdown');
    formData.append('isActive', active || true);
    formData.append('DivisionUID', DivisionUID);

    formDataConsole(formData);
    let url = '/api/hrms/master/department/list';
    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//departmentlist response", res)
            return res;
        })
        .catch(checkAPIError);
}


export const SubDepartmentList = (user, directory, DepartmentUID, active) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('Type', 'Dropdown');
    formData.append('isActive', active || true);
    formData.append('DepartmentUID', DepartmentUID);

    formDataConsole(formData);
    let url = '/api/hrms/master/subdepartment/list';
    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//subdepartmentlist response", res)
            return res;
        })
        .catch(checkAPIError);
}
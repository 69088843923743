import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, directory, empUID, StartDate, EndDate) => {
    let formData = new FormData();
    if(!user) {
        user = JSON.parse(localStorage.getItem("user"))
    }
    let signature = empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmployeeUID', empUID);
    formData.append('StartDate', StartDate);
    formData.append('EndDate', EndDate);
    let url = '/api/hrms/leave/request/list';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        //  return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {            
            devLog("// leave request list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const ApprovalList = (user, directory, empUID, StartDate, EndDate) => {
    let formData = new FormData();
    let signature = empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmployeeUID', empUID);
    formData.append('StartDate', StartDate);
    formData.append('EndDate', EndDate);
    let url = '/api/hrms/leave/request/approval/list';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// approval list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (formData, user, directory, empUID) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('leaveTypeUID').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append("EmployeeUID", user.empUID);
    //formData.append("ApproveBy", "3c6c81ec-0db9-4c83-b1c7-d5a2c75d2bf8");

    let url = '/api/hrms/leave/request';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// Request add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Detail = (user, directory, DataUID) => {
    let formData = new FormData();
    let signature = DataUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('DataUID', DataUID);
    let url = '/api/hrms/leave/request/detail';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// leave request detail list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Approve = (formData, user, directory) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('leaveRequestUID').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    //formData.append("EmployeeUID", user.empUID);
    //formData.append("ApproveBy", "3c6c81ec-0db9-4c83-b1c7-d5a2c75d2bf8");

    let url = '/api/hrms/leave/request/approve';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// Request approve response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Reject = (formData, user, directory) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('leaveRequestUID').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    //formData.append("EmployeeUID", user.empUID);
    //formData.append("ApproveBy", "3c6c81ec-0db9-4c83-b1c7-d5a2c75d2bf8");

    let url = '/api/hrms/leave/request/reject';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// Request approve response", res)
            return res;
        })
        .catch(checkAPIError);
}
export const Cancel = (formData, user, directory) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('leaveRequestUID').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append("EmployeeUID", user.empUID);

    let url = '/api/hrms/leave/request/cancel';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        //return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// Request cancel response", res)
            return res;
        })
        .catch(checkAPIError);

}
import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user) => {
    let formData = new FormData();
    let { language: { ipaddress } } = store.getState();

    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);

    let url = '/api/core/coreaccount/list';

    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// coreaccount list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const New = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
   
    formDataConsole(formData);
    let url = '/api/core/coreaccount/new'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// core account new response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    
    formDataConsole(formData)
    let url = '/api/core/coreaccount/update'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// core account update response", res)
            return res;
        })
        .catch(checkAPIError);
}
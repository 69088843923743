import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';

export const List = (user, ApplyStatus) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);
    if(!user) {
        user = JSON.parse(localStorage.getItem("user"))
    }
    let { language: { ipaddress } } = store.getState();
 
    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ApplyStatus', ApplyStatus);
    let url = '/api/hrms/leave/massleave/list';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {            
            devLog("// mass leave list response", res)
            return res;
        })
        .catch(checkAPIError);
}


export const Add = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append("EmployeeUID", user.empUID);

    let url = '/api/hrms/leave/massleave/new';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// mass leave add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/hrms/leave/massleave/update';
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//action type update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Delete = (user, MassApplyUID) => {
    let formData = new FormData();      
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('MassApplyUID', MassApplyUID);

    let url = '/api/hrms/leave/massleave/delete';    
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('//mass leave delete response', res)
            return res;
        })
        .catch(checkAPIError);
}

export const Detail = (user, MassApplyUID) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);
    let { language: { ipaddress } } = store.getState();

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('MassApplyUID', MassApplyUID);
    let url = '/api/hrms/leave/massleave/detail';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// mass leave detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Status = (user, MassApplyUID) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);
    let { language: { ipaddress } } = store.getState();

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('MassApplyUID', MassApplyUID);
    formData.append('Status', 'Submitted');
    let url = '/api/hrms/leave/massleave/status';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// mass leave status response", res)
            return res;
        })
        .catch(checkAPIError);
}
import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, OrgUnitUID, type, isactive, EmployeeUID) => {
    if (OrgUnitUID === undefined) {
        OrgUnitUID = "";
    }
    if (type === undefined) {
        type = "";
    }
    if (EmployeeUID === undefined) {
        EmployeeUID = "";
    }
    if (isactive === undefined) {
        isactive = "";
    }
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    //let directory = '../../../../../';
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmployeeUID', EmployeeUID);
    formData.append('OrgUnitUID', OrgUnitUID);
    formData.append('Type', type);
    formData.append('IsActive', isactive);

    formDataConsole(formData);
   let url='/api/hrms/employee/list'
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Detail = (user, directory, employee, detailtype, datauid) => {
    var formData = new FormData();
    let signature;
    let { language: { ipaddress } } = store.getState();
    if (detailtype) {
        signature = datauid.toLowerCase() + employee.empUID.toLowerCase() + user.token;
    } else {
        let uid = employee ? employee.empUID : user.empUID;
        signature = uid.toLowerCase() + user.empUID.toLowerCase() + user.token;
    }

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    if (detailtype) {
        formData.append('DetailType', detailtype);
        formData.append('DataUID', datauid);
    } else {
        formData.append('DetailType', "");
        formData.append('DataUID', employee ? employee.empUID : user.empUID);
    }
    let url = '/api/hrms/employee/detail';
    formDataConsole(formData)

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('employeeCode').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);
    let url = '/api/hrms/employee/add';
    //let directory = '../../../';
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (state, formData, user, directory) => {
    let signature = state.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append("EmpUID", state.empUID);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url='/api/hrms/employee/update';

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Deactivate = (user) => {
    var formData = new FormData();
    let signature = user.empUID.toLowerCase() + user.userUID.toLowerCase() + user.token;
    //let directory = '../../';
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.userUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmpUID', user.empUID);

    formDataConsole(formData);
    let url='/api/hrms/employee/deactivate';
   // return fetch(directory.concat(DEV_URL, ''), {
    return fetch(DEV_URL.concat(url), {   
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee deactivate response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const UploadProfilePicture = (state, user, formData, directory) => {
    let signature = state.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmpUID', state.empUID);

    let url = '/api/hrms/employee/uploadprofilepicture';

    formDataConsole(formData);

    devLog('url', url);

   // return fetch(directory.concat(DEV_URL, url), {
    return fetch(DEV_URL.concat(url), {  
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// employee upload picture response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const ResetPassword = (formData, user, directory, EmpUID) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);
    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append("EmpUID", EmpUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);
    let url='/api/login/forget';
  //  return fetch(directory.concat(DEV_URL, ''), {
    return fetch(DEV_URL.concat(url), {    
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// rest password response", res)
            return res;
        })
        .catch((err) => {
            devLog('error', err)
        })
        .catch(checkAPIError);
}

export const RoleAddUpdate = (formData, user, EmpUID) => {
    let { language: { ipaddress } } = store.getState();
    let signature = EmpUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append("EmployeeUID", EmpUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);

    let url = '/api/hrms/employee/Role/addupdate';

    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// role addupdate response", res)
            return res;
        })
        .catch(checkAPIError);
}
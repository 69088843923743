import React, { Component } from "react";

import Blade from "../../Components/Blade";
import BladeBody from "../../Components/Blade/BladeBody";
import UnorderList from "../../Components/Lists/Unorder";
import API from "../../_apis/hrms/employee";
import Searchbox from '../../Components/Searchbox';

//methods
import { showLastBlade } from "../../_helpers/helper.methods";
//redux
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { errorNotification } from "../../_reducers/reducer.notification";
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../_language/en.json';
import id from '../../_language/id.json';
//import { stat } from "fs";

class Menu extends Component {
    async componentDidUpdate(prevProps, prevState) {
        let { dataChanged, userdata, location, location: { state } } = this.props;
        if (prevProps !== this.props) {
            if (location.pathname.includes('basicinfo') && this.state.location === 'basicinfo' && dataChanged) {
                this.setState({ load: true })
                if (location.pathname.includes('myinfo')) {
                    let url = "../../../../../../";
                    let res = await API.Employee.Detail(this.props.user, url);
                    if (res.status !== '1') {
                        this.setState({
                            load: false,
                            item: res,
                        })
                    } else {
                        this.props.errorNotification(res.message);
                    }
                } else {
                    let url = "../../../../../../";
                    let res = await API.Employee.Detail(this.props.user, url, this.state.item);
                    if (res.status !== '1') {
                        this.setState({
                            load: false,
                            item: res
                        })
                    } else {
                        this.props.errorNotification(res.message);
                    }
                }
            } else if (location.pathname === '/home/hrms/allemporgunit/allemployee/menu' && this.state.item.empUID) {
                if (location.state) {
                    this.setState({
                        load: true,
                        title: state.title || "",
                        menu: state.menu || [],
                        fullmenu: state.menu,
                        subtitle: state.subtitle,
                        selectedOrgUnitUID: state.selectedOrgUnitUID ? state.selectedOrgUnitUID : ""
                    })
                    let url = "../../../../../";
                    let res = await API.Employee.Detail(this.props.user, url, state.item);
                    if (res.status !== "1") {
                        this.setState({
                            load: false,
                            item: res
                        }, () => {
                            let item = { path: "/home/hrms/allemporgunit/allemployee/menu/basicinfo" }
                            this.navigate(item)
                        })
                    } else {
                        this.props.errorNotification(res.message);
                    }
                }
            } else if (location.pathname.includes(this.state.location) && dataChanged && userdata && userdata.empUID) {
                this.setState({
                    item: userdata
                })
            }
        }
    }

    constructor(props){
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en"); 
        this.state = {
            location: '',
                load: false,
                item: {empUID:null},
                subtitle: "Human Resource Management System",
                title: "Menu",
                menu: [],
                fullmenu: [],
                input: ''
           
        }
    }
    

    async componentDidMount() {
        let { match } = this.props;
        let { history: { location: { state } } } = this.props;
        if(state===undefined)
        {
            state = {
                location: '',
                load: false,
                item: {empUID:null},
                subtitle: "Human Resource Management System",
                title: "Menu",
                menu: [],
                fullmenu: [],
                input: ''
            }
        
        }
           
        if (match.path === "/home/hrms/allemporgunit/allemployee/menu") {
            this.setState({
                load: true,
                location: 'allemporgunit/allemployee/menu',
                title: state.title || "",
                icon: state.icon,
                menu: state.menu || [],
                fullmenu: state.menu,
                subtitle: state.subtitle,
                selectedOrgUnitUID: state.selectedOrgUnitUID
            })
            let url = "../../../../";
            let res = await API.Employee.Detail(this.props.user, url, state.item);
            if (res.status !== '1') {
                this.setState({
                    load: false,
                    item: res
                }, () => {
                    let item = { path: "/home/hrms/allemporgunit/allemployee/menu/basicinfo" }
                    this.navigate(item)
                })
            } else {
                this.props.errorNotification(res.message);
            }
        } else if (match.path === '/home/hrms/myinfo') {
            this.setState({
                load: true,
                location: '/hrms/myinfo',
                title: state.title || "",
                icon: state.icon,
                menu: state.menu || [],
                fullmenu: state.menu,
                subtitle: state.subtitle
            })
            let url = "../../../../";
            let res = await API.Employee.Detail(this.props.user, url);
            if (res.status !== '1') {
                this.setState({
                    load: false,
                    item: res,
                    subtitle: res.firstName + " " + res.lastName
                })
            } else {
                this.props.errorNotification(res.message);
            }
        } else if (match.path === '/home/hrms/myinfo/workhistory' || match.path === '/home/hrms/allemporgunit/allemployee/menu/workhistory') {
            this.setState({
                noapi: true,
                item: state.item,
                location: 'workhistory/',
                title: state.title || "",
                icon: state.icon,
                menu: state.menu || [],
                fullmenu: state.menu,
                subtitle: state.item.employeeCode
            })
        } else if (match.path === '/home/hrms/absence/mypresence') {
            this.setState({
                noapi: true,
                item: state.item,
                location: 'mypresence/',
                title: state.title || "",
                icon: state.icon,
                menu: state.menu || [],
                fullmenu: state.menu,
                subtitle: state.title
            })
        } else if (match.path === '/home/hrms/absence/allpresence') {
            this.setState({
                noapi: true,
                item: state.item,
                location: 'allpresence/',
                title: state.title || "",
                icon: state.icon,
                menu: state.menu || [],
                fullmenu: state.menu,
                subtitle: state.title
            })
        } else if (match.path === '/home/hrms/absence/approveadjustment') {
            this.setState({
                noapi: true,
                item: state.item,
                location: 'approveadjustment/',
                title: state.title || "",
                icon: state.icon,
                menu: state.menu || [],
                fullmenu: state.menu,
                subtitle: state.title
            })
        } else if (match.path === '/home/hrms/rms/candidates') {
            this.setState({
                noapi: true,
                item: state.item,
                location: 'candidates/',
                title: state.title || "",
                icon: state.icon,
                menu: state.menu || [],
                fullmenu: state.menu,
                subtitle: state.title
            })
        } else if (match.path === '/home/hrms/rms/master') {
            this.setState({
                location: '/hrms/rms/master/',
                title: state.title || "",
                icon: state.icon,
                menu: state.menu || [],
                fullmenu: state.menu,
                subtitle: "Master"
            })
        }
        else if(match.path === '/home/epms')
        {
            this.setState({
                noapi: true,
                location: '/home/epms?',
                title: state.title || "",
                menu: (state.parentmenu?state.parentmenu:state.menu) || [],
                icon: state.icon,
                fullmenu: state.menu,
                subtitle: state.subtitle
            })
        }  
        else if(match.path === '/home/epms/okr' || match.path === '/home/epms/pem' || match.path === '/home/epms/modsetup' || match.path === '/home/epms/perdsetup' || match.path === '/home/epms/teamsetup')
        {
            this.setState({
                noapi: true,
                location: '/home/epms?',
                title: state.title || "",
                menu: state.menu || [],
                icon: state.icon,
                fullmenu: state.menu,
                subtitle: state.subtitle
            })
        } 
        else {
            this.setState({
                noapi: true,
                location: '/home/hrms?',
                title: state.title || "",
                menu: state.menu || [],
                icon: state.icon,
                fullmenu: state.menu,
                subtitle: state.subtitle
            })
        }

    }

    state = {
        location: '',
        load: false,
        item: {},
        subtitle: "Human Resource Management System",
        title: "Menu",
        menu: [],
        fullmenu: [],
        input: ''
    }

    navigate = (item) => {
        let { match } = this.props;
        if (match.path === '/home/hrms/allemporgunit/allemployee/menu') {
            if (item.path.includes('basicinfo')) {
                this.setState({ location: 'basicinfo' })
                this.props.history.push({
                    pathname: item.path,
                    state: {
                        icon: item.icon,
                        item: { ...this.state.item }
                    }
                })
            } else if (item.path.includes('workhistory')) {
                this.props.history.push({
                    pathname: item.path,
                    state: {
                        icon: item.icon,
                        item: { ...this.state.item },
                        menu: item.subMenu,
                        title: item.value,
                        //empUID: this.props.location.state.item.empUID
                        empUID: this.state.item.empUID
                    }
                })
                showLastBlade();
            } else if (item.path.includes('validloc')) {
                this.props.history.push({
                    pathname: item.path,
                    state: {
                        icon: item.icon,
                        item: { ...this.state.item },
                        menu: item.subMenu,
                        title: item.value,
                        selectedOrgUnitUID: this.state.selectedOrgUnitUID
                    }
                })
                showLastBlade();
            } else {
                this.setState({ location: 'allemporgunit/allemployee/menu/' })
                this.props.history.push({
                    pathname: item.path,
                    state: {
                        icon: item.icon,
                        item: { ...this.state.item }
                    }
                })
            }
            showLastBlade();
        } else if (match.path === '/home/hrms/myinfo') {
            if (item.path.includes('basicinfo')) {
                this.setState({ location: 'basicinfo' })
                this.props.history.push({
                    pathname: item.path,
                    state: {
                        icon: item.icon,
                        item: { ...this.state.item }
                    }
                })
            } else if (item.path.includes('workhistory')) {
                this.props.history.push({
                    pathname: item.path,
                    state: {
                        icon: item.icon,
                        item: { ...this.state.item },
                        menu: item.subMenu,
                        title: item.value
                    }
                })
                showLastBlade();
            } else {
                this.setState({ location: 'myinfo/' })
                this.props.history.push({
                    pathname: item.path,
                    state: {
                        icon: item.icon,
                        title: item.value,
                        item: { ...this.state.item }
                    }
                })
            }
            showLastBlade();
        } else if (match.path === '/home/hrms/allemporgunit/allemployee/menu/workhistory'
            || match.path === '/home/hrms/myinfo/workhistory') {
            this.props.history.push({
                pathname: item.path,
                state: {
                    title: item.value,
                    icon: item.icon,
                    item: { ...this.state.item }
                }
            })
            showLastBlade();
        } else if (match.path === '/home/hrms/absence/mypresence') {
            this.props.history.push({
                pathname: item.path,
                state: {
                    title: item.value,
                    icon: item.icon,
                    item: { ...this.state.item }
                }
            })
            showLastBlade();
        } else if (match.path === '/home/hrms/absence/allpresence') {
            this.props.history.push({
                pathname: item.path,
                state: {
                    title: item.value,
                    icon: item.icon,
                    item: { ...this.state.item }
                }
            })
            showLastBlade();
        } else if (match.path === '/home/hrms/absence/approveadjustment') {
            this.props.history.push({
                pathname: item.path,
                state: {
                    title: item.value,
                    icon: item.icon,
                    item: { ...this.state.item }
                }
            })
            showLastBlade();
        } else if (match.path === '/home/hrms/absence/candidates') {
            this.props.history.push({
                pathname: item.path,
                state: {
                    title: item.value,
                    icon: item.icon,
                    item: { ...this.state.item }
                }
            })
            showLastBlade();
        } else if (match.path === '/home/hrms/rms/master') {
            this.setState({ location: 'rms/master/' })
            this.props.history.push({
                pathname: item.path,
                state: {
                    icon: item.icon,
                    item: { ...this.state.item }
                }
            })
        } 
        else {
            this.props.history.push({
                pathname: item.path,
                state: {
                    menu: item.subMenu,
                    title: item.value,
                    icon: item.icon,
                    parentmenu:this.state.menu
                }
            })
        }
    }

    close = () => {
        let { match, history } = this.props;
        if (match.path.toLowerCase() === "/home/hrms/myinfo" || match.path.toLowerCase() === "/home/hrms/absence/mypresence" ||
            match.path.toLowerCase() === "/home/hrms/absence/allpresence" || match.path.toLowerCase() === "/home/hrms/absence/approveadjustment" ||
            match.path.toLowerCase() === "/home/hrms/absence/candidates") {
            //history.push("/home/hrms");
            history.push("/home");
        } else if (match.path === "/home/hrms/allemporgunit/allemployee/menu") {
            history.push("/home/hrms/allemporgunit/allemployee",{from:'back'})
        } else if (match.path.includes('/allemporgunit/allemployee/menu/workhistory')) {
            history.push('/home/hrms/allemporgunit/allemployee',{from:'back'})
        } else if (match.path.includes('/myinfo/workhistory')) {
            history.push('/home/hrms/myinfo')
        } else if (match.path.toLowerCase() === '/home/hrms/rms/candidates') {
            //history.push('/home/hrms')
            history.push("/home");
        } else if (match.path.toLowerCase().includes('rms/master')) {
            //history.push('/home/hrms')
            history.push("/home");
        } else {
            history.push("/home");
        }
    }

    handleChange = (e) => {
        this.setState({
            input: e.target.value
        }, () => {
            this.filter(this.state.input);
        })
    }

    filter = (e) => {
        let menu = this.state.fullmenu;
        let filteredmenu = menu.filter((menu) => {
            if (menu.subMenu) {
                return menu.subMenu.some((submenu) => {
                    return submenu.value.toLowerCase().includes(e.toLowerCase())
                })
            }
            return false;
        })
        this.setState({
            menu: []
        }, () => {
            this.setState({ menu: filteredmenu })
        })
    }
    clearFilter = () => {
        this.setState({
            input: ''
        }, () => {
            this.filter("");
        })
    }
    render() {
        let { history: { location: { pathname } } } = this.props;
        let { icon, menu, title, subtitle, location } = this.state;
        return (
            <Blade match={this.props.match}
                close={this.close}
                load={this.state.load}
                icon={icon || '/images/hrms.png'}
                head={title}
                subhead={subtitle}
                //toolinput={this.filter}

                handleclick={(e) => { this.toolbar(e) }}>
                <BladeBody match={this.props.match} notoolbar load={this.state.load}>
                    <Searchbox
                        value={this.state.input}
                        clearFilter={this.clearFilter}
                        onChange={this.handleChange}
                    />
                    <div className="divider"></div>
                    <ul className="list __items" style={{ margin: 1 }}>
                        {menu.map((item, id) => (
                            <div className="list" key={id}>
                                <p style={{ marginTop: 10, marginBottom: 5, padding: 0, fontSize: 12, fontWeight: 'bold' }}>{item.value !== null ? <Translate id={item.value === null ? "" : item.value} >{item.value === null ? "" : item.value}</Translate> : ""}</p>

                                <UnorderList
                                    path={pathname}
                                    id={location.includes('epms?')
                                        ? `${id}hrms` :
                                        location.includes('hrms?')
                                        ? `${id}hrms`
                                        : location.includes('myinfo')
                                            ? `${id}myinfo`
                                            : location.includes('menu')
                                                ? `${id}menu`
                                                : location.includes('abscence')
                                                    ? `${id}abscence`
                                                    : ''}
                                    imagestyle={{ height: 20, width: 20 }}
                                    style={{ paddingLeft: 10, verticalAlign: "baseline", fontSize: 13 }}
                                    itemclick={this.navigate}
                                    list={item.subMenu || []} />
                            </div>
                        ))}
                    </ul>
                </BladeBody>
            </Blade >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    errorNotification
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    dataChanged: state.notification.dataChanged,
    userdata: state.notification.userdata
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(Menu))
import React from 'react'
import { Route } from 'react-router-dom'
//components
import ContactAddressDetails from "./ContactAddressDetails"
import ContactAddressDetailsList from "./ContactAddressDetailsList"

class ContactDetails extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route path={`${match.path}`} component={ContactAddressDetailsList} />
                <Route path={`${match.path}/add`} component={ContactAddressDetails} />
                <Route path={`${match.path}/edit`} component={ContactAddressDetails} />
            </React.Fragment>
        );
    }
}

export default ContactDetails;
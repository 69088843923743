import React from 'react';
import BreadcrumbConfig from 'auto-breadcrumb';
import Breadcrumb from './breadCrumb';
import BreadcrumbsItem from './BreadcrumbItem';
import { Link } from 'react-router-dom';


const Breadcrumbs = BreadcrumbConfig({
    Breadcrumb: Breadcrumb,
    BreadcrumbsItem: BreadcrumbsItem,
    itemRender: (name, path) =>
        path
            ? <Link to={path}>
                {name}
            </Link>
            : `${name}`
});

export default Breadcrumbs
import React, { Component } from "react";

import Blade from "../../../../Components/Blade";
import BladeBody from "../../../../Components/Blade/BladeBody";

import TableList from "../../../../Components/Lists/TableList";
import Pagination from '../../../../Components/Pagination';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { errorNotification, createNotification } from "../../../../_reducers/reducer.notification";
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../_language/en.json';
import id from '../../../../_language/id.json';

//methods
import { showLastBlade } from "../../../../_helpers/helper.methods";
//api

import API from "../../../../_apis/hrms/master";
import EmployeeAPI from '../../../../_apis/hrms/employee';

class List extends Component {
    async componentDidUpdate(prevProps, prevState) {
        let { dataChanged, history: { location } } = this.props;   
        if (prevProps !== this.props) {
            if (dataChanged) {
                if (location.pathname.includes('edit')
                    || location.pathname.includes('add')) {
                    this.setState({
                        load: true,
                        orgUnitUID:location.state.orgUnitUID
                    })
                    //let url = '../../../../../../';
                    let res = await API.Location.List(this.props.user, location.state.orgUnitUID, "");
                    this.setState({ load: false })
                    if (res.status !== '1') {
                        this.setState({
                            list: res
                        })   
                       
                    }
                }
            } 
            else if (location.state && location.state.previous) {    
                //let url = '../../../../../';
                if(location.state){
                this.setState({
                    subtitle: location.state.subtitle,
                    orgUnitUID: location.state.item.uid,
                    load: true
                });
                let res = await API.Location.List(this.props.user, location.state.orgUnitUID, "");
                this.setState({ load: false })
                if (res.status !== '1') {
                    this.setState({
                        list: res
                    })
                } 
                }
            }
        }   
    }
    async componentDidMount() {
        let { match, history: { location: { state } } } = this.props;
        let url = '../../../../../../';
        let res = await EmployeeAPI.Employee.Detail(this.props.user, url);
        if(res.roleList.length)
        {
            this.setState({
                roleName: res.roleList[0].roleName
            });
        }          
        if (match.path.includes('locationlist')) {           
            this.setState({
                title: 'Location List',
                subtitle: state.subtitle,
                orgUnitUID: state.item.uid,
                toolbar: [...this.state.protectedToolbar],
                dataLoad: 'locationlist',
                load: true
            },() =>{
                //let a = this.state.orgUnitUID;
            });
            if(state.orgUnitUID){
            let res2 = await API.Location.List(this.props.user, state.orgUnitUID, "");
            this.setState({ load: false })
            if (res2.status !== '1') {
                
                this.setState({
                    list: res2
                })

            } else {
                this.props.createNotification('Operation Failed', res2.message)
            }
            }
        } else {
            this.setState({
                title: state.title,
                subtitle: state.title
            })
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            pageOfItems: [],
            toolbar: [],
            protectedToolbar: [{
                icon: '/images/add_black.png',
                name: <Translate id="hrms.master.location.add" />,
                value: "add"
            }],
            roleName:'',
            dataLoad: '',
            subtitle: '',
            load: false,
            list: [],
            listOrg: [],
            orgUnitUID: ''
        }
    }
    selectedData = (item, index) => {
        let { history, match } = this.props;
        //let idKnowledge = item.orgUnitUID;
        this.setState({ index })
        if (match.path.includes('locationlist')) {
            if(this.state.roleName === "Super Admin"){
            this.setState({
                dataLoad: 'locationlist/'
            }, () => {
                history.push({
                    //pathname: `${match.path}/edit/`+idKnowledge,
                    pathname: `${match.path}/edit`,
                    state: {
                        subtitle: item.orgUnitName,
                        orgUnitUID: this.state.orgUnitUID,
                        list: this.state.list,
                        roleName: this.state.roleName,
                        item
                    }                    
                });
            })}
        } else {
            history.push({
                pathname: `${match.path}/locationlist`,
                state: {
                    //subtitle: item.text,
                    subtitle: this.state.orgUnitName,
                    previous: true,
                    roleName: this.state.roleName,
                    item
                }
            });
        }
        showLastBlade();
    }
    close = () => {
        let { history, match } = this.props;
        if (match.path.includes('locationlist')) {
            history.push('/home/hrms/master/location')
        } else {
            history.push('/home')
        }

    }
    toolbarclick = (e) => {
        let { match, history } = this.props;
        //let a = this.state.orgUnitUID;
        if (e === "add") {
            let elem = document.getElementById(`list${'locationlist'}${this.state.index}`);
            if (elem) {
                elem.classList.remove('__selected');
            }
            this.setState({
                dataLoad: 'locationlist/'
            }, () => {
                history.push({
                    pathname: `${match.path}/add`,
                    state: {
                        orgUnitUID:this.state.orgUnitUID,
                        list: this.state.list
                    }
                });
            })
        }
        showLastBlade();
    }
    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems: pageOfItems });
    }
    sortDirection = (e, field) => {
        let sortOrder = this.state.sortOrder;
        if (sortOrder.includes(field)) {
            if (sortOrder.includes('asc')) {
                this.setState({
                    sortOrder: field + "-desc",
                }, () => {
                    this.onSort(this.state.sortOrder);
                });
            } else {
                this.setState({
                    sortOrder: field + "-asc",
                }, () => {
                    this.onSort(this.state.sortOrder);
                });
            }
        } else {
            this.setState({
                sortOrder: field + "-asc",
            }, () => {
                this.onSort(this.state.sortOrder);
            });
        }
    }

    render() {
        //let { master } = this.props;
        let { list, toolbar, pageOfItems } = this.state;

        let { match } = this.props;
        return (
            <Blade match={this.props.match}
                max={match.path.includes('locationlist') ? 'max': 'true'}
                load={this.state.load}
                // close={this.close}
                close={() => this.props.history.push("/home")}
                icon={this.state.icon || '/images/location.png'}
                head={this.state.title}
                subhead={this.state.subtitle}
                toolbar={this.state.roleName === "Super Admin" ? toolbar : ''}
                handleclick={this.state.roleName === "Super Admin" ? this.toolbarclick : ''}>
                <BladeBody match={this.props.match} load={this.state.load}>
                    <Pagination
                        mainFilter={{ jsonName: 'locationType', disName: 'Location Type' }}
                        filters={[{ jsonName: 'locationName', disName: 'Location Name' },
                        { jsonName: 'orgName', disName: 'Org Name' }]}

                        items={list}
                        onChangePage={(e) => { this.onChangePage(e) }} 
                        onRef={(onSort) => {this.onSort = onSort}}/>

                    <TableList
                        id='locationlist'
                        returnSelected={this.selectedData}
                        fields={["locationType", 'locationName', 'orgName']}
                        list={pageOfItems || []}
                        header={[<Translate id="hrms.master.orgUnit.locationType" />, <Translate id="hrms.master.orgUnit.locationName" />, <Translate id="hrms.master.orgUnit.orgName" />]} 
                        onHeaderClick={this.onSort}>
                    </TableList>
                </BladeBody>
            </Blade>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    errorNotification
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    dataChanged: state.notification.dataChanged,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(List))
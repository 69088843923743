import React from 'react'
import { Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'
//components
import Menu from "../../../Components/Menu/Menu";
import BasicInfo from "./MyInformation/ProfileInfo/BasicInfo";
import ContactDetails from "./MyInformation/ContactDetails";
import FamilyDetails from "./MyInformation/FamilyDetails";
import EducationDetails from "./MyInformation/EducationDetails";
import WorkHistory from "./MyInformation/WorkHistory";
import MyOrgChart from "./MyInformation/OrganisationalChart/MyOrgChart";
import Role from './MyInformation/Role';
import CTCInfo from './MyInformation/CTCInfo/CTCInfo';
import IdCardInfo from './MyInformation/IdCardInfo';
import ValidLocInfo from './MyInformation/ValidLocInfo';
import OtherInfo from './MyInformation/OtherInfo/OtherInfo';

class EmployeeInfo extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Helmet>
                    <title>Sarvam - Manage Employee Information</title>
                </Helmet>
                <Route path={`${match.path}`} component={Menu} />
                <Route path={`${match.path}/basicinfo`} component={BasicInfo} />
                <Route path={`${match.path}/contactdetails`} component={ContactDetails} />
                <Route path={`${match.path}/familydetails`} component={FamilyDetails} />
                <Route path={`${match.path}/educationdetails`} component={EducationDetails} />
                <Route path={`${match.path}/workhistory`} component={WorkHistory} />
                <Route path={`${match.path}/orgchart`} component={MyOrgChart} />
                <Route path={`${match.path}/validrole`} component={Role} />
                <Route path={`${match.path}/idcardInfo`} component={IdCardInfo} />
                <Route path={`${match.path}/CTCInfo`} component={CTCInfo} />
                <Route path={`${match.path}/validloc`} component={ValidLocInfo} />
                <Route path={`${match.path}/otherinfo`} component={OtherInfo} />
            </React.Fragment>
        );
    }
}

export default EmployeeInfo;
import React, { Component } from "react";
import { withTheme } from 'theming';
import { connect } from 'react-redux';

class BladeBody extends Component {
    checkPathBeforeShowLoading = (masterload) => {
        let { match } = this.props;
        if (match.path === masterload.path && masterload.load) {
            return true
        } else {
            return false
        }
    }
    render() {
        let { theme, masterLoading } = this.props;
        return (
            <div style={this.props.notoolbar
                ? {
                    height: "calc(100% - 60px)", color: theme.bladebody.color,
                    backgroundColor: theme.bladebody.backgroundColor
                }
                : theme.bladebody}
                className={this.props.load || this.checkPathBeforeShowLoading(masterLoading) ? "blade-container __loading" : "blade-container"}>
                <div className="blade-content" >
                    <div className="blade-inner">
                        <div className="inner-block">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    masterLoading: state.user.masterLoading,
})
export default connect(
    mapStateToProps
)(withTheme(BladeBody));


import React from "react";
import { withTheme } from 'theming';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../_language/en.json';
import id from '../../_language/id.json';

class UnorderList extends React.Component {
    //componentWillReceiveProps(next) {
        // if (next.list) {
        //     this.setState({
        //         id: this.props.id || 'nomenu',
        //         list: next.list
        //     })
        // }

    //}

    constructor(props){
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
    }

    click = (item, i) => {
        let selitem = Object.assign({}, item);
        this.props.itemclick(selitem, i)
    }
    state = {
        id: this.props.id || 'nomenu',
        selected: -1,
        list: this.props.list || []
    }
    getIcon = (item) => {
        let { menu } = this.props.theme;
        if (menu.color === 'white') {
            return item.replace('_black.png', '.png');
        } else if (menu.color === 'black') {
            return item.replace('.png', '_black.png');
        } else {
            return '#';
        }
    }
    render() {
        let { id } = this.state;
        let { style, imagestyle, theme } = this.props;
        return (
            <ul className="list __items" style={{ backgroundColor: theme.menu.backgroundColor }}>
                {this.state.list.map((item, index) => (
                    <li id={`${id}${index}`} key={index} className={theme.menu.color === 'white' ? "list-item dark" : 'list-item'} onClick={() => this.click(item, index)} style={{
                        padding: '5px 5px 5px 0px', backgroundColor: theme.menu.backgroundColor,
                        borderBottom: theme.menu.borderBottom
                    }}>
                        <span className="" >{item.icon && <img alt={"icon"} style={imagestyle ? imagestyle : { height: 20, width: 20 }} src={`${this.getIcon(item.icon)}`} />}</span>
                        <span style={Object.assign({}, style, { color: theme.menu.color })} className="list-name"><Translate id={item.value} >{item.value}</Translate></span>
                    </li>
                ))}

            </ul>
        )
    }
}

export default withLocalize(withTheme(UnorderList));
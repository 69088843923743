export const SET_THEME = 'theme'

const initialState = {
    theme: getTheme(2)
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_THEME:
            return {
                ...state,
                theme: action.theme
            }

        default:
            return state
    }
}
export const setTheme = (theme) => {
    return dispatch => {
        dispatch({
            type: SET_THEME,
            theme
        })
    }
}

export const lightTheme = {
    nav: {},
    topbar: {},
    notification: {},
    breadcrumb: {},
    table: {
        color: 'black',
        background: 'white'
    },
    bladetoolbar: {
        color: 'black',
        backgroundColor: 'white'
    },
    bladebody: {
        color: 'black',
        backgroundColor: 'white'
    },
    menu: {
        color: 'black',
        backgroundColor: 'white'
    },
    checkbox: {
        color: 'black',
        backgroundColor: ''
    },
    inputs: {
        color: 'black',
        backgroundColor: ''
    },
    pagination:{
        color: 'black',
        backgroundColor: ''
    },
    dashboard: {}
}

export const darkTheme = {
    nav: {},
    topbar: {},
    notification: {},
    breadcrumb: {},
    table: {
        color: 'white',
        background: '#232323'
    },
    bladetoolbar: {
        color: 'white',
        backgroundColor: '#232323'
    },
    bladebody: {
        color: 'white',
        backgroundColor: '#232323'
    },
    menu: {
        color: 'white',
        backgroundColor: '#232323',
        borderBottom: '1px solid #3d3d3d'
    },
    checkbox: {
        color: 'white',
        backgroundColor: '#232323'
    },
    inputs: {
        color: 'white',
        backgroundColor: '#333'
    },
    pagination:{
        color: 'white',
        backgroundColor: '#333'
    },
    dashboard: {
        color: 'white',
        backgroundColor: '#333'
    }
}

export function getTheme(no) {

    let theme1 = {
        nav: {
            color: "white",
            backgroundColor: '#003c6c'
        },
        topbar: {
            color: "white",
            backgroundColor: '#003056'
        },
        dropdownText: {
            color: "white",
        },
        notification: {
            color: "black",
            backgroundColor: "white"
        },
        breadcrumb: {
            backgroundColor: '#295b84'
        },
        bladetoolbar: {
            color: 'black',
            backgroundColor: 'white'
        },
        bladebody: {
            color: 'black',
            backgroundColor: 'white'
        },
        menu: {
            color: 'black',
            backgroundColor: ''
        },
        checkbox: {
            color: 'black',
            backgroundColor: ''
        },
        inputs: {
            color: 'black',
            backgroundColor: ''
        },
        pagination:{
            color: 'black',
            backgroundColor: ''
        },
        dashboard: {}
    }
    let theme2 = {
        nav: {},
        topbar: {},
        notification: {},
        breadcrumb: {},
        table: {
            color: 'black',
            background: 'white'
        },
        bladetoolbar: {
            color: 'black',
            backgroundColor: 'white'
        },
        bladebody: {
            color: 'black',
            backgroundColor: 'white'
        },
        menu: {
            color: 'black',
            backgroundColor: 'white'
        },
        checkbox: {
            color: 'black',
            backgroundColor: ''
        },
        inputs: {
            color: 'black',
            backgroundColor: ''
        },
        pagination:{
            color: 'black',
            backgroundColor: ''
        },
        dashboard: {}
    }
    let bladetheme1 = {
        nav: {},
        topbar: {},
        notification: {},
        breadcrumb: {},
        table: {
            color: 'white',
            background: '#333'
        },
        bladetoolbar: {
            color: 'white',
            backgroundColor: '#333'
        },
        bladebody: {
            color: 'white',
            backgroundColor: '#333'
        },
        menu: {
            color: 'white',
            backgroundColor: '#333',
            borderBottom: '1px solid #3d3d3d'
        },
        checkbox: {
            color: 'white',
            backgroundColor: '#333'
        },
        inputs: {
            color: 'white',
            backgroundColor: '#333'
        },
        pagination:{
            color: 'white',
            backgroundColor: '#333'
        },
        dashboard: {
            color: 'white',
            backgroundColor: '#333'
        }
    }
    switch (no) {
        case 1: return theme1;
        case 2: return theme2;
        case 3: return bladetheme1;
        case 4: return theme1
        default: return {}
    }
}
import React from "react";
import Datetime from "react-datetime";
import { ValidatorComponent } from 'react-form-validator-core';
import { withTheme } from 'theming';
class DateTimePicker extends ValidatorComponent {

    render() {
        const { theme, value, name, label, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        let required = validators[0] === 'required';
        return (
            <div>
                <Datetime
                    value={value}
                    {...rest}
                    renderInput={(a, b, c) => this.renderInput(a, b, c, theme, required)} />
                <input
                    style={{ display: "none" }}
                    value={value}
                    name={name}
                    readOnly='readonly'/>
                    {/* ref={(r) => { this.input = r; }} /> */}
                {this.errorText()}
            </div>
        );
    }
    renderInput(props, openCalendar, closeCalendar, theme, required) {
        // function clear() {
        //     props.onChange({ target: { value: '' } });
        // }
        return (
            <div className="form-group">
                {(props.label !== undefined && props.label !== '') && ( 
                <label className="form-label">{props.label}
                    <span style={{ color: 'red' }}>{required && ' *'}</span>
                </label>)}
               

                <div className="form-input">
                    <input
                        ref={(r) => this.input = r}
                        style={theme.inputs}
                        {...props}
                    />
                    <button type="button" disabled={props.disabled} onClick={openCalendar} className="btn __other" >
                        <i className="btn-ico fa fa-calendar"></i>
                    </button>
                </div>
            </div>
        );
    }
    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'red', fontSize: 12 }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default withTheme(DateTimePicker);
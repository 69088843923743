import React, { useState } from 'react';
import Api from "../../_apis";
import { ValidatorForm } from "react-form-validator-core";
import CValidator from "../../Components/Validators/CValidator";
import { handleFormErrors } from '../../_helpers/helper.methods';

const LoginEmail = React.forwardRef((props, ref) => {
    // initialization
    const [email, setEmail] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState("")

    const EmailRequest = async (e) => {
        setError("")
        setSubmitted(true)

        const data = new FormData(e.target);
        let res = await Api.Login.Email(data);
        setSubmitted(false)
        if (res.status !== '1') {
            props.history.push({
                pathname: "/loginpass",
                state: { res, UserId: email }
            })
        } else {
            setError(res.message)
        }
    }

    //render() {     
    //let { submitted, email, error } = this.state;
    return (
        <React.Fragment>
            <img className='logo' src='/images/sarvamnamegreen.png' alt="" />
            <div className='window'>
                {submitted && <div className="window-status">
                    <div className={"progress-bar"}></div>
                </div>}
                <header className='window-head'>
                    <span className='window-t'>Sign in</span>
                </header>
                <div className='window-cnt'>
                    <ValidatorForm
                        ref={ref}
                        onError={handleFormErrors}
                        onSubmit={EmailRequest}
                    >
                        <CValidator
                            label={"Username"}
                            value={email}
                            name="UserId"
                            onChange={(e) => setEmail(e.target.value)}
                            validators={['required']}
                            errorMessages={['This field is required']}
                            autoFocus
                        />
                        <div className="clearfix">
                            <div className="column">
                                <div className="form-group">
                                    <a onClick={() => props.history.push("/subscription")} href="">Don't have an account yet? Sign Up</a>
                                </div>
                            </div>
                            <div className="column">
                                <div className="form-group __right">
                                    <button style={submitted ? { minWidth: 100 } : {}} disabled={submitted ? true : false} className={submitted ? "btn __loading" : "btn"}>
                                        <span>Submit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ValidatorForm>
                    {error &&
                        <div style={{ color: 'red' }}>{error}</div>
                    }
                </div>
            </div >
        </React.Fragment>
    );
    //)
})

export default LoginEmail;
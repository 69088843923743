import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, SubModuleUID) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Type', "List");
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('SubModuleUID', SubModuleUID);
    formData.append('IsActive', true);
    formDataConsole(formData);

    let url = '/api/wf/core/action/list';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//action type list response", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
        .catch(checkAPIError);
}

export const Add = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature =  user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
   
    formDataConsole(formData);
    let url = '/api/wf/core/action/add'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//action type add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user) => {
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/wf/core/action/update';
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//action type update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Delete = (user, ActionUID) => {
    let formData = new FormData();      
    let signature = ActionUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ActionUID', ActionUID);

    let url = '/api/wf/core/action/delete';    
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('//action type delete response', res)
            return res;
        })
        .catch(checkAPIError);
}

export const Detail = (formData, user, directory) => {
    let signature = formData.get('ActionUID').toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    let url = '/api/wf/core/action/detail';

    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//action type detail response", res)
            return res;
        })
        .catch(checkAPIError);
}
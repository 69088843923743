import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from '../../../_helpers/helper.methods';
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const Add = (formData, user, directory, type, employee) => {
    let { language: { ipaddress } } = store.getState();
    let signature;
    let url;
    if (type === 'current') {
        url = '/api/hrms/employee/work/currentcompany/add';
        signature = formData.get('orgUnitUID').toLowerCase() + user.empUID.toLowerCase() + user.token;
    } else if (type === 'previous') {
        url = '/api/hrms/employee/work/previouscompany/add';
        formData.append('AdditionalWork', 0);
        formData.append('WorkPercentage', 0);
        if (employee) {
            signature = employee.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
        } else {
            signature = user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
        }
    }

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmpUID', employee ? employee.empUID : user.empUID);

    formDataConsole(formData);

    devLog("raw sig", signature);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
  //  return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// work add response', res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user, directory, type, state,employee) => {
    let { language: { ipaddress } } = store.getState();
    let signature;
    let url;
    if (type === 'current') {
        url = '/api/hrms/employee/work/currentcompany/update';
        signature = state.workUID.toLowerCase() + user.empUID.toLowerCase() + user.token;

    } else if (type === 'previous') {
        formData.append('AdditionalWork', 0);
        url = '/api/hrms/employee/work/previouscompany/update';
        signature = state.workUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    }

    devLog("raw sig", signature);
    devLog('url', url);
    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('workUID', state.workUID);
    formData.append('EmpUID', employee ? employee.empUID : user.empUID);

    formDataConsole(formData);

    return fetch(DEV_URL.concat(url), {
   // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// work update response', res)
            return res;
        })
        .catch(checkAPIError);
}

export const Delete = (user, workUID, directory) => {
    let formData = new FormData();
    let signature = workUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('workUID', workUID);

    formDataConsole(formData)

    let url='/api/hrms/employee/work/currentcompany/delete';
    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, ''), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// work delete response', res)
            return res;
        })
        .catch(checkAPIError);
}


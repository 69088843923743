import React from 'react';

class Modal extends React.Component {

    render() {
        return (
            <React.Fragment>
                {this.props.modal &&
                    <React.Fragment>
                        <div className="modal-backdrop fade in"
                            style={{ zIndex: 1040 }}></div>
                        <div modal-render="true" tabIndex="-1"
                            role="dialog" className="modal fade in"
                            style={{ zIndex: 1050, justifyContent: 'center', alignItems: 'center', display: "flex", opacity: 1 }}>
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h3 className="modal-title">{this.props.title}</h3>
                                    </div>
                                    <div className="modal-body">
                                        {this.props.children}
                                    </div>
                                    <div className="modal-footer">
                                        <button onClick={this.props.Ok} className="btn">{this.props.footer[0]}</button>
                                        <button onClick={this.props.Cancel} className="btn" >{this.props.footer[1]}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>}
            </React.Fragment>
        )
    }
}
export default Modal
import React from 'react'
import { Route } from 'react-router-dom'
//components
import ValidLocInfoDetail from "./ValidLocInfoDetail"
import ValidLocInfoList from "./ValidLocInfoList"

class ValidLocInfo extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route path={`${match.path}`} component={ValidLocInfoList} />
                <Route path={`${match.path}/add`} component={ValidLocInfoDetail} />
                <Route path={`${match.path}/edit`} component={ValidLocInfoDetail} />           
            </React.Fragment>
        );
    }
}

export default ValidLocInfo;
import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, OrgUnitUID, active) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('OrgUnitUID', OrgUnitUID);
    formData.append('isActive', active || true);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);  

    formDataConsole(formData);

    let url = '/api/hrms/documents/list';

    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//documentlist response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('DocumentTitle').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);
    let url = '/api/hrms/documents/add'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// document add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user) => {
    let signature = formData.get('DocumentTitle').toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/hrms/documents/update';
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// document update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Deactive = (user, documentUID) => {
    let formData = new FormData();
    let signature = documentUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('UID', documentUID);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/hrms/documents/deactive';
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// document deactive response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const PaySlipList = (user) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('EmployeeUID', user.empUID);
    formData.append('DocumentType', 'Pay Slip');
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress); 
    formDataConsole(formData);

    let url = '/api/hrms/employee/payslip/list';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//payslip list response", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
        .catch(checkAPIError);
}

export const IncomeTaxList = (user) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('EmployeeUID', user.empUID);
    formData.append('DocumentType', 'Income Tax');
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress); 
    formDataConsole(formData);

    let url = '/api/hrms/employee/incometax/list';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//income tax list response", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
        .catch(checkAPIError);
}

export const PersonalDocList = (user) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress); 
    formDataConsole(formData);

    let url = '/api/hrms/employee/personaldoc/list';

    devLog('url', url)
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("//personal docs list response", res)
            if (res.status !== '1') {
                return res;
            } else {
                throw Error(res.message);
            }
        })
        .catch(checkAPIError);
}

export const PersonalDocAdd = (formData, user) => {
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/hrms/employee/personaldoc/add';
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// personal doc add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const PersonalDocUpdate = (formData, user) => {
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/hrms/employee/personaldoc/update';
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// personal doc update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const PersonalDocDetail = (user, DocumentUID) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('DocumentUID', DocumentUID);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/hrms/employee/personaldoc/detail';
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// personal doc detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const PersonalDocDelete = (user, DocumentUID) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('DocumentUID', DocumentUID);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData)
    let url = '/api/hrms/employee/personaldoc/delete';
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// personal doc delete response", res)
            return res;
        })
        .catch(checkAPIError);
}
import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";

import TableList from "../../../../../Components/Lists/TableList";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateComponent, errorNotification } from '../../../../../_reducers/reducer.notification';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';
//methods
import { showLastBlade } from "../../../../../_helpers/helper.methods";
//api
import API from "../../../../../_apis/hrms/employee";

import Pagination from '../../../../../Components/Pagination';

class ContactAddressDetailsList extends Component {
    async componentDidUpdate(prevProps, prevState) {
        let { dataChanged, history: { location } } = this.props;

        if (dataChanged && prevProps !== this.props) {
            this.setState({
                load: true
            })
            if (location.pathname.includes("/home/hrms/allemporgunit/allemployee/menu/contactdetails/")) {
                let url = "../../../../../";
                let res = await API.Employee.Detail(this.props.user, url, this.state.item);
                if (res.status !== '1') {
                    this.props.updateComponent(res);
                    this.setState({
                        load: false,
                        list: res.addressList,
                    })
                } else {
                    this.props.errorNotification(res.message);
                }
            } else if (location.pathname.includes('/home/hrms/myinfo/contactdetails/')) {

                let url = "../../../../../";
                let res = await API.Employee.Detail(this.props.user, url);
                if (res.status !== '1') {
                    this.props.updateComponent(res);
                    this.setState({
                        load: false,
                        list: res.addressList,
                    })
                } else {
                    this.props.errorNotification(res.message);
                }
            }
        }
    }
    componentDidMount() {
        let { match, history } = this.props;
        let { item, icon } = this.props.history.location.state;
        this.setState({ icon });
        if (match.path.includes("allemployee")) {
            setTimeout(() => {
                this.setState({
                    subtitle: item.employeeCode,
                    item: item,
                    list: item.addressList
                })
            }, 1000);
        } else {
            setTimeout(() => {
                this.setState({
                    subtitle: item.employeeCode,
                    item: item,
                    list: item.addressList
                })
            }, 1000);
        }
        if (item.addressList.length === 0) {
            history.push({
                pathname: `${match.path}/add`,
                state: { item: item }
            });
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            pageOfItems: [],
            subtitle: '',
            item: {},
            load: false,
            list: []
        }
    }
    selectedData = (item, index) => {
        let { history, match } = this.props;
        this.setState({
            index
        })
        history.push({
            pathname: `${match.path}/edit`,
            state: { item, empoyee: this.state.item, list: this.state.list }
        });
        showLastBlade();
    }
    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems: pageOfItems });
    }

    close = () => {
        let { match, history } = this.props;
        if (match.path.includes("/hrms/myinfo")) {
            history.push("/home/hrms/myinfo")
        } else if (match.path.includes("allemployee/menu")) {
            history.push("/home/hrms/allemporgunit/allemployee/menu")
        } else {
            history.push("/home");
        }
    }
    toolbarclick = (e) => {
        let { match, history } = this.props;
        if (e === "add") {
            let elem = document.getElementById(`list${'address'}${this.state.index}`);
            if (elem) {
                elem.classList.remove('__selected')
            }
            history.push({
                pathname: `${match.path}/add`,
                state: {
                    item: this.state.item
                }
            });
            showLastBlade();
        }
    }
    render() {
        let toolbar = [{
            icon: '/images/add_black.png',
            name: <Translate id="hrms.employeeInfo.myInformation.add" />,
            value: "add"
        }];
        return (
            <Blade match={this.props.match}
                max
                load={this.state.load}
                close={this.close}
                icon={this.state.icon || '/images/contact_details.png'}
                head={<Translate id="hrms.employeeInfo.myInformation.contactAddressDetails" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={this.state.load}>

                    <Pagination
                        filters={[{ disName: 'Address', jsonName: 'address' },
                        { disName: 'City', jsonName: 'city' },
                        { disName: 'Country', jsonName: 'country' },
                        { disName: 'Phone', jsonName: 'phone' }]}
                        mainFilter={{ disName: 'Address', jsonName: 'address' }}
                        items={this.state.list}
                        onChangePage={(e) => { this.onChangePage(e) }}
                        onRef={(onSort) => { this.onSort = onSort }}
                    />

                    <TableList
                        id='address'
                        returnSelected={this.selectedData}
                        fields={["address", 'city', 'country', 'phone', 'isActive']}
                        list={this.state.pageOfItems || []}
                        header={[<Translate id="hrms.employeeInfo.myInformation.address" />, <Translate id="hrms.employeeInfo.myInformation.city" />, <Translate id="hrms.employeeInfo.myInformation.country" />, <Translate id="hrms.employeeInfo.myInformation.phone" />, <Translate id="hrms.employeeInfo.myInformation.active" />]}
                        onHeaderClick={this.onSort}
                    >
                    </TableList>
                </BladeBody>
            </Blade >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateComponent,
    errorNotification
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    dataChanged: state.notification.dataChanged
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(ContactAddressDetailsList))
import React from "react";
import { withTheme } from 'theming';

class CheckBox extends React.Component {
    render() {
        let { theme, label, ...rest } = this.props;
        return (<div className={rest.disabled
            ? "form-control __checkbox __disabled"
            : "form-control __checkbox"}
            style={Object.assign({}, theme.checkbox, { border: "none", paddingTop: "10px !important", paddingLeft: 0 })}>
            <label className="form-label" style={{ fontWeight: 'unset', lineHeight: 1, verticalAlign: "unset" }}>
                <input {...rest} type="checkbox" style={{ fontSize: 12 }}/>
                <span className="check"></span>
                <span className="name" style={{ fontSize: 12, color: theme.checkbox.color }}>{label}</span>
            </label>
        </div>)
    }
}

export default withTheme(CheckBox);

import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from '../../../_helpers/helper.methods';
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const Update = (ctcpm,ctcpa, user, empuid) => {
    let { language: { ipaddress } } = store.getState();
    let signature
    let formData = new FormData();
    signature = empuid.toLowerCase() + user.empUID.toLowerCase() + user.token;    
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmployeeUID', empuid);
    formData.append('CTCPA', ctcpa);
    formData.append('CTCPM', ctcpm);

    formDataConsole(formData);
    let url = '/api/hrms/employee/ctc/update';

    return fetch(DEV_URL.concat(url), {
    //return fetch(directory.concat(DEV_URL, 'api/hrms/employee/ctc/update'), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// ctc update response', res)
            return res;
        })
        .catch(checkAPIError);
}
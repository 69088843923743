import React from 'react';
import RSlider from 'react-rangeslider';
import { ValidatorComponent } from 'react-form-validator-core';
import '../../Components/Theme/slider.css'

class Slider extends ValidatorComponent {

    render() {
        const { disable, name, value, label, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;

        return (
            <div className="form-group">
                <label className="form-label">{label}</label>
                <div className={disable ? 'form-input __disabled' : "form-input"} >
                    <RSlider
                        value={value}
                        {...rest}
                    />
                    <p style={{ textAlign: "center" }} className="value">{`${value} ${this.props.max !== 100 ? '' : '%'}`}</p>

                    <input style={{ display: 'none' }}
                        value={value}
                        name={name}
                        ref={(r) => { this.input = r; }}
                    />
                </div>
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'red', fontSize: 12 }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default Slider;

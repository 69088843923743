import React, { Component } from "react";

export default class ViewContainer extends Component {
    render() {
        return (
            <article className="cnt" id={this.props.id} role="main">
                <div className="cnt-inner">
                    {this.props.children}
                </div>
            </article>
        )
    }
}


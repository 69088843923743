import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, directory, EmpUID) => {
    let formData = new FormData();
    if(!user) {
        user = JSON.parse(localStorage.getItem("user"))
    }
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);
    
    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmpUID', EmpUID ? EmpUID : '');
    formData.append('IsActive', true);

    formDataConsole(formData);
    let url='/api/hrms/leave/master/list';
    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, ''), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            if(res.status!=='1')
            {
                localStorage.setItem("leavemaster-"+EmpUID,JSON.stringify(res));
            } 
            devLog("// leave master list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (formData, user, directory) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('leaveName').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);
    let url='/api/hrms/leave/master/add';
    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, ''), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// master add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Detail = (user, directory, LeaveTypeUID, EmpUID) => {
    var formData = new FormData();
    let signature = LeaveTypeUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('LeaveTypeUID', LeaveTypeUID);
    formData.append('EmpUID', EmpUID ? EmpUID : '');

    formDataConsole(formData)

    let url='/api/hrms/leave/master/detail';
    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, ''), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// master detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user, directory, state) => {
    let signature = state.leaveTypeUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('LeaveTypeUID', state.leaveTypeUID);

    formDataConsole(formData);

    let url='/api/hrms/leave/master/update';
    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, ), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// master update response", res)
            return res;
        })
        .catch(checkAPIError);
}

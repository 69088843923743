import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'

import user from "./reducer.user";
import menu from "./reducer.menu";
import theme from "./reducer.theme";
import language from "./reducer.language";
import notification from './reducer.notification';

export default combineReducers({
    routing: routerReducer,
    user,
    menu,
    theme,
    notification,
    language
})
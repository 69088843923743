import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";
import TextValidator from "../../../../../Components/Validators/TextValidator";
import { ValidatorForm } from 'react-form-validator-core';
import SelectValidator from "../../../../../Components/Validators/SelectValidator";
import TextAreaValidator from "../../../../../Components/Validators/TextareaValidator";
import DatetimePicker from "../../../../../Components/DateTimePicker/DatePicker";
import Modal from "../../../../../Components/Modal";

//Methods 
import { formatDate, getCalenderView, handleFormErrors } from '../../../../../_helpers/helper.methods';
import moment from 'moment';

//api 
import API from '../../../../../_apis/hrms/employee';
import MasterAPI from '../../../../../_apis/Core';
//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createNotification, dataChanged } from '../../../../../_reducers/reducer.notification';
import Cropper from 'react-cropper';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';

//200kb 
const MAX_IMAGE_CROP_SIZE = 200000;
class IdCardInfoDetail extends Component {

    componentDidUpdate(prevProps, prevState) {
        let { history: { location } } = this.props;
        if (prevProps !== this.props && location.pathname.includes("edit")) {
            this.setState({
                subtitle: location.state.item.cardNo,
                idCardDetails: { ...location.state.item }
            }, () => {
                if (this.state.idCardDetails.fileURL) {
                    this.getBlobFromURL(this.state.idCardDetails.fileURL);
                }
            })
        }
    }
    componentDidMount() {
        let { history, match } = this.props;
        if (match.path.includes("edit")) {
            this.setState({
                subtitle: history.location.state.item.cardNo,
                idCardDetails: { ...history.location.state.item }
            }, () => {
                if (this.state.idCardDetails.fileURL) {
                    this.getBlobFromURL(this.state.idCardDetails.fileURL);
                }
            })
        }
    }
    getinitialState = () => {
        return {
            idcardUID: '',
            cardType: '',
            cardNo: '',
            address: '',
            district: '',
            province: '',
            city: '',
            postalCode: '',
            country: '',
            expiryDate: '',
            fileURL: ''
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            nocountry: false,
            city: '',
            country: '',
            modal: {
                visible: false
            },
            photoUrl: null,
            subtitle: 'Id Card Details',
            load: false,
            idCardDetails: this.getinitialState(),
            file: null,
            imagemodal: false,
            defaultimage: "/images/profile-default.jpg",
        }
    }
    handleChange = (event) => {
        let idCardDetails = this.state.idCardDetails;
        if (event.target.type === "checkbox") {
            this.setState({
                idCardDetails: {
                    ...idCardDetails,
                    [event.target.name]: !idCardDetails[event.target.name]
                }
            })
        } else {
            this.setState({
                idCardDetails: {
                    ...idCardDetails,
                    [event.target.name]: event.target.value
                }
            })
        }
    }
    handleCalendar = (momentd, name) => {
        let idCardDetails = this.state.idCardDetails;
        this.setState({
            idCardDetails: {
                ...idCardDetails,
                [name]: momentd
            }
        })
    }
    getBlobFromURL = async (blob) => {
        let url = '../../../../';
        let res = await MasterAPI.AccessBlob(this.props.user, blob, url);
        if (res.status !== '1') {
            this.setState({
                photoUrl: res.message
            })
        }
    }
    resetImageUploadButton = () => {
        document.getElementById('cardtype').value = '';
    }
    handleSubmit = async (e) => {
        let formData = new FormData(e.target);
        let { match, history: { location: { state } } } = this.props;
        this.setState({ load: true })
        let idCardDetails = this.state.idCardDetails;
        if (idCardDetails.expiryDate) {
            let converteddate = moment(idCardDetails.expiryDate).format("YYYY-MM-DD");
            formData.set('expiryDate', converteddate);
        }
        // if (this.state.file) {
        //     formData.append('File', this.state.file);
        // }
        if (this.state.file) {
            let croppedImage = await this.getCroppedImage();
            if (croppedImage.size > MAX_IMAGE_CROP_SIZE) {
                this.resetImageUploadButton();
                this.props.createNotification('Alert', 'Profile picture size is greater than 200kb')
                this.setState({ load: false })
                return
            }
            formData.append('File', croppedImage);
        }
        if (match.path.includes('add')) {
            if (match.path.includes("allemployee")) {
                formData.append('EmpUID', state.employee.empUID);
                let res = await API.Card.Add(formData, this.props.user);
                this.setState({ load: false })
                if (res.status === "0") {
                    this.props.dataChanged();
                    this.close();
                    this.props.createNotification('Success', res.message);
                } else {
                    this.props.createNotification('Operation Failed', res.message);
                }
            } else {
                formData.append('EmpUID', this.props.user.empUID);
                let res = await API.Card.Add(formData, this.props.user);
                this.setState({ load: false })
                if (res.status === "0") {
                    this.props.dataChanged();
                    this.close();
                    this.props.createNotification('Success', res.message);
                } else {
                    this.props.createNotification('Operation Failed', res.message);
                }
            }

        } else {
            formData.set('IDCardUID', this.state.idCardDetails.idCardUID);
            let res = await API.Card.Update(formData, this.props.user, idCardDetails)
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.close();
                this.props.createNotification('Success', res.message);
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }
        }
    }

    addCityCountry = (e) => {
        if (e === 'city') {
            let { idCardDetails } = this.state;
            if (idCardDetails.country) {
                this.setState({
                    nocountry: false,
                    modal: {
                        visible: true,
                        title: "Add City",
                        type: 'city'
                    }
                })
            } else {
                this.setState({ nocountry: true })
            }
        } else if (e === 'country') {
            this.setState({
                modal: {
                    visible: true,
                    title: "Add Country",
                    type: 'country'
                }
            })
        }
    }
    addDataToMaster = (type) => {
        let { master: { modulemaster } } = this.props;
        let { city, country, idCardDetails } = this.state;
        if (type === 'city') {
            modulemaster.cityList.push({
                addInfo: idCardDetails.country,
                text: city
            })
            this.setState({
                idCardDetails: {
                    ...idCardDetails,
                    [type]: city,
                }
            })
        } else if (type === 'country') {
            modulemaster.countryList.push({ id: '', text: this.state.country })
            this.setState({
                idCardDetails: {
                    ...idCardDetails,
                    [type]: country,
                }
            })
        }
        this.setState({
            modal: {
                visible: false
            }
        })
    }

    loadImage = (event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            this.setState({
                imagemodal: true,
                imagePreviewUrl: reader.result,
                file: file
            });
        }
        reader.readAsDataURL(file)
    }
    uploadImage = async () => {
        this.setState({
            imagemodal: false,
            file: this.refs.cropper.getCroppedCanvas(),
            cropimage: this.refs.cropper.getCroppedCanvas().toDataURL('image/jpg')
        })
    }
    getCroppedImage = () => {
        return new Promise((resolve, reject) => {
            this.state.file.toBlob((blob) => {
                resolve(blob)
            }, 'image/jpeg');
        })
    }
    close = () => {
        let { match, history } = this.props;
        if (match.path.includes("/hrms/myinfo")) {
            history.push("/home/hrms/myinfo/idcardInfo")
        } else if (match.path.includes("allemployee/menu/idcardinfo")) {
            history.push("/home/hrms/allemporgunit/allemployee/menu/idcardinfo")
        } else {
            history.push("/home");
        }
    }
    reset = () => {
        let { history, match } = this.props;
        if (match.path.includes('edit')) {
            this.setState({ idCardDetails: history.location.state.item });
        } else if (match.path.includes('add')) {
            this.setState({ idCardDetails: this.getinitialState() });
        }
    }
    toolbarclick = async (e) => {
        if (e === "save") {
            document.getElementById("submit").click();
        } else if (e === 'deactive') {
            this.setState({ load: true })
            let idCardDetails = this.state.idCardDetails;
            let res = await API.Card.Delete(this.props.user, idCardDetails)
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.props.createNotification('Success', res.message);
                this.close();
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }
        } else if (e === "reset") {
            this.reset();
        }
    }
    render() {
        let { idCardDetails, photoUrl, nocountry, modal } = this.state;
        let toolbar = [{
            icon: '/images/save_black.png',
            name: <Translate id="hrms.employeeInfo.myInformation.save" />,
            value: "save"
        }, {
            icon: '/images/reset_black.png',
            name: "Reset",
            value: "reset"
        }];
        let { match } = this.props;
        if (match.path.includes("edit")) {
            toolbar.push({
                icon: '/images/deactive_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.deactive" />,
                value: "deactive"
            })
        }
        return (
            <Blade match={this.props.match}
                max={false}
                load={this.state.load}
                close={this.close}
                icon={this.state.icon || '/images/idcard.png'}
                head={"Id Card Details"}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={this.state.load}>

                    {this.state.imagemodal &&
                        <React.Fragment>
                            <div className="modal-backdrop fade in"
                                style={{ zIndex: 1040 }}></div>
                            <div modal-render="true" tabIndex="-1"
                                role="dialog" className="modal fade in"
                                style={{ zIndex: 1050, justifyContent: 'center', alignItems: 'center', display: "flex", opacity: 1 }}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title"><Translate id="hrms.employeeInfo.myInformation.adjustYourImage" /></h3>
                                        </div>
                                        <div className="modal-body">
                                            {this.state.imagePreviewUrl &&
                                                <Cropper
                                                    style={{ height: 400, width: '100%' }}
                                                    ref='cropper'
                                                    aspectRatio={10 / 8}
                                                    src={this.state.imagePreviewUrl} />
                                            }

                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.uploadImage} className="btn"><Translate id="hrms.employeeInfo.myInformation.ok" /></button>
                                            <button onClick={() => this.setState({ imagemodal: false })} className="btn btn-default" ><Translate id="hrms.employeeInfo.myInformation.cancel" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>}
                    <Modal
                        modal={modal.visible}
                        title={modal.title}
                        footer={[<Translate id="hrms.employeeInfo.myInformation.save" />, <Translate id="hrms.employeeInfo.myInformation.cancel" />]}
                        Ok={() => { document.getElementById('modal').click() }}
                        Cancel={() => this.setState({ modal: { visible: false } })}>
                        <ValidatorForm
                            ref="form"
                            onError={handleFormErrors}
                            onSubmit={() => this.addDataToMaster(modal.type)}
                        >
                            {modal.type === 'city'
                                ?
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.name" />}
                                    value={this.state.city}
                                    onChange={(e) => this.setState({ city: e.target.value })}
                                    name="city"
                                    validators={['required']}
                                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required2" />]}
                                />
                                : <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.name" />}
                                    value={this.state.country}
                                    onChange={(e) => this.setState({ country: e.target.value })}
                                    name="country"
                                    validators={['required']}
                                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required2" />]}
                                />}
                            <div className="form-group">
                                <button id="modal" style={{ display: "none" }} type="submit"></button>
                            </div>
                        </ValidatorForm>
                    </Modal>

                    {Inputs(this.addCityCountry,
                        this.loadImage, photoUrl,
                        this.props.master, idCardDetails,
                        this.handleSubmit, this.handleChange,
                        this.handleCalendar, nocountry, this.state.cropimage, this.state.defaultimage)}

                </BladeBody>
            </Blade >
        )
    }
}
const Inputs = (addCityCountry,
    loadImage, photoUrl, master,
    idCardDetails, handleSubmit, handleChange,
    handleCalendar, nocountry, cropimage, defaultimage) => (

    <ValidatorForm
        ref="form"
        onError={handleFormErrors}
        onSubmit={handleSubmit}
    >

        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.cardType" />}
            onSelect={handleChange}
            name="cardType"
            value={idCardDetails.cardType}
            data={master.modulemaster.idCardTypeList.map(({ text }) => text)}
            options={{ placeholder: "Choose one" }}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.cardNo" />}
            onChange={handleChange}
            name="cardNo"
            value={idCardDetails.cardNo || ''}
            validators={['required']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
        />
        <div style={{ display: "flex", flexDirection: "column", marginTop: 10, flex: 1, alignItems: "center" }}>
            <div style={{ width: "100%" }}>
                {cropimage || photoUrl
                    ? <img
                        alt='user'
                        style={{ height: 200, width: '100%' }}
                        src={cropimage ? cropimage : photoUrl ? photoUrl : defaultimage} />
                    // src={defaultimage} />
                    : <img
                        alt='user'
                        style={{ height: 200, width: '100%' }}
                        src={defaultimage} />
                }
            </div>
            <div style={{
                display: "flex", flexDirection: "column",
                justifyContent: "space-around", marginTop: 2
            }}>
                <input name='cardtypeimage' type="file" id='cardtype' onChange={loadImage} style={{ display: 'none' }} />
                <button onClick={() => document.getElementById('cardtype').click()} className="btn" style={{ backgroundColor: '#4c4c4c' }} type='button' >
                    <Translate id="hrms.employeeInfo.myInformation.selectCardTypeImage" />
                </button>
            </div>
        </div>
        <TextAreaValidator
            label={<Translate id="hrms.employeeInfo.myInformation.address" />}
            onChange={handleChange}
            name="address"
            value={idCardDetails.address || ''}
        />

        <TextValidator
            label={<Translate id="hrms.master.orgUnit.district" />}
            onChange={handleChange}
            name="district"
            value={idCardDetails.district || ''}
        />

        <SelectValidator
            add={() => addCityCountry('country')}
            name="country"
            label={<Translate id="hrms.employeeInfo.myInformation.country" />}
            value={idCardDetails.country || ''}
            data={master.modulemaster.countryList.map(({ text }) => text)}
            onSelect={handleChange}
            options={{ placeholder: "Choose Country" }}
        />

        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.province" />}
            value={idCardDetails.province || ''}
            name="province"
            onSelect={handleChange}
            data={master.modulemaster.provinceList.filter((item) => item.addInfo === idCardDetails.country).map(({ text }) => text)}
            options={{ placeholder: 'Choose Province' }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
        />

        <SelectValidator
            add={() => addCityCountry('city')}
            label={<Translate id="hrms.employeeInfo.myInformation.city" />}
            name="city"
            value={idCardDetails.city || ''}
            onSelect={handleChange}
            data={master.modulemaster.cityList.filter((item) => item.addInfo === idCardDetails.country).map(({ text }) => text)}
            options={{ placeholder: "Choose City" }}
        />
        {nocountry && <p style={{ color: "red", fontSize: 12 }}>Select Country First</p>}

        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.postalCode" />}
            onChange={handleChange}
            name="postalCode"
            validators={['isNumber']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidMobile" />]}
            value={idCardDetails.postalCode || ''}
        />

        <DatetimePicker
            closeOnSelect
            onChange={(m) => handleCalendar(m, "expiryDate")}
            name="expiryDate"
            isValidDate={(current) => { return current.isAfter(moment().subtract(1, 'day')) }}
            viewDate={getCalenderView(idCardDetails.expiryDate)}
            value={formatDate(idCardDetails.expiryDate || '')}
            inputProps={{
                placeholder: 'DD/MM/YYYY',
                label: <Translate id="hrms.employeeInfo.myInformation.expiryDate" />
            }}
            validators={['matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidDate" />]}
            dateFormat="DD/MM/YYYY"
            timeFormat={false} />

        <div className="form-group">
            <button id="submit" style={{ display: "none" }} type="submit"></button>
        </div>
    </ValidatorForm>
);


const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    dataChanged
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(IdCardInfoDetail))
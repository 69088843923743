
import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import Select2 from 'react-select2-wrapper';
import { withTheme } from 'theming';
class SelectValidator extends ValidatorComponent {
    setClass = (theme) => {
        if (theme.inputs.color === 'white') {
            document.getElementById('body').classList.add('selectdark');
        } else {
            document.getElementById('body').classList.remove('selectdark');
        }
    }
    render() {
        const { add, theme, label, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        this.setClass(theme);
        let required = validators[0] === 'required';
        return (
            <div className="form-group" style={label ? {} : { /*margin: 0*/ }}>
                {label && <label className="form-label">{label}
                    <span style={{ color: 'red' }}>{required && ' *'}</span>
                </label>}
                <div className={theme.inputs.color === 'white' ? "form-input selectdark" : 'form-input'}>
                    <div style={add ? { display: 'flex', width: 190 } : {width:'100%'}}>
                        <Select2
                            {...rest}
                            ref={(r) => { this.Select2 = r; }}
                        />
                    </div>
                    <div>
                        {add && <button
                            style={{ background: '#43b0e6', color: 'white' }}
                            type='button' onClick={() => add()} className='btn'>+</button>}
                    </div>
                </div>
                {this.errorText()}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'red', fontSize: 12 }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}
export default withTheme(SelectValidator);
import React from 'react'
import { Route } from 'react-router-dom'
//components

import ViewContainer from "../../Components/ViewContainer";
import DashboardView from "./Dashboard";
import NewsDet from "./NewsDet";

class Dashboard extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <ViewContainer id={"viewcontainer"}>
                <Route path={`${match.path}`} component={DashboardView} />
                <Route path={`${match.path}/details`} component={NewsDet} />
            </ViewContainer>
        );
    }
}

export default Dashboard;
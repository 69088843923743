import React, { Component } from "react";

import Blade from "../../../../Components/Blade";
import BladeBody from "../../../../Components/Blade/BladeBody";
import TextValidator from "../../../../Components/Validators/TextValidator";
import { ValidatorForm } from 'react-form-validator-core';
//import CheckBox from "../../../../Components/Validators/CheckBox";
//import AttachFile from "../../../../Components/AttachFile";
import TextAreaValidator from "../../../../Components/Validators/TextareaValidator";
import SelectValidator from "../../../../Components/Validators/SelectValidator";
import CheckBox from "../../../../Components/Validators/CheckBox";
//api 
import Modal from "../../../../Components/Modal";
import API from '../../../../_apis/hrms/master';
import APImaster from '../../../../_apis/Core';
//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { errorNotification, createNotification, dataChanged } from '../../../../_reducers/reducer.notification';
import {devLog, handleFormErrors } from "../../../../_helpers/helper.methods";
import {
    storeMaster
} from "../../../../_reducers/reducer.user";
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../_language/en.json';
import id from '../../../../_language/id.json';

class LocationDetails extends Component {

    async componentDidUpdate(prevProps, prevState) {
        let { dataChanged, history: { location } } = this.props;
        if ( dataChanged && prevProps !== this.props) {
            if (location.pathname.includes("edit")) {
                let formData = new FormData();
                formData.append('DataUID', location.state.item.uid);
                let url = '../../../../../';
                let res = await API.Location.Detail(formData, this.props.user, url);
                this.setState({ load: false })             
                if (res.status !== '1') {
                    res[0].ssidList=res[0].ssidList!=="null"?JSON.parse(res[0].ssidList):[];
                    res[0].macList=res[0].macList!=="null"?JSON.parse(res[0].macList):[];
                    this.setState({
                        orginalDetails: res[0],
                        orgUnitDetails: res[0]
                    })
                }
                this.setState({
                    subtitle: location.state.item.documentTitle,
                    //orgUnitDetails: { ...location.state.item },
                    orguid: location.state.item.orgUnitUID
                })
            }
        }        
    }
    async componentDidMount() {
        let { history, match } = this.props;
        if (match.path.includes("edit")) {
            let formData = new FormData();
            formData.append('DataUID', history.location.state.item.uid);
            let url = '../../../../../';
            let res = await API.Location.Detail(formData, this.props.user, url);
            this.setState({ load: false })
                if (res.status !== '1') {
                    res[0].ssidList=res[0].ssidList!=="null"?JSON.parse(res[0].ssidList):[];
                    res[0].macList=res[0].macList!=="null"?JSON.parse(res[0].macList):[];
                    this.setState({
                        orginalDetails: res[0],
                        orgUnitDetails: res[0]
                    })
                }
            
            this.setState({
                subtitle: history.location.state.item.documentTitle,
                orguid: history.location.state.orgUnitUID
            })
        }
        else {
            this.setState({
                locationlist: history.location.state.list,
                orguid: history.location.state.orgUnitUID
            })
        }
    }
    getinitialState = () => {
        return {
            address: '',
            district: '',
            province: '',
            isActive: true,
            virtualLocation: true,
            primaryLocation: false,
            city: '',
            country: '',
            phone: '',
            postalCode: '',
            fax: '',
            TimeZone: '',
            latitudePoint: '',
            longitudePoint: '',
            allowedDistance: '',
            locationType:'',
            singlemac:'',
            singlessid:'',
            ssidList:[],
            macList:[]
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            nocountry: false,
            city: '',
            OrgUnitUID:'',
            locationlist: [],
            isclickssid:false,
            isclickmac:false,
            isclickvirtual:false,
            isclickprimary:false,
            country: '',
            modal: {
                visible: false
            },
            orgUnitDetails: this.getinitialState(),
            load: false
        }
    }
    handleChange = (event) => {
        let { name, value } = event.target;
        name = name[0].toLowerCase() + name.slice(1);
        let orgUnitDetails = this.state.orgUnitDetails;
        if (event.target.type === "checkbox") {            
            if(name === "virtualLocation" || name === "primaryLocation"){
                this.setState({
                    orgUnitDetails: {
                        ...orgUnitDetails,
                        virtualLocation: !orgUnitDetails[name] && name === "virtualLocation",
                        primaryLocation: !orgUnitDetails[name] && name === "primaryLocation"
                    }
                })
            }
            else {
                this.setState({
                    orgUnitDetails: {
                        ...orgUnitDetails,
                        [name]: !orgUnitDetails[name]
                    }
                })
            }            
        } else {
            this.setState({
                orgUnitDetails: {
                    ...orgUnitDetails,
                    [name]: value
                }
            })
        }
    }
    handleSubmit = async (e) => {
        //let { orgUnitDetails } = this.state;
        let { match } = this.props;

        if((this.state.primaryLocation === true && this.state.virtualLocation === true) || (this.state.primaryLocation === false && this.state.virtualLocation === false))
        {
            this.props.createNotification('Operation Failed',<Translate id="hrms.master.orgUnit.error" />);
            return false;
        } 


        if(this.state.orgUnitDetails.primaryLocation === true )
        {
            if(this.state.orgUnitDetails.latitudePoint==="0"||this.state.orgUnitDetails.latitudePoint==="")
            {
                this.props.createNotification('Operation Failed', "LatitudePoints Cannot Be Null");
                return false;
            }
            else if(this.state.orgUnitDetails.longitudePoint==="0"||this.state.orgUnitDetails.longitudePoint==="")
            {
                this.props.createNotification('Operation Failed', "LongitudePoint Cannot Be Null");
                return false;
            }
          
        } 
        
        let formData = new FormData(e.target);
        formData.append('MacList',  JSON.stringify(this.state.orgUnitDetails.macList));
        formData.append('SsidList',  JSON.stringify(this.state.orgUnitDetails.ssidList));
        formData.set('isActive', this.state.orgUnitDetails.isActive); 
        formData.set('virtualLocation', this.state.orgUnitDetails.virtualLocation);
        formData.set('primaryLocation', this.state.orgUnitDetails.primaryLocation);
        this.setState({ load: true })
        
        if (match.path.includes('add')) {                 
            formData.append('OrgUnitUID', this.state.orguid);
            //let url = '../../../../../';
            let res = await API.Location.Add(formData, this.props.user);
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.close();
                this.props.createNotification('Success', res.message ? res.message : "Data Saved.");
                this.resetmaster();
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }           
        } else {
            formData.append('OrgUnitUID', this.state.orguid);
            formData.append('UID', this.state.orgUnitDetails.uid);
            let res = await API.Location.Update(formData, this.props.user);
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.close();
                this.props.createNotification('Success', res.message ? res.message : "Data Saved.");
                this.resetmaster();
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }
        }
    }
    resetmaster = async () =>{
        let res = await APImaster.Master.List(this.props.user, 'hrms');
        if (res.status !== '1') {
            let mlist = {
                ...this.props.master,
                modulemaster: res
            }
            devLog('mliust', mlist)
            this.props.storeMaster(mlist)
        }
    }
    close = () => {
        let { history } = this.props;
        history.push('/home/hrms/master/location/locationlist')
    }
    reset = () => {
        let { match } = this.props;
        if (match.path.includes('edit')) {
            this.setState({ orgUnitDetails: this.state.orgUnitDetails });
        } else if (match.path.includes('add')) {
            this.setState({ orgUnitDetails: this.getinitialState() });
        }
    }
    toolbarclick = async (e) => {
        if (e === "save") {
            document.getElementById("submit").click();
        } else if (e === 'deactive') {
            this.setState({ load: true })
            let { uid } = this.state.orgUnitDetails;
            //let url = '../../../../../';
            let res = await API.Location.Deactive(this.props.user, uid)
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.props.createNotification('Success', res.message);
                this.close();
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }
        } else if (e === "reset") {
            this.reset();
        }
    }
    addToList = (e) => {
        //let orgUnitDetails = this.state.orgUnitDetails;
       if (e === 'mac') {
          
            if((this.state.orgUnitDetails.singlemac||"")!=="")
            {
                this.setState({
                    orgUnitDetails: {
                        ...this.state.orgUnitDetails,
                        macList: [...this.state.orgUnitDetails.macList, this.state.orgUnitDetails.singlemac],
                        singlemac:'',
                      
                    },
                    isclickmac:false
                    
                })
            }
            else
            {
                this.setState({
                    isclickmac:true
                })
            }
         } else if (e === 'ssid') {
            if((this.state.orgUnitDetails.singlessid||"")!=="")
            {
              
                this.setState({
                    orgUnitDetails: {
                        ...this.state.orgUnitDetails,
                        ssidList: [...this.state.orgUnitDetails.ssidList, this.state.orgUnitDetails.singlessid],
                        singlessid:''
                    },
                    isclickssid:false
                })
            }
            else
            {
                this.setState({
                    isclickssid:true
                })
            }
        }
    }
    removeFromlist = (e,value) => {
        //let orgUnitDetails = this.state.orgUnitDetails;
       if (e === 'mac') {
            this.setState({
                orgUnitDetails: {
                    ...this.state.orgUnitDetails,
                    macList: this.state.orgUnitDetails.macList.filter(el=>el!==value),
                    singlemac:''
                }
                
            })
         } else if (e === 'ssid') {
            this.setState({
                orgUnitDetails: {
                    ...this.state.orgUnitDetails,
                    ssidList: this.state.orgUnitDetails.ssidList.filter(el=>el!==value),
                    singlessid:''
                },
            })
        }
    }
    
    render() {
        let { orgUnitDetails, modal, nocountry } = this.state;
        let toolbar = [{
            icon: '/images/save_black.png',
            name: <Translate id="hrms.master.orgUnit.save" />,
            value: "save"
        }, {
            icon: '/images/reset_black.png',
            name: "Reset",
            value: "reset"
        }];
        return (
            <Blade match={this.props.match}
                max={false}
                load={this.state.load}
                close={this.close}
                icon={this.state.icon || '/images/orgunit.png'}
                head={<Translate id="hrms.master.orgUnit.orgUnitDetails" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={this.state.load}>
                    <Modal
                        modal={modal.visible}
                        title={modal.title}
                        footer={[<Translate id="hrms.master.orgUnit.save" />, <Translate id="hrms.master.orgUnit.cancel" />]}
                        Ok={() => { document.getElementById('modal').click() }}
                        Cancel={() => this.setState({ modal: { visible: false } })}>
                        <ValidatorForm
                            ref="form"
                            onError={handleFormErrors}
                            onSubmit={() => this.addDataToMaster(modal.type)}>
                            {modal.type === 'city'
                                ?
                                <TextValidator
                                    label={<Translate id="hrms.master.orgUnit.name" />}
                                    value={this.state.city}
                                    onChange={(e) => this.setState({ city: e.target.value })}
                                    name="city"
                                    validators={['required']}
                                    errorMessages={[<Translate id="hrms.master.orgUnit.required" />]}
                                />
                                : <TextValidator
                                    label={<Translate id="hrms.master.orgUnit.name" />}
                                    value={this.state.country}
                                    onChange={(e) => this.setState({ country: e.target.value })}
                                    name="country"
                                    validators={['required']}
                                    errorMessages={[<Translate id="hrms.master.orgUnit.required" />]}
                                />}
                            <div className="form-group">
                                <button id="modal" style={{ display: "none" }} type="submit"></button>
                            </div>
                        </ValidatorForm>
                    </Modal>


                    {Inputs(this.onmapclick,
                        this.props.master,
                        orgUnitDetails,
                        this.handleSubmit,
                        this.handleChange,
                        nocountry,this.state.OrgUnitUID,
                        this.addToList,this.removeFromlist,
                        this.state.orgUnitDetails.ssidList,this.state.orgUnitDetails.macList,this.state.isclickssid,this.state.isclickmac
                    )}

                </BladeBody>
            </Blade >
        )
    }
}

const Inputs = (onmapclick, master,
    orgUnitDetails,
    handleSubmit,
    handleChange,
    nocountry,OrgUnitUID,addtolist,removeFromlist,ssidList,macList,isclickssid,isclickmac,isclickprimary,isclickvirtual) => (
    
        <ValidatorForm
            ref="form"
            onError={handleFormErrors}
            onSubmit={handleSubmit}
        >
       
            {/*<TextValidator
                label={<Translate id="hrms.master.orgUnit.locationType" />}
                onChange={handleChange}
                name="LocationType"
                value={orgUnitDetails.locationType || ''}
                validators={['required']}
                errorMessages={[<Translate id="hrms.master.orgUnit.required2" />]}
            />*/}
            <SelectValidator
                label={<Translate id="hrms.master.orgUnit.locationType" />}
                value={orgUnitDetails.locationType || ''}
                name="LocationType"
                onSelect={handleChange}
                data={master.locationTypeList}
                options={{ placeholder: 'Choose Location Type' }}
                validators={['required']}
                errorMessages={[<Translate id="hrms.master.orgUnit.required2" />]}
            />
            <CheckBox
                label={<Translate id="hrms.master.orgUnit.virtualLocation" />}
                name="virtualLocation"
                onChange={handleChange}
                checked={orgUnitDetails.virtualLocation}
                disabled={isclickvirtual}
            />
            <CheckBox
                label={<Translate id="hrms.master.orgUnit.primaryLocation" />}
                name="primaryLocation"
                onChange={handleChange}
                checked={orgUnitDetails.primaryLocation}
                disabled={isclickprimary}
            />
            <TextValidator
                label={<Translate id="hrms.master.orgUnit.locationName" />}
                onChange={handleChange}
                name="LocationName"
                value={orgUnitDetails.locationName || ''}
                validators={['required']}
                errorMessages={[<Translate id="hrms.master.orgUnit.required2" />]}
            />
            <TextValidator
                label={<Translate id="hrms.master.orgUnit.locationShortName" />}
                onChange={handleChange}
                name="LocationShortName"
                value={orgUnitDetails.locationShortName || ''}
                validators={['required']}
                errorMessages={[<Translate id="hrms.master.orgUnit.required2" />]}
            />
            <TextAreaValidator
                label={<Translate id="hrms.master.orgUnit.address" />}
                onChange={handleChange}
                name="address"
                value={orgUnitDetails.address || ''}
            />
            <SelectValidator
                name="country"
                label={<Translate id="hrms.master.orgUnit.country" />}
                value={orgUnitDetails.country || ''}
                data={master.countryList.map(({ text }) => text)}
                onSelect={handleChange}
                options={{ placeholder: "Choose Country" }}
            />
            <SelectValidator
                label={<Translate id="hrms.employeeInfo.myInformation.province" />}
                value={orgUnitDetails.province || ''}
                name="province"
                onSelect={handleChange}
                data={master.provinceList.filter((item) => item.addInfo === orgUnitDetails.country).map(({ text }) => text)}
                options={{ placeholder: 'Choose Province' }}
                validators={['required']}
                errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
            />
            <SelectValidator
                label={<Translate id="hrms.master.orgUnit.city" />}
                name="city"
                value={orgUnitDetails.city || ''}
                onSelect={handleChange}
                data={master.cityList.filter((item) => item.addInfo === orgUnitDetails.country).map(({ text }) => text)}
                options={{ placeholder: "Choose City" }}
            />
            <TextValidator
                label={<Translate id="hrms.master.orgUnit.district" />}
                onChange={handleChange}
                name="district"
                value={orgUnitDetails.district || ''}
            />
            <TextValidator
                label={<Translate id="hrms.master.orgUnit.postalCode" />}
                onChange={handleChange}
                name="postalCode"
                validators={['isNumber']}
                errorMessages={[<Translate id="hrms.master.orgUnit.invalidPostalCode" />]}
                value={orgUnitDetails.postalCode || ''}
            />
           
            

            
            {nocountry && <p style={{ color: "red", fontSize: 12 }}><Translate id="hrms.master.orgUnit.selectCountry" /></p>}


            {/* <GoogleMap
            defaultZoom={8}
            onClick={onmapclick}
            defaultCenter={{ lat: -34.397, lng: 150.644 }}
        >
            <Marker position={{ lat: -34.397, lng: 150.644 }} />
        </GoogleMap> */}
            
            <TextValidator
                label={<Translate id="hrms.master.orgUnit.latitudePoints" />}
                onChange={handleChange}
                name="latitudePoint"
                disabled={orgUnitDetails.virtualLocation}
                //validators={['matchRegexp:^[-+]?[0-9]+(?:\.[0-9]+)?$']}
                errorMessages={orgUnitDetails.virtualLocation?[]:[<Translate id="hrms.employeeInfo.myInformation.required" />,<Translate id="hrms.master.orgUnit.invalidLatitude" />]}
                value={orgUnitDetails.virtualLocation?0:(orgUnitDetails.latitudePoint || '')}
                validators={orgUnitDetails.virtualLocation?[]:['required','matchRegexp:^[-+]?[0-9]+(?:.[0-9]+)?$']}
            />
            <TextValidator
                label={<Translate id="hrms.master.orgUnit.longitudePoints" />}
                onChange={handleChange}
                name="longitudePoint"
                disabled={orgUnitDetails.virtualLocation}
                //validators={['matchRegexp:^[-+]?[0-9]+(?:\.[0-9]+)?$']}
                errorMessages={orgUnitDetails.virtualLocation?[]:[<Translate id="hrms.employeeInfo.myInformation.required" />,<Translate id="hrms.master.orgUnit.invalidLongitude" />]}
                value={orgUnitDetails.virtualLocation?0:(orgUnitDetails.longitudePoint || '')}
                validators={orgUnitDetails.virtualLocation?[]:['required','matchRegexp:^[-+]?[0-9]+(?:.[0-9]+)?$']}
            />
            <TextValidator
                label={<Translate id="hrms.master.orgUnit.allowedDistance" />}
                onChange={handleChange}
                name="allowedDistance"                
                validators={['matchRegexp:^[-+]?[0-9]+(?:.[0-9]+)?$']}
                errorMessages={[<Translate id="hrms.master.orgUnit.invalidDistance" />]}
                value={orgUnitDetails.allowedDistance || 0}
            />
            <div style={{ display: orgUnitDetails.virtualLocation ? 'none':'block' }}>
             <TextValidator
                label={<Translate id="hrms.master.orgUnit.ssid" />}
                add={()=>{addtolist("ssid")}}
                onChange={handleChange}
                name="singlessid"
                disabled={orgUnitDetails.virtualLocation}                
                value={orgUnitDetails.singlessid || ''}
            />
                 {isclickssid&&(orgUnitDetails.singlessid||"")==="" && (<div style={{color: "red", fontSize: "12px;"}}><Translate id="hrms.master.orgUnit.required2" /></div>)}
            <br/>
            <div className="table-wrapper" style={{ width:"100%", height: "calc(100% - 500px)", overflow: 'auto',marginLeft:"0px" } }>
           
              <table className="table" style={{width:"100%",minWidth:"100%"}}>
                <thead>
                    <tr className="table-item">
                        <th className="table-col"><Translate id="hrms.master.orgUnit.ssidname" /></th>
                        <th className="table-col">&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {ssidList&&ssidList.length>0? 
                        ssidList.map((item,i) => (
                    <tr className="table-item">
                        <td className="table-col">{item}</td>
                        <td className="table-col"><button type="button" onClick={()=>removeFromlist("ssid",item)} className="btn" style={{background: "rgb(67, 176, 230)", color: "white" ,padding:"0 10px" , height:"auto" ,lineHeight:"normal"}}>-</button></td>
                    </tr>
                        ))
                     :(
                    <tr className="table-item">
                        <td colSpan="2" className="table-col"><Translate id="hrms.master.orgUnit.nodatassid" /></td>
                    </tr>
                    )}
                </tbody>
              </table>
              </div>
              </div>
             <TextValidator
                label={<Translate id="hrms.master.orgUnit.mac" />}
                add={()=>{addtolist("mac")}}
                onChange={handleChange}
                name="singlemac"
                value={orgUnitDetails.singlemac || ''}
            />
            {isclickmac&&(orgUnitDetails.singlemac||"")==="" && (<div style={{color: "red", fontSize: "12px;"}}><Translate id="hrms.master.orgUnit.required2" /></div>)}
            <br />
            <div className="table-wrapper" style={{ width:"100%", height: "calc(100% - 500px)", overflow: 'auto',marginLeft:"0px" } }>
           
           <table className="table" style={{width:"100%",minWidth:"100%"}}>
             <thead>
                 <tr className="table-item">
                     <th className="table-col"><Translate id="hrms.master.orgUnit.macname" /></th>
                     <th className="table-col">&nbsp;</th>
                 </tr>
             </thead>
             <tbody>
                 {macList&&macList.length>0? 
                     macList.map((item,i) => (
                 <tr className="table-item">
                     <td className="table-col">{item}</td>
                     <td className="table-col"><button type="button" onClick={()=>removeFromlist("mac",item)} className="btn" style={{background: "rgb(67, 176, 230)", color: "white" ,padding:"0 10px" , height:"auto" ,lineHeight:"normal"}}>-</button></td>
                 </tr>
                     ))
                  :(
                 <tr className="table-item">
                     <td colSpan="2" className="table-col"><Translate id="hrms.master.orgUnit.nodatamac" /></td>
                 </tr>
                 )}
             </tbody>
           </table>
           </div>            
            <CheckBox
                label={<Translate id="hrms.master.orgUnit.active" />}
                name="isActive"
                onChange={handleChange}
                checked={orgUnitDetails.isActive}
            />
            <div className="form-group">
                <button id="submit" style={{ display: "none" }} type="submit"></button>
            </div>
        </ValidatorForm>
    );



const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    errorNotification,
    dataChanged,
    storeMaster
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(LocationDetails))

import React from 'react';
import { ValidatorComponent } from 'react-form-validator-core';
import { withTheme } from 'theming';
class TextareaValidator extends ValidatorComponent {

    render() {
        const { theme, children, label, errorMessages, validators, requiredError, validatorListener, ...rest } = this.props;
        let required = validators[0] === 'required';
        return (
            <div className="form-group">
                <label className="form-label">{label}
                <span style={{ color: 'red' }}>{required && ' *'}</span>
                </label>{label.props.id === "hrms.absenceManagement.notes" ? this.errorText() : ''}
                <div className="form-input">
                    <textarea
                        style={theme.inputs}
                        {...rest}
                        ref={(r) => { this.textarea = r; }}
                    />
                    {children}
                </div>
                {label.props.id !== "hrms.absenceManagement.notes" ? this.errorText() : ''}
            </div>
        );
    }

    errorText() {
        const { isValid } = this.state;

        if (isValid) {
            return null;
        }

        return (
            <div style={{ color: 'red', fontSize: 12 }}>
                {this.getErrorMessage()}
            </div>
        );
    }
}

export default withTheme(TextareaValidator);
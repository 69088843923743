import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";
import { ValidatorForm } from 'react-form-validator-core';
import TextAreaValidator from "../../../../../Components/Validators/TextareaValidator";
import SelectValidator from "../../../../../Components/Validators/SelectValidator";
import CheckBox from "../../../../../Components/Validators/CheckBox";
import DatetimePicker from "../../../../../Components/DateTimePicker/DatePicker";
import AttachFile from "../../../../../Components/AttachFile";
import moment from "moment";
//api 
import API from '../../../../../_apis/hrms/employee';
import APImaster from '../../../../../_apis/Core';
//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { errorNotification, createNotification, dataChanged } from '../../../../../_reducers/reducer.notification';
import { devLog, handleFormErrors } from "../../../../../_helpers/helper.methods";
import {
    storeMaster
} from "../../../../../_reducers/reducer.user";
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';

class TerminationDetails extends Component {

    async componentDidUpdate(prevProps, prevState) {
        let { history: { location } } = this.props;
        
        if (prevProps !== this.props) {
            if (location.pathname.includes("edit")) {
                let formData = new FormData();
                formData.append('TerminationUID', location.state.item.terminationUID);
                let url = '../../../../../../';
    
                let res = await API.Personeel.TerminationDetail(formData, this.props.user, url);
                if (res.status !== '1') {
                    res.processDate = moment(res.processDate);
                    res.effectiveDate = moment(res.effectiveDate);
                    this.setState({
                        load: false,
                        terminationDetails: res,
                        empUID: location.state.item.empUID,
                        subtitle: location.state.item.terminationType
                    })
                }           
            }
            else if (location.pathname.includes("termination")) {
                this.setState({
                    empUID: location.state.item.empUID,
                    terminationDetails: this.getinitialState()
                })
            }
        }        
    }
    async componentDidMount() {
        let { history, match } = this.props;
        if (match.path.includes("edit")) {
            let formData = new FormData();
            formData.append('TerminationUID', history.location.state.item.terminationUID);
            let url = '../../../../../../';
            let res = await API.Personeel.TerminationDetail(formData, this.props.user, url)
            if (res.status !== '1') {
                res.processDate = moment(res.processDate)
                res.effectiveDate = moment(res.effectiveDate)
                this.setState({
                    load: false,
                    terminationDetails: res,
                    subtitle: history.location.state.subtitle,
                    empUID: history.location.state.item.empUID })
                }            
        }
        else if (history.location.pathname.includes("termination")) {
            this.setState({
                terminationlist: history.location.state.list,
                empUID: history.location.state.item.empUID,
                subtitle: history.location.state.subtitle,
                terminationDetails: this.getinitialState()
            })
        }
    }
    getinitialState = () => {
        return {
            terminationType: '',
            terminationUID: '',
            processDate: '',
            effectiveDate: '',
            description: '',
            file: '',
            fileURL: '',
            fileUploadURL: '',
            blockRehire: ''    
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            terminationlist: [],
            terminationDetails: this.getinitialState(),
            load: false
        }
    }
    handleChange = (event) => {
        let { name, value } = event.target;
        name = name[0].toLowerCase() + name.slice(1);
        let terminationDetails = this.state.terminationDetails;
        if (event.target.type === "checkbox") {
            this.setState({
                terminationDetails: {
                    ...terminationDetails,
                    [name]: !terminationDetails[name]
                }
            })
        } else {
            this.setState({
                terminationDetails: {
                    ...terminationDetails,
                    [name]: value
                }
            })
        }
    }
    
    handleCalendar = (momentd, name) => {
        let terminationDetails = this.state.terminationDetails;
        name = name[0].toLowerCase() + name.slice(1);
        this.setState({
            terminationDetails: {
                ...terminationDetails,
                [name]: momentd
            }
        })
    }

    handleSubmit = async (e) => {
        let { match } = this.props;
        
        let formData = new FormData(e.target);
        this.setState({ load: true })
        
        let terminationDetails = this.state.terminationDetails;
        let processDate = moment(terminationDetails.processDate).format("YYYY-MM-DD HH:mm:ss");
        let effectiveDate = moment(terminationDetails.effectiveDate).format("YYYY-MM-DD HH:mm:ss"); 
        formData.set('ProcessDate', processDate);        
        formData.set('EffectiveDate', effectiveDate);
        formData.set('BlockRehire', terminationDetails.blockRehire ? true : false);
        formData.append('EmpUID', this.state.empUID);
        if (match.path.includes('termination')) {              
            if (this.state.terminationDetails.file) {                
                formData.append('File', this.state.terminationDetails.file);
                formData.append('FileURL', this.state.terminationDetails.fileUploadURL);
            }          
            let res = await API.Personeel.TerminationAdd(formData, this.props.user);
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.close();
                this.props.createNotification('Success', res.message ? res.message : "Data Saved.");
                this.resetmaster();
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }            
        } else {           
            formData.append('TerminationUID', this.state.terminationDetails.terminationUID);
            if (this.state.terminationDetails.file) {                
                formData.append('File', this.state.terminationDetails.file);
                formData.append('FileURL', this.state.terminationDetails.fileUploadURL);
            }
            let res = await API.Personeel.TerminationUpdate(formData, this.props.user);
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.close();
                this.props.createNotification('Success', res.message ? res.message : "Data Saved.");
                this.resetmaster();
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }
        }
    }
    resetmaster = async () =>{
        let res = await APImaster.Master.List(this.props.user, 'hrms');
        if (res.status !== '1') {
            let mlist = {
                ...this.props.master,
                modulemaster: res
            }
            devLog('mliust', mlist)
            this.props.storeMaster(mlist)
        }
    }
    close = () => {
        let { match, history } = this.props;
        //history.push('/home/hrms/allemporgunit/allemployee/menu/workhistory/current')
        history.push(
        {
            pathname: match.path.replace("/termination", "").replace("/add", ""),
        })
    }
    reset = () => {
        let { match } = this.props;
        if (match.path.includes('edit')) {
            this.setState({ terminationDetails: this.state.terminationDetails });
        } else if (match.path.includes('termination')) {
            this.setState({ terminationDetails: this.getinitialState() });
        }
    }
    toolbarclick = async (e) => {
        if (e === "save") {
            document.getElementById("submit").click();
        } else if (e === 'delete') {
            this.setState({ load: true })
            let { terminationUID } = this.state.terminationDetails;
            let res = await API.Personeel.TerminationDelete(this.props.user, terminationUID)
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.props.createNotification('Success', res.message);
                this.close();
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }
        } else if (e === "reset") {
            this.reset();
        }
    } 

    uploadResume = (event) => {
        let { files } = event.target;
        if (files.length === 0) {
            return;
        }
        let file = files[0];
        if (!file.type.includes('pdf')) {
            this.props.errorNotification("File must be PDF");
            document.getElementById('file').value = '';
            this.setState((state) => ({
                terminationDetails: {
                    ...state.terminationDetails,
                    file: null,
                    fileUploadURL: ''
                }
            }))
            return;
        }
        else{
            this.setState((state) => ({
                terminationDetails: {
                    ...state.terminationDetails,
                    fileUploadURL: file.name,
                    file,
                }           
            }))
        }
    }

    downloadResume = async (fileURL) => {
        if (fileURL !== null) {
            let { user } = this.props;
            this.setState({
                load: true
            })
            let resume = window.open(null, 'sharer', 'toolbar=0,status=0,width=548,height=325');
            resume.document.write('Loading Please Wait.....')
            let res = await APImaster.AccessBlob(user, fileURL, "/");
            this.setState({ load: false })
            if (res.status !== '1') {
                resume.location.href = res.message;
            } else {
                this.props.errorNotification(res.message);
            }
        } else {
            alert("Resume not available!");
        }
   }
    
    render() {
        let { terminationDetails } = this.state;
        let toolbar = [{
            icon: '/images/save_black.png',
            name: <Translate id="hrms.personeelManagement.termination.save" />,
            value: "save"
        }, {
            icon: '/images/reset_black.png',
            name: "Reset",
            value: "reset"
        }];
        let { match } = this.props;
        if (match.path.includes("edit")) {
            toolbar.push({
                icon: '/images/trash_black.png',
                name: <Translate id="hrms.personeelManagement.termination.delete" />,
                value: "delete"
            })
        }
        return (
            <Blade match={this.props.match}
                max={false}
                load={this.state.load}
                close={this.close}
                icon={this.state.icon || '/images/orgunit.png'}
                head={<Translate id="hrms.personeelManagement.termination.terminationDetail" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>               

                <BladeBody match={this.props.match} load={this.state.load}>
                {Inputs(this.props.master,
                    terminationDetails,
                    this.handleSubmit,
                    this.handleChange,
                    this.handleCalendar,
                    this.uploadResume,
                    this.downloadResume,
                    this.state.empUID,
                )}
            </BladeBody>
        </Blade >
        )
    }
}

const Inputs = (master, terminationDetails, handleSubmit, handleChange, handleCalendar, uploadResume, downloadResume, empUID ) => (
<ValidatorForm
                    ref="form"
                    onError={handleFormErrors}
                    onSubmit={handleSubmit}
                >                       
                    <SelectValidator
                        label={<Translate id="hrms.personeelManagement.termination.terminationType" />}
                        value={terminationDetails.terminationType || ''}
                        name="TerminationType"
                        onSelect={handleChange}
                        data={master.terminationTypeList}
                        options={{ placeholder: 'Choose Termination Type' }}
                        validators={['required']}
                        errorMessages={[<Translate id="hrms.personeelManagement.termination.required" />]}
                    />    
                    <DatetimePicker
                        closeOnSelect
                        name='ProcessDate'
                        value={terminationDetails.processDate || ''}
                        onChange={(m) => handleCalendar(m, "ProcessDate")}
                        inputProps={{
                            placeholder: 'DD/MM/YYYY',
                            label: <Translate id="hrms.personeelManagement.termination.processDate" />
                        }}
                        validators={['required']}
                        errorMessages={[<Translate id="hrms.personeelManagement.termination.required" />]}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false} />   
                    <DatetimePicker
                        closeOnSelect
                        name='EffectiveDate'
                        value={terminationDetails.effectiveDate || ''}
                        onChange={(m) => handleCalendar(m, "EffectiveDate")}
                        inputProps={{
                            placeholder: 'DD/MM/YYYY',
                            label: <Translate id="hrms.personeelManagement.termination.effectiveDate" />
                        }}                        
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false} />   
                    <TextAreaValidator
                        label={<Translate id="hrms.personeelManagement.termination.description" />}
                        onChange={handleChange}
                        name="Description"
                        value={terminationDetails.description || ''}
                    />
                    <AttachFile
                        label={<Translate id="hrms.personeelManagement.termination.file" />}
                        id='file'
                        name="File"
                        accepts='.pdf'
                        onChange={uploadResume}
                        value={terminationDetails.fileUploadURL || ''}
                        placeholder='Choose one PDF'
                    /> 
                    {terminationDetails.fileURL
                    &&  <React.Fragment>
                            <i className="btn-ico fa fa-file" onClick={()=>downloadResume(terminationDetails.fileURL)} style={{ cursor: 'pointer' }}></i><span style={{marginLeft: 5, fontSize: 'small'}}><Translate id="hrms.personeelManagement.termination.download" /></span>                     
                        </React.Fragment>}
                    <CheckBox
                        label={<Translate id="hrms.personeelManagement.termination.blockRehire" />}
                        name="BlockRehire"
                        onChange={handleChange}
                        checked={terminationDetails.blockRehire}
                    />
                
                    <div className="form-group">
                        <button id="submit" style={{ display: "none" }} type="submit"></button>
                    </div>
                </ValidatorForm>
        
    );

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    errorNotification,
    dataChanged,
    storeMaster
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(TerminationDetails))
import * as Employee from "./employee";
import * as Address from "./address";
import * as Family from './family';
import * as Education from "./education";
import * as Card from './card';
import * as Work from './work';
import * as CTC from './CTC';
import * as Personeel from './personeel';
import * as Validloc from './validloc';
import * as Otherinfo from './otherinfo';
import * as Reimbursement from './reimbursement';

export default {
    Employee,
    Address,
    Family,
    Education,
    Card,
    Work,
    CTC,
    Personeel,
    Validloc,
    Otherinfo,
    Reimbursement
}
import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import Select2 from 'react-select2-wrapper';

import { withTheme } from 'theming';

//redux
import {
    removeUser, updateSelectedOrgUnit, storeMaster,
    storeOrgList,
} from "../../_reducers/reducer.user";
import { getTheme } from "../../_reducers/reducer.theme";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { withLocalize, Translate } from "react-localize-redux";

import { setLanguagePage } from "../../_reducers/reducer.language";
import Notification from './Notification'
//apis
import Api from "../../_apis/index";
import Pagination from '../Pagination';
import API from "../../_apis/hrms/master";
import en from "../../_language/en.json"
import id from "../../_language/id.json"
import Blade from '../Blade';
import DropdownList from '../Lists/DropdownList';
import { showLastBlade } from '../../_helpers/helper.methods';
import { errorNotification, createNotification, dataChanged } from "../../_reducers/reducer.notification";
import OrgunitList from '../../Module/HRMS/Master/Location/OrgunitList';
import BladeTopbar from '../Blade/BladeTopbar';
import Location from '../../Module/HRMS/Master/Location';
import SelectValidator from '../Validators/SelectValidator';

class Topbar extends React.Component {
    change = (e) => {
        let theme = getTheme(e);
        this.props.changeTheme(theme);
    }

    signout = () => {
        let { user } = this.props;

        this.props.removeUser();
        this.props.goto("/")
        Api.Login.Remove(user)
            .then((res) => {

            })

        localStorage.removeItem("user");
        localStorage.removeItem("coremaster");
        localStorage.removeItem("leavemaster-" + user.empUID);
    }
    changeLanguage = (language) => {
        this.props.setLanguagePage(language)
        this.props.setActiveLanguage(language)
    }
    showHome = () => {
        this.props.goto('/home')
    }
    changePassword = () => {
        this.props.goto('/changepassword', this.props.user)
    }
    // async componentDidMount() {
    //     let { match, history: { location: { state } } } = this.props;
    //     let url = '../../../../../../';                
    //     let res = await API.OrgUnit.List(this.props.user, url, true);
    //     this.setState({ load: false })
    //         if (res.status !== '1') {
    //             this.setState({
    //                 listOrg: res,
    //             })
    //         } else {
    //             this.props.errorNotification(res.message);
    //         }

    //     if (match.path.includes('locationlist')) {           
    //         this.setState({
    //             title: 'Org Unit List',
    //             subtitle: state.subtitle,
    //             org: state.item.id,
    //             toolbar: [...this.state.protectedToolbar],
    //             dataLoad: 'locationlist',
    //             load: true
    //         });            
    //     } else {
    //         this.setState({
    //             title: state.title,
    //             subtitle: state.title
    //         })
    //     }
    // }
    // constructor(props) {
    //     super(props);
    //     this.props.addTranslationForLanguage(id, "id");
    //     this.props.addTranslationForLanguage(en, "en");
    //     this.state = {
    //         pageOfItems: [],
    //         toolbar: [],
    //         protectedToolbar: [{
    //             icon: '/images/add_black.png',
    //             name: <Translate id="hrms.master.location.add" />,
    //             value: "add"
    //         }],
    //         roleName:'',
    //         dataLoad: '',
    //         subtitle: '',
    //         load: false,
    //         list: [],
    //         listOrg: [],
    //         orgUnitUID: ''
    //     }
    // }
    // selectedData = (item, index) => {
    //     let { history, match } = this.props;
    //     this.setState({ index })             
    //         history.push({
    //             pathname: `${match.path}/locationlist`,
    //             state: {
    //                 orgUnitUID:item.uid,
    //                 subtitle: item.orgUnitName,
    //                 previous: true,
    //                 roleName: this.state.roleName,
    //                 item
    //             }
    //         });        
    //     showLastBlade();
    // }
    // close = () => {
    //     let { history, match } = this.props;
    //     if (match.path.includes('locationlist')) {
    //         history.push('/home/hrms/master/location')
    //     } else {
    //         history.push('/home')
    //     }

    // }
    // toolbarclick = (e) => {
    //     let { match, history } = this.props;
    //     if (e === "add") {
    //         let elem = document.getElementById(`list${'locationlist'}${this.state.index}`);
    //         if (elem) {
    //             elem.classList.remove('__selected');
    //         }
    //         this.setState({
    //             dataLoad: 'locationlist/'
    //         }, () => {
    //             history.push({
    //                 pathname: `${match.path}/add`,
    //                 state: {
    //                     orguid: this.state.orgUnitUID,
    //                     list: this.state.list
    //                 }
    //             });
    //         })
    //     }
    //     showLastBlade();
    // }
    // onChangePage = (pageOfItems) => {
    //     this.setState({ pageOfItems: pageOfItems });
    // }
    // sortDirection = (e, field) => {
    //     let sortOrder = this.state.sortOrder;
    //     if (sortOrder.includes(field)) {
    //         if (sortOrder.includes('asc')) {
    //             this.setState({
    //                 sortOrder: field + "-desc",
    //             }, () => {
    //                 this.onSort(this.state.sortOrder);
    //             });
    //         } else {
    //             this.setState({
    //                 sortOrder: field + "-asc",
    //             }, () => {
    //                 this.onSort(this.state.sortOrder);
    //             });
    //         }
    //     } else {
    //         this.setState({
    //             sortOrder: field + "-asc",
    //         }, () => {
    //             this.onSort(this.state.sortOrder);
    //         });
    //     }
    // }
    // checkPathBeforeShowLoading = (masterload) => {
    //     let { match } = this.props;
    //     if (match.path === masterload.path && masterload.load) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    handleChange = (event) => {
        let value = event.target.value;
        if (this.props.selectedOrgUnit !== value) {
            this.props.goto(`/home`);
            this.props.updateSelectedOrgUnit(value);
        }
    }

    render() {
        let { theme, user, toolbar, match, selectedOrgUnit,
            orgList } = this.props;
        // let { pageOfItems } = this.state;
        return (
            <div className="topbar" style={theme.topbar}>
                <div className="topbar-content" style={{ display: "flex", justifyContent: "space-between" }}>
                    {/* <a className="topbar-home text-prm topbar-hover">BravoERP</a> */}
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>

                        <a style={{ display: "flex", alignItems: "center" }} onClick={this.showHome} className="topbar-home" id="dropdownMenuButton">
                            <img style={{ height: 35 }} alt="Sarvam" src={'/images/sarvamnamewhite.png'} />
                        </a>
                        {user.subscriptionLogoURL && <a style={{ display: "flex", alignItems: "center" }} className="topbar-home">
                            <img style={{ height: 35 }} alt={user.subscriptionLogoURL !== null ?"subscriptionlogo" : ''} src={user.subscriptionLogoURL} />
                        </a>}
                        <p style={{ color: '#43b0e6', margin: 0 }}>{user.subscriptionName ? user.subscriptionName : user.subscription}</p>
                    </div>
                    <div className="topbar-placeholder"></div>
                    <div style={{ display: 'inline-block' }}>
                    </div>
                    <div style={{ display: "flex" }}>
                        <div className="dropdown" style={{ display: "flex", alignItems: "center", }}>
                            <div>{"Welcome, " + user.empName + ' - ' + (user.orgUnitName ? user.orgUnitName : user.subscription)}</div>
                        </div>
                        <div className='selectdark' style={{
                            marginLeft: 10, marginRight: 10
                        }}>
                            <Select2
                                label={<Translate id="hrms.allEmployee.title" />}
                                value={selectedOrgUnit || ''}
                                name="orgUnit"
                                style={{ color: 'white' }}
                                className='mySelect'
                                onSelect={this.handleChange}
                                data={orgList.map(({ uid, orgUnitName }) => ({ id: uid, text: orgUnitName }))}
                                options={{ placeholder: 'Select Org Unit' }}
                            />
                        </div>
                        {/* <div className="dropdown" style={{ display: "flex", marginLeft: "13px" }}>
                            <a style={{ display: "flex", alignItems: "center", fontSize: "13px" }} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <Translate style={{height: 25}} id="hrms.master.location.organisationUnit" />
                            </a>
                        </div>                     */}
                        <div className="dropdown">
                            <a style={{ display: "flex", alignItems: "center" }} className="topbar-home" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img style={{ height: 25, width: 25 }} alt="Settings" src={'/images/settings.png'} />
                            </a>
                            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <div style={{ padding: '0 0 0 10px' }}><Translate id="settings" /></div>
                                <div className="dropdown-divider">
                                </div>
                                <div style={{ display: 'flex', margin: 10 }}>
                                    {/* <a style={{ padding: 5, height: 30, width: 35, border: '1px solid black', "borderRadius": "100%", backgroundColor: "#0078d7", marginRight: 5 }} className="dropdown-item" onClick={() => { this.change(1) }}><span ></span></a> */}
                                    <a style={{ padding: 5, height: 30, width: 35, border: '1px solid black', "borderRadius": "100%", backgroundColor: "#ffffff", marginRight: 5 }} className="dropdown-item" onClick={() => { this.change(2) }}><span ></span></a>
                                    <a style={{ padding: 5, height: 30, width: 35, border: '1px solid black', "borderRadius": "100%", backgroundColor: "#000000", marginRight: 5 }} className="dropdown-item" onClick={() => { this.change(3) }}><span ></span></a>
                                </div>
                                <div style={{ display: 'flex', margin: 10 }}>
                                    <a onClick={() => { this.changeLanguage("en") }} style={{ marginRight: 10 }}>English</a> | <a onClick={() => { this.changeLanguage("id") }} style={{ marginLeft: 10 }}>Bahasa</a>
                                </div>
                            </div>
                        </div>
                        <div className="dropdown">
                            <a style={{ display: "flex", alignItems: "center" }} className="topbar-home" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <img style={{ height: 25, width: 25 }} alt="user" src={'/images/user.png'} />
                            </a>
                            <div style={{ alignItems: "center", width: 250 }} className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">

                                <a onClick={this.changePassword} className="dropdown-item" style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                                    <span>
                                        <Translate id="changepassword" />
                                    </span>
                                    <span>
                                        <img style={{ height: 20, width: 20 }} alt="Settings" src={'/images/password_black.png'} />
                                    </span>
                                </a>
                                <a onClick={this.signout} className="dropdown-item" style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                                    <span>
                                        <Translate id="signout" />
                                    </span>
                                    <span>
                                        <img style={{ height: 20, width: 20 }} alt="Settings" src={'/images/logout_black.png'} />
                                    </span>
                                </a>

                            </div>
                        </div>

                    </div>
                </div>
                <Notification />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    removeUser,
    setLanguagePage,
    createNotification,
    errorNotification,
    updateSelectedOrgUnit,
    storeMaster,
    storeOrgList,
    goto: (e, data) => push({ pathname: e, state: { data } })
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master,
    orgList: state.user.orgList,
    selectedOrgUnit: state.user.selectedOrgUnit,
    activelanguagepage: state.language.activelanguagepage,
    routing: state.routing,
    dataChanged: state.user.master,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(withTheme(Topbar)))
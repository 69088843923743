import { formDataConsole, devLog,getSignature,DEV_URL, checkAPIError } from '../../../_helpers/helper.methods';
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const Add = (formData, user) => {
    formDataConsole(formData);
    let signature = formData.get('EmpUID').toLowerCase() + user.empUID + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    //formData.append('EmpUID', user.empUID);

    formDataConsole(formData);
    let url = '/api/hrms/employee/card/add';    
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// card add response', res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user, state) => {
    let signature = state.idCardUID + user.empUID + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    //formData.append('IDcardUID', state.idCardUID);

    let url = '/api/hrms/employee/card/update';    
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// card update response', res)
            return res;
        })
        .catch(checkAPIError);
}

export const Delete = (user, state) => {
    let formData = new FormData();      
    let signature = state.idCardUID + user.empUID + user.token;
    let { language: { ipaddress } } = store.getState();
        devLog("raw sig", signature);
    
        signature = getSignature(signature);
    
        formData.append('Signature', signature);
        formData.append('Subscription', user.subscription);
        formData.append("ActionBy", user.empUID);
        formData.append('DeviceIP', ipaddress);
        formData.append('IDCardUID', state.idCardUID);
    
        let url = '/api/hrms/employee/card/delete';    
        return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// card delete response', res)
            return res;
        })
        .catch(checkAPIError);
}
// export const Detial = (user, state) => {
//     let formData = new FormData();
//     let signature = user.empUID + user.userUID + user.token;

//     devLog("raw sig", signature);

//     signature = getSignature(signature);

//     formData.append('Signature', signature);
//     formData.append('Subscription', user.subscription);
//     formData.append("ActionBy", user.userUID);
//     formData.append('DeviceIP', ipaddress);
//     formData.append('EmpUID', user.empUID);

//     return fetch('../../../api/hrms/employee/card/detail', {
//         method: 'POST',
//         body: formData
//     })
//         .then((res) => res.json())
//         .then((res) => {
//             devLog('// card detail response', res)
//             return res;
//         })
// }



import React from 'react';


class AttachFile extends React.Component {

    render() {
        let { label, onChange, value, id, placeholder } = this.props;
        return (
            <div className="form-group">
                <label className="form-label">{label}</label>
                <div className="form-input">
                    <input name={id} type="file" id={id || 'file'} onChange={onChange} />
                    <input placeholder={placeholder} readOnly='readonly' type="text" value={value} />
                    <button onClick={() => document.getElementById(id || 'file').click()} className="btn __file" type='button'>
                        <i className="btn-ico fa fa-image"></i>
                    </button>
                </div>
            </div>
        );
    }

}

export default AttachFile;
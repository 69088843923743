import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";
import TextValidator from "../../../../../Components/Validators/TextValidator";
import { ValidatorForm } from 'react-form-validator-core';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//api
import API from '../../../../../_apis/Core';
import MasterAPI from '../../../../../_apis/hrms/master';
import EmployeeAPI from '../../../../../_apis/hrms/employee';
import SelectValidator from "../../../../../Components/Validators/SelectValidator";
import { withLocalize, Translate } from 'react-localize-redux';
import { createNotification } from '../../../../../_reducers/reducer.notification';
import { handleFormErrors } from "../../../../../_helpers/helper.methods";

class RoleInfo extends Component {
    async componentDidMount() {
        let { history: { location: { state } }, match } = this.props;
        if (match.path.includes('allemployee')) {
            this.setState({ load: true });
            this.setState({
                toolbar: [...this.state.protectedToolbar]
            })
            let empresponse = await EmployeeAPI.Employee.Detail(this.props.user, null, state.item);
            let employeeRoleList = empresponse.roleList;
            this.setState({
                validRoleList: employeeRoleList
            })
            let res = await API.Module(this.props.user);
            if (res.status !== '1') {
                let rolelist = [];
                let selectedRole = [];
                res.map(async ({ uid, moduleName }, i, arr) => {
                    let role = await MasterAPI.Role.List(this.props.user, 'list', uid, true);
                    let employeeRole = employeeRoleList.find(({ moduleUID }) => {
                        return uid === moduleUID
                    })
                    let selectedrec = {
                        uid: employeeRole ? employeeRole.employeeRoleUID : null,
                        moduleuid: uid,
                        roleuid: employeeRole ? employeeRole.roleUID : null,
                    }
                    rolelist.push({
                        moduleName, roleList: role,
                        selectedrec
                    });
                    selectedRole.push(selectedrec);
                    if (arr.length - 1 === i) {
                        this.setState({
                            load: false,
                            roleList: rolelist,
                            selectedRole,
                            moduleList:res
                        })
                    }
                })
            } else {
                this.props.createNotification('Operation Failed', res.message)
            }
        }
        this.setState({
            employee: state.item,
            icon: state.icon,
            title: state.title
        })
    }
    constructor(props) {
        super(props);
        this.state = {
            employee: {
                roleList: []
            },
            load: false,
            roleList: [],
            toolbar: [],
            protectedToolbar: [{
                icon: '/images/save_black.png',
                name: "Save",
                value: "save"
            }, {
                icon: '/images/reset_black.png',
                name: "Reset",
                value: "reset"
            }],
            selectedRole: [],
            validRoleList: [],
            moduleList: []
        }
    }
    handleChange = (e, selectedrec, index) => {
        let selectedRole = this.state.selectedRole.slice(0);
        let roleList = this.state.roleList.slice(0);
        selectedRole[index].roleuid = e.target.value;
        roleList[index].selectedrec.roleuid = e.target.value;
         this.setState({
            selectedRole,
            roleList
        });
    }
    handleSubmit = async (e) => {
        let { employee } = this.state;
        let formData = new FormData();
        let arraypush = [];
        for(let i = 0; i < this.state.validRoleList.length; i++)
        {
            let arraydetail = {};
            arraydetail.uid = this.state.validRoleList[i].employeeRoleUID ? this.state.validRoleList[i].employeeRoleUID : "00000000-0000-0000-0000-000000000000";
            arraydetail.moduleuid = this.state.validRoleList[i].moduleUID;
            arraydetail.roleuid = this.state.validRoleList[i].roleUID;
            arraypush.push(arraydetail);
        }

        formData.append('RoleJSON', JSON.stringify(arraypush));
        let res = await EmployeeAPI.Employee.RoleAddUpdate(formData, this.props.user, employee.empUID);
        if (res.status !== '1') {
            this.props.createNotification('Success', res.message);
            this.close();
        } else {
            this.props.createNotification('Operation Failed', res.message)
        }
    }
    close = () => {
        let { match } = this.props;
        if (match.path.includes('allemployee')) {
            this.props.history.push('/home/hrms/allemporgunit/allemployee/menu')
        } else {
            this.props.history.push('/home/hrms/myinfo')
        }
    }
    toolbarclick = (e) => {
        if (e === 'save') {
            document.getElementById('submit').click();
        }
    }
    addToList = () => {
        let message = "";

        if (this.state.selectedRole.length > 0)
        {
            let filteredrole = this.state.validRoleList.filter((role) => {
                return role.roleUID === this.state.selectedRole[0].roleuid;
            })
            if (filteredrole.length > 0)
            {
                message += "Role already in list \n";
            }
        }
        else
        {
            message += "Please choose role to be added\n";
        }

        if(message === "")
        {
            let selrole = this.state.roleList[0].roleList.filter((role) => {
                return role.uid === this.state.selectedRole[0].roleuid;
            })
            let roleadd = {};
            roleadd.roleUID = this.state.selectedRole[0].roleuid;
            roleadd.moduleUID = this.state.moduleList.length > 0 ? this.state.moduleList[0].uid : "";
            roleadd.moduleName = this.state.moduleList.length > 0 ? this.state.moduleList[0].moduleName : "";
            roleadd.roleName = selrole.length > 0 ? selrole[0].roleName : "";
            let validRoleList = this.state.validRoleList;
            validRoleList.push(roleadd);
            this.setState({
                validRoleList: validRoleList
            });
        }
        else
        {
            alert(message);
        }
    }
    removeFromlist = (value) => {
        let validRoleList = this.state.validRoleList;
        validRoleList = validRoleList.filter((od) => {
            return od.roleUID !== value.roleUID;
        })
        this.setState({
            validRoleList: validRoleList
        })

    }
    render() {
        let { match } = this.props;
        let { toolbar, roleList, load, employee, validRoleList } = this.state;
        return (
            <Blade match={this.props.match}
                close={this.close}
                load={load}
                icon={this.state.icon || '/images/myorgchart.png'}
                head={<Translate id="breadcrumbs.rolelist" />}
                subhead={<Translate id="breadcrumbs.rolelist" />}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={load}>
                    <ValidatorForm
                        ref="form"
                        onError={handleFormErrors}
                        onSubmit={this.handleSubmit}
                    >
                        {match.path.includes('myinfo')
                            ? <React.Fragment>
                                {employee.roleList.map((item) => (
                                    <TextValidator
                                        disabled
                                        label={item.moduleName}
                                        name={item.roleName}
                                        value={item.roleName}
                                    />))}
                            </React.Fragment>
                            : <React.Fragment>
                                {roleList.map(({ moduleName, roleList, selectedrec }, index) => (
                                    <SelectValidator
                                        add={() => this.addToList()}
                                        key={index}
                                        label={moduleName}
                                        value={selectedrec.roleuid}
                                        name={moduleName}
                                        onSelect={(e) => this.handleChange(e, selectedrec, index)}
                                        data={roleList.map(({ roleName, uid }) => ({ text: roleName, id: uid }))}
                                        options={{ placeholder: 'Choose one' }}
                                    />
                                ))}
                            </React.Fragment>}
                        <br />
                        <div className="table-wrapper" style={{ height: "calc(100% - 500px)", overflow: 'hidden' }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className='table-col'><Translate id="hrms.roleName" /></th>
                                        <th className='table-col'><Translate id="hrms.moduleName" /></th>
                                        <th className="table-col">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {validRoleList && validRoleList.length > 0 ? 
                                        validRoleList.map((item,i) => (                                      
                                        <tr className="table-item">
                                            <td className="table-col">{item.roleName}</td>
                                            <td className="table-col">{item.moduleName}</td>
                                            <td className="table-col"><button type="button" onClick={()=>this.removeFromlist(item)} className="btn" style={{background: "#ff0000", color: "white" ,padding:"0 10px" , height:"auto" ,lineHeight:"normal"}}>-</button></td>
                                        </tr>
                                        ))
                                        :(
                                        <tr className="table-item">
                                            <td colSpan="3" className="table-col"><Translate id="hrms.absenceManagement.noDataFound" /></td>
                                        </tr>
                                        )}
                                </tbody>                
                            </table>
                        </div>
                        <div className="form-group">
                            <button id="submit" style={{ display: "none" }} type="submit"></button>
                        </div>
                    </ValidatorForm>
                </BladeBody>
            </Blade >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(RoleInfo))
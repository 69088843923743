import React, { Component } from "react";

import Blade from "../../../../Components/Blade";
import BladeBody from "../../../../Components/Blade/BladeBody";

import TableList from "../../../../Components/Lists/TableList";
import Pagination from '../../../../Components/Pagination';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { errorNotification, createNotification } from "../../../../_reducers/reducer.notification";
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../_language/en.json';
import id from '../../../../_language/id.json';

//methods
import { showLastBlade } from "../../../../_helpers/helper.methods";
//api

import API from "../../../../_apis/hrms/master";
import DropdownList from "../../../../Components/Lists/DropdownList";
import BladeTopbar from "../../../../Components/Blade/BladeTopbar";
//import EmployeeAPI from '../../../../_apis/hrms/employee';

class List extends React.Component {
    async componentDidMount() {
        let { match, history: { location: { state } } } = this.props;
        // let url = '../../../../../../';
        let url = '../../../../../';
        let res = await API.OrgUnit.List(this.props.user, url, true);
        this.setState({ load: false })
        if (res.status !== '1') {
            let selectedOrgUnit = this.props.selectedOrgUnit;
            let foundIndex = res.findIndex((e) => e.uid === selectedOrgUnit);
            this.setState({
                listOrg: res,
                selectedIndex: foundIndex
            }, () => {
            });
        } else {
            this.props.errorNotification(res.message);
        }
        this.setState({ load: false })

        if (match.path.includes('locationlist')) {
            this.setState({
                title: 'Org Unit List',
                subtitle: state.subtitle,
                org: state.item.id,
                toolbar: [...this.state.protectedToolbar],
                dataLoad: 'locationlist',
                load: true
            });
        } else {
            // this.setState({
            //     title: state.title,
            //     subtitle: state.title
            // })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.history.location.pathname === '/home/hrms/master/location') {
            if (this.state.listOrg !== null && this.state.listOrg.length !== 0) {
                if (this.state.selectedIndex !== null && this.state.selectedIndex > -1) {
                    this.selectedData(this.state.listOrg[this.state.selectedIndex]);
                }
            }
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            pageOfItems: [],
            toolbar: [],
            protectedToolbar: [{
                icon: '/images/add_black.png',
                name: <Translate id="hrms.master.location.add" />,
                value: "add"
            }],
            roleName: '',
            dataLoad: '',
            subtitle: '',
            load: false,
            list: [],
            listOrg: [],
            orgUnitUID: '',
            menu: this.props.history.location.state.menu,
        }
    }
    selectedData = (item, index) => {
        let { history, match } = this.props;
        // this.setState({ index })             
        //     history.push({
        //         pathname: `${match.path}/locationlist`,
        //         state: {
        //             orgUnitUID:item.uid,
        //             subtitle: item.orgUnitName,
        //             previous: true,
        //             roleName: this.state.roleName,
        //             item
        //         }
        //     });
        // this.setState({ index })
        this.props.history.push({
            pathname: `${match.path}/locationlist`,
            state: {
                selectedOrgUnitUID: item.uid,
                menu: this.state.menu,
                orgUnitUID: item.uid,
                subtitle: item.orgUnitName,
                previous: true,
                roleName: this.state.roleName,
                item
            }
        });
        showLastBlade();
    }
    close = () => {
        let { history, match } = this.props;
        if (match.path.includes('locationlist')) {
            history.push('/home/hrms/master/location')
        } else {
            history.push('/home')
        }

    }
    toolbarclick = (e) => {
        let { match, history } = this.props;
        if (e === "add") {
            let elem = document.getElementById(`list${'locationlist'}${this.state.index}`);
            if (elem) {
                elem.classList.remove('__selected');
            }
            this.setState({
                dataLoad: 'locationlist/'
            }, () => {
                history.push({
                    pathname: `${match.path}/add`,
                    state: {
                        orguid: this.state.orgUnitUID,
                        list: this.state.list
                    }
                });
            })
        }
        showLastBlade();
    }
    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems: pageOfItems });
    }
    sortDirection = (e, field) => {
        let sortOrder = this.state.sortOrder;
        if (sortOrder.includes(field)) {
            if (sortOrder.includes('asc')) {
                this.setState({
                    sortOrder: field + "-desc",
                }, () => {
                    this.onSort(this.state.sortOrder);
                });
            } else {
                this.setState({
                    sortOrder: field + "-asc",
                }, () => {
                    this.onSort(this.state.sortOrder);
                });
            }
        } else {
            this.setState({
                sortOrder: field + "-asc",
            }, () => {
                this.onSort(this.state.sortOrder);
            });
        }
    }

    render() {
        //let { master } = this.props;
        let { toolbar, pageOfItems } = this.state;

        //let { match } = this.props;
        return (
            <div></div>
            // <Blade match={this.props.match}
            //     max={false}//max={match.path.includes('locationlist') ? 'max': 'true'}
            //     load={this.state.load}
            //     close={this.close}
            //     icon={this.state.icon || '/images/location.png'}
            //     head={<Translate id="hrms.master.location.organisationUnit" />}
            //     subhead={<Translate id="hrms.master.location.organisationUnit" />}
            //     toolbar={this.state.roleName === "Super Admin" ? toolbar : ''}
            //     handleclick={this.state.roleName === "Super Admin" ? this.toolbarclick : ''}>
            //     <BladeBody match={this.props.match} load={this.state.load}>
            //         <div>
            //                 <Pagination
            //                     mainFilter={{ jsonName: 'orgUnitName', disName: 'Org Unit' }}
            //                     filters={[{ jsonName: 'orgUnitName', disName: 'Org Unit' }]}
            //                     //items={master.modulemaster.orgUnitList}
            //                     items={this.state.listOrg}
            //                     onChangePage={(e) => { this.onChangePage(e) }} 
            //                     onRef={(onSort) => {this.onSort = onSort}}/>
            //                 <TableList
            //                     id='location'
            //                     returnSelected={this.selectedData} 
            //                     fields={['orgUnitName']}
            //                     list={pageOfItems || []}
            //                     header={[<Translate id="hrms.master.location.organisationUnit" />]} 
            //                     onHeaderClick={this.onSort}>
            //                 </TableList>  
            //         </div>
            //     </BladeBody>
            // </Blade>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    errorNotification
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    dataChanged: state.notification.dataChanged,
    master: state.user.master,
    selectedOrgUnit: state.user.selectedOrgUnit,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(List))
import React from 'react'
import { Route } from 'react-router-dom'
//components
import IdCardInfoDetail from "./IdCardInfoDetail"
import IdCardInfoList from "./IdCardInfoList"

class IdCardInfo extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route path={`${match.path}`} component={IdCardInfoList} />
                <Route path={`${match.path}/add`} component={IdCardInfoDetail} />
                <Route path={`${match.path}/edit`} component={IdCardInfoDetail} />
            </React.Fragment>
        );
    }
}

export default IdCardInfo;
import * as Master from './master';
import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../_helpers/helper.methods";
import store from '../../_helpers/helper.store';
//var ip = require("ip");

export const AccessBlob = (user, blob, directory) => {
    let { language: { ipaddress } } = store.getState();
    let signature = blob.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog('raw signature', signature)

    let url = '/api/core/accessbloburl';
    signature = getSignature(signature);

    let formData = new FormData();

    formData.append('BlobURL', blob);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    formDataConsole(formData);
    devLog('url', url)

    //return fetch(directory.concat(DEV_URL, url), {
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// blob url response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Module = (user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog('raw signature', signature)

    let url = '/api/core/module';
    signature = getSignature(signature);

    let formData = new FormData();

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('IsActive', true)

    formDataConsole(formData);
    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// module response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const SubModule = (user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog('raw signature', signature)

    let url = '/api/core/submodule';
    signature = getSignature(signature);

    let formData = new FormData();

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('IsActive', true);

    formDataConsole(formData);
    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// submodule response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const SubModuleList = (ModuleUID) => {
    let { language: { ipaddress } } = store.getState();
    // let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;

    // devLog('raw signature', signature)

    let url = '/api/core/submodule/list';
    // signature = getSignature(signature);

    let formData = new FormData();

    // formData.append('Signature', signature);
    // formData.append('Subscription', user.subscription);
    // formData.append('ActionBy', user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('IsActive', true);
    formData.append('ModuleUID', ModuleUID);

    formDataConsole(formData);
    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// sub module list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export default {
    Master,
    AccessBlob,
    Module,
    SubModule,
    SubModuleList
}

import React, { Component } from "react";

//blades
import Blade from "../../Components/Blade";
import BladeBody from "../../Components/Blade/BladeBody";


class NewsDet extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {        
        let { history: { location: { state } } } = nextProps;
        if (prevState.news.uid === state.item.uid) {
            return null
        } else {
            return {
                subtitle: state.subtitle,
                news: state.item
            }
        }
    }
    componentDidMount() {
        let { history: { location } } = this.props;
        this.setState({
            subtitle: location.state.item.newsTitle,
            news: location.state.item
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            news: {}
        }

    }
    close = () => {
        this.props.history.push("/home")
    }
    render() {
        let { load, subtitle, news } = this.state;
        return (
            <Blade match={this.props.match}
                max
                load={load}
                close={this.close}
                icon={this.state.icon || '/images/leavecancel.png'}
                head={'News Details'}
                subhead={subtitle || ''}
                handleclick={this.toolbarclick} >
                <BladeBody match={this.props.match} notoolbar load={load}>
                    <div style={{ display: "flex", paddingTop: 10 }}>
                        <div style={{ flex: 1 }}>
                            <h1>{news.newsTitle}</h1>
                            <div style={{ textAlign: "center", marginTop: 30, marginBottom: 30 }}><img alt="newsimage" src={news.imageURL} style={{ maxWidth: "100%" }} /></div>
                            <p>{news.content}</p>
                        </div>
                    </div>
                </BladeBody>
            </Blade>
        )
    }
}


export default NewsDet
import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";

import TableList from "../../../../../Components/Lists/TableList";
//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateComponent, errorNotification } from "../../../../../_reducers/reducer.notification";
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';

//methods
import { showLastBlade, formatDate } from "../../../../../_helpers/helper.methods";
//api
import API from "../../../../../_apis/hrms/employee";
import Pagination from '../../../../../Components/Pagination';

class WorkList extends Component {
    async componentDidUpdate(prevProps, prevState) {
        let { dataChanged, history: { location } } = this.props;
        if (dataChanged && prevProps !== this.props) {
            let toolbar = location.pathname.includes("allemployee/menu/workhistory/current/") ? this.state.protectedToolbar : this.state.publicToolbar;
            toolbar.map((data, i) => {
                toolbar[i] = {
                    ...data,
                    disabled: location.pathname.includes("allemployee/menu/workhistory/") ? false : true
                }
                return null;
            })
            this.setState({
                toolbar,
                load: true
            })
            if (location.pathname.includes("workhistory/current/")
                || location.pathname.includes("workhistory/previous/")) {
                let url = "../../../../../../../";
                let res = await API.Employee.Detail(this.props.user, url, this.state.employee);
                if (res.status !== '1') {
                    this.props.updateComponent(res);
                    this.setState({
                        load: false,
                        originallist: location.pathname.includes('current')
                            ? res.currentCompanyWorkList
                            : res.previousCompanyWorkList,
                        list: this.getLocDateFromArray(
                            location.pathname.includes('current')
                                ? res.currentCompanyWorkList.slice(0)
                                : res.previousCompanyWorkList.slice(0)
                        )
                    })
                } else {
                    this.props.errorNotification(res.message);
                }
            } else if (location.pathname.includes('workhistory/previous/')) {

                let url = "../../../../../../../";
                let res = await API.Employee.Detail(this.props.user, url);
                if (res.status !== '1') {
                    this.props.updateComponent(res);
                    this.setState({
                        load: false,
                        originallist: res.previousCompanyWorkList,
                        list: this.getLocDateFromArray(res.previousCompanyWorkList.slice(0))
                    })
                } else {
                    this.props.errorNotification(res.message);
                }
            }
        }
    }
    getLocDateFromArray = (res) => {
        let data = [...res];
        data.map((item, i) => {
            data[i] = {
                ...item,
                startDate: item.startDate ? formatDate(item.startDate) : '',
                endDate: item.endDate ? formatDate(item.endDate) : ''
            };
            return null;
        })
        return data;
    }
    componentDidMount() {
        let { match } = this.props;
        let { history: { location: { state } } } = this.props;
        //this.setState({ icon: state.icon });
        if (match.path.includes("allemployee")) {
            if (match.path.includes('current')) {
                setTimeout(() => {
                    this.setState({
                        toolbar: this.state.protectedToolbar,
                        title: state.title,
                        employee: state.item,
                        subtitle: state.item.firstName + ' ' + (state.item.middleName !== null ? state.item.middleName : '') + ' ' + (state.item.lastName !== null ? state.item.lastName : ''),
                        originallist: state.item.currentCompanyWorkList,
                        list: this.getLocDateFromArray(state.item.currentCompanyWorkList.slice(0))
                    })
                }, 1000);
            } else if (match.path.includes('previous')) {
                setTimeout(() => {
                    this.setState({
                        toolbar: this.state.publicToolbar,
                        title: state.title,
                        employee: state.item,
                        subtitle: state.item.firstName + ' ' + (state.item.middleName !== null ? state.item.middleName : '') + ' ' + (state.item.lastName !== null ? state.item.lastName : ''),
                        originallist: state.item.previousCompanyWorkList,
                        list: this.getLocDateFromArray(state.item.previousCompanyWorkList.slice(0))
                    })
                }, 1000);
            }
        } else {
            if (match.path.includes('current')) {
                setTimeout(() => {
                    this.setState({
                        toolbar: this.state.protectedToolbar,
                        title: state.title,
                        employee: state.item,
                        subtitle: state.item.firstName + ' ' + (state.item.middleName !== null ? state.item.middleName : '') + ' ' + (state.item.lastName !== null ? state.item.lastName : ''),
                        originallist: state.item.currentCompanyWorkList,
                        list: this.getLocDateFromArray(state.item.currentCompanyWorkList.slice(0))
                    })
                }, 1000);
            } else if (match.path.includes('previous')) {
                setTimeout(() => {
                    this.setState({
                        toolbar: this.state.publicToolbar,
                        title: state.title,
                        employee: state.item,
                        subtitle: state.item.firstName + ' ' + (state.item.middleName !== null ? state.item.middleName : '') + ' ' + (state.item.lastName !== null ? state.item.lastName : ''),
                        originallist: state.item.previousCompanyWorkList,
                        list: this.getLocDateFromArray(state.item.previousCompanyWorkList.slice(0))
                    })
                }, 1000);
            }

        }
    }

    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            pageOfItems: [],
            title: '',
            toolbar: [],
            publicToolbar: [{
                icon: '/images/add_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.add" />,
                value: "add"
            }],
            protectedToolbar: [{
                icon: '/images/add_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.addAdditionalJob" />,
                value: 'AdditionalJob',
                disabled: true,
            }, {
                icon: '/images/add_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.addPromotion" />,
                value: 'Promotion',
                disabled: true,
            }, {
                icon: '/images/add_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.addTransfer" />,
                value: 'Transfer',
                disabled: true,
            }, {
                icon: '/images/add_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.changeStatus" />,
                value: 'Status',
                disabled: true,
            }, {
                icon: '/images/add_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.edit" />,
                value: 'Update',
                disabled: true,
            }, {
                icon: '/images/add_black.png',
                name: <Translate id="hrms.personeelManagement.termination.addTermination" />,
                value: 'Termination',
                disabled: true,
            }],
            selectedData: {},
            employee: {},
            subtitle: '',
            load: false,
            originallist: [],
            list: []
        }
    }
    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems: pageOfItems });
    }
    selectedData = (item, index) => {
        let { history, match } = this.props;
        this.setState({ index })
        if (match.path.includes('current')
            && match.path.includes('allemployee')) {
            if (history.location.pathname.includes('view') || history.location.pathname.includes('edit')) {
                history.push(history.location.pathname.slice(0, -5))
            }
            let toolbar = [...this.state.protectedToolbar]
            if (item && !item.endDate) {
                if (item.workPercentage < 100) {
                    toolbar.map((data, i) => {
                        toolbar[i] = {
                            ...data,
                            disabled: false
                        }
                        return null;
                    })
                    this.setState({
                        selectedData: this.state.originallist[index],
                        toolbar
                    })
                } else {
                    toolbar.map((data, i) => {
                        toolbar[i] = {
                            ...data,
                            disabled: data.value === 'addaddtionaljob' ? true : false
                        }
                        return null;
                    })
                    this.setState({
                        selectedData: this.state.originallist[index],
                        toolbar
                    })
                }
            } else if (!item) {

                this.setState({
                    toolbar: this.state.protectedToolbar
                })

            } else if (item && item.endDate) {

                this.setState({ toolbar: this.state.protectedToolbar })
                this.props.history.push({
                    pathname: `${match.path}/view`,
                    state: { item: this.state.originallist[index], empUID: this.state.employee.empUID }
                })
                showLastBlade();
            }
        } else if (match.path.includes('/current')
            && match.path.includes('/myinfo')) {
            history.push({
                pathname: `${match.path}/view`,
                state: { item: this.state.originallist[index] }
            });
            showLastBlade();
        } else if (match.path.includes('previous')) {
            history.push({
                pathname: `${match.path}/edit`,
                state: { item: this.state.originallist[index] }
            });
            showLastBlade();
        }
        // history.push({
        //     pathname: `${match.path}/edit`,
        //     state: { item }
        // });

    }
    close = () => {
        let { match, history } = this.props;
        if (match.path.includes("/hrms/myinfo")) {
            history.push("/home/hrms/myinfo/workhistory")
        } else if (match.path.includes("allemployee/menu")) {
            history.push("/home/hrms/allemporgunit/allemployee/menu/workhistory")
        } else {
            history.push("/home");
        }
    }
    toolbarclick = (e) => {
        let { employee } = this.state;
        let { match, history } = this.props;
        if (e === "add") {
            let id = match.path.includes('current') ? 'currentwork' : 'previouswork';

            let elem = document.getElementById(`list${id}${this.state.index}`);
            if (elem) {
                elem.classList.remove('__selected')
            }
            history.push({
                pathname: `${match.path}/add`,
                state: {
                    employee
                }
            });
        } else if (e === "Update") {
            // let id= match.path.includes('current') ? 'currentwork' : 'previouswork';
            // let elem = document.getElementById(`list${id}${this.state.index}`);                       
            // if (elem) {
            //     elem.classList.remove('__selected')
            // }

            history.push({
                pathname: `${match.path}/update`,
                state: {
                    employee,
                    action: e,
                    item: { ...this.state.selectedData }
                }
            });
        } else if (e === "Termination") {
            let elem = document.getElementById(`list${'termination'}${this.state.index}`);
            if (elem) {
                elem.classList.remove('__selected');
            }
            this.setState({
                dataLoad: 'termination/'
            }, () => {
                history.push({
                    pathname: `${match.path}/termination`,
                    state: {
                        //orgUnitUID: this.state.orgUnitUID,
                        item: {
                            empUID: this.state.employee.empUID
                        },
                        list: this.state.list,
                        subtitle: ""
                    }
                });
            })

        } else if (e) {
            let id = match.path.includes('current') ? 'currentwork' : 'previouswork';
            let elem = document.getElementById(`list${id}${this.state.index}`);
            if (elem) {
                elem.classList.remove('__selected')
            }
            history.push({
                pathname: `${match.path}/edit`,
                state: {
                    employee,
                    action: e,
                    item: { ...this.state.selectedData }
                }
            });
        }
        showLastBlade();
    }

    render() {
        let { match } = this.props;
        let { toolbar, title, list, pageOfItems } = this.state;
        return (
            <Blade match={this.props.match}
                max='max'
                load={this.state.load}
                close={this.close}
                icon={this.state.icon || '/images/workhistory.png'}
                head={title}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={this.state.load}>
                    {match.path.includes('current')
                        ? <React.Fragment>
                            <Pagination
                                mainFilter={{ jsonName: 'orgUnitName', disName: 'Organisation' }}
                                filters={[{ jsonName: 'orgUnitName', disName: 'Organisation' }, { jsonName: 'division', disName: 'Division' }, { jsonName: 'positionName', disName: 'Position' }]}
                                items={list}
                                onChangePage={(e) => { this.onChangePage(e) }}
                                onRef={(onSort) => { this.onSort = onSort }} />

                            <TableList
                                id='currentwork'
                                returnSelected={this.selectedData}
                                fields={['orgUnitName', 'divisionName', 'positionName', "directManagerName", 'indirectManager', 'startDate', 'endDate', 'workPercentage']}
                                list={pageOfItems || []}
                                header={[<Translate id="hrms.employeeInfo.myInformation.organisation" />, <Translate id="hrms.employeeInfo.myInformation.division" />, <Translate id="hrms.employeeInfo.myInformation.position" />, <Translate id="hrms.employeeInfo.myInformation.directManager" />, <Translate id="hrms.employeeInfo.myInformation.indirectManager" />, <Translate id="hrms.employeeInfo.myInformation.startDate" />, <Translate id="hrms.employeeInfo.myInformation.endDate" />, <Translate id="hrms.employeeInfo.myInformation.workPercentage" />]}
                                onHeaderClick={this.onSort}>
                            </TableList>
                        </React.Fragment>
                        : <React.Fragment>
                            <Pagination
                                mainFilter={{ jsonName: 'title', disName: 'Title' }}
                                filters={[{ jsonName: 'title', disName: 'Title' }, { disName: 'Company', jsonName: 'companyName' }]}
                                items={list}
                                onChangePage={(e) => { this.onChangePage(e) }}
                                onRef={(onSort) => { this.onSort = onSort }} />

                            <TableList
                                id='previouswork'
                                returnSelected={this.selectedData}
                                fields={["title", 'companyName', 'startDate', 'endDate']}
                                list={pageOfItems || []}
                                header={[<Translate id="hrms.employeeInfo.myInformation.titleWork" />, <Translate id="hrms.employeeInfo.myInformation.company" />, <Translate id="hrms.employeeInfo.myInformation.from" />, <Translate id="hrms.employeeInfo.myInformation.to" />]}
                                onHeaderClick={this.onSort}>
                            </TableList>
                        </React.Fragment>}
                </BladeBody>
            </Blade >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateComponent,
    errorNotification
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    dataChanged: state.notification.dataChanged
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(WorkList))
import React from 'react'
import { Route } from 'react-router-dom'
//components
import FamilyInformation from "./FamilyInfromation"
import List from "./List"

class ContactDetails extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route path={`${match.path}`} component={List} />
                <Route path={`${match.path}/add`} component={FamilyInformation} />
                <Route path={`${match.path}/edit`} component={FamilyInformation} />
            </React.Fragment>
        );
    }
}

export default ContactDetails;
import React from 'react'
import { Route } from 'react-router-dom'
//components
import LocationList from "./LocationList"
import Details from "./LocationDetail"
import OrgunitList from "./OrgunitList"
import Topbar from '../../../../Components/Topbar'

class Location extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route path={`${match.path}`} component={OrgunitList} />
                <Route path={`${match.path}/locationlist`} component={LocationList} />
                <Route path={`${match.path}/locationlist/edit`} component={Details} />
                <Route path={`${match.path}/locationlist/add`} component={Details} />
            </React.Fragment>
        );
    }
}

export default Location;
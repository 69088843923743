

import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";

//custom components
import TextValidator from "../../../../../Components/Validators/TextValidator";
import { ValidatorForm } from 'react-form-validator-core';
import SelectValidator from "../../../../../Components/Validators/SelectValidator";
import DatetimePicker from "../../../../../Components/DateTimePicker/DatePicker";
import Slider from "../../../../../Components/Slider";
import TextareaValidator from "../../../../../Components/Validators/TextareaValidator";
import CheckBox from "../../../../../Components/Validators/CheckBox";

//methods
import { devLog, formatDate, getCalenderView, handleFormErrors, } from '../../../../../_helpers/helper.methods';

//api
import API from "../../../../../_apis/hrms/employee";
import MasterAPI from '../../../../../_apis/hrms/master';
import APIBlob from '../../../../../_apis/Core';
import moment from 'moment';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createNotification, dataChanged } from '../../../../../_reducers/reducer.notification';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';
let tomorrow = moment().add(1, 'day');
var futuredatevalid = function (current) {
    return current.isBefore(tomorrow);
};
class MyWorkHistory extends Component {

    componentDidUpdate(prevProps, prevState) {

        let { history: { location } } = this.props;
        if (prevProps !== this.props) {
            if (location.pathname.includes("view") && location.state.item) {
                this.setState({
                    subtitle: location.state.item.orgUnitName,
                    workHistory: {
                        ...location.state.item,
                        startDate: formatDate(location.state.item.startDate || ''),
                        endDate: formatDate(location.state.item.endDate || ''),
                        departmentUID: location.state.item.departmentUID ? location.state.item.departmentUID.toUpperCase() : "",
                        subDepartmentUID: location.state.item.subDepartmentUID ? location.state.item.subDepartmentUID.toUpperCase() : ""
                    }
                })
            } else if ((location.pathname.includes('previous/edit') && location.state.item) || (location.pathname.includes('previous/update') && location.state.item)) {
                this.setState({
                    subtitle: location.state.item.title,
                    workHistory: {
                        ...location.state.item,
                        startDate: formatDate(location.state.item.startDate || ''),
                        endDate: formatDate(location.state.item.endDate || ''),
                        departmentUID: location.state.item.departmentUID ? location.state.item.departmentUID.toUpperCase() : "",
                        subDepartmentUID: location.state.item.subDepartmentUID ? location.state.item.subDepartmentUID.toUpperCase() : ""
                    }
                })
            } else if (location.pathname.includes('current/edit') || location.pathname.includes('current/update')) {
                if (location.state.action === "AdditionalJob") {
                    this.setState({
                        subtitle: location.state.item.orgUnitName,
                        action: location.state.action,
                        workHistory: {
                            ...location.state.item,
                            startDate: formatDate(location.state.item.startDate || ''),
                            endDate: formatDate(location.state.item.endDate || ''),
                            orgUnitUID: '',
                            divisionUID: '',
                            positionUID: '',
                            departmentUID: '',
                            subDepartmentUID: '',
                            directManagerUID: '',
                            indirectManagerUID: '',
                            workPercentage: '',
                            contractLength: '',
                        }
                    })
                } else
                    if (location.state.action === "Promotion" || location.state.action === "Transfer") {
                        this.setState({
                            subtitle: location.state.item.orgUnitName,
                            action: location.state.action,
                            workHistory: {
                                ...location.state.item,
                                startDate: formatDate(location.state.item.startDate || ''),
                                endDate: formatDate(location.state.item.endDate || ''),
                                directManagerUID: '',
                                indirectManagerUID: '',
                                positionUID: '',
                                departmentUID: location.state.item.departmentUID ? location.state.item.departmentUID.toUpperCase() : "",
                                subDepartmentUID: location.state.item.subDepartmentUID ? location.state.item.subDepartmentUID.toUpperCase() : ""
                            }
                        })
                    } else {
                        this.setState({
                            subtitle: location.state.item.orgUnitName,
                            action: location.state.action,
                            workHistory: {
                                ...location.state.item,
                                startDate: formatDate(location.state.item.startDate || ''),
                                endDate: formatDate(location.state.item.endDate || ''),
                                departmentUID: location.state.item.departmentUID ? location.state.item.departmentUID.toUpperCase() : "",
                                subDepartmentUID: location.state.item.subDepartmentUID ? location.state.item.subDepartmentUID.toUpperCase() : ""
                            }
                        })
                    }
            }
        }
    }
    componentDidMount = async () => {
        let { history: { location: { state } }, match } = this.props;
        if (match.path.includes("view")) {
            this.setState({
                subtitle: state.item.orgUnitName,
                workHistory: {
                    ...state.item,
                    startDate: formatDate(state.item.startDate || ''),
                    endDate: formatDate(state.item.endDate || ''),
                    departmentUID: state.item.departmentUID ? state.item.departmentUID.toUpperCase() : "",
                    subDepartmentUID: state.item.subDepartmentUID ? state.item.subDepartmentUID.toUpperCase() : ""
                }
            })
            let res = await API.Personeel.TerminationList(this.props.user, this.props.user.empUID);
            this.setState({ load: false })
            if (res.status !== '1') {
                if (res.length > 0) {
                    res[0].processDate = formatDate(res[0].processDate)
                    res[0].effectiveDate = formatDate(res[0].effectiveDate)
                    this.setState({
                        terminationObj: res[0]

                    })
                }
            }
            let url1 = '../../../../../../../';
            let res1 = await MasterAPI.OrgUnit.List(this.props.user, url1, true);
            devLog("-------- org unit --------- ");
            devLog(res1);
            this.setState({ load: false })
            if (res.status !== '1') {
                this.setState({
                    orgUnitList: res1,
                })
            }

        } else if (match.path.includes('current/edit') || match.path.includes('current/update')) {
            if (state.action === "AdditionalJob") {
                this.setState((st) => ({
                    subtitle: state.item.orgUnitName,
                    toolbar: st.protectedtoolbar,
                    action: state.action,
                    employee: state.employeee,
                }), () => {
                    //let a = this.state.workHistory;
                    //let b = a;
                })

            } else if (state.action === "Promotion" || state.action === "Transfer") {
                if (state.item.orgUnitUID) {
                    this.getDropdownFromAPI({ name: 'orgUnitUID', value: state.item.orgUnitUID }, 'pre');
                    //await this.getPositionList(state.item.orgUnitUID);
                }
                if (state.item.divisionUID) {
                    //this.getReportToList(state.item.positionUID);
                    await this.getDropdownFromAPI({ name: 'divisionUID', value: state.item.divisionUID }, 'pre');
                }
                if (state.item.departmentUID) {
                    //this.getReportToList(state.item.positionUID);                    
                    await this.getDropdownFromAPI({ name: 'departmentUID', value: state.item.departmentUID }, 'pre');
                }
                this.setState((st) => ({

                    subtitle: state.item.orgUnitName,
                    toolbar: st.protectedtoolbar,
                    action: state.action,
                    employee: state.employeee,
                    workHistory: {
                        ...state.item,
                        startDate: formatDate(state.item.startDate || ''),
                        endDate: formatDate(state.item.endDate || ''),
                        divisionUID: state.item.divisionUID ? state.item.divisionUID.toUpperCase() : "",
                        departmentUID: state.item.departmentUID ? state.item.departmentUID.toUpperCase() : "",
                        subDepartmentUID: state.item.subDepartmentUID ? state.item.subDepartmentUID.toUpperCase() : ""
                    }
                }), () => {
                    //let a = this.state.workHistory;
                    //let b = a;
                })
            } else {
                if (state.item.orgUnitUID) {
                    this.getDropdownFromAPI({ name: 'orgUnitUID', value: state.item.orgUnitUID }, 'pre');
                    //await this.getPositionList(state.item.orgUnitUID);
                }
                if (state.item.positionUID) {
                    //this.getReportToList(state.item.positionUID);
                    this.getDropdownFromAPI({ name: 'positionUID', value: state.item.positionUID }, 'pre');
                }
                if (state.item.divisionUID) {
                    //this.getReportToList(state.item.positionUID);
                    await this.getDropdownFromAPI({ name: 'divisionUID', value: state.item.divisionUID }, 'pre');
                }
                if (state.item.departmentUID) {
                    //this.getReportToList(state.item.positionUID);                    
                    await this.getDropdownFromAPI({ name: 'departmentUID', value: state.item.departmentUID }, 'pre');
                }
                if (state.item.directMangerbyPosition === false || state.item.indirectMangerbyPosition === false) {
                    await this.getallManagerList(state.item.orgUnitUID);
                }
                this.setState((st) => ({

                    subtitle: state.item.orgUnitName,
                    toolbar: st.protectedtoolbar,
                    action: state.action,
                    employee: state.employeee,
                    workHistory: {
                        ...state.item,
                        startDate: formatDate(state.item.startDate || ''),
                        endDate: formatDate(state.item.endDate || ''),
                        divisionUID: state.item.divisionUID ? state.item.divisionUID.toUpperCase() : "",
                        departmentUID: state.item.departmentUID ? state.item.departmentUID.toUpperCase() : "",
                        subDepartmentUID: state.item.subDepartmentUID ? state.item.subDepartmentUID.toUpperCase() : ""
                    }
                }), () => {
                    //let a = this.state.workHistory;
                    //let b = a;
                })
            }
        } else if (match.path.includes('previous')) {
            this.setState({ icon: '/images/previouscompany.png' });
            if (match.path.includes('edit')) {
                this.setState({
                    subtitle: state.item.title,
                    toolbar: [...this.state.protectedtoolbar],
                    workHistory: {
                        ...state.item,
                        startDate: formatDate(state.item.startDate || ''),
                        endDate: formatDate(state.item.endDate || ''),
                        departmentUID: state.item.departmentUID ? state.item.departmentUID.toUpperCase() : "",
                        subDepartmentUID: state.item.subDepartmentUID ? state.item.subDepartmentUID.toUpperCase() : ""
                    }
                })
            } else {
                this.setState({
                    employee: {
                        ...state.employee,
                    },
                    toolbar: [...this.state.protectedtoolbar],
                })
            }

        }

    }
    getinitialObj = () => {
        return {
            terminationType: '',
            processDate: '',
            effectiveDate: '',
            description: '',
            blockRehire: '',
            fileURL: '',
            orgUnitList: []
        }
    }
    getinitialState = () => {
        let { match } = this.props;
        if (match.path.includes('previous')) {
            return {
                title: '',
                companyName: '',
                startDate: '',
                endDate: '',
                jobDesc: ''
            }
        } else if (match.path.includes('current/view')) {
            return {
                title: '',
                companyName: '',
                startDate: '',
                endDate: '',
                jobDesc: ''
            }
        } else if (match.path.includes('current/edit') || match.path.includes('current/update')) {
            return {
                directManagerName: '',
                directManagerUID: '',
                divisionName: '',
                divisionUID: '',
                employeeStatus: '',
                endDate: '',
                indirectManager: '',
                indirectManagerUID: '',
                orgUnitName: '',
                orgUnitUID: '',
                positionName: '',
                positionUID: '',
                departmentUID: '',
                departmentName: '',
                subDepartmentUID: '',
                subDepartmentName: '',
                reasonType: '',
                startDate: '',
                workPercentage: '',
                directMangerbyPosition: true,
                indirectMangerbyPosition: true,
                contractLength: '',
            }
        }
        devLog(match.path.includes('current/edit'));
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            subtitle: 'Work History Details',
            toolbar: [],
            protectedtoolbar: [{
                icon: '/images/save_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.save" />,
                value: "save"
            }, {
                icon: '/images/reset_black.png',
                name: "Reset",
                value: "reset"
            }],
            workHistory: this.getinitialState(),
            dropDownList: {
                managerList: [],
                positionList: [],
                departmentList: [],
                subDepartmentList: [],
                allManagerList: []
            },
            orgUnitList: props.orgList,
            directmanagervalidationflag: true,
            terminationObj: this.getinitialObj()

        }
    }
    handleChange = async (event, name) => {
        let workHistory = this.state.workHistory;

        if (name) {
            this.setState({
                workHistory: {
                    ...workHistory,
                    [name]: event
                }
            })
        } else {
            let { name: evename, value } = event.target;
            let controlname = event.target.name;
            let controletype = event.target.type;
            let controlvalue = event.target.value;
            if (controlname === "directMangerbyPosition" || controlname === "indirectMangerbyPosition") {
                if (this.state.dropDownList.allManagerList.length <= 0) {
                    //let result = await this.getallManagerList();
                }
            }
            if (evename === 'positionUID') {
                let position = '';
                this.state.dropDownList.positionList.forEach((item) => {
                    if (item.id === value) {
                        position = item.addInfo2;
                        //return true
                    }
                })
                this.setState({
                    workHistory: {
                        ...workHistory,
                        jobDesc: position,
                        [controlname]: controlvalue
                    }
                })
            } else if (evename === 'reasonType') {
                this.setState({
                    action: value
                })
            } else if (controletype === "checkbox") {
                this.setState({
                    workHistory: {
                        ...workHistory,
                        [controlname]: !workHistory[controlname]
                    }
                })
            }
            else {
                this.setState({
                    workHistory: {
                        ...workHistory,
                        [controlname]: controlvalue
                    }
                })
            }
        }
    }
    getallManagerList = async (orgUnitUID) => {
        let url = '../../../';
        if (orgUnitUID === undefined || orgUnitUID === null) {
            orgUnitUID = this.state.workHistory.orgUnitUID;
        }

        let res = await MasterAPI.Lists.ReportingTo(this.props.user, url, null, 'all', true, null, orgUnitUID);
        this.setState({ load: false })
        if (res.status !== '1') {
            this.setState({
                dropDownList: {
                    ...this.state.dropDownList,
                    allManagerList: res
                }
            })
            return true;
        }
        return false;
    }
    getPositionList = async (value) => {
        let url = '../../../../../../';
        let res = await MasterAPI.Lists.PositionList(this.props.user, url, value);
        this.setState({ load: false })
        if (res.status !== '1') {
            this.setState({
                dropDownList: {
                    ...this.state.dropDownList,
                    positionList: res,
                    //managerList: []
                }
            }, () => {
                this.getallManagerList();
                return res;
            });
        } else {
            return null;
        }
    }
    getDivisionList = async (value) => {
        let url = '../../../../../../';
        let resdiv = await MasterAPI.Lists.DivisionList(this.props.user, url, value);
        this.setState({ load: false })
        devLog('------ Status ---------');
        devLog(resdiv);
        if (resdiv.status !== '1') {
            this.setState({
                dropDownList: {
                    ...this.state.dropDownList,
                    divisionList: resdiv
                }
            }, () => {
                // this.getallManagerList();
                return resdiv;
            });
        } else {
            return null;
        }
    }
    getReportToList = async (value) => {
        let url = '../../../../../../';
        let res = await MasterAPI.Lists.ReportingTo(this.props.user, url, value, 'all');
        this.setState({ load: false })
        if (res.status !== '1') {
            this.setState({
                dropDownList: {
                    ...this.state.dropDownList,
                    managerList: res
                },
                workHistory: {
                    ...this.state.workHistory,
                    // directManagerUID: '',
                    // indirectManagerUID: ''
                }
            })
        }
    }
    getDepartmentList = async (value) => {
        let url = '../../../../../../';
        let res = await MasterAPI.Lists.DepartmentList(this.props.user, url, value);
        this.setState({ load: false })
        if (res.status !== '1') {
            this.setState({
                dropDownList: {
                    ...this.state.dropDownList,
                    departmentList: res,
                    //managerList: []
                }
            }, () => {
                //this.getallManagerList();
                return res;
            });
        } else {
            return null;
        }
    }
    getSubDepartmentList = async (value) => {
        let url = '../../../../../../';
        let res = await MasterAPI.Lists.SubDepartmentList(this.props.user, url, value);
        this.setState({ load: false })
        if (res.status !== '1') {
            this.setState({
                dropDownList: {
                    ...this.state.dropDownList,
                    subDepartmentList: res,
                    //managerList: []
                }
            }, () => {
                //this.getallManagerList();
                return res;
            });
        } else {
            return null;
        }
    }

    getDropdownFromAPI = async (e, prePopulate) => {
        this.setState({ load: true })
        if (prePopulate) {

            if (e.name === 'orgUnitUID') {
                await this.getPositionList(e.value);
                devLog('------- Division Called ------------', e.name)
                await this.getDivisionList(e.value);
                devLog('------- Division Called ------------')
            } else if (e.name === 'positionUID') {
                await this.getReportToList(e.value);
            } else if (e.name === 'divisionUID') {
                await this.getDepartmentList(e.value);
            } else if (e.name === 'departmentUID') {
                await this.getSubDepartmentList(e.value)
            }
        } else {
            let { value, name } = e.target;
            if (name === 'orgUnitUID') {
                await this.getPositionList(value);
                devLog('------- Division Called ------------', e.name)
                await this.getDivisionList(value);
                devLog('------- Division Called ------------')
            } else if (name === 'positionUID') {
                await this.getReportToList(value);
            } else if (e.target.name === 'divisionUID') {
                await this.getDepartmentList(value);
            } else if (e.target.name === 'departmentUID') {
                await this.getSubDepartmentList(value);
            }
        }
    }
    handleCalendar = (momentd, name) => {
        let workHistory = this.state.workHistory;
        if (typeof momentd !== "string") {
            let startdate = momentd.format("DD/MM/YYYY");
            this.setState({
                workHistory: {
                    ...workHistory,
                    [name]: name === 'endDate'
                        ? momentd.isAfter(moment(workHistory.startDate, 'DD/MM/YYYY'))
                            && momentd.isBefore(moment().subtract(1, 'day'))
                            ? startdate
                            : startdate.slice(6) <= new Date().getFullYear() ? startdate : startdate.slice(0, 6)
                        : momentd.isBefore(moment().subtract(1, 'day'))
                            ? startdate
                            : startdate.slice(6) <= new Date().getFullYear() ? startdate : startdate.slice(0, 6)
                },
            })
        } else {
            this.setState({
                workHistory: {
                    ...workHistory,
                    [name]: momentd.slice(6) <= new Date().getFullYear() ? momentd : momentd.slice(0, 6)
                }
            })
        }
    }
    handleSubmit = async (e) => {
        let formData = new FormData(e.target);

        formData.set("startDate", getCalenderView(this.state.workHistory.startDate, 'server'));
        formData.set('endDate', getCalenderView(this.state.workHistory.endDate, 'server'));

        let { match, history: { location: { state } } } = this.props;
        this.setState({ load: true })
        if (match.path.includes('/previous')) {
            if (match.path.includes('/add')) {

                let url = '../../../../../../../';

                if (match.path.includes("allemployee")) {

                    let res = await API.Work.Add(formData, this.props.user, url, 'previous', state.employee);
                    this.setState({ load: false })
                    if (res.status === "0") {
                        this.props.dataChanged();
                        this.close();
                        this.props.createNotification('Success', res.message);
                    } else {
                        this.props.createNotification('Operation Failed', res.message);
                    }
                } else {

                    let res = await API.Work.Add(formData, this.props.user, url, 'previous');
                    this.setState({ load: false })
                    if (res.status === "0") {
                        this.props.dataChanged();
                        this.close();
                        this.props.createNotification('Success', res.message);
                    } else {
                        this.props.createNotification('Operation Failed', res.message);
                    }
                }
            } else {

                let url = "../../../../../../";
                let res = await API.Work.Update(formData, this.props.user, url, 'previous', this.state.workHistory);
                this.setState({ load: false })
                if (res.status === "0") {
                    this.props.dataChanged();
                    this.props.createNotification('Success', res.message);
                } else {
                    this.props.createNotification('Operation Failed', res.message);
                }
            }
        } else if (match.path.includes('/current')) {
            let { action } = this.state;
            formData.append('AdditionalWork', state.action === 'AdditionalJob' ? 1 : 0);
            formData.append("ReasonType", action);
            let url = '../../../../../../../';
            if (match.path.includes("update")) {
                let res = await API.Work.Update(formData, this.props.user, url, 'current', this.state.workHistory, state.employee);
                this.setState({ load: false })
                if (res.status === "0") {
                    this.props.dataChanged();
                    this.close();
                    this.props.createNotification('Success', res.message);
                } else {
                    this.props.createNotification('Operation Failed', res.message);
                }
            }
            else if (match.path.includes("allemployee")) {

                let res = await API.Work.Add(formData, this.props.user, url, 'current', state.employee);
                this.setState({ load: false })
                if (res.status === "0") {
                    this.props.dataChanged();
                    this.close();
                    this.props.createNotification('Success', res.message);
                } else {
                    this.props.createNotification('Operation Failed', res.message);
                }
            }
        }
    }

    close = () => {
        let { match, history } = this.props;
        if (match.path.includes("/hrms/myinfo") || match.path.includes('allemployee/menu')) {
            if (match.path.includes('add')) {
                history.push(history.location.pathname.slice(0, -4))
            } else {
                history.push(history.location.pathname.slice(0, -5))
            }
        } else {
            history.push("/home");
        }
    }
    deleteAPI = async () => {
        this.setState({ load: true })
        let { workUID } = this.state.workHistory;
        let url = '../../../../../';
        let res = await API.Work.Delete(this.props.user, workUID, url)
        this.setState({ load: false })
        if (res.status === "0") {
            this.props.dataChanged();
            this.props.createNotification('Success', res.message);
            this.close();
        } else {
            this.props.createNotification('Operation Failed', res.message);
        }
    }
    reset = () => {
        let { history, match } = this.props;
        if (match.path.includes('edit')) {
            this.setState({});
            this.setState({
                workHistory: history.location.state.item
            }, () => {
                let { history } = this.props;
                this.setState({
                    workHistory: {
                        ...this.props.history.location.state.item,
                        startDate: formatDate(history.location.state.item.startDate || ''),
                        endDate: formatDate(history.location.state.item.endDate || '')
                    }
                })
            })

        } else if (match.path.includes('add')) {
            this.setState(this.getinitialState());
        }
    }
    toolbarclick = (e) => {
        if (e === "save") {
            document.getElementById("submit").click();
        } else if (e === 'delete') {
            this.deleteAPI();
        } else if (e === 'reset') {
            this.reset();
        }
    }
    checkvalidation = (value) => {
        let list = this.state.dropDownList.positionList;
        let uid = this.state.workHistory.positionUID;
        let returnvalue = false;
        if (list.length > 0) {
            this.state.dropDownList.positionList.some((item) => {
                if (item.uid !== null) {
                    if (item.id.toLocaleLowerCase() === uid.toLowerCase()) {
                        if (item.addInfo2 === null || item.addInfo2 === undefined || item.addInfo2 === "") {
                            returnvalue = false;
                        } else {
                            returnvalue = true;
                        }
                    }
                }
                return returnvalue
            })
        }
        return returnvalue;

    }

    downloadResume = async (fileURL) => {
        if (fileURL !== null) {
            let { user } = this.props;
            this.setState({
                load: true
            })
            let resume = window.open(null, 'sharer', 'toolbar=0,status=0,width=548,height=325');
            resume.document.write('Loading Please Wait.....')
            let res = await APIBlob.AccessBlob(user, fileURL, "/");
            this.setState({ load: false })
            if (res.status !== '1') {
                resume.location.href = res.message;
            } else {
                this.props.errorNotification(res.message);
            }
        } else {
            alert("Resume not available!");
        }
    }

    render() {
        let { workHistory, dropDownList, action, toolbar, directmanagervalidationflag, orgUnitList } = this.state;
        let { match, master } = this.props;
        return (
            <Blade match={this.props.match}
                max={false}
                load={this.state.load}
                close={this.close}
                icon={this.state.icon || '/images/currentcompany.png'}
                head={<Translate id="hrms.employeeInfo.myInformation.workHistoryDetails" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={this.state.load}>
                    {Inputs(action, match,
                        dropDownList, master, orgUnitList,
                        workHistory, this.handleSubmit,
                        this.handleChange, this.handleCalendar,
                        this.getDropdownFromAPI, directmanagervalidationflag,
                        this.checkvalidation, this.getdepartmentFromApi, this.state.terminationObj, this.downloadResume
                    )}

                </BladeBody>
            </Blade >
        )
    }
}
const Inputs = (action, match,
    dropDownList, master, orgUnitList,
    workHistory, handleSubmit,
    handleChange, handleCalendar,
    getDropdownFromAPI,
    directmanagervalidationflag, checkvalidation, getdepartmentFromApi, terminationObj, downloadResume
) => (

    <ValidatorForm
        ref="form"
        onError={handleFormErrors}
        onSubmit={handleSubmit}
    >
        {match.path.includes('previous')
            && <React.Fragment>
                <TextValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.titleWork" />}
                    onChange={handleChange}
                    name="title"
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                    value={workHistory.title || ''}
                />
                <TextValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.company" />}
                    onChange={handleChange}
                    name="companyName"
                    value={workHistory.companyName || ''}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />
                <DatetimePicker
                    closeOnSelect
                    isValidDate={(current) => { return current.isBefore(moment().subtract(1, 'day')) }}
                    onChange={(m) => handleCalendar(m, "startDate")}
                    value={workHistory.startDate || ''}
                    name='startDate'
                    viewDate={getCalenderView(workHistory.startDate)}
                    inputProps={{
                        placeholder: 'DD/MM/YYYY',
                        label: <Translate id="hrms.employeeInfo.myInformation.from" />
                    }}
                    validators={['required', 'matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />, <Translate id="hrms.employeeInfo.myInformation.invalidDate" />]}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false} />

                <DatetimePicker
                    closeOnSelect
                    name='endDate'
                    isValidDate={(current) => {
                        return current.isAfter(moment(workHistory.startDate, 'DD/MM/YYYY'))
                            && current.isBefore(moment().subtract(1, 'day'))
                    }}
                    viewDate={getCalenderView(workHistory.endDate)}
                    onChange={(m) => handleCalendar(m, "endDate")}
                    value={workHistory.endDate || ''}
                    inputProps={{
                        placeholder: 'DD/MM/YYYY',
                        label: <Translate id="hrms.employeeInfo.myInformation.to" />
                    }}
                    validators={['required', 'matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
                    errorMessages={['This field is required', 'Invalid Date']}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false} />

                <TextareaValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.positionDescription" />}
                    onChange={handleChange}
                    name="jobDesc"
                    value={workHistory.jobDesc || ''}
                />
            </React.Fragment>
        }

        {match.path.includes('/current/view')
            && <React.Fragment>

                <TextValidator
                    disabled
                    label={<Translate id="hrms.employeeInfo.myInformation.orgUnit" />}
                    onChange={handleChange}
                    name='orgUnitName'
                    value={workHistory.orgUnitName || ''}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />

                <TextValidator
                    disabled
                    label={<Translate id="hrms.employeeInfo.myInformation.position" />}
                    onChange={handleChange}
                    value={workHistory.positionName || ''}
                    name='positionName'
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />
                <TextValidator
                    disabled
                    label={<Translate id="hrms.employeeInfo.myInformation.division" />}
                    onChange={handleChange}
                    name='divisionName'
                    value={workHistory.divisionName || ''}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />
                <TextValidator
                    disabled
                    label={<Translate id="hrms.employeeInfo.myInformation.department" />}
                    onChange={handleChange}
                    name='departmentName'
                    value={workHistory.departmentName || ''}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />
                <TextValidator
                    disabled
                    label={<Translate id="hrms.employeeInfo.myInformation.subDepartment" />}
                    onChange={handleChange}
                    name='subDepartmentName'
                    value={workHistory.subDepartmentName || ''}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />
                <TextValidator
                    disabled
                    label={<Translate id="hrms.employeeInfo.myInformation.directManager" />}
                    onChange={handleChange}
                    name='directManagerName'
                    value={workHistory.directManagerName || ''}
                    validators={directmanagervalidationflag === true ? ['required'] : []}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />
                <TextValidator
                    disabled
                    label={<Translate id="hrms.employeeInfo.myInformation.indirectManager" />}
                    onChange={handleChange}
                    name='indirectManager'
                    value={workHistory.indirectManager || ''}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />

                <TextValidator
                    disabled
                    label={<Translate id="hrms.employeeInfo.myInformation.startDate" />}
                    onChange={handleChange}
                    value={workHistory.startDate || ''}
                    name='startDate'
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />
                <TextValidator
                    disabled
                    label={<Translate id="hrms.employeeInfo.myInformation.endDate" />}
                    onChange={handleChange}
                    value={workHistory.endDate || ''}
                    name='endDate'
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />

                <TextValidator
                    disabled
                    label={<Translate id="hrms.personeelManagement.termination.terminationType" />}
                    onChange={handleChange}
                    value={terminationObj.terminationType || ''}
                    name='TerminationType'
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />

                <TextValidator
                    disabled
                    label={<Translate id="hrms.personeelManagement.termination.processDate" />}
                    onChange={handleChange}
                    value={terminationObj.processDate || ''}
                    name='processDate'
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />

                <TextValidator
                    disabled
                    label={<Translate id="hrms.personeelManagement.termination.effectiveDate" />}
                    onChange={handleChange}
                    value={terminationObj.effectiveDate || ''}
                    name='effectiveDate'
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />

                <TextValidator
                    disabled
                    label={<Translate id="hrms.personeelManagement.termination.description" />}
                    onChange={handleChange}
                    value={terminationObj.description || ''}
                    name='description'
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />

                {terminationObj.fileURL
                    && <React.Fragment>
                        <i className="btn-ico fa fa-file" onClick={() => downloadResume(terminationObj.fileURL)} style={{ cursor: 'pointer' }}></i><span style={{ marginLeft: 5, fontSize: 'small' }}><Translate id="hrms.personeelManagement.termination.download" /></span>
                    </React.Fragment>}

                <CheckBox
                    label={<Translate id="hrms.personeelManagement.termination.blockRehire" />}
                    name="BlockRehire"
                    onChange={handleChange}
                    checked={terminationObj.blockRehire}
                    disabled
                />

                <Slider
                    disbled
                    label={<Translate id="hrms.employeeInfo.myInformation.workPercentage" />}
                    name='percentage'
                    min={0}
                    max={100}
                    step={1}
                    value={Number(workHistory.workPercentage || 0)}
                />

            </React.Fragment>
        }

        {(match.path.includes('current/edit') || match.path.includes('current/update'))
            && match.path.includes('allemployee')
            && <React.Fragment>
                <SelectValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.companyOrgUnit" />}
                    onSelect={(e) => { handleChange(e); getDropdownFromAPI(e) }}
                    name="orgUnitUID"
                    value={workHistory.orgUnitUID || ''}
                    // data={master.modulemaster.orgUnitList}
                    data={orgUnitList.map(({ uid, orgUnitName }) => ({ id: uid, text: orgUnitName }))}
                    // data={master.modulemaster.orgUnitList.map(({ uid, orgUnitName }) => ({ id: uid, text: orgUnitName }))}
                    options={{ placeholder: "Choose one" }}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}

                />
                <SelectValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.division" />}
                    //onSelect={handleChange}
                    onSelect={(e) => { handleChange(e); getDropdownFromAPI(e) }}
                    name="divisionUID"
                    value={workHistory.divisionUID || ''}
                    //data={master.modulemaster.divisionList}
                    data={dropDownList.divisionList}
                    options={{ placeholder: "Choose one" }}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}


                // label={<Translate id="hrms.employeeInfo.myInformation.division" />}
                // onSelect={(e) => { handleChange(e); getDropdownFromAPI(e) }}
                // name="divisionUID"
                // value={workHistory.divisionUID || ''}
                // data={master.modulemaster.divisionList}
                // options={{ placeholder: "Choose one" }}
                // validators={['required']}
                // errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}

                />
                <SelectValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.department" />}
                    onSelect={(e) => { handleChange(e); getDropdownFromAPI(e) }}
                    name="departmentUID"
                    value={workHistory.departmentUID || ''}
                    data={dropDownList.departmentList}
                    options={{ placeholder: "Choose one" }}
                />
                <SelectValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.subDepartment" />}
                    onSelect={handleChange}
                    name="subDepartmentUID"
                    value={workHistory.subDepartmentUID || ''}
                    data={dropDownList.subDepartmentList}
                    options={{ placeholder: "Choose one" }}
                />
                <SelectValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.position" />}
                    onSelect={(e) => { handleChange(e); getDropdownFromAPI(e) }}
                    name="positionUID"
                    value={workHistory.positionUID || ''}
                    data={dropDownList.positionList}
                    options={{ placeholder: "Choose one" }}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}

                />

                <DatetimePicker
                    closeOnSelect
                    name='startDate'
                    maxDate={new Date()}
                    value={workHistory.startDate || ''}
                    viewDate={getCalenderView(workHistory.startDate)}
                    onChange={(m) => handleCalendar(m, "startDate")}
                    inputProps={{
                        placeholder: 'DD/MM/YYYY',
                        label: <Translate id="hrms.employeeInfo.myInformation.from" />
                    }}
                    // validators={['required', 'matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
                    validators={['required', 'matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />, <Translate id="hrms.employeeInfo.myInformation.invalidDate" />]}
                    dateFormat="DD/MM/YYYY"
                    isValidDate={futuredatevalid}
                    timeFormat={false} />

                <SelectValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.employmentStatus" />}
                    onSelect={handleChange}
                    name="employeeStatus"
                    value={workHistory.employeeStatus || ''}
                    data={master.modulemaster.employeeStatusList.map(({ text }) => (text))}
                    options={{ placeholder: "Choose one" }}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />
                <TextValidator
                    label={<Translate id="hrms.allEmployee.contractLength" />}
                    onChange={handleChange}
                    min={1}
                    name="contractLength"
                    type="number"
                    value={workHistory.contractLength}
                    disabled={workHistory.employeeStatus.toLowerCase() !== 'contract'}
                    validators={workHistory.employeeStatus.toLowerCase() === 'contract' ? ['required'] : []}
                    errorMessages={workHistory.employeeStatus.toLowerCase() === 'contract' ? [<Translate id="hrms.employeeInfo.myInformation.required" />] : []}
                />
                {/* <SelectValidator
                        label="Direct Manager"
                        onSelect={handleChange}
                        name="directManagerUID"
                        value={workHistory.directManagerUID || ''}
                        data={dropDownList.managerList.map(({ id, text, listType }) => { return listType === 'DirectManager' ? { id, text } : null })}
                        options={{ placeholder: "Choose one" }}
                        validators={directmanagervalidationflag === true ? ['required'] : []}
                        errorMessages={['This field is required']}
                    /> */}

                <SelectValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.directManager" />}
                    onSelect={handleChange}
                    name="directManagerUID"
                    value={workHistory.directManagerUID || ''}
                    data={workHistory.directMangerbyPosition === true ? dropDownList.managerList.map(({ id, text, listType }) => { return listType === 'DirectManager' ? { id, text } : null }) : dropDownList.allManagerList.map(({ id, text, listType }) => { return listType === 'ALL' ? { id, text } : null })}
                    options={{ placeholder: "Choose one" }}
                    validators={checkvalidation() ? ['required'] : []}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />
                <CheckBox
                    label={<Translate id="hrms.employeeInfo.myInformation.filterPosition" />}
                    name="directMangerbyPosition"
                    checked={workHistory.directMangerbyPosition}
                    onChange={handleChange}
                    disabled={workHistory.orgUnitUID ? false : true}
                />
                <SelectValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.indirectManager" />}
                    onSelect={handleChange}
                    name="indirectManagerUID"
                    value={workHistory.indirectManagerUID || ''}
                    data={workHistory.indirectMangerbyPosition === true ? dropDownList.managerList.map(({ id, text, listType }) => { return listType === 'IndirectManager' ? { id, text } : null }) : dropDownList.allManagerList.map(({ id, text, listType }) => { return listType === 'ALL' ? { id, text } : null })}
                    options={{ placeholder: "Choose one" }}
                //validators={['required']}
                //errorMessages={['This field is required']}
                />
                <CheckBox
                    label={<Translate id="hrms.employeeInfo.myInformation.filterPosition" />}
                    name="indirectMangerbyPosition"
                    checked={workHistory.indirectMangerbyPosition}
                    onChange={handleChange}
                    disabled={workHistory.orgUnitUID ? false : true}
                />
                <TextValidator
                    disabled
                    label={<Translate id="hrms.employeeInfo.myInformation.reason" />}
                    onChange={handleChange}
                    name="reasonType"
                    value={action || ''}
                    validators={['required']}
                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                />
                <Slider
                    label={<Translate id="hrms.employeeInfo.myInformation.workPercentage" />}
                    name='workPercentage'
                    onChange={(e) => handleChange(e, 'workPercentage')}
                    min={1}
                    max={100}
                    step={1}
                    value={Number(workHistory.workPercentage || 0)}
                />
                <TextareaValidator
                    label={<Translate id="hrms.employeeInfo.myInformation.positionDescription" />}
                    onChange={handleChange}
                    name="jobDesc"
                    value={workHistory.jobDesc || ''}
                />
            </React.Fragment>
        }

        <div className="form-group">
            <button id="submit" style={{ display: "none" }} type="submit"></button>
        </div>
    </ValidatorForm>
);

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    dataChanged
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master,
    orgList: state.user.orgList,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(MyWorkHistory))
import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';

export const AwardList = (user, EmpUID) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + EmpUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('EmpUID', EmpUID);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);  
    formDataConsole(formData);
    let url = '/api/hrms/employee/award/list';

    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
           
            devLog("//award list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const AwardAdd = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + formData.get('EmpUID').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
   
    formDataConsole(formData);
    let url = '/api/hrms/employee/award/add'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// award add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const AwardUpdate = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + formData.get('AwardUID').toLowerCase() + user.empUID.toLowerCase() + user.token;
     devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    
    formDataConsole(formData)
    let url = '/api/hrms/employee/award/update'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// award update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const AwardDetail = (formData, user, directory) => {
    let signature = user.subscription.toLowerCase() + formData.get('AwardUID').toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    let url = '/api/hrms/employee/award/detail';

    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// award detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const AwardDelete = (user, AwardUID, directory) => {
    let formData = new FormData();
    let signature = AwardUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('AwardUID', AwardUID);

    formDataConsole(formData)

    let url='/api/hrms/employee/award/delete';
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// award delete response', res)
            return res;
        })
        .catch(checkAPIError);
}

export const DisciplineList = (user, EmpUID) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + EmpUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('EmpUID', EmpUID);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);  
    formDataConsole(formData);
    let url = '/api/hrms/employee/discipline/list';

    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
           
            devLog("//discipline list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const DisciplineAdd = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + formData.get('EmpUID').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
   
    formDataConsole(formData);
    let url = '/api/hrms/employee/discipline/add'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// discipline add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const DisciplineUpdate = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + formData.get('DisciplinaryUID').toLowerCase() + user.empUID.toLowerCase() + user.token;
     devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    
    formDataConsole(formData)
    let url = '/api/hrms/employee/discipline/update'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// discipline update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const DisciplineDetail = (formData, user, directory) => {
    let signature = user.subscription.toLowerCase() + formData.get('DisciplinaryUID').toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    let url = '/api/hrms/employee/discipline/detail';

    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// discipline detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const DisciplineDelete = (user, DisciplinaryUID, directory) => {
    let formData = new FormData();
    let signature = DisciplinaryUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('DisciplinaryUID', DisciplinaryUID);

    formDataConsole(formData)

    let url='/api/hrms/employee/discipline/delete';
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// discipline delete response', res)
            return res;
        })
        .catch(checkAPIError);
}

export const TerminationList = (user, EmpUID) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + EmpUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);
    formData.append('EmpUID', EmpUID);
    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);  
    formDataConsole(formData);
    let url = '/api/hrms/employee/work/termination/list';

    devLog('url', url)

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
           
            devLog("//termination list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const TerminationAdd = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + formData.get('EmpUID').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
   
    formDataConsole(formData);
    let url = '/api/hrms/employee/work/termination/add'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// termination add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const TerminationUpdate = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = user.subscription.toLowerCase() + formData.get('TerminationUID').toLowerCase() + user.empUID.toLowerCase() + user.token;
     devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    
    formDataConsole(formData)
    let url = '/api/hrms/employee/work/termination/update'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// termination update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const TerminationDetail = (formData, user, directory) => {
    let signature = user.subscription.toLowerCase() + formData.get('TerminationUID').toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);

    let url = '/api/hrms/employee/work/termination/detail';

    formDataConsole(formData);
    devLog('url', url);
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// termination detail response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const TerminationDelete = (user, TerminationUID, directory) => {
    let formData = new FormData();
    let signature = TerminationUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('TerminationUID', TerminationUID);

    formDataConsole(formData)

    let url='/api/hrms/employee/work/termination/delete';
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// termination delete response', res)
            return res;
        })
        .catch(checkAPIError);
}
import React from 'react'

//redux
import {
    clearNotification
} from "../../_reducers/reducer.notification";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

//theme
import { withTheme } from 'theming';

class Notification extends React.Component {
    getDate = () => {
        let time = new Date();
        time = time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        return time
    }
    render() {
        let { visible, title,message, theme } = this.props;
        if (visible) {
            return (
                <div className="topbar-notifications" style={theme.notification}>
                    <div className="notification-title">
                        <p style={{ display: "flex" }}>
                            {theme.notification.backgroundColor !== "white"
                                ? <img alt='notify' style={{ height: 15, width: 15, marginRight: 5 }} src={'/images/notification.png'} />
                                : <img alt='notify' style={{ height: 15, width: 15, marginRight: 5 }} src={'/images/notification_black.png'} />}

                            {title}</p>

                        <p>{this.getDate()}</p>
                    </div>
                    <div className="notification-message">
                        {message}
                    </div>
                    <a onClick={this.props.clearNotification} className="topbar-btn">
                        x
              </a>
                </div>
            );
        } else {
            return null
        }
    }
}
Notification.defaultProps = {
    visible: true,
    title:'Notification',
    message:'Something went wrong'
}
const mapDispatchToProps = dispatch => bindActionCreators({
    clearNotification
}, dispatch)
const mapStateToProps = state => ({
    visible: state.notification.visible,
    title: state.notification.title,
    message:state.notification.message
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTheme(Notification))
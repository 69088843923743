import { formDataConsole, devLog,getSignature,DEV_URL, checkAPIError } from '../../../_helpers/helper.methods';
import store from '../../../_helpers/helper.store';

export const Add = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('EmpUID').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
   
    formDataConsole(formData);
    let url = '/api/hrms/employee/validloc/add'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// validloc add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('ValidLocUID').toLowerCase() + user.empUID.toLowerCase() + user.token;
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    
    formDataConsole(formData)
    let url = '/api/hrms/employee/validloc/update'
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// validloc update response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Delete = (user, ValidLocUID) => {
    let formData = new FormData();      
    let signature = ValidLocUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('ValidLocUID', ValidLocUID);

    let url = '/api/hrms/employee/validloc/delete';    
    return fetch(DEV_URL.concat(url), {
        method: 'POST',
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// validloc delete response', res)
            return res;
        })
        .catch(checkAPIError);
}
import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";

//custom components
import TextValidator from "../../../../../Components/Validators/TextValidator";
import { ValidatorForm } from 'react-form-validator-core';
import SelectValidator from "../../../../../Components/Validators/SelectValidator";
import DatetimePicker from "../../../../../Components/DateTimePicker/DatePicker";
import TextAreaValidator from "../../../../../Components/Validators/TextareaValidator";
import Modal from "../../../../../Components/Modal";

//methods
import { formatDate, getCalenderView, handleFormErrors } from '../../../../../_helpers/helper.methods';

//api
import API from "../../../../../_apis/hrms/employee";
import moment from 'moment';
//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createNotification, dataChanged } from '../../../../../_reducers/reducer.notification';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';

class FamilyInformation extends Component {

    componentDidUpdate(prevProps, prevState) {
        let { history: { location } } = this.props;
        if (prevProps !== this.props && location.pathname.includes("edit")) {
            this.setState({
                subtitle: location.state.item.familyName,
                familyInfo: {
                    ...location.state.item,
                    expiryDate: formatDate(location.state.item.expiryDate || ''),
                    dob: formatDate(location.state.item.dob || '')
                }
            })
        }
    }
    componentDidMount() {
        let { history, match } = this.props;
        if (match.path.includes("edit")) {
            this.setState({
                subtitle: history.location.state.item.familyName,
                familyInfo: {
                    ...history.location.state.item,
                    expiryDate: formatDate(history.location.state.item.expiryDate || ''),
                    dob: formatDate(history.location.state.item.dob || '')
                }
            })
        }
    }
    getinitialState = () => {
        return {
            address: '',
            familyName: '',
            relation: '',
            expiryDate: '',
            birthPlace: '',
            dob:'',
            bloodType: '',
            mobileNo: '',
            email: '',
            phoneNo: '',
            postalCode: '',
            city: '',
            country: '',
            district: ''
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en"); 
        this.state = {
            nocountry: false,
            city: '',
            country: '',
            modal: {
                visible: false
            },
            subtitle: <Translate id="hrms.employeeInfo.myInformation.familyInformation" />,
            load: false,
            familyInfo: this.getinitialState()
        }
    }
    handleChange = (event) => {
        let familyInfo = this.state.familyInfo;
        if (event.target.type === "checkbox") {
            this.setState({
                familyInfo: {
                    ...familyInfo,
                    [event.target.name]: !familyInfo[event.target.name]
                }
            })
        } else {
            this.setState({
                familyInfo: {
                    ...familyInfo,
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    handleCalendar = (momentd, name) => {
        let familyInfo = this.state.familyInfo;
        if (typeof momentd !== "string") {
            let date = momentd.format('DD/MM/YYYY');
            this.setState({
                familyInfo: {
                    ...familyInfo,
                    [name]: name === 'expiryDate'
                        ? momentd.isAfter(moment().subtract(1, 'day'))
                            ? date
                            : date.slice(0, 6)
                        : date
                }
            })
        } else {
            this.setState({
                familyInfo: {
                    ...familyInfo,
                    [name]: momentd
                }
            })
        }
    }

    addCityCountry = (e) => {
        if (e === 'city') {
            let { familyInfo } = this.state;
            if (familyInfo.country) {
                this.setState({
                    nocountry: false,
                    modal: {
                        visible: true,
                        title: <Translate id="hrms.employeeInfo.myInformation.addCity" />,
                        type: 'city'
                    }
                })
            } else {
                this.setState({ nocountry: true })
            }
        } else if (e === 'country') {
            this.setState({
                modal: {
                    visible: true,
                    title: <Translate id="hrms.employeeInfo.myInformation.addCountry" />,
                    type: 'country'
                }
            })
        }
    }
    addDataToMaster = (type) => {
        let { master: { modulemaster } } = this.props;
        let { city, country, familyInfo } = this.state;
        if (type === 'city') {
            modulemaster.cityList.push({
                addInfo: familyInfo.country,
                text: city
            })
            this.setState({
                familyInfo: {
                    ...familyInfo,
                    [type]: city,
                }
            })
        } else if (type === 'country') {
            modulemaster.countryList.push({ id: '', text: this.state.country })
            this.setState({
                familyInfo: {
                    ...familyInfo,
                    [type]: country,
                }
            })
        }
        this.setState({
            modal: {
                visible: false
            }
        })
    }

    handleSubmit = async (e) => {
        let { match, history: { location: { state } } } = this.props;
        this.setState({ load: true })

        if (match.path.includes('add')) {
            let url = '../../../../../';
            let formData = new FormData(e.target);

            formData.set('expiryDate', getCalenderView(this.state.familyInfo.expiryDate, 'server'));
            formData.set('dob', getCalenderView(this.state.familyInfo.dob, 'server'))

            if (match.path.includes("allemployee")) {
                let res = await API.Family.Add(formData, this.props.user, url, state.item);
                this.setState({ load: false })
                if (res.status === "0") {
                    this.props.dataChanged();
                    this.close();
                    this.props.createNotification('Success', res.message);
                } else {
                    this.props.createNotification('Operation Failed', res.message);
                }
            } else {
                let res = await API.Family.Add(formData, this.props.user, url);
                this.setState({ load: false })
                if (res.status === "0") {
                    this.props.dataChanged();
                    this.close();
                    this.props.createNotification('Success', res.message);
                } else {
                    this.props.createNotification('Operation Failed', res.message);
                }
            }

        } else {
            let url = '../../../../../';

            const formData = new FormData(e.target);
            formData.set('expiryDate', getCalenderView(this.state.familyInfo.expiryDate, 'server'));
            formData.set('dob', getCalenderView(this.state.familyInfo.dob, 'server'));

            let res = await API.Family.Update(formData, this.props.user, url, this.state.familyInfo);
            this.setState({
                load: false
            })
            if (res.status === "0") {
                this.props.dataChanged();
                this.close();
                this.props.createNotification('Success', res.message);
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }
        }

    }
    close = () => {
        let { match, history } = this.props;
        if (match.path.includes("/hrms/myinfo")) {
            history.push("/home/hrms/myinfo/familydetails")
        } else if (match.path.includes("allemployee/menu")) {
            history.push("/home/hrms/allemporgunit/allemployee/menu/familydetails")
        } else {
            history.push("/home");
        }
    }
    deleteAPI = async () => {
        this.setState({ load: true })
        let { familyUID } = this.state.familyInfo;
        let url = '../../../../../';
        let res = await API.Family.Delete(this.props.user, familyUID, url)
        this.setState({ load: false })
        if (res.status === "0") {
            this.props.dataChanged();
            this.props.createNotification('Success', res.message);
            this.close();
        } else {
            this.props.createNotification('Operation Failed', res.message);
        }
    }
    reset = () => {
        let { history, match } = this.props;
        if (match.path.includes('edit')) {
            this.setState({
                familyInfo: {
                    ...history.location.state.item,
                    expiryDate: formatDate(history.location.state.item.expiryDate || ''),
                    dob: formatDate(history.location.state.item.dob || '')
                }
            });
        } else if (match.path.includes('add')) {
            this.setState({ familyInfo: this.getinitialState() });
        }
    }
    toolbarclick = (e) => {
        if (e === "save") {
            document.getElementById("submit").click();
        } else if (e === 'delete') {
            this.deleteAPI();
        } else if (e === 'reset') {
            this.reset();
        }
    }
    render() {
        let { familyInfo, modal, nocountry } = this.state;
        let toolbar = [{
            icon: '/images/save_black.png',
            name: <Translate id="hrms.employeeInfo.myInformation.save" />,
            value: "save"
        }, {
            icon: '/images/reset_black.png',
            name: "Reset",
            value: "reset"
        }];
        let { match } = this.props;
        if (match.path.includes("edit")) {
            toolbar.push({
                icon: '/images/trash_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.delete" />,
                value: "delete"
            })
        }
        return (
            <Blade match={this.props.match}
                max={false}
                load={this.state.load}
                close={this.close}
                icon={this.state.icon || '/images/familyinfo.png'}
                head={<Translate id="hrms.employeeInfo.myInformation.familyInformation" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={this.state.load}>
                    <Modal
                        modal={modal.visible}
                        title={modal.title}
                        footer={[<Translate id="hrms.employeeInfo.myInformation.save" />, <Translate id="hrms.employeeInfo.myInformation.cancel" />]}
                        Ok={() => { document.getElementById('modal').click() }}
                        Cancel={() => this.setState({ modal: { visible: false } })}>
                        <ValidatorForm
                            ref="form"
                            onError={handleFormErrors}
                            onSubmit={() => this.addDataToMaster(modal.type)}
                        >
                            {modal.type === 'city'
                                ?
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.name" />}
                                    value={this.state.city}
                                    onChange={(e) => this.setState({ city: e.target.value })}
                                    name="city"
                                    validators={['required']}
                                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required2" />]}
                                />
                                : <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.name" />}
                                    value={this.state.country}
                                    onChange={(e) => this.setState({ country: e.target.value })}
                                    name="country"
                                    validators={['required']}
                                    errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required2" />]}
                                />}
                            <div className="form-group">
                                <button id="modal" style={{ display: "none" }} type="submit"></button>
                            </div>
                        </ValidatorForm>
                    </Modal>

                    {Inputs(
                        this.addCityCountry,
                        this.props.master,
                        familyInfo,
                        this.handleSubmit,
                        this.handleChange,
                        this.handleCalendar,
                        this.state.dropdownlist,
                        nocountry)}

                </BladeBody>
            </Blade >
        )
    }
}

const Inputs = (addCityCountry, master,
    familyInfo, handleSubmit, handleChange,
    handleCalendar, dropdownlist, nocountry) => (

        <ValidatorForm
            ref="form"
            onError={handleFormErrors}
            onSubmit={handleSubmit}
        >
            <TextValidator
                label={<Translate id="hrms.employeeInfo.myInformation.name" />}
                onChange={handleChange}
                name="familyName"
                validators={['required']}
                errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                value={familyInfo.familyName}
            >
            </TextValidator>

            <SelectValidator
                label={<Translate id="hrms.employeeInfo.myInformation.relationship" />}
                onSelect={handleChange}
                name="relation"
                value={familyInfo.relation}
                data={master.relationList}
                validators={['required']}
                errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                options={{ placeholder: "Choose Relationship" }}
            />

        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.placeOfBirth" />}
            onChange={handleChange}
            name="birthPlace"
            value={familyInfo.birthPlace || ''}
        />

            <DatetimePicker
                closeOnSelect
                onChange={(m) => handleCalendar(m, "dob")}
                name="dob"
                //isValidDate={(current) => { return current.isAfter(moment().subtract(1, 'day')) }}
                isValidDate={(current) => { return current.isBefore(moment().subtract(1, 'day')) }}
                viewDate={getCalenderView(familyInfo.dob)}
                value={familyInfo.dob}
                inputProps={{
                    placeholder: 'DD/MM/YYYY',
                    label: <Translate id="hrms.employeeInfo.myInformation.dob" />
                }}
                validators={['matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
                errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidDate" />]}
                dateFormat="DD/MM/YYYY"
                timeFormat={false} />
            
            <SelectValidator
                label={<Translate id="hrms.employeeInfo.myInformation.bloodType" />}
                value={familyInfo.bloodType || ''}
                name="bloodType"
                onSelect={handleChange}
                data={master.bloodTypeList}
                options={{ placeholder: 'Blood Type' }}
            />

            <TextValidator
                label={<Translate id="hrms.employeeInfo.myInformation.primaryMobileNumber" />}
                onChange={handleChange}
                name="mobileNo"
                validators={['isNumber']}
                errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidMobile" />]}
                value={familyInfo.mobileNo || ''}
            />
            <TextValidator
                label={<Translate id="hrms.employeeInfo.myInformation.secondaryMobileNumber" />}
                onChange={handleChange}
                name="phoneNo"
                validators={['isNumber']}
                errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidMobile" />]}
                value={familyInfo.phoneNo || ''}
            />

            <TextValidator
                label={<Translate id="hrms.employeeInfo.myInformation.email" />}
                onChange={handleChange}
                name="email"
                validators={['isEmail']}
                errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidEmail" />]}
                value={familyInfo.email || ''}
            />
            <TextValidator
                label={<Translate id="hrms.employeeInfo.myInformation.postalCode" />}
                onChange={handleChange}
                name="postalCode"
                validators={['isNumber']}
                errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidPostalCode" />]}
                value={familyInfo.postalCode || ''}
            />

            <TextAreaValidator
                label={<Translate id="hrms.employeeInfo.myInformation.address" />}
                onChange={handleChange}
                name="address"
                value={familyInfo.address || ''}
            />

            <SelectValidator
                add={() => addCityCountry('country')}
                name="country"
                label={<Translate id="hrms.employeeInfo.myInformation.country" />}
                value={familyInfo.country || ''}
                data={master.modulemaster.countryList.map(({ text }) => text)}
                onSelect={handleChange}
                options={{ placeholder: "Choose Country" }}
            />
            
            {nocountry && <p style={{ color: "red", fontSize: 12 }}><Translate id="hrms.employeeInfo.myInformation.selectCountry" /></p>}
            {/* 
            <TextValidator
                label={<Translate id="hrms.employeeInfo.myInformation.province" />}
                onChange={handleChange}
                name="district"
                value={familyInfo.district || ''}
            />
            */}

            <SelectValidator
                label={<Translate id="hrms.employeeInfo.myInformation.province" />}
                value={familyInfo.district || ''}
                name="district"
                onSelect={handleChange}
                data={master.modulemaster.provinceList.filter((item) => item.addInfo === familyInfo.country).map(({ text }) => text)}
                options={{ placeholder: 'Choose Province' }}
            />

            <SelectValidator
                add={() => addCityCountry('city')}
                label={<Translate id="hrms.employeeInfo.myInformation.city" />}
                name="city"
                value={familyInfo.city || ''}
                onSelect={handleChange}
                data={master.modulemaster.cityList.filter((item) => item.addInfo === familyInfo.country).map(({ text }) => text)}
                options={{ placeholder: "Choose City" }}
            />

            <DatetimePicker
                closeOnSelect
                onChange={(m) => handleCalendar(m, "expiryDate")}
                name="expiryDate"
                isValidDate={(current) => { return current.isAfter(moment().subtract(1, 'day')) }}
                viewDate={getCalenderView(familyInfo.expiryDate)}
                value={familyInfo.expiryDate}
                inputProps={{
                    placeholder: 'DD/MM/YYYY',
                    label: <Translate id="hrms.employeeInfo.myInformation.expiryDate" />
                }}
                validators={['matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
                errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidDate" />]}
                dateFormat="DD/MM/YYYY"
                timeFormat={false} />
            <div className="form-group">
                <button id="submit" style={{ display: "none" }} type="submit"></button>
            </div>
        </ValidatorForm>
    );

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    dataChanged
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(FamilyInformation))
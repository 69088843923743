import React, { Component } from "react";
import MasterAPI from '../../_apis/Core';
import { withTheme } from "theming/dist/cjs";
class TableList extends React.Component {

    static getDerivedStateFromProps(next) {
        if (next.list) {
            return {
                list: next.list,
                multiselect: next.multiselect,
                singleselect: next.singleselect,
                header: next.header,
                fields: next.fields,
                id: next.id || 'i',
            }
        }
        // if (!next.singleselect && !next.multiselect) {
        //     if (this.state.selected >= 0) {
        //         document.getElementById(`list${this.state.id}${this.state.selected}`).classList.remove("__selected");
        //         this.state.selected = -1
        //     }
        // }
    }
    state = {
        multiselect: false,
        singleselect: false,
        list: this.props.list,
        header: this.props.header,
        fields: this.props.fields,
        id: this.props.id || 'i',
        selected: -1,
        multirecord: [],
        selectall: false,
        sortOrder: 'asc'
    }
    click = (item, i) => {
        let { id } = this.state;
        let selitem = Object.assign({}, item);
        if (this.state.singleselect) {
            this.singleselect(i, item);
        } else if (this.state.multiselect) {

        } else {
            if (this.state.selected >= 0) {
                let sel = document.getElementById(`list${id}${this.state.selected}`);
                if (sel) {
                    sel.classList.remove("__selected")
                }
            }
            document.getElementById(`list${id}${i}`).classList.add("__selected")
            this.setState({ selected: i })
            this.props.returnSelected(selitem, i)
        }
    }
    download = async (item) =>{
        let res = await MasterAPI.AccessBlob(this.props.user,item);
        if (res.status !== '1') {
            window.open(res.message, 'sharer', 'toolbar=0,status=0,width=548,height=325')
        }
    }
    selectall = () => {
        if (this.state.multiselect) {
            this.props.multiselectCallBack('allselect');
            this.multiSelect();
        } else {
            this.multiSelect();
        }
    }
    multiSelect = () => {
        if (this.state.selectall) {
            this.props.list.forEach((item, index, arr) => {
                this.select(index, false);
            })
            this.setState({
                selectall: false
            })
        } else {
            this.props.list.forEach((item, index, arr) => {
                this.select(index, true);
            })
            this.setState({
                selectall: true
            })
        }
    }
    select = (i, select) => {
        let { id } = this.state;
        if (select) {
            if (this.state.selected >= 0) {
                document.getElementById(`${id}${this.state.selected}`).checked = false;
                document.getElementById(`list${id}${this.state.selected}`).classList.remove("__selected")
            }
            document.getElementById(`${id}${i}`).checked = true;
            document.getElementById(`list${id}${i}`).classList.add("__selected")
        } else {
            document.getElementById(`${id}${i}`).checked = false;
            document.getElementById(`list${id}${i}`).classList.remove("__selected")
        }
    }
    singleselect = (i, selitem, evt) => {
        let { id } = this.state;
        let element = document.getElementById(`${id}${i}`).checked;
        if (this.state.singleselect) {
            if ((evt && evt.target.checked)
                || (typeof evt === 'undefined' && !element)) {
                this.props.returnSelected(selitem, i)
                this.setState({ selected: i })
                this.select(i, true);
            } else {
                this.props.returnSelected(null);
                this.setState({ selected: -1 })
                this.select(i, false);
            }
        } else {
            // if (evt && evt.target.checked) {
            //     this.props.multiselectCallBack(selitem, i);
            // }
        }
    }
    typeCheck = (e, header) => {
        if (header) {

        } else {

            if (typeof e !== 'number') {
                return 'table-col'
            } else if (typeof e === 'number') {
                return 'table-col text-right'
            } else {
                return 'table-col'
            }
        }
    }
    sortDirection = (e, field) => {
        let sortOrder = this.state.sortOrder;
        if (sortOrder.includes(field)) {
            if (sortOrder.includes('asc')) {
                this.setState({
                    sortOrder: field + "-desc",
                }, () => {
                    this.props.onHeaderClick(this.state.sortOrder);
                });
            } else {
                this.setState({
                    sortOrder: field + "-asc",
                }, () => {
                    this.props.onHeaderClick(this.state.sortOrder);
                });
            }
        } else {
            this.setState({
                sortOrder: field + "-asc",
            }, () => {
                this.props.onHeaderClick(this.state.sortOrder);
            });
        }
    }
    render() {
        let { theme, list, fields, header, selectall, singleselect, id } = this.props;
        return (
            <div className="table-wrapper" style={this.props.style ? this.props.style : { height: "calc(100% - 500px)", overflow: 'auto' }}>
                <table className="table" style={theme.table}>
                    <thead>
                        <tr>
                            {!this.props.disabled
                                && <th className="table-col">
                                    {!singleselect &&
                                        <input type="checkbox" onChange={this.selectall} />
                                    }
                                </th>}
                            {header.map((item, i) => (
                                id === "balance" || id === "module" || id === "role" || id === "quicklinks" ?
                                <th key={i} className='table-col'>{item}</th> :
                                <th key={i} className='table-col' onClick={(ev) => this.sortDirection(ev, fields[i])}>{item}<i className="fa fa-sort"></i></th>
                            ))}
                        </tr>
                    </thead>
                    {list.length > 0 && <tbody>
                        {list.map((mainitem, tri) => (
                            <tr id={`list${id}${tri}`} key={tri}
                                className={`table-item`}>
                                {selectall
                                    ? <td className="table-col">
                                        <input id={`${id}${tri}`} checked={selectall ? true : false} type="checkbox" />
                                    </td>
                                    : !this.props.disabled
                                    && <td className="table-col">
                                        <input id={`${id}${tri}`} type="checkbox" onChange={(evt) => this.singleselect(tri, mainitem, evt)} />
                                    </td>
                                }
                                
                                {fields.map((item, i) => (
                                   <td key={i} className={this.typeCheck(mainitem[item])} onClick={() => this.click(mainitem, tri)}>
                                        {typeof mainitem[item] === 'boolean'
                                            ? <input readOnly type='checkbox' checked={mainitem[item]} />
                                            : (mainitem[item]==="download"?<i className="fa fa-file" onClick={()=>this.download(mainitem["documentURL"])}></i>:
                                            fields[i].indexOf("Progress") !== -1 ?
                                            (
                                                <div className="progress">
                                                    <div className="progress-bar2" role="progressbar" style={{ width:mainitem[item]  + "%" }}>{mainitem[item] + "%"}</div>
                                                </div>
                                            )
                                            :
                                            mainitem[item])}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>}
                </table>
                {this.props.children}
            </div>
        )
    }
}

export default withTheme(TableList)
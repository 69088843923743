import React from 'react';
import Api from "../../_apis";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../../Components/Validators/CValidator";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

//methods
import { createNotification } from '../../_reducers/reducer.notification';
import { handleFormErrors } from '../../_helpers/helper.methods';

class ChangePassword extends React.Component {    
    state = {
        empUID: this.props.history.location.state.data.empUID,
        oldPassword:'',
        newPassword:'',
        confirmPassword:'',
        submitted: false
    }    
    componentDidMount() {        
        ValidatorForm.addValidationRule('isconfirmpaswordmatch', (value) => {
            if(value !== ""){
            if (value !== this.state.newPassword) {
                return false;
            }
            return true;
        }
            return true;
        });

        ValidatorForm.addValidationRule('isoldPasswordMatch', (value) => {
            if(value !== ""){
            if (value === this.state.oldPassword) {
                return false;
            }
            return true;
        }
            return true;
        });
    }
    EmailRequest = async (e) => {
        this.setState({
            error: '',
            submitted: true
        })
        const data = new FormData(e.target);
        data.append('empUID', this.props.history.location.state.data.empUID);

        //let res = await Api.Login.Email(data);
        let res = await Api.Login.changePassword(this.props.user, data);
        this.setState({ submitted: false })
        if (res.status !== '1') {
            this.props.createNotification('Success', 'Password has been changed!');
            this.props.history.push("/");
            /*this.props.history.push({
                pathname: "/loginpass",
                state: { res, UserId: this.state.email }
            })*/
        } else {
            this.setState({ error: res.message })
        }       
    }
    render() {     
        let { submitted, oldPassword, newPassword, confirmPassword, error } = this.state;
        return (
            <div className='window' style={{ minHeight: '320px' }}>
                {submitted && <div className="window-status">
                    <div className={"progress-bar"}></div>
                </div>}
                <header className='window-head'>
                    <span className='window-t'>Change Password</span>
                </header>
                <div className='window-cnt'>
                    <ValidatorForm
                        ref="form"
                        onError={handleFormErrors}
                        onSubmit={this.EmailRequest}
                    >                      
                        <TextValidator
                            label={"Old Password"}
                            value={oldPassword}
                            onChange={(e) => this.setState({ oldPassword: e.target.value })}
                            name="oldPassword"
                            type="password"
                            validators={['required']}
                            errorMessages={['Old Password is required']}
                        />
                        <TextValidator
                            label={"New Password"}
                            value={newPassword}
                            onChange={(e) => this.setState({ newPassword: e.target.value })}
                            name="newPassword"
                            type="password"
                            validators={['required','isoldPasswordMatch']}
                            errorMessages={['New Password is required','New password and old password should not be same']}
                        />

                        <TextValidator
                            label={"Confirm Password"}
                            value={confirmPassword}
                            onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                            name="confirmPassword"
                            type="password"
                            validators={['required','isconfirmpaswordmatch']}
                            errorMessages={['Confirm Password is required','Confirm password should be same as new password.']}
                        />
                        <div className="clearfix">
                            <div className="column">
                                <div className="form-group __right">
                                    <button style={submitted ? { minWidth: 100 } : {}} disabled={submitted ? true : false} className={submitted ? "btn __loading" : "btn"}>
                                        <span>Submit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ValidatorForm>
                    {error &&
                        <div style={{ color: 'red' }}>{error}</div>
                    }
                </div>
            </div >
        );
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification   
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    routing: state.routing
})
export default connect(
    mapStateToProps,
    mapDispatchToProps,    
)(ChangePassword);
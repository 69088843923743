import React, { useState, useEffect } from 'react';
import Api from "../../_apis";
import HrmsApi from "../../_apis/hrms/master";
//redux
import {
    storeUser, storeMaster,
    storeOrgList,
} from "../../_reducers/reducer.user";
import {
    setMenu
} from "../../_reducers/reducer.menu";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { ValidatorForm } from "react-form-validator-core";
import CValidator from "../../Components/Validators/CValidator";
import { handleFormErrors } from '../../_helpers/helper.methods';

const LoginPassword = React.forwardRef((props, ref) => {
    const [password, setPassword] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [forgetPassword, setForgetPassword] = useState("")
    const [forgetPassEmail, setForgetPassEmail] = useState("")
    const [forgetPassSuccess, seetForgetPassSuccess] = useState(null)
    const [error, setError] = useState("")

    useEffect(() => {
        let { location } = props;
        let { UserId } = location.state;
        setForgetPassEmail(UserId)
    })
    const PasswordRequest = async (e) => {
        setError("")
        setSubmitted(true)
        const data = new FormData(e.target);
        let { state } = props.history.location;
        let res = await Api.Login.Password(state, data);
        if (res.status !== '1') {
            localStorage.setItem("user", JSON.stringify(res.login));
            props.storeUser(res.login);
            props.setMenu(res.menu);
            getMaster(res.login);
        } else {
            setSubmitted(false)
            setError(res.message)
        }
    }
    const getMaster = async (res) => {
        let masterlist = await Api.Core.Master.List(res, null, '');
        let orgList = await HrmsApi.OrgUnit.List(res, '', true);
        setSubmitted(false)
        if (orgList.status !== '1') {
            props.storeOrgList(orgList);
        }
        if (masterlist.status !== '1') {
            props.storeMaster(masterlist)
            props.history.push("/home")
        }
    }
    const handleForgetPassword = () => {
        setError(null)
        setForgetPassword(!forgetPassword)
    }
    const forgetPasswordAPI = async () => {
        let { history } = props;
        let { res } = history.location.state;
        //let { forgetPassEmail } = this.state;
        setSubmitted(true)
        setError(null)
        let formData = new FormData();
        formData.append('email', forgetPassEmail);
        let res2 = await Api.Login.Forget(res, formData);
        setSubmitted(false)
        if (res2.status === '1') {
            throw Error(res2.message);
        } else {
            seetForgetPassSuccess("Relax. New password sent to your email. Don't forget to check junk folder in your mail box. See you soon!")
        }
    }
    //render() {
    //let { submitted, forgetPassSuccess, forgetPassEmail, forgetPassword, password, error } = this.state;

    if (forgetPassword) {
        return (
            <React.Fragment>
                <img className='logo' src='/images/sarvamnamegreen.png' alt="" />
                <div className='window'>
                    {submitted && <div className="window-status">
                        <div className={"progress-bar"}></div>
                    </div>}
                    <header className='window-head'>
                        <span className='window-t'>Forget Password</span>
                    </header>
                    {!forgetPassSuccess
                        ? <div className='window-cnt'>
                            <ValidatorForm
                                onError={handleFormErrors}
                                onSubmit={forgetPasswordAPI}
                            >
                                <CValidator
                                    label="Email"
                                    value={forgetPassEmail}
                                    onChange={(e) => setForgetPassEmail(e.target.value)}
                                    name="email"
                                    validators={['required', 'isEmail']}
                                    errorMessages={['This field is required', 'Invalid Email']}
                                    autoFocus
                                />
                                <div className="clearfix">
                                    <div className="column">
                                        <div className="form-group">
                                            <button type='button' onClick={handleForgetPassword} className="btn __cancel">
                                                <span>Back</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="column">
                                        <div className="form-group __right">
                                            <button style={submitted ? { minWidth: 100 } : {}} disabled={submitted ? true : false} className={submitted ? "btn __loading" : "btn"}>
                                                <span>Submit</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                            {error &&
                                <div style={{ color: 'red' }}>{error}</div>
                            }
                        </div>
                        : <div className='window-cnt'>
                            <div className="clearfix">
                                <h6 style={{ marginTop: 10 }}>{forgetPassSuccess}</h6>
                                <div className="column" style={{ marginTop: 40 }}>
                                    <div className="form-group __right">
                                        <button onClick={() => props.history.push('/')} className={"btn"}>
                                            <span>Okay</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>}
                </div >
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <img className='logo' src='/images/sarvamnamegreen.png' alt="" />
            <div className='window'>
                {submitted && <div className="window-status">
                    <div className={"progress-bar"}></div>
                </div>}
                <header className='window-head'>
                    <span className='window-t'>Sign in</span>
                </header>
                <div className='window-cnt'>
                    <ValidatorForm
                        ref={ref}
                        onError={handleFormErrors}
                        onSubmit={PasswordRequest}
                    >
                        <CValidator
                            label={"Password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            name="UserPassword"
                            type="password"
                            validators={['required']}
                            errorMessages={['This field is required']}
                            autoFocus
                        />
                        <div className="clearfix">
                            <div className="column">
                                <div className="form-group">
                                    <a onClick={(e) => { e.preventDefault(); handleForgetPassword() }} href="">Forgot your password?</a>
                                </div>
                            </div>
                            <div className="column">
                                <div className="form-group __right">
                                    <button style={submitted ? { minWidth: 100 } : {}} disabled={submitted ? true : false} className={submitted ? "btn __loading" : "btn"}>
                                        <span>Submit</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ValidatorForm>
                    {error &&
                        <div style={{ color: 'red' }}>{error}</div>
                    }
                </div>
            </div >
        </React.Fragment>
    );
    //}
})
const mapDispatchToProps = dispatch => bindActionCreators({
    storeUser,
    storeMaster,
    storeOrgList,
    setMenu
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginPassword)
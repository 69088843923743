import React, { Component } from "react";
import { Link, Route } from 'react-router-dom';
import { getBreadcrumbs } from '../../_reducers/reducer.menu';
import en from '../../_language/en.json';
import id from '../../_language/id.json';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import { bindActionCreators } from 'redux';
import { errorNotification } from '../../_reducers/reducer.notification';
import { withTheme } from 'theming';
const dynamicRoutesMap = (data) => {
    let routedata = getBreadcrumbs();
    let rname = '';
    Object.keys(routedata).some(key => {
        if (data === key) {
            //rname = routedata[key]
            rname = key          
            return true;
        }
        return false;
    })
    return rname;
}
class BreadcrumbsItem extends Component {
    
    constructor(props) {
        super(props);      
        if(this.props.languages)
        {
            this.props.addTranslationForLanguage(id, "id");
            this.props.addTranslationForLanguage(en, "en"); 
        }  
        
    }   
    componentDidMount() {        
        
    }
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <li style={{ marginLeft: 10 }} className={'breadcrumb-active'}>
                    {!match.isExact ?
                        <p>
                            <Link className='navlink' to={match.url || ''}>
                                {/* <Link className='navlink' to={home ? '/home/dashboard' : match.url || ''}> */}
    
                                
                                <Translate id={"breadcrumbs."+dynamicRoutesMap(match.params.path)} />
                            </Link>
                            <span style={{ color: 'lightgrey' }}>{' > '}</span>
                        </p>
                        : <p style={{ color: 'lightgrey' }}><Translate id={"breadcrumbs."+dynamicRoutesMap(match.params.path)} /></p>
                    }
                </li>
                <Route path={`${match.url}/:path`} component={BreadcrumbsItem} />
            </React.Fragment>
        )
    }

}

const mapDispatchToProps = dispatch => bindActionCreators({
    errorNotification,
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(withTheme(BreadcrumbsItem)))



import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import store from '../../_helpers/helper.store';
const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        let logged = store.getState().user.loggedin
        if (logged) {
            return (
                <Component {...props} />
            )
        } else {
            return (
                <Redirect to={{ pathname: '/', state: { from: props.location } }} />
            )
        }
    }
    } />
)
export default PrivateRoute;

import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";

import TableList from "../../../../../Components/Lists/TableList";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateComponent, errorNotification } from '../../../../../_reducers/reducer.notification';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';
//methods
import { showLastBlade, formatDate } from "../../../../../_helpers/helper.methods";
//api
import API from "../../../../../_apis/hrms/employee";

import Pagination from '../../../../../Components/Pagination';

class ValidLocInfoList extends Component {
    
    async componentDidUpdate(prevProps, prevState) {
        let { dataChanged, history: { location: { pathname } } } = this.props;

        if (dataChanged && prevProps !== this.props) {
            if (pathname.includes("allemployee/menu/validloc/")) {
                let url = "../../../../../";
                let res = await API.Employee.Detail(this.props.user, url, this.state.employee);
                if (res.status !== '1') {                            
                    this.props.updateComponent(res);
                    this.setState({
                        load: false,
                        list: res.validLocList.map((i)=>{
                            return {
                                ...i,
                                validFrom: formatDate(i.validFrom),
                                validTo: formatDate(i.validTo)
                            }
                        })
                    })
                } else {
                    this.props.errorNotification(res.message);
                }
            } else if (pathname.toLowerCase().includes('myinfo/validloc/')) {

                let url = "../../../../../";
                let res = await API.Employee.Detail(this.props.user, url);
                if (res.status !== '1') {
                    this.props.updateComponent(res);
                    
                    this.setState({
                        load: false,
                        list: res.validLocList.map((i)=>{
                            return {
                                ...i,
                                validFrom: formatDate(i.validFrom),
                                validTo: formatDate(i.validTo)
                            }
                        })
                    })
                } else {
                    this.props.errorNotification(res.message);
                }
            }
        }
    }
    componentDidMount() {        
        let { match, history } = this.props;
        let { item, icon } = this.props.history.location.state;
        this.setState({ icon });
        if (match.path.includes("allemployee")) {
            this.setState({
                subtitle: item.employeeCode,
                employee: item,
                list: item.validLocList.map((i)=>{
                    return {
                        ...i,
                        validFrom: formatDate(i.validFrom),
                        validTo: formatDate(i.validTo)
                    }
                }),
                selectedOrgUnitUID: this.props.history.location.state.selectedOrgUnitUID
            })
        } else {
            this.setState({
                subtitle: item.employeeCode,
                employee: item,
                list: item.validLocList.map((i)=>{
                    return {
                        ...i,
                        validFrom: formatDate(i.validFrom),
                        validTo: formatDate(i.validTo)
                    }
                }),
                selectedOrgUnitUID: this.props.history.location.state.selectedOrgUnitUID
            })
        }
        if (item.validLocList.length === 0) {
            history.push({
                pathname: `${match.path}/add`,
                state: { employee: item, selectedOrgUnitUID: this.props.history.location.state.selectedOrgUnitUID }
            });
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en"); 
        this.state = {
            pageOfItems: [],
            subtitle: '',
            employee: {},
            load: false,
            list: []
        }
    }
    selectedData = (item, index) => {
        let { history, match } = this.props;
        this.setState({
            index
        })
        history.push({
            pathname: `${match.path}/edit`,
            state: { item, employee: this.state.employee, selectedOrgUnitUID: this.state.selectedOrgUnitUID }
        });
        showLastBlade();
    }
    onChangePage = (pageOfItems) => {
        this.setState({ pageOfItems: pageOfItems });
    }
    Change = (item) => {
        let items = this.state.pageOfItems;
        let data = items.findIndex(i => i.id === item.id)
        items[data].name = "Changed";
        this.setState({ pageOfItems: items })
    }

    close = () => {
        let { match, history } = this.props;
        if (match.path.includes("/hrms/myinfo")) {
            history.push("/home/hrms/myinfo")
        } else if (match.path.includes("allemployee/menu")) {
            history.push("/home/hrms/allemporgunit/allemployee/menu")
        } else {
            history.push("/home");
        }
    }
    toolbarclick = (e) => {
        let { match, history } = this.props;
        if (e === "add") {
            let elem = document.getElementById(`list${'validloc'}${this.state.index}`);
            if (elem) {
                elem.classList.remove('__selected');
            }
            history.push({
                pathname: `${match.path}/add`,
                state: {
                    employee: this.state.employee,
                    //selectedOrgUnitUID: this.props.history.location.state.selectedOrgUnitUID
                    selectedOrgUnitUID: this.state.selectedOrgUnitUID
                }
            });
            showLastBlade();
        }
    }
    render() {
        let toolbar = [{
            icon: '/images/add_black.png',
            name: <Translate id="hrms.employeeInfo.myInformation.add" />,
            value: "add"
        }];
        return (
            <Blade match={this.props.match}
                max
                load={this.state.load}
                close={this.close}
                icon={this.state.icon || '/images/location.png'}
                head={<Translate id="hrms.employeeInfo.myInformation.validLoc" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={this.state.load}>

                    <Pagination
                        mainFilter={{ jsonName: 'locationName', disName: 'Location Name' }}
                        filters={[{ jsonName: 'locationName', disName: 'Location Name' },{ jsonName: 'validFrom', disName: 'Valid From' },{ jsonName: 'validTo', disName: 'Valid To' }]}
                        items={this.state.list}
                        onChangePage={(e) => { this.onChangePage(e) }} 
                        onRef={(onSort) => {this.onSort = onSort}}/>

                    <TableList
                        id='validloc'
                        returnSelected={this.selectedData}
                        fields={["locationName", "validFrom", "validTo"]}
                        list={this.state.pageOfItems || []}
                        header={[<Translate id="hrms.master.orgUnit.locationName" />, <Translate id="hrms.employeeInfo.myInformation.validFrom" />, <Translate id="hrms.employeeInfo.myInformation.validTo" />]} 
                        onHeaderClick={this.onSort}>
                    </TableList>
                </BladeBody>
            </Blade >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    updateComponent,
    errorNotification
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    dataChanged: state.notification.dataChanged
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(ValidLocInfoList))
import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";
import TextValidator from "../../../../../Components/Validators/TextValidator";
import CheckBox from "../../../../../Components/Validators/CheckBox";
import { ValidatorForm } from 'react-form-validator-core';

//Methods 
//import { handleFormErrors } from '../../../../../_helpers/helper.methods';

//api 
import API from '../../../../../_apis/hrms/employee';
//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createNotification, dataChanged } from '../../../../../_reducers/reducer.notification';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';

class OtherInfo extends Component {

    async componentDidMount() {
        let { history: { location: { state } } } = this.props;
        let url = "../../../../../";
        let res = await API.Employee.Detail(this.props.user, url, state.item);
        if (res.status !== "1") {
            this.setState({
                //otherInfoType: state.item.otherInfoList[0].otherInfoType,
                //otherInfoValue: state.item.otherInfoList[0].otherInfoValue,
                //isActive: state.item.otherInfoList[0].isActive,
                uid: state.item.empUID,
                icon: state.icon,
                title: state.title,
                otherInfoList: res.otherInfoList
            })
        }
    }

    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            subtitle: <Translate id="hrms.employeeInfo.myInformation.otherInfoDetails" />,
            load: false,
            otherInfoList: []
        }
    }

    handleChange = (event) => {
        let otherInfoList = this.state.otherInfoList;
        if (event.target.type === "checkbox") {
            this.setState({
                otherInfoList: {
                    ...otherInfoList,
                    [event.target.alt]: {
                        ...otherInfoList[event.target.alt],
                        [event.target.name]: !otherInfoList[event.target.alt].isActive
                    }
                }
            })
        } else {
            this.setState({
                otherInfoList: {
                    ...otherInfoList,
                    [event.target.alt]: {
                        ...otherInfoList[event.target.alt],
                        [event.target.name]: event.target.value
                    }
                }
            })
        }
    }

    handleSubmit = async (e) => {
        //let { match, history: { location: { state } } } = this.props;
        this.setState({ load: true })
        let numsave = 0;
        function createNotif(res) {
            if (res.status !== "0") {
                this.props.createNotification('Operation Failed', res.message);
            }
            else {
                numsave++;
            }
        }
        for (var i = 0; i < 5; i++) {
            if (this.GetValueOtherInfo('otherInfoType', i) === "" && this.GetValueOtherInfo('otherInfoValue', i) !== "") {
                this.props.createNotification('Operation Failed', "Info type cannot blank");
                return false;
            }
        }

        for (i = 0; i < 5; i++) {
            let formData = new FormData();
            formData.append('otherInfoUID', this.GetValueOtherInfo('otherInfoUID', i));
            formData.append('empUID', this.state.uid);
            formData.append('otherInfoType', this.GetValueOtherInfo('otherInfoType', i));
            formData.append('otherInfoValue', this.GetValueOtherInfo('otherInfoValue', i));
            formData.append('isActive', this.GetValueOtherInfo('isActive', i) !== "" ? this.GetValueOtherInfo('isActive', i) : false);
            if (this.GetValueOtherInfo('otherInfoUID', i) === "" && this.GetValueOtherInfo('otherInfoType', i) !== "") {
                let res = await API.Otherinfo.Add(formData, this.props.user);
                createNotif(res)
                // if (res.status !== "0") {
                //     this.props.createNotification('Operation Failed', res.message);
                // }
                // else {
                //     numsave++;
                // }
            }
            else if (this.GetValueOtherInfo('otherInfoUID', i) !== "" && this.GetValueOtherInfo('otherInfoType', i) !== "") {

                let res = await API.Otherinfo.Update(formData, this.props.user)
                createNotif(res)
                // if (res.status !== "0") {
                //     this.props.createNotification('Operation Failed', res.message);
                // }
                // else {
                //     numsave++;
                // }
            }
            else if (this.GetValueOtherInfo('otherInfoUID', i) !== "" && this.GetValueOtherInfo('otherInfoType', i) === "") {
                let res = await API.Otherinfo.Delete(formData, this.props.user)
                createNotif(res)
                // if (res.status !== "0") {
                //     this.props.createNotification('Operation Failed', res.message);
                // }
                // else {
                //     numsave++;
                // }
            }
            else {
                numsave++;
            }
        }



        if (numsave > 0 && numsave < 6) {
            this.props.dataChanged();
            this.close();
            this.props.createNotification('Success', "Data Saved.");
        }
    }

    GetValueOtherInfo = (name, index) => {
        let otherInfoList = this.state.otherInfoList;
        if (otherInfoList) {
            if (otherInfoList[index])
                return otherInfoList[index][name] ? otherInfoList[index][name] : ""
            else
                return ""
        }
        else
            return "";
    }

    close = () => {
        let { match } = this.props;
        if (match.path.includes('allemployee')) {
            this.props.history.push('/home/hrms/allemporgunit/allemployee/menu')
        } else {
            this.props.history.push('/home/hrms/myinfo')
        }
    }

    toolbarclick = (e) => {
        if (e === 'save') {
            document.getElementById('submit').click();
        }
        else {
            this.getinitialState();
        }
    }

    render() {
        let {
            load } = this.state.otherInfoList;
        let toolbar = [{
            icon: '/images/save_black.png',
            name: <Translate id="hrms.employeeInfo.myInformation.save" />,
            value: "save"
        }/*, {
            icon: '/images/reset_black.png',
            name: "Reset",
            value: "reset"
        }*/];
        return (
            <Blade match={this.props.match}
                max
                load={load}
                close={this.close}
                icon={this.state.icon || '/images/location.png'}
                head={<Translate id="hrms.employeeInfo.myInformation.otherInfoDetails" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={load}>

                    <ValidatorForm
                        ref="form"
                        onSubmit={this.handleSubmit}
                    >

                        <div className="row">
                            <div className="col-md-5">
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.otherInfoType" />}
                                    onChange={this.handleChange}
                                    name="otherInfoType"
                                    alt="0"
                                    value={this.GetValueOtherInfo("otherInfoType", 0)}
                                />
                            </div>
                            <div className="col-md-5">
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.otherInfoValue" />}
                                    onChange={this.handleChange}
                                    name="otherInfoValue"
                                    alt="0"
                                    value={this.GetValueOtherInfo("otherInfoValue", 0)}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: 35 }}>
                                <CheckBox
                                    label={<Translate id="hrms.employeeInfo.myInformation.active" />}
                                    name="isActive"
                                    alt="0"
                                    onChange={this.handleChange}
                                    checked={this.GetValueOtherInfo("isActive", 0)}
                                    value={this.GetValueOtherInfo("isActive", 0)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.otherInfoType" />}
                                    onChange={this.handleChange}
                                    name="otherInfoType"
                                    alt="1"
                                    value={this.GetValueOtherInfo("otherInfoType", 1)}
                                />
                            </div>
                            <div className="col-md-5">
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.otherInfoValue" />}
                                    onChange={this.handleChange}
                                    name="otherInfoValue"
                                    alt="1"
                                    value={this.GetValueOtherInfo("otherInfoValue", 1)}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: 35 }}>
                                <CheckBox
                                    label={<Translate id="hrms.employeeInfo.myInformation.active" />}
                                    name="isActive"
                                    alt="1"
                                    onChange={this.handleChange}
                                    checked={this.GetValueOtherInfo("isActive", 1)}
                                    value={this.GetValueOtherInfo("isActive", 1)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.otherInfoType" />}
                                    onChange={this.handleChange}
                                    name="otherInfoType"
                                    alt="2"
                                    value={this.GetValueOtherInfo("otherInfoType", 2)}
                                />
                            </div>
                            <div className="col-md-5">
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.otherInfoValue" />}
                                    onChange={this.handleChange}
                                    name="otherInfoValue"
                                    alt="2"
                                    value={this.GetValueOtherInfo("otherInfoValue", 2)}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: 35 }}>
                                <CheckBox
                                    label={<Translate id="hrms.employeeInfo.myInformation.active" />}
                                    name="isActive"
                                    alt="2"
                                    onChange={this.handleChange}
                                    checked={this.GetValueOtherInfo("isActive", 2)}
                                    value={this.GetValueOtherInfo("isActive", 2)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.otherInfoType" />}
                                    onChange={this.handleChange}
                                    name="otherInfoType"
                                    alt="3"
                                    value={this.GetValueOtherInfo("otherInfoType", 3)}
                                />
                            </div>
                            <div className="col-md-5">
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.otherInfoValue" />}
                                    onChange={this.handleChange}
                                    name="otherInfoValue"
                                    alt="3"
                                    value={this.GetValueOtherInfo("otherInfoValue", 3)}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: 35 }}>
                                <CheckBox
                                    label={<Translate id="hrms.employeeInfo.myInformation.active" />}
                                    name="isActive"
                                    alt="3"
                                    onChange={this.handleChange}
                                    checked={this.GetValueOtherInfo("isActive", 3)}
                                    value={this.GetValueOtherInfo("isActive", 3)}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-5">
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.otherInfoType" />}
                                    onChange={this.handleChange}
                                    name="otherInfoType"
                                    alt="4"
                                    value={this.GetValueOtherInfo("otherInfoType", 4)}
                                />
                            </div>
                            <div className="col-md-5">
                                <TextValidator
                                    label={<Translate id="hrms.employeeInfo.myInformation.otherInfoValue" />}
                                    onChange={this.handleChange}
                                    name="otherInfoValue"
                                    alt="4"
                                    value={this.GetValueOtherInfo("otherInfoValue", 4)}
                                />
                            </div>
                            <div className="col-md-2" style={{ marginTop: 35 }}>
                                <CheckBox
                                    label={<Translate id="hrms.employeeInfo.myInformation.active" />}
                                    name="isActive"
                                    alt="4"
                                    onChange={this.handleChange}
                                    checked={this.GetValueOtherInfo("isActive", 4)}
                                    value={this.GetValueOtherInfo("isActive", 4)}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <button id="submit" style={{ display: "none" }} type="submit"></button>
                        </div>
                    </ValidatorForm>

                </BladeBody>
            </Blade >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    dataChanged
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(OtherInfo))
import React, { Component } from "react";

//blades
import Blade from "../../../Components/Blade";
import BladeBody from "../../../Components/Blade/BladeBody";

//validators
import TextValidator from "../../../Components/Validators/TextValidator";
import { ValidatorForm } from 'react-form-validator-core';
import SelectValidator from "../../../Components/Validators/SelectValidator";
import DatetimePicker from "../../../Components/DateTimePicker/DatePicker";
import CheckBox from "../../../Components/Validators/CheckBox";

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getCalenderView, formDataConsole, handleFormErrors, devLog } from '../../../_helpers/helper.methods';
import { createNotification, dataChanged } from '../../../_reducers/reducer.notification';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../_language/en.json';
import id from '../../../_language/id.json';
import { push } from 'react-router-redux'

//api
import MasterAPI from "../../../_apis/hrms/master";
import API from '../../../_apis/hrms/employee';
import moment from 'moment';

import Cropper from 'react-cropper';

//200kb 
const MAX_IMAGE_CROP_SIZE = 200000;

class AddEmployee extends Component {
    async componentDidMount() {
        let { user, master, match } = this.props;
        let moduleID = master.coreModule[0].id;
        this.setState({ load: true });
        let res = await MasterAPI.Role.List(user, 'dropdown', moduleID, true);
        if (res.status !== '1') {
            this.setState({
                load: false,
                dropDownList: {
                    ...this.state.dropDownList,
                    moduleList: res
                }
            })
        }
        let url1 = '../../../../../';
        let res1 = await MasterAPI.OrgUnit.List(this.props.user, url1, true);
        this.setState({ load: false })
        if (res.status !== '1') {
            this.setState({
                dropDownList: {
                    ...this.state.dropDownList,
                    orgUnitList: res1
                }
            })
        }
        if (match.path.includes('movetoemployee')) {
            let { history: { location: { state } } } = this.props;
            this.setState({
                title: <Translate id="hrms.allEmployee.moveToEmployee" />,
                subtitle: state.item.firstname,
                basicinfo: {
                    ...this.state.basicinfo,
                    ...state.item,
                    salutation: state.item.title,
                    firstName: state.item.firstname,
                    lastName: state.item.lastname,
                    maritalStatus: state.item.marital,
                    mobileNo: state.item.primaryContactNo,
                    altMobileNo: state.item.secondaryContactNo,
                    birthDate: state.item.dob ? moment(state.item.dob).format('DD/MM/YYYY') : ''
                }
            })
        }
    }
    getinitialState = () => {
        return {
            salutation: '',
            division: '',
            departmentName: '',
            subDepartmentName: '',
            orgUnitName: '',
            employeeCode: '',
            firstName: '',
            middleName: '',
            lastName: '',
            religion: '',
            bloodType: '',
            userId: '',
            altEmail: '',
            mobileNo: '',
            altMobileNo: '',
            email: '',
            gender: '',
            isActive: true,
            birthDate: '',
            age: '',
            maritalStatus: '',
            joinDate: '',
            yearsInService: '',
            positionName: '',
            employeeStatus: '',
            birthPlace: '',
            nationality: '',
            directmanagerfilter: true,
            indirectmanagerfilter: true,
            period: '',
            contractLength: ''
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            toolbar: [{
                icon: '/images/save_black.png',
                name: <Translate id="hrms.allEmployee.save" />,
                value: "save"
            }, {
                icon: '/images/reset_black.png',
                name: "Reset",
                value: "reset"
            }],
            load: false,
            title: <Translate id="hrms.allEmployee.addEmployee" />,
            subtitle: <Translate id="hrms.allEmployee.addEmployee" />,
            dropDownList: {
                moduleList: [],
                positionList: [],
                managerList: [],
                allManagerList: [],
                divisionList: [],
                departmentList: [],
                subDepartmentList: [],
                orgUnitList: []
            },
            basicinfo: this.getinitialState(),
            directmanagervalidationflag: true
        }
    }
    handleChange = async (event) => {
        let basicinfo = this.state.basicinfo;
        let controlname = event.target.name;
        let controletype = event.target.type;
        let controlvalue = event.target.value;
        // if (controlname === "directmanagerfilter" || controlname === "indirectmanagerfilter") {
        //     // if (this.state.dropDownList.allManagerList.length <= 0) {
        //     //     let result = await this.getallManagerList();
        //     // }
        // }
        if (controletype === "checkbox") {
            this.setState({
                basicinfo: {
                    ...basicinfo,
                    [controlname]: !basicinfo[controlname]
                }
            })
        } else {
            this.setState({
                basicinfo: {
                    ...basicinfo,
                    [controlname]: controlvalue
                }
            })
        }
    }

    handleCalendar = (date, name) => {
        let basicinfo = this.state.basicinfo;
        if (typeof date !== "string") {
            let lcdate = date.format("DD/MM/YYYY");
            this.setState({
                basicinfo: {
                    ...basicinfo,
                    [name]: lcdate.slice(6) <= new Date().getFullYear() ? lcdate : lcdate.slice(0, 6)
                }
            }, () => {
                if (name === "birthDate") {
                    this.calculateAge()
                } else if (name === "joinDate") {
                    this.calculatePeriod()
                }
            })
        } else {
            this.setState({
                basicinfo: {
                    ...basicinfo,
                    [name]: date.slice(6) <= new Date().getFullYear() ? date : date.slice(0, 6)
                }
            })
        }
    }
    calculateAge = () => {
        let { birthDate } = this.state.basicinfo;
        this.setState({
            basicinfo: {
                ...this.state.basicinfo,
                age: birthDate ? new Date().getFullYear() - new Date(getCalenderView(birthDate, 'server')).getFullYear() : '',
            }
        })
    }

    monthDiff = (d1, d2) => {
        var months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;
        months -= d1.getMonth() + 1;
        months += d2.getMonth();
        // edit: increment months if d2 comes later in its month than d1 in its month
        if (d2.getDate() >= d1.getDate())
            months++
        // end edit
        return months <= 0 ? 0 : months;
    }

    calculatePeriod = () => {
        let { joinDate } = this.state.basicinfo;
        this.setState({
            basicinfo: {
                ...this.state.basicinfo,
                period: joinDate ? this.monthDiff(new Date(getCalenderView(joinDate, 'server')), new Date()) : '',
            }
        })
    }

    getDropdownFromAPI = async (e) => {
        this.setState({ load: true })
        devLog('------ Status ---------');
        let { value, name } = e.target;
        if (name === 'orgUnitName') {
            let url = '../../../';
            let res = await MasterAPI.Lists.PositionList(this.props.user, url, value);
            this.setState({ load: false })
            if (res.status !== '1') {
                this.setState({
                    dropDownList: {
                        ...this.state.dropDownList,
                        positionList: res,
                        managerList: []
                    }
                }, () => {
                    this.getallManagerList();
                })
            }
            let resdiv = await MasterAPI.Lists.DivisionList(this.props.user, url, value);
            devLog('------ Status ---------');
            devLog(resdiv.status);
            if (resdiv.status !== '1') {
                this.setState({
                    dropDownList: {
                        ...this.state.dropDownList,
                        divisionList: resdiv
                    }
                })

            }
        } else if (name === 'positionName') {
            let url = '../../../';
            let res = await MasterAPI.Lists.ReportingTo(this.props.user, url, value, 'all');
            this.setState({ load: false })
            if (res.status !== '1') {
                this.setState({
                    dropDownList: {
                        ...this.state.dropDownList,
                        managerList: res
                    }
                })
            }
            var managerpositionuid = this.state.dropDownList.positionList[e.target.selectedIndex].addInfo2;
            if (managerpositionuid === null || managerpositionuid === undefined) {
                this.setState({
                    directmanagervalidationflag: false
                })
            } else {
                this.setState({
                    directmanagervalidationflag: true
                })
            }
        } else if (name === 'divisionName') {
            let url = '../../../';
            let res = await MasterAPI.Lists.DepartmentList(this.props.user, url, value);
            this.setState({ load: false })
            if (res.status !== '1') {
                this.setState({
                    dropDownList: {
                        ...this.state.dropDownList,
                        departmentList: res,
                    }
                }, () => {
                    this.getallManagerList();
                })
            }
        } else if (name === 'departmentName') {
            let url = '../../../';
            let res = await MasterAPI.Lists.SubDepartmentList(this.props.user, url, value);
            this.setState({ load: false })
            if (res.status !== '1') {
                this.setState({
                    dropDownList: {
                        ...this.state.dropDownList,
                        subDepartmentList: res,
                    }
                }, () => {
                    this.getallManagerList();
                })
            }
        }
    }

    getallManagerList = async () => {
        let url = '../../../';
        let orgUnitUID = this.state.basicinfo.orgUnitName;
        let res = await MasterAPI.Lists.ReportingTo(this.props.user, url, null, 'all', true, null, orgUnitUID);
        this.setState({ load: false })
        if (res.status !== '1') {
            this.setState({
                dropDownList: {
                    ...this.state.dropDownList,
                    allManagerList: res
                }
            })
            return true;
        }
        return false;
    }

    getCroppedImage = () => {
        return new Promise((resolve, reject) => {
            this.state.file.toBlob((blob) => {
                resolve(blob)
            }, 'image/jpeg');
        })
    }

    handleSubmit = async (e) => {
        this.setState({ load: true })
        let { user, match } = this.props;
        let { basicinfo, file } = this.state;
        let formData = new FormData(e.target);

        if (match.path.includes('movetoemployee')) {
            formData.append('CandidateUID', basicinfo.candUID);
        }
        if (file) {
            let croppedImage = await this.getCroppedImage();
            if (croppedImage.size > MAX_IMAGE_CROP_SIZE) {
                this.resetImageUploadButton();
                this.props.createNotification('Alert', 'Profile picture size is greater than 200kb')
                this.setState({ load: false })
                return
            }
            formData.append('File', croppedImage);
        }
        formData.append('orgUnitUID', formData.get('orgUnitName'));
        formData.append('DivisionUID', formData.get('divisionName'));
        formData.append('DirectManagerUID', formData.get('directManager') === null ? '' : formData.get('directManager'));
        formData.append('InDirectManagerUID', formData.get('inDirectManager') || '');
        formData.append('PositionUID', formData.get('positionName'));
        formData.append('DepartmentUID', formData.get('departmentName') || '');
        formData.append('SubDepartmentUID', formData.get('subDepartmentName') || '');
        formData.set('isActive', this.state.basicinfo.isActive);
        formData.set("birthDate", getCalenderView(this.state.basicinfo.birthDate, 'server'));
        formData.set('joinDate', getCalenderView(this.state.basicinfo.joinDate, 'server'));

        formDataConsole(formData);

        let res = await API.Employee.Add(formData, user);
        this.setState({ load: false })
        if (res.status !== '1') {
            this.props.createNotification('Success', res.message);
            this.props.dataChanged();
            let { match, history } = this.props;
            if (match.path.includes('movetoemployee')) {
                this.props.history.push(match.path.slice(0, -14));
            } else if (match.path.includes('employeeadd')) {
                // history.push('/home/hrms/allemporgunit/allemployee')
                this.props.goto(`/home`);
                this.props.goto(`/home/hrms/allemporgunit/`);
            }
        } else {
            this.props.createNotification('Operation Failed', res.message)
        }
    }
    loadImage = (event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            this.setState({
                modal: true,
                imagePreviewUrl: reader.result
            });
        }
        reader.readAsDataURL(file)
    }

    resetImageUploadButton = () => {
        document.getElementById('profilePhoto').value = '';
    }
    uploadImage = () => {
        this.setState({
            modal: false,
            file: this.refs.cropper.getCroppedCanvas(),
            cropimage: this.refs.cropper.getCroppedCanvas().toDataURL()
        })
    }
    toolbarclick = (e) => {
        if (e === "save") {
            document.getElementById("submit").click();
        } else if (e === 'reset') {
            this.setState({ basicinfo: this.getinitialState() })
        }
    }
    handleClose = () => {
        let { match, history } = this.props;
        if (match.path.includes('employeeadd')) {
            this.props.history.push({ pathname: match.path.replace("/employeeadd", "").replace("/allemployee", "") })
            //history.push("/home/hrms/allemporgunit/allemployee")
            this.props.history.push("/home/hrms/allemporgunit/allemployee")
        } else if (match.path.includes('moveto')) {
            this.props.history.push(match.path.slice(0, -14))
        }
    }
    render() {
        let defaultimage = "/images/profile-default.jpg";
        let { title, subtitle, toolbar, photoUrl, modal, cropimage, imagePreviewUrl, basicinfo, load, dropDownList } = this.state;
        return (
            <Blade match={this.props.match}
                max={false}
                load={load}
                close={this.handleClose}
                icon={this.state.icon || '/images/myinfo.png'}
                head={title}
                subhead={subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={load}>

                    {modal &&
                        <React.Fragment>
                            <div className="modal-backdrop fade in"
                                style={{ zIndex: 1040 }}></div>
                            <div modal-render="true" tabindex="-1"
                                role="dialog" className="modal fade in"
                                style={{ zIndex: 1050, justifyContent: 'center', alignItems: 'center', display: "flex", opacity: 1 }}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title"><Translate id="hrms.allEmployee.adjustYourImage" /></h3>
                                        </div>
                                        <div className="modal-body">
                                            {imagePreviewUrl &&
                                                <Cropper
                                                    style={{ height: 400, width: '100%' }}
                                                    ref='cropper'
                                                    aspectRatio={10 / 8}
                                                    src={imagePreviewUrl} />
                                            }

                                        </div>
                                        <div className="modal-footer">
                                            <button onClick={this.uploadImage} className="btn"><Translate id="hrms.allEmployee.ok" /></button>
                                            <button onClick={() => this.setState({ modal: false })} className="btn btn-default" ><Translate id="hrms.allEmployee.cancel" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>}

                    <div style={{ display: "flex", paddingTop: 10 }}>
                        <div style={{ flex: 1 }}>

                            <div style={{ display: "flex", flexDirection: "column", flex: 1, paddingLeft: 10, alignItems: "center" }}>
                                <div style={{ width: "100%" }}>
                                    {cropimage || photoUrl
                                        ? <img
                                            alt='user'
                                            style={{ height: 200, width: '100%' }}
                                            src={cropimage ? cropimage : photoUrl ? photoUrl : defaultimage} />
                                        : <img
                                            alt='user'
                                            style={{ height: 200, width: '100%' }}
                                            src={defaultimage} />
                                    }
                                </div>
                                <div style={{
                                    width: '100%', display: "flex", flexDirection: "column",
                                    justifyContent: "space-around", marginTop: 5
                                }}>
                                    <input name='profilePhoto' type="file" id='profilePhoto' onChange={this.loadImage} style={{ display: 'none' }} />
                                    <button onClick={() => document.getElementById('profilePhoto').click()} className="btn" style={{ backgroundColor: '#4c4c4c' }} type='button' >
                                        <Translate id="hrms.allEmployee.uploadImage" />
                                    </button>
                                </div>
                                <p style={{ fontSize: 12, textAlign: 'center', marginTop: 10 }}><Translate id="hrms.allEmployee.imageNotif" /></p>
                            </div>
                            {Inputs(this.getDropdownFromAPI, dropDownList, this.props.master, this.handleCalendar, basicinfo, this.handleSubmit, this.handleChange, this.state.directmanagervalidationflag, this.getallManagerList)}

                        </div>
                        {/* <div style={{ display: "flex", flexDirection: "column", flex: 1, paddingLeft: 10, alignItems: "center" }}>
                            <div style={{ width: "80%" }}>

                                <img src="https://www.1plusx.com/app/mu-plugins/all-in-one-seo-pack-pro/images/default-user-image.png"
                                    style={{ height: 200, width: '100%' }} />

                            </div>
                            <div style={{
                                width: '80%', display: "flex", flexDirection: "column",
                                justifyContent: "space-around", height: 80
                            }}>

                                <button className="btn">Upload Pictrue</button>
                                <button className="btn">Change Password</button>

                            </div>

                        </div> */}
                    </div>
                </BladeBody>
            </Blade >
        )
    }
}

const Inputs = (getDropdownFromAPI, dropDownList, master, handleCalendar, basicinfo, handleSubmit, handleChange, directmanagervalidationflag, getallManagerList) => (

    <ValidatorForm
        ref="form"
        onError={handleFormErrors}
        onSubmit={handleSubmit}
    >
        <SelectValidator
            label={<Translate id="hrms.allEmployee.title" />}
            value={basicinfo.salutation || ''}
            name="salutation"
            onSelect={handleChange}
            data={master.salutationList.map(({ id, text }) => ({ id, text }))}
            options={{ placeholder: 'Choose one' }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}

        />
        <TextValidator
            label={<Translate id="hrms.allEmployee.firstName" />}
            onChange={handleChange}
            name="firstName"
            value={basicinfo.firstName}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.middleName" />}
            onChange={handleChange}
            name="middleName"
            value={basicinfo.middleName || ''}
        />
        <TextValidator
            label={<Translate id="hrms.allEmployee.lastName" />}
            onChange={handleChange}
            name="lastName"
            value={basicinfo.lastName}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
        />
        <TextValidator
            label={<Translate id="hrms.allEmployee.employeeCode" />}
            onChange={handleChange}
            name="employeeCode"
            value={basicinfo.employeeCode}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
        />
        <TextValidator
            label={<Translate id="hrms.allEmployee.mobileNumber" />}
            onChange={handleChange}
            name="mobileNo"
            validators={['isNumber']}
            errorMessages={[<Translate id="hrms.allEmployee.invalidMobile" />]}
            value={basicinfo.mobileNo || ""}
        />
        <TextValidator
            label={<Translate id="hrms.allEmployee.alternateMobileNumber" />}
            onChange={handleChange}
            name="altMobileNo"
            validators={['isNumber']}
            errorMessages={[<Translate id="hrms.allEmployee.innvalidMobile" />]}
            value={basicinfo.altMobileNo || ""}
        />
        <TextValidator
            label={<Translate id="hrms.allEmployee.emailAddress" />}
            onChange={handleChange}
            name="email"
            validators={['required', 'isEmail']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />, <Translate id="hrms.allEmployee.invalidEmail" />]}
            value={basicinfo.email || ""} />

        <TextValidator
            label={<Translate id="hrms.allEmployee.alternateEmailAddress" />}
            onChange={handleChange}
            name="altEmail"
            validators={['isEmail']}
            errorMessages={[<Translate id="hrms.allEmployee.invalidEmail" />]}
            value={basicinfo.altEmail || ""} />

        <SelectValidator
            label={<Translate id="hrms.allEmployee.gender" />}
            value={basicinfo.gender}
            name="gender"
            onSelect={handleChange}
            data={master.genderList.map(({ id, text }) => ({ id, text }))}
            options={{ placeholder: 'Gender' }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
        />

        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.bloodType" />}
            value={basicinfo.bloodType || ''}
            name="bloodType"
            onSelect={handleChange}
            data={master.bloodTypeList}
            options={{ placeholder: 'Blood Type' }}
        />

        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.religion" />}
            value={basicinfo.religion || ''}
            name="religion"
            onSelect={handleChange}
            data={master.religionList}
            options={{ placeholder: 'Religion' }}
        />

        <DatetimePicker
            closeOnSelect
            value={basicinfo.birthDate}
            name="birthDate"
            isValidDate={(current) => { return current.isBefore(moment().subtract(1, 'year')) }}
            viewDate={getCalenderView(basicinfo.birthDate)}
            onChange={(m) => { handleCalendar(m, "birthDate") }}
            inputProps={{
                placeholder: 'DD/MM/YYYY',
                label: <Translate id="hrms.allEmployee.birthDate" />
            }}
            validators={['required', 'matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />, <Translate id="hrms.allEmployee.invalidDate" />]}
            dateFormat="DD/MM/YYYY"
            timeFormat={false} />

        <TextValidator
            label={<Translate id="hrms.allEmployee.placeOfBirth" />}
            onChange={handleChange}
            name="birthPlace"
            value={basicinfo.birthPlace}
        />

        <TextValidator
            label={<Translate id="hrms.allEmployee.age" />}
            disabled
            name="age"
            type="number"
            value={basicinfo.age}
        />

        <SelectValidator
            label={<Translate id="hrms.allEmployee.maritalStatus" />}
            value={basicinfo.maritalStatus}
            name="maritalStatus"
            onSelect={handleChange}
            data={master.maritalList.map(({ id, text }) => ({ id, text }))}
            options={{ placeholder: "Marital Status" }}
        />

        <DatetimePicker
            closeOnSelect
            value={basicinfo.joinDate}
            name="joinDate"
            viewDate={getCalenderView(basicinfo.joinDate)}
            onChange={(e) => handleCalendar(e, 'joinDate')}
            inputProps={{
                placeholder: 'DD/MM/YYYY',
                label: <Translate id="hrms.allEmployee.joinedDate" />,
            }}
            validators={['required', 'matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />, <Translate id="hrms.allEmployee.invalidDate" />]}
            dateFormat="DD/MM/YYYY"
            timeFormat={false} />

        <SelectValidator
            label={<Translate id="hrms.allEmployee.company" />}
            onSelect={(e) => { handleChange(e); getDropdownFromAPI(e) }}
            name="orgUnitName"
            value={basicinfo.orgUnitName}
            //data={master.modulemaster.orgUnitList}
            data={dropDownList.orgUnitList.map(({ uid, orgUnitName }) => ({ id: uid, text: orgUnitName }))}
            options={{ placeholder: "Choose one" }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
        />
        <SelectValidator
            label={<Translate id="hrms.allEmployee.division" />}
            //onSelect={handleChange}
            onSelect={(e) => { handleChange(e); getDropdownFromAPI(e) }}
            name="divisionName"
            value={basicinfo.divisionName}
            //data={master.modulemaster.divisionList}
            data={dropDownList.divisionList}
            options={{ placeholder: "Choose one" }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
        />
        <SelectValidator
            label={<Translate id="hrms.allEmployee.department" />}
            //onSelect={handleChange}
            onSelect={(e) => { handleChange(e); getDropdownFromAPI(e) }}
            name="departmentName"
            value={basicinfo.departmentName}
            data={dropDownList.departmentList}
            options={{ placeholder: "Choose one" }}
        />
        <SelectValidator
            label={<Translate id="hrms.allEmployee.subDepartment" />}
            onSelect={handleChange}
            name="subDepartmentName"
            value={basicinfo.subDepartmentName}
            data={dropDownList.subDepartmentList}
            options={{ placeholder: "Choose one" }}
        />
        <SelectValidator
            label={<Translate id="hrms.allEmployee.position" />}
            onSelect={(e) => { handleChange(e); getDropdownFromAPI(e) }}
            name="positionName"
            value={basicinfo.positionName}
            data={dropDownList.positionList}
            options={{ placeholder: "Choose one" }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
        />
        <SelectValidator
            label={<Translate id="hrms.allEmployee.directManager" />}
            onSelect={handleChange}
            name="directManager"
            value={basicinfo.directManager}
            data={basicinfo.directmanagerfilter === true ? dropDownList.managerList.filter(({ id, text, listType }) => { return listType === 'DirectManager' }) : dropDownList.allManagerList.filter(({ id, text, listType }) => { return listType === 'ALL' })}
            options={{ placeholder: "Choose one" }}
            validators={directmanagervalidationflag === true ? ['required'] : []}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
        />
        <CheckBox
            label={<Translate id="hrms.allEmployee.filterPosition" />}
            name="directmanagerfilter"
            checked={basicinfo.directmanagerfilter}
            onChange={handleChange}
            disabled={basicinfo.orgUnitName ? false : true}
        />
        <SelectValidator
            label={<Translate id="hrms.allEmployee.indirectManager" />}
            onSelect={handleChange}
            name="inDirectManager"
            value={basicinfo.inDirectManager || ''}
            data={basicinfo.indirectmanagerfilter === true ? dropDownList.managerList.filter(({ id, text, listType }) => { return listType === 'IndirectManager' }) : dropDownList.allManagerList.filter(({ id, text, listType }) => { return listType === 'ALL' })}
            options={{ placeholder: "Choose one" }}
        />
        <CheckBox
            label={<Translate id="hrms.allEmployee.filterPosition" />}
            name="indirectmanagerfilter"
            checked={basicinfo.indirectmanagerfilter}
            onChange={handleChange}
            disabled={basicinfo.orgUnitName ? false : true}
        />
        <SelectValidator
            label={<Translate id="hrms.allEmployee.employmentStatus" />}
            name="employeeStatus"
            value={basicinfo.employeeStatus}
            onSelect={handleChange}
            data={master.modulemaster.employeeStatusList.map(({ text }) => (text))}
            options={{ placeholder: "Choose One" }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
        />
        <TextValidator
            label={<Translate id="hrms.allEmployee.contractLength" />}
            onChange={handleChange}
            name="contractLength"
            type="number"
            min={1}
            value={basicinfo.contractLength}
            disabled={basicinfo.employeeStatus.toLowerCase() !== 'contract'}
            validators={basicinfo.employeeStatus.toLowerCase() === 'contract' ? [ 'required']:[]}
            errorMessages={basicinfo.employeeStatus.toLowerCase() === 'contract' ?  [<Translate id="hrms.allEmployee.required" />] : []}
        />
        <TextValidator
            disabled={true}
            label={<Translate id="hrms.allEmployee.period" />}
            name="period"
            value={basicinfo.period}
        />
        <SelectValidator
            label={<Translate id="hrms.allEmployee.nationality" />}
            name="nationality"
            value={basicinfo.nationality || ''}
            onSelect={handleChange}
            data={master.modulemaster.countryList.map(({ text }) => text)}
            options={{ placeholder: "Choose one" }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
        />

        <SelectValidator
            label={<Translate id="hrms.allEmployee.role" />}
            name="roleUID"
            value={basicinfo.roleUID || ''}
            onSelect={handleChange}
            data={dropDownList.moduleList.map(({ text, id }) => ({ text, id }))}
            validators={['required']}
            errorMessages={[<Translate id="hrms.allEmployee.required" />]}
            options={{ placeholder: "Choose one" }}
        />
        {/* 
        <TextValidator

            label="KTP / PASSPORT NO"
            onChange={handleChange}
            name="ktp"
            value={basicinfo.ktp}
        />
 */}

        <div className="form-group">
            <button id="submit" style={{ display: "none" }} type="submit"></button>
        </div>
    </ValidatorForm >
);


const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    dataChanged,
    goto: (e, data) => push({ pathname: e, state: { data } })
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master,
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(AddEmployee))
import React from 'react'

import SideBarItem from './SidebarItem'
import { withTheme } from 'theming';
import Searchbox from '../../Components/Searchbox';
class Sidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collapseMenu: false,
            menu:[]
        }
    }

    componentDidMount(){
        let menu = this.props.menu[0].subMenu;        
        this.setState({
            menu: menu
        })
    }

    toggleMenu() {
        if (document.getElementById("navbar").classList.contains("__collapsed")) {
            document.getElementById('breadcrumbs').classList.add('long');
            this.setState({
                collapseMenu: false
            })
        }
        else {
            document.getElementById('breadcrumbs').classList.remove('long');
            this.setState({
                collapseMenu: true
            })
        }
    }

    handleChange = (e) => {
        this.setState({
            input: e.target.value
        }, () => {
            this.filter(this.state.input);
        })
    }

    filter = (e) => {
        let menu = this.props.menu[0].subMenu;
        var submenulist = [];
        for (var i = 0; i < menu.length; i++) {
            for (var j = 0; j < menu[i].subMenu.length; j++)
            {
                submenulist.push(menu[i].value);
            }            
        }
        let filteredsubmenu = submenulist.filter((submenu) => {
            return submenu.toLowerCase().includes(e.toLowerCase())
        })
        let filteredmenu = menu.filter((menu) => {
            if (menu.subMenu) {
                return filteredsubmenu.includes(menu.value)|| menu.subMenu.some((submenu) => {
                    return submenu.value.toLowerCase().includes(e.toLowerCase()) 
                })
            }
            return null;
        })
        this.setState({
            menu: filteredmenu
        })
    }

    clearFilter = () => {
        this.setState({
            input: ''
        }, () => {
            this.filter("");
        })
    }
    

    render() {
        let { theme } = this.props;
        return (
            <nav id="navbar" className={`nav-bar ${this.state.collapseMenu ? "__collapsed" : ""}`}>
                <div className="bar">
                    <div className="wrapper">
                        <div className="list __items" style={theme.nav}>
                            <div style={{ textAlign: "right", marginRight: 10, height: 20 }}
                                onClick={() => this.toggleMenu()}>
                                <a>
                                    {this.state.collapseMenu
                                        ? <i style={{ ...{ fontSize: 20, position: 'relative', top: '-5px' } }} className="list-ico fa fa-angle-double-right"></i>
                                        : <i style={{ ...{ fontSize: 20, position: 'relative', top: '-5px' } }} className="list-ico fa fa-angle-double-left"></i>
                                    }
                                </a>
                                </div>
                            <ul>
                                {/*this.props.menu.map((item, i) => (
                                    <li className="list-item" key={i} style={theme.nav}>
                                        <SideBarItem style={theme.nav} onClick={() => this.props.changepage(item)} icon={item.icon} value={item.value} />
                                    </li>
                                ))*/}
                                <li className="list-item" style={theme.nav}>
                                    <a className="list-link" onClick={this.props.onClick}>
                                        <span className="list-name" style={{fontSize:12,width:'100%'}}>
                                            <Searchbox
                                                value={this.state.input}
                                                clearFilter={this.clearFilter}
                                                onChange={this.handleChange}
                                            />
                                        </span>
                                    </a>                                    
                                </li>
                                
                                {this.state.menu.map((item, i) => (                        
                                    /*<span className="">{item.icon && <img alt={"icon"} style={this.props.imagestyle ? this.props.imagestyle : { height: 20, width: 20 }} src={`${this.getIcon(item.icon)}`} />}</span> &&*/
                                    <li className="list-item" key={i} style={theme.nav}>  
                                        <div data-toggle="collapse" href={"#collapse"+i} className={i === 0 ? "divcol" : "divcol collapsed"}>
                                            <span className="list-name" style={{fontSize:11}}>
                                                {item.value}
                                            </span>
                                        </div>
                                        <ul id={"collapse"+i} className={i === 0 ? "collapse show" : "collapse"}>
                                            {item.subMenu.map((itemkey, x) => (
                                            <li className="list-item" key={x} style={theme.nav}>  
                                                <SideBarItem style={theme.nav} onClick={() => this.props.changepage(itemkey)} icon={itemkey.icon} value={itemkey.value} />
                                            </li>
                                        ))}
                                        </ul>
                                        <span className="list-name" style={{height:1,background:'#fff',width:'100%'}}></span>
                                    </li>                              
                                ))}                                
                            </ul>
                        </div>
                    </div>
                </div>
            </nav >
        )
    }
}

export default withTheme(Sidebar);
import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import PrivateRoute from './Components/PrivateRoute';
import { SESS_STORAGE_KEY } from './_helpers/helper.methods';
import store from './_helpers/helper.store';
import LoginPage from './MainPage/LoginPage';
import MainPage from './MainPage/MainPage';
import SignupPage from './MainPage/SignupPage';

const App = () => {

    useEffect(() => {
        const unsubscribeStore = store.subscribe(() => {
            let userData = store.getState();
            sessionStorage.setItem(SESS_STORAGE_KEY, JSON.stringify(userData));
        })

        return unsubscribeStore // return clean up function
    })

    return (
        <LocalizeProvider>
            <div className="layout-cnt">
                <Route path="/" component={LoginPage} />
                <PrivateRoute path="/home" component={MainPage} />
            </div>
        </LocalizeProvider>
    );
}

export default App;
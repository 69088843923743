import React from 'react';
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import PrivateRoute from '../Components/PrivateRoute';
import LoginEmail from "../Module/Login/LoginEmail";
import LoginPassword from "../Module/Login/LoginPassword";
import ChangePassword from "../Module/Login/ChangePassword";
import SignUp from "../Module/Login/SignUp";
import SignupPage from './SignupPage';

function LoginPage() {
    return (
        <div>
            <Helmet>
                <title>Sarvam - HRIS for busy people</title>
            </Helmet>
            <Route exact path="/" component={LoginEmail} />
            <Route exact path="/loginpass" component={LoginPassword} />
            <Route path="/signup" component={SignUp} />
            <Route path='/subscription' component={SignupPage}/>
            <PrivateRoute exact path="/changepassword" component={ChangePassword} />
        </div>
    );
}

export default LoginPage;
import React from 'react'
import { Route } from 'react-router-dom'
//components
import MyEducationDetails from "./MyEducationDetails"
import List from "./List"

class EducationDetails extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route path={`${match.path}`} component={List} />
                <Route path={`${match.path}/add`} component={MyEducationDetails} />
                <Route path={`${match.path}/edit`} component={MyEducationDetails} />           
            </React.Fragment>
        );
    }
}

export default EducationDetails;
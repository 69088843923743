import { storeMaster } from '../_reducers/reducer.user';
import API from '../_apis/Core';
import { devLog } from './helper.methods';
import { LOAD_MASTER } from '../_reducers/reducer.user';
/*
const StopLoading = {
    type: LOAD_MASTER,
    masterLoading: {
        load: false,
        path: ''
    }
}

const StartLoading = {
    type: LOAD_MASTER,
    masterLoading: {
        load: true,
        path: ''
    }
}
*/
export const MasterAPIMiddleware = store => next => action => {
    if (action.type === "@@router/LOCATION_CHANGE") {
        let { pathname } = action.payload
        let { masterLoading } = store.getState().user;
        if (pathname.includes('hrms/')) {
            let pathNameArr = pathname.split('/');
            let oldPathNameArr = masterLoading.path.split('/');
            if ((pathNameArr.length > oldPathNameArr.length ||
                pathNameArr.length === oldPathNameArr.length) &&
                pathNameArr[pathNameArr.length - 1] !== oldPathNameArr[pathNameArr.length - 1]) {
                getHRMSMaster(store, action);
            } else {
                store.dispatch({
                    type: LOAD_MASTER,
                    masterLoading: {
                        load: false,
                        path: action.payload.pathname
                    }
                })
            }
        }
    }
    next(action);
}

const getHRMSMaster = async (store, action) => {
    let { user, user: { master: { modulemaster } } } = store.getState();
    store.dispatch({
        type: LOAD_MASTER,
        masterLoading: {
            load: true,
            path: action.payload.pathname
        }
    })
    try {
        let res = await API.Master.List(user.user, 'hrms', modulemaster.lastFetchDate[0].text);
        if (res.status !== '1') {
            let { user: { master, master: { modulemaster } } } = store.getState();

            if (res.cityList.length) {
                modulemaster.cityList = res.cityList
            }
            if (res.provinceList.length) {
                modulemaster.provinceList = res.provinceList
            }
            if (res.countryList.length) {
                modulemaster.countryList = res.countryList
            }
            if (res.divisionList.length) {
                modulemaster.divisionList = res.divisionList
            }
            if (res.downloadAPKList.length) {
                modulemaster.downloadAPKList = res.downloadAPKList
            }
            if (res.employeeStatusList.length) {
                modulemaster.employeeStatusList = res.employeeStatusList
            }
            if (res.holidayList.length) {
                modulemaster.holidayList = res.holidayList
            }
            if (res.idCardTypeList.length) {
                modulemaster.idCardTypeList = res.idCardTypeList
            }
            if (res.industryList.length) {
                modulemaster.industryList = res.industryList
            }
            if (res.jobFunctionList.length) {
                modulemaster.jobFunctionList = res.jobFunctionList
            }
            if (res.lastFetchDate.length) {
                modulemaster.lastFetchDate = res.lastFetchDate
            }
            if (res.orgUnitList.length) {
                modulemaster.orgUnitList = res.orgUnitList
            }
            if (res.rmsInterviewDurationList.length) {
                modulemaster.rmsInterviewDurationList = res.rmsInterviewDurationList
            }
            if (res.workReasonTypeList.length) {
                modulemaster.workReasonTypeList = res.workReasonTypeList
            }
            if (res.yearsofWorkExpList.length) {
                modulemaster.yearsofWorkExpList = res.yearsofWorkExpList
            }
            let modData = {
                ...master,
                modulemaster: modulemaster
            }
            store.dispatch(storeMaster(modData));
        } else {
            store.dispatch({
                type: LOAD_MASTER,
                masterLoading: {
                    load: false,
                    path: action.payload.pathname
                }
            })
        }
    } catch (err) {
        devLog(err.message);
        store.dispatch({
            type: LOAD_MASTER,
            masterLoading: {
                load: false,
                path: action.payload.pathname
            }
        })
    }
}

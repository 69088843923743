
import React from 'react';
import { withTheme } from 'theming';

const DashboardGrid = (props) => {
    return (
        <div className={
            props.theme.dashboard.color === 'white'
                ? 'dashboard-grid black' : 'dashboard-grid'}
            style={props.style}>
            {props.children}
        </div>
    )
}
export default withTheme(DashboardGrid);
import React, {Suspense} from 'react'
import { Route } from 'react-router-dom'
import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from 'react-dom/server';
import Topbar from '../Components/Topbar'
import Sidebar from '../Components/Sidebar';
import {
    setMenu/*, getMenu*/
} from "../_reducers/reducer.menu";
import { setLanguagePage } from "../_reducers/reducer.language";
import {
    createNotification
} from "../_reducers/reducer.notification";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ThemeProvider } from 'theming';
import Breadcrumbs from '../Components/Breadcrumbs';
import HRMS from "../Module/HRMS";
import Dashboard from "../Module/Dashboard";
//import EPMS from "../Module/EPMS";

const EPMS = React.lazy(() => import('../Module/EPMS'));

class MainPage extends React.Component {
    state = {
        theme: this.props.theme
    }

    constructor(props) {
        super(props);
        this.props.initialize({
            languages: [
                { name: "English", code: "en" },
                { name: "Bahasa", code: "id" }
            ],
            options: { renderToStaticMarkup }
        });

        this.props.setActiveLanguage(this.props.activelanguagepage);


    }

    componentDidMount() {
        //  let menu=getMenu();
        //  this.props.setMenu(menu);
        // this.props.history.push('/home/dashboard');
    }
    changePage = (item) => {
        this.props.history.push({
            pathname: item.path,
            state: {
                subtitle: "Human Resource Management System",
                menu: item.subMenu,
                title: item.value,
                icon: item.icon
            }
        })
    }
    changeTheme = (theme) => {

        this.setState({
            theme
        })
    }
    render() {
        let { match, routing } = this.props;

        return (
            <ThemeProvider theme={this.state.theme}>
                <div>
                    <Topbar changeTheme={this.changeTheme} />
                    <Breadcrumbs pathname={routing.location.pathname} />
                    <div className="pageWrapper">
                        <Sidebar menu={this.props.menu} changepage={this.changePage} />
                        <Suspense fallback={<div><img alt='loading' style={{ height: 100, width: 100 }} src={'/images/loading.gif'} /></div>}>
                            <Route path={`${match.path}/hrms`} component={HRMS} />
                        </Suspense>
                        <Route path={`${match.path}`} exact component={Dashboard} />
                        <Suspense fallback={<div><img alt='loading' style={{ height: 100, width: 100 }} src={'/images/loading.gif'} /></div>}>
                            <Route path={`${match.path}/epms`} component={EPMS} />
                        </Suspense>
                    </div>
                </div>
            </ThemeProvider>
        );
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    setMenu,
    setLanguagePage,
    createNotification
}, dispatch)
const mapStateToProps = state => ({
    menu: state.menu.menu,
    theme: state.theme.theme,
    activelanguagepage: state.language.activelanguagepage,
    user: state.user.user,
    routing: state.routing
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(MainPage))





import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";
import { ValidatorForm } from 'react-form-validator-core';
import SelectValidator from "../../../../../Components/Validators/SelectValidator";
import CheckBox from "../../../../../Components/Validators/CheckBox";
import DatetimePicker from "../../../../../Components/DateTimePicker/DatePicker";
//api 
import API from '../../../../../_apis/hrms/employee';
import APILocation from "../../../../../_apis/hrms/master";
import APImaster from '../../../../../_apis/Core';
//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { errorNotification, createNotification, dataChanged } from '../../../../../_reducers/reducer.notification';
import { devLog, handleFormErrors } from "../../../../../_helpers/helper.methods";
import {
    storeMaster
} from "../../../../../_reducers/reducer.user";
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';
import moment from "moment";

class ValidLocInfoDetail extends Component {

    componentDidUpdate(prevProps, prevState) {
        let { history: { location } } = this.props;
        if (prevProps !== this.props && location.pathname.includes("edit")) {
            this.setState({
                subtitle: location.state.employee.employeeCode,
                validLocDetail: { ...location.state.item },
                empUID: location.state.employee.empUID
            })
        }
    }
    
    async componentDidMount() {
        let { history, match } = this.props;
        if (match.path.includes("edit")) {
            this.setState({
                subtitle: history.location.state.subtitle,
                validLocDetail: { ...history.location.state.item },
                empUID: history.location.state.employee.empUID,
            })
        }
        else if (match.path.includes("add")) {
            this.setState({
                empUID: history.location.state.employee.empUID,
                subtitle: history.location.state.subtitle,
                validLocDetail: this.getinitialState()
            })
        }
        try {
            let res = await APILocation.Location.List(this.props.user, history.location.state.selectedOrgUnitUID, true);
            res.validFrom = moment(res.validFrom);
            res.validTo = moment(res.validTo);    
            this.setState({
                validLocList: res
            })
        } catch (error) {
            devLog("err occured", error)
        }  
    }

    getinitialState = () => {
        return {            
            validLocUID:'',
            locationUID: '',
            locationName: '',
            isActive: '',
            validFrom: '',
            validTo: ''
        }
    }

    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            validLocList: [],
            validLocDetail: this.getinitialState(),
            load: false
        }
    }
    handleChange = (event) => {
        let { name, value } = event.target;
        name = name[0].toLowerCase() + name.slice(1);
        let validLocDetail = this.state.validLocDetail;
        if (event.target.type === "checkbox") {
            this.setState({
                validLocDetail: {
                    ...validLocDetail,
                    [name]: !validLocDetail[name]
                }
            })
        } else {
            this.setState({
                validLocDetail: {
                    ...validLocDetail,
                    [name]: value
                }
            })
        }
    } 
    
    handleCalendar = (momentd, name) => {        
        let validLocDetail = this.state.validLocDetail;
        name = name[0].toLowerCase() + name.slice(1);
        this.setState({
            validLocDetail: {
                ...validLocDetail,
                [name]: momentd
            }
        })
    }

    handleSubmit = async (e) => {
        let { match } = this.props;
        let validLocDetail = this.state.validLocDetail;
        let formData = new FormData(e.target);
        this.setState({ load: true })
        let validFrom = moment(validLocDetail.validFrom).format("YYYY-MM-DD HH:mm:ss"); 
        let validTo = moment(validLocDetail.validTo).format("YYYY-MM-DD HH:mm:ss");  
        formData.set('ValidFrom', validFrom);
        formData.set('ValidTo', validTo);
        formData.append('EmpUID', this.state.empUID);        
        formData.set('isActive', validLocDetail.isActive ? true : false)
        if (match.path.includes('add')) {                  
            let res = await API.Validloc.Add(formData, this.props.user);
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.close();
                this.props.createNotification('Success', res.message ? res.message : "Data Saved.");
                this.resetmaster();
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }            
        } else {
            formData.append('ValidLocUID', this.state.validLocDetail.validLocUID);            
            let res = await API.Validloc.Update(formData, this.props.user)
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.close();
                this.props.createNotification('Success', res.message ? res.message : "Data Saved.");
                this.resetmaster();
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }
        }
    }

    resetmaster = async () =>{
        let res = await APImaster.Master.List(this.props.user, 'hrms');
        if (res.status !== '1') {
            let mlist = {
                ...this.props.master,
                modulemaster: res
            }
            devLog('mliust', mlist)
            this.props.storeMaster(mlist)
        }
    }

    close = () => {
        let { history } = this.props;
        history.push('/home/hrms/allemporgunit/allemployee/menu/validloc')
    }

    reset = () => {
        let { match } = this.props;
        if (match.path.includes('edit')) {
            this.setState({ validLocDetail: this.state.validLocDetail });
        } else if (match.path.includes('add')) {
            this.setState({ validLocDetail: this.getinitialState() });
        }
    }

    toolbarclick = async (e) => {
        if (e === "save") {
            document.getElementById("submit").click();
        } else if (e === 'delete') {
            this.setState({ load: true })
            let { validLocUID } = this.state.validLocDetail;
            let res = await API.Validloc.Delete(this.props.user, validLocUID)
            this.setState({ load: false })
            if (res.status === "0") {
                this.props.dataChanged();
                this.props.createNotification('Success', res.message);
                this.close();
            } else {
                this.props.createNotification('Operation Failed', res.message);
            }
        } else if (e === "reset") {
            this.reset();
        }
    } 
    
    render() {
        let { validLocDetail } = this.state;
        let toolbar = [{
            icon: '/images/save_black.png',
            name: <Translate id="hrms.employeeInfo.myInformation.save" />,
            value: "save"
        }, {
            icon: '/images/reset_black.png',
            name: "Reset",
            value: "reset"
        }];
        let { match } = this.props;
        if (match.path.includes("edit")) {
            toolbar.push({
                icon: '/images/trash_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.delete" />,
                value: "delete"
            })
        }
        return (
            <Blade match={this.props.match}
                max={false}
                load={this.state.load}
                close={this.close}
                icon={this.state.icon || '/images/location.png'}
                head={<Translate id="hrms.employeeInfo.myInformation.validLoc" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>               

                <BladeBody match={this.props.match} load={this.state.load}>
                {Inputs(this.props.master,
                    validLocDetail,
                    this.handleSubmit,
                    this.handleChange,
                    this.handleCalendar,
                    this.state.empUID,
                    this.state.validLocList
                )}
            </BladeBody>
        </Blade >
        )
    }
}

const Inputs = (master, validLocDetail, handleSubmit, handleChange, handleCalendar, empUID, validLocList ) => (
<ValidatorForm
                    ref="form"
                    onError={handleFormErrors}
                    onSubmit={handleSubmit}
                >     
                    <SelectValidator
                        label={<Translate id="hrms.master.orgUnit.locationName" />}
                        value={validLocDetail.locationUID || ''}
                        name="LocationUID"
                        onSelect={handleChange}
                        data={validLocList.map(({ uid, locationName }) => ({ id:uid, text:locationName }))}
                        options={{ placeholder: 'Choose Location' }}
                        validators={['required']}
                        errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                    />  
                    <DatetimePicker
                        closeOnSelect
                        name='ValidFrom'
                        value={validLocDetail.validFrom || ''}
                        onChange={(m) => handleCalendar(m, "ValidFrom")}
                        inputProps={{
                            placeholder: 'DD/MM/YYYY',
                            label: <Translate id="hrms.employeeInfo.myInformation.validFrom" />
                        }}
                        validators={['required']}
                        errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false} />
                    <DatetimePicker
                        closeOnSelect
                        name='ValidTo'
                        value={validLocDetail.validTo || ''}
                        isValidDate={(current) => { return current.isAfter(moment().subtract(1, 'day')) }}
                        onChange={(m) => handleCalendar(m, "ValidTo")}
                        inputProps={{
                            placeholder: 'DD/MM/YYYY',
                            label: <Translate id="hrms.employeeInfo.myInformation.validTo" />
                        }}
                        validators={['required']}
                        errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
                        dateFormat="DD/MM/YYYY"
                        timeFormat={false} />                                        
                    <CheckBox
                        label={<Translate id="hrms.employeeInfo.myInformation.active" />}
                        name="isActive"
                        onChange={handleChange}
                        checked={validLocDetail.isActive}
                    /> 
                    <div className="form-group">
                        <button id="submit" style={{ display: "none" }} type="submit"></button>
                    </div>
                </ValidatorForm>
        
    );

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    errorNotification,
    dataChanged,
    storeMaster
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(ValidLocInfoDetail))
import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from "../../../_helpers/helper.methods";
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const List = (user, directory, empUID) => {
    let formData = new FormData();
    let signature = user.subscription.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog( "raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmpUID', '');
    formData.append('LeaveTypeUID', '');
    formData.append('TransactionUID', '');
    formData.append('TransactionType', 'ADJUSTMENT');

    let url = '/api/hrms/leave/adjustment/list';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// adjustment list response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const AddMassAdj = (formData, user, directory)  => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('leaveTypeUID').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('TransactionType', 'ADJUSTMENT');

    let url = '/api/hrms/leave/adjustment/mass';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// mass adjustment add response", res)
            return res;
        })
        .catch(checkAPIError);
}

export const Add = (formData, user, directory) => {
    let { language: { ipaddress } } = store.getState();
    let signature = formData.get('leaveTypeUID').toLowerCase() + user.empUID.toLowerCase() + user.token;

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('TransactionType', 'ADJUSTMENT');

    let url = '/api/hrms/leave/adjustment/add';

    formDataConsole(formData);
    devLog('url', url);

    return fetch(DEV_URL.concat(url), {
        // return fetch(directory.concat(DEV_URL, url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog("// adjustment add response", res)
            return res;
        })
        .catch(checkAPIError);
}
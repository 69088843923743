import React, { Component } from "react";

import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";
import TextValidator from "../../../../../Components/Validators/TextValidator";
import { ValidatorForm } from 'react-form-validator-core';

//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';

import EmployeeAPI from '../../../../../_apis/hrms/employee';

import { createNotification } from '../../../../../_reducers/reducer.notification';
import { handleFormErrors } from "../../../../../_helpers/helper.methods";

class CTCInfo extends Component {
    async componentDidMount() {
        let { history: { location: { state } } } = this.props;
        if(this.props.location.pathname === "/home/hrms/myinfo/CTCInfo"){
            this.setState({
                toolbar: ''
            });
        }else{
        this.setState({
            toolbar: [...this.state.protectedToolbar]
        });
    }
        this.setState({
            ctcpa: state.item.ctc[0].ctcpa,
            ctcpm: state.item.ctc[0].ctcpm,
            uid:state.item.empUID,
            employee: state.item.ctc,
            icon: state.icon,
            title: state.title
        })
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en"); 
        this.state = {
            employee: {
                ctc: []
            },
            uid:'',
            ctcpa: '',
            ctcpm: '',
            load: false,
            ctc: [],
            toolbar: [],
            protectedToolbar: [{
                icon: '/images/save_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.add" />,
                value: "save"
            }, {
                icon: '/images/reset_black.png',
                name: "Reset",
                value: "reset"
            }],
            selectedRole: []
        }
    }
    handleChange = (e, selectedrec, index) => {     
        if (e.target.name === "ctcpm") {
            this.setState({
                ctcpm: e.target.value
            });
        } else if (e.target.name === "ctcpa") {
            this.setState({
                ctcpa: e.target.value
            });
        }
    }
    handleSubmit = async (e) => {
        let { uid,ctcpa,ctcpm } = this.state;   
        this.setState({
            load: true
        })     
        let res = await EmployeeAPI.CTC.Update(ctcpm,ctcpa, this.props.user, uid);
        if (res.status !== '1') {
            this.props.createNotification('Success', res.message);
        } else {
            this.props.createNotification('Operation Failed', res.message)
        }
        this.setState({
            load: false
        })  
    }
    close = () => {
        let { match } = this.props;
        if (match.path.includes('allemployee')) {
            this.props.history.push('/home/hrms/allemporgunit/allemployee/menu')
        } else {
            this.props.history.push('/home/hrms/myinfo')
        }
    }
    toolbarclick = (e) => {
        if (e === 'save') {
            document.getElementById('submit').click();
        }
    }
    render() {
        let { toolbar, title, load, ctcpa, ctcpm } = this.state;
        return (
            <Blade match={this.props.match}
                close={this.close}
                load={load}
                icon={this.state.icon || '/images/myorgchart.png'}
                head={title}
                subhead={title}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={load}>


                    <ValidatorForm
                        ref="form"
                        onError={handleFormErrors}
                        onSubmit={this.handleSubmit}
                    >
                        <TextValidator
                            label={"CTCPM"}
                            name={"ctcpm"}
                            value={ctcpm}
                            onChange={this.handleChange}
                            disabled = {this.props.match.path.toLowerCase().includes('allemployee') ? false : true}
                        />
                        <TextValidator
                            label={"CTCPA"}
                            name={"ctcpa"}
                            value={ctcpa}
                            onChange={this.handleChange}
                            disabled = {this.props.match.path.toLowerCase().includes('allemployee') ? false : true}
                        />
                        <div className="form-group">
                            <button id="submit" style={{ display: "none" }} type="submit"></button>
                        </div>
                    </ValidatorForm>
                </BladeBody>
            </Blade >
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(CTCInfo))
import React, { Component } from "react";

//blades
import Blade from "../../../../../Components/Blade";
import BladeBody from "../../../../../Components/Blade/BladeBody";

//validators
import TextValidator from "../../../../../Components/Validators/TextValidator";
import { ValidatorForm } from 'react-form-validator-core';
import SelectValidator from "../../../../../Components/Validators/SelectValidator";
import CheckBox from "../../../../../Components/Validators/CheckBox";
import DatetimePicker from "../../../../../Components/DateTimePicker/DatePicker";

import moment from "moment";
//redux
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../../../../_language/en.json';
import id from '../../../../../_language/id.json';

//methods
import { devLog, formatDate, getCalenderView, handleFormErrors } from '../../../../../_helpers/helper.methods';
import { createNotification, dataChanged } from '../../../../../_reducers/reducer.notification';
//api
import API from "../../../../../_apis/hrms/employee";
import MasterAPI from '../../../../../_apis/Core';
import Cropper from 'react-cropper';
import Webcam from "react-webcam";
//200kb 
const MAX_IMAGE_CROP_SIZE = 200000;

class BasicInfo extends Component {
    async componentDidMount() {
        let { match } = this.props;
        this.setState({ icon: this.props.history.location.state.icon });
        this.setState({
            pathname: match.path
        })
        if (match.path.includes("allemployee")) {
            let { item } = this.props.history.location.state;
            let { user } = this.props;
            let url = "../../../../../";
            let res = await API.Employee.Detail(user, url, item);
            try {
                if (res.photoUrl) {
                    this.getBlobFromURL(res.photoUrl);
                }
                this.setState({
                    toolbar: [...this.state.toolbar, ...this.state.allEmpToolbar],
                    load: false,
                    originalbasicinfo: res,
                    basicinfo: res,
                    subtitle: res.employeeCode,
                }, () => {
                    this.setState({
                        basicinfo: {
                            ...this.state.basicinfo,
                            age: res.birthDate ? new Date().getFullYear() - new Date(res.birthDate).getFullYear() : '',
                            joinDate: formatDate(res.joinDate || ''),
                            birthDate: formatDate(res.birthDate || ''),
                            endDate: this.calculateEndDate(res.joinDate, res.contractLength),
                            //roleName: res.roleList.length > 0 ? res.roleList[0].roleName : ''
                        }
                    })
                })
            } catch (error) {
                devLog("err occured", error)
            }
            let resEMP = await API.Employee.Detail(user, url);
            if (resEMP.roleList.length > 0) {
                for (const item of resEMP.roleList) {
                    if (item.roleName === "HR Admin") {
                        this.setState({
                            isHR: true
                        })
                        break;
                    }
                }
            } else {
                this.setState({
                    isHR: false
                })
            }
        } else if (match.path.includes('myinfo')) {
            let url = "../../../../";
            let res = await API.Employee.Detail(this.props.user, url);
            try {
                if (res.photoUrl) {
                    this.getBlobFromURL(res.photoUrl);
                }
                if (res.roleList.length > 0) {
                    for (const item of res.roleList) {
                        if (item.roleName === "HR Admin") {
                            this.setState({
                                isHR: true
                            })
                            break;
                        }
                    }
                } else {
                    this.setState({
                        isHR: false
                    })
                }
                this.setState({
                    disableField: true,
                    load: false,
                    originalbasicinfo: res,
                    basicinfo: res,
                    subtitle: res.employeeCode
                }, () => {
                    this.setState({
                        basicinfo: {
                            ...this.state.basicinfo,
                            age: res.birthDate ? new Date().getFullYear() - new Date(res.birthDate).getFullYear() : '',
                            joinDate: formatDate(res.joinDate || ''),
                            birthDate: formatDate(res.birthDate || ''),
                            //roleName: res.roleList.length > 0 ? res.roleList[0].roleName : ''
                        }
                    })
                })
            } catch (error) {
                devLog("err occured", error)
            }
        }
    }
    getBlobFromURL = async (blob) => {
        let url = '../../../../';
        let res = await MasterAPI.AccessBlob(this.props.user, blob, url);
        if (res.status !== '1') {
            this.setState({
                photoUrl: res.message
            })
        }
    }
    getinitialState = () => {
        return {
            employeeCode: '',
            firstName: '',
            middleName: '',
            lastName: '',
            religion: '',
            bloodType: '',
            userId: '',
            altEmail: '',
            mobileNo: '',
            altMobileNo: '',
            email: '',
            gender: '',
            birthDate: '',
            isActive: '',
            age: '',
            maritalStatus: '',
            joinDate: '',
            yearsInService: '',
            positionName: '',
            employeeStatus: '',
            birthPlace: '',
            nationality: '',
            ktp: '',
            departmentName: '',
            subDepartmentName: '',
            serviceMonth: '',
            empSequence: '',
            contractLength: '',
            allowVirtualLocation: false,
            endDate: '',
            roleName: '',
        }
    }
    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
        this.state = {
            modal: false,
            using_webcam: false,
            is_fromwebcam: false,
            toolbar: [{
                icon: '/images/save_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.update" />,
                value: "update"
            }, {
                icon: '/images/reset_black.png',
                name: "Reset",
                value: "reset"
            }],
            allEmpToolbar: [{
                icon: '/images/password_black.png',
                name: <Translate id="hrms.employeeInfo.myInformation.resetPassword" />,
                value: "resetpassword"
            }],
            load: true,
            subtitle: '',
            buttonflag: "",
            disableField: false,
            isHR: false,
            pathname: '',
            originalbasicinfo: {},
            basicinfo: this.getinitialState()
        }
    }

    handleChange = (event) => {

        let basicinfo = this.state.basicinfo;
        if (event.target.type === "checkbox") {
            this.setState({
                basicinfo: {
                    ...basicinfo,
                    [event.target.name]: !basicinfo[event.target.name]
                }
            })
        } else {
            this.setState({
                basicinfo: {
                    ...basicinfo,
                    [event.target.name]: event.target.value
                }
            })
        }
    }

    handleCalendar = (date, name) => {
        let basicinfo = this.state.basicinfo;
        if (typeof date !== "string") {
            let lcdate = date.format("DD/MM/YYYY");
            if (name === 'joinDate' && this.state.basicinfo.contractLength !== null && (this.state.basicinfo.employeeStatus === "Contract" || this.state.basicinfo.employeeStatus === "Probation")) {

                this.setState({
                    basicinfo: {
                        ...basicinfo,
                        [name]: lcdate,
                        endDate: this.calculateEndDate(date, this.state.basicinfo.contractLength)
                    }
                })
            }
            else {
                this.setState({
                    basicinfo: {
                        ...basicinfo,
                        [name]: name === 'birthDate'
                            ? lcdate.slice(6) <= new Date().getFullYear()
                                ? lcdate
                                : lcdate.slice(0, 6)
                            : lcdate
                    }
                }, () => { if (name === "birthDate") this.calculateAge() })
            }
        } else {
            this.setState({
                basicinfo: {
                    ...basicinfo,
                    [name]: name === 'birthDate'
                        ? date.slice(6) <= new Date().getFullYear()
                            ? date
                            : date.slice(0, 6)
                        : date
                }
            })
        }
    }
    calculateAge = () => {
        let { birthDate } = this.state.basicinfo;
        this.setState({
            basicinfo: {
                ...this.state.basicinfo,
                age: birthDate ? new Date().getFullYear() - new Date(getCalenderView(birthDate, 'server')).getFullYear() : '',
            }
        })
    }

    calculateEndDate = (join, contract) => {
        var dt = new Date(join);
        var a = (dt.setMonth(dt.getMonth() + parseInt(contract, 10)));
        a = new Date(a);
        return formatDate(a);
    }

    handleEndDate = (join, contract) => {
        var dt = new Date(join);
        var a = (dt.setMonth(dt.getMonth() + parseInt(contract, 10)));
        a = new Date(a);
        return a;
    }

    getCroppedImage = () => {
        return new Promise((resolve, reject) => {
            this.state.file.toBlob((blob) => {
                resolve(blob)
            }, 'image/jpeg');
        })
    }

    handleSubmit = async (e) => {
        this.setState({
            load: true
        })
        let url = '../../../../';
        let { buttonflag, file, basicinfo } = this.state;
        const formData = new FormData(e.target);
        if (buttonflag === "update") {

            if (file) {
                let croppedImage = await this.getCroppedImage();
                if (croppedImage.size > MAX_IMAGE_CROP_SIZE) {
                    this.resetImageUploadButton();
                    this.props.createNotification('Alert', 'Profile picture size is greater than 200kb')
                    this.setState({ load: false })
                    return
                }

                formData.append('File', croppedImage);
            }
            formData.append('employeeCode', basicinfo.employeeCode);
            formData.append('salutation', basicinfo.salutation);
            formData.append('firstName', basicinfo.firstName !== null ? basicinfo.firstName : null);
            formData.append('middleName', basicinfo.middleName !== null ? basicinfo.middleName : null);
            formData.append('lastName', basicinfo.lastName !== null ? basicinfo.lastName : null);
            formData.append('email', basicinfo.email !== null ? basicinfo.email : null);
            formData.append('altEmail', basicinfo.altEmail !== null ? basicinfo.altEmail : null);
            formData.append('mobileNo', basicinfo.mobileNo !== null ? basicinfo.mobileNo : null);
            formData.append('altMobileNo', basicinfo.altMobileNo !== null ? basicinfo.altMobileNo : null);
            formData.append('birthPlace', basicinfo.birthPlace !== null ? basicinfo.birthPlace : null);
            formData.set("birthDate", getCalenderView(basicinfo.birthDate, 'server'));
            formData.append('gender', basicinfo.gender);
            formData.append('bloodType', basicinfo.bloodType);
            formData.append('religion', basicinfo.religion);
            formData.append('maritalStatus', basicinfo.maritalStatus);
            formData.append('nationality', basicinfo.nationality);
            formData.set('joinDate', getCalenderView(basicinfo.joinDate, 'server'));
            formData.append('employeeStatus', basicinfo.employeeStatus);
            formData.set('allowVirtualLocation', basicinfo.allowVirtualLocation);
            formData.set('isActive', basicinfo.isActive);
            formData.append('contractLength', basicinfo.contractLength !== null ? basicinfo.contractLength : "");
            let res = await API.Employee.Update(basicinfo, formData, this.props.user, url);
            if (res.status === "0") {
                this.props.dataChanged();
                this.props.createNotification('Success', res.message)
            } else {
                this.props.createNotification('Operation Failed', res.message)
            }
            this.setState(() => {
                if (res.status === '0')
                    return {
                        file: null,
                        load: false
                    }
                else
                    return { load: false }
            })
        } else if (buttonflag === "resetpassword") {
            let res = await API.Employee.ResetPassword(formData, this.props.user, url, this.state.basicinfo.empUID);
            if (res.status === "0") {
                this.props.dataChanged();
                this.props.createNotification('Success', res.message)
            } else {
                this.props.createNotification('Operation Failed', res.message)
            }
            this.setState({
                load: false
            })
        }
    }

    resetImageUploadButton = () => {
        document.getElementById('profilePhoto').value = '';
    }

    loadImage = (event) => {
        let reader = new FileReader();
        let file = event.target.files[0];
        reader.onloadend = () => {
            this.setState({
                modal: true,
                imagePreviewUrl: reader.result,
                is_fromwebcam: false
            });
        }
        reader.readAsDataURL(file)

        if (file !== event.target.value) {
            event.target.value = ''
        }
    }

    uploadImage = async () => {
        this.setState({
            modal: false,
            file: this.refs.cropper.getCroppedCanvas(),
            is_fromwebcam: false,
            cropimage: this.refs.cropper.getCroppedCanvas().toDataURL('image/jpg')
        })
    }

    // let { match } = this.props;
    // let formData = new FormData();
    // formData.append('file', this.state.file);
    // let url = '../../../../';
    // this.setState({ load: true })
    // API.Employee.UploadProfilePicture(this.state.basicinfo, this.props.user, formData, url)
    //     .then((res) => {
    //         this.setState({ load: false })
    //         if (res.status !== '1') {
    //             this.props.createNotification('Upload Success', res.message);
    //         } else {
    //             this.props.createNotification('Operation Failed', res.message);
    //         }
    //     })
    setRef = webcam => {
        this.webcam = webcam;
    };
    reset = () => {
        let { match } = this.props;
        if (match.path.includes('basicinfo')) {
            let { originalbasicinfo } = this.state;
            this.setState({
                basicinfo: { ...this.state.originalbasicinfo }
            }, () => {
                this.setState({
                    basicinfo: {
                        ...this.state.basicinfo,
                        age: originalbasicinfo.birthDate ? new Date().getFullYear() - new Date(originalbasicinfo.birthDate).getFullYear() : '',
                        joinDate: formatDate(originalbasicinfo.joinDate || ''),
                        birthDate: formatDate(originalbasicinfo.birthDate || '')
                    }
                })
            })

        } else if (match.path.includes('add')) {
            this.setState({ basicinfo: this.getinitialState() });
        }
    }
    toolbarclick = (e) => {
        if (e === "update") {
            this.setState({
                buttonflag: "update"
            }, () => {
                document.getElementById("submit").click();
            })

        } else if (e === 'reset') {
            this.reset();
        } else if (e === 'resetpassword') {
            this.setState({
                buttonflag: "resetpassword"
            }, () => {
                document.getElementById("resetpassword").click();
            })
        }
    }
    close = () => {
        let { match } = this.props;
        if (match.path.includes("allemployee")) {
            // this.props.history.goBack();
            this.props.history.push("/home/hrms/allemporgunit/allemployee/menu")
        } else {
            this.props.history.push("/home/hrms/myinfo")
        }
    }
    capture = () => {
        const imageSrc = this.webcam.getScreenshot();
        this.setState({ using_webcam: false, imagePreviewUrl: imageSrc })
    }

    render() {
        let defaultimage = "/images/profile-default.jpg";
        let { modal, cropimage, imagePreviewUrl, toolbar,
            basicinfo, load,
            disableField, photoUrl, isHR, pathname } = this.state;
        return (
            <Blade match={this.props.match}
                max={true}
                load={load}
                close={this.close}
                icon={this.state.icon || '/images/basicinfo.png'}
                head={<Translate id="hrms.employeeInfo.myInformation.basicInformation" />}
                subhead={this.state.subtitle}
                toolbar={toolbar}
                handleclick={this.toolbarclick}>
                <BladeBody match={this.props.match} load={load}>

                    {modal &&
                        <React.Fragment>
                            <div className="modal-backdrop fade in"
                                style={{ zIndex: 1040 }}></div>
                            <div modal-render="true" tabIndex="-1"
                                role="dialog" className="modal fade in"
                                style={{ zIndex: 1050, justifyContent: 'center', alignItems: 'center', display: "flex", opacity: 1 }}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h3 className="modal-title"><Translate id="hrms.employeeInfo.myInformation.adjustYourImage" /></h3>
                                        </div>
                                        <div className="modal-body">
                                            {this.state.using_webcam ? <Webcam
                                                ref={this.setRef}
                                                screenshotFormat="image/jpeg"
                                                style={{ height: 300, width: '100%' }} /> :
                                                imagePreviewUrl &&
                                                <Cropper
                                                    style={{ height: 400, width: '100%' }}
                                                    ref='cropper'
                                                    aspectRatio={10 / 8}
                                                    src={imagePreviewUrl} />
                                            }

                                        </div>
                                        <div className="modal-footer">
                                            {this.state.using_webcam ?
                                                <button onClick={this.capture} className="btn"><Translate id="hrms.employeeInfo.myInformation.capture" /></button> :
                                                <button onClick={this.uploadImage} className="btn"><Translate id="hrms.employeeInfo.myInformation.ok" /></button>}
                                            <button onClick={() => this.setState({ modal: false })} className="btn btn-default" ><Translate id="hrms.employeeInfo.myInformation.cancel" /></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>}
                    <div style={{ display: "flex", paddingTop: 10 }}>
                        <div style={{ flex: 1 }}>

                            {Inputs(disableField, this.props.master, this.handleCalendar, basicinfo, this.handleSubmit, this.handleChange, isHR, pathname)}

                        </div>
                        <div style={{ display: "flex", flexDirection: "column", flex: 1, paddingLeft: 10, alignItems: "center" }}>

                            <div style={{ width: "80%" }}>
                                {
                                    (cropimage || photoUrl
                                        ? <img
                                            alt='user'
                                            style={{ height: 200, width: '100%' }}
                                            src={cropimage ? cropimage : photoUrl ? photoUrl : defaultimage} />
                                        : <img
                                            alt='user'
                                            style={{ height: 200, width: '100%' }}
                                            src={defaultimage} />)
                                }
                            </div>
                            <div style={{
                                width: '80%', display: "flex", flexDirection: "column",
                                justifyContent: "space-around", marginTop: 10
                            }}>
                                <input name='profilePhoto' type="file" id='profilePhoto' onChange={this.loadImage} style={{ display: 'none' }} />
                                <div>
                                    <button onClick={() => document.getElementById('profilePhoto').click() && this.setState({ using_webcam: false })} className="btn" style={{ backgroundColor: '#4c4c4c', width: '100%', marginBottom: 10 }} type='button' >
                                        <Translate id="hrms.employeeInfo.myInformation.uploadImage" />
                                    </button>

                                    <button onClick={() => this.setState({ using_webcam: true, modal: true })} className="btn" style={{ backgroundColor: '#4c4c4c', width: '100%' }} type='button' >
                                        <Translate id="hrms.employeeInfo.myInformation.takePicture" />
                                    </button>
                                </div>
                                {/* <button onClick={() => document.getElementById('resetpassword').click()} className="btn" style={{ backgroundColor: '#4c4c4c' }} type='button' >
                                    Change Password
                                </button> */}
                            </div>
                            <p style={{ textAlign: 'center', fontSize: 12, marginTop: 10 }}><Translate id="hrms.employeeInfo.myInformation.imageNotif" /></p>
                            <div style={{
                                width: '80%', display: "flex", flexDirection: "column",
                                justifyContent: "space-around", marginTop: 10
                            }}>
                                <p><span style={{ fontSize: 12 }}>{<Translate id="hrms.employeeInfo.myInformation.status" />}<br />{basicinfo.isActive === true ? <span style={{ backgroundColor: '#34c62f', color: '#fff', padding: '5px 15px' }}>{<Translate id="hrms.employeeInfo.myInformation.active" />}</span> : <span style={{ backgroundColor: '#e04a29', color: '#fff', padding: '5px 15px' }}>{<Translate id="hrms.employeeInfo.myInformation.nonactive" />}</span>}</span></p>
                                <p><span style={{ fontSize: 12 }}>{<Translate id="hrms.employeeInfo.myInformation.serviceMonth" />}</span><br />{this.state.basicinfo.serviceMonth || "-"}</p>
                                {(basicinfo.employeeStatus === "Contract" || basicinfo.employeeStatus === "Probation") && basicinfo.contractLength !== null ? <p><span style={{ fontSize: 12 }}>{<Translate id="hrms.employeeInfo.myInformation.contractEnds" />}</span><br />{this.state.basicinfo.endDate}</p> : ""}
                                <p><span style={{ fontSize: 12 }}>{<Translate id="hrms.employeeInfo.myInformation.empSequence" />}</span><br />{this.state.basicinfo.empSequence}</p>

                            </div>
                        </div>
                    </div>
                </BladeBody>
            </Blade >
        )
    }
}

const Inputs = (disableField, master, handleCalendar, basicinfo, handleSubmit, handleChange, isHR, pathname) => (

    <ValidatorForm
        ref="form"
        onError={handleFormErrors}
        onSubmit={handleSubmit}
    >
        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.title" />}
            value={basicinfo.salutation || ''}
            name="salutation"
            onSelect={handleChange}
            data={master.salutationList.map(({ id, text }) => ({ id, text }))}
            options={{ placeholder: 'Choose one' }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
            disabled={isHR === false}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.firstName" />}
            onChange={handleChange}
            name="firstName"
            value={basicinfo.firstName || ''}
            validators={['required']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
            disabled={isHR === false}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.middleName" />}
            onChange={handleChange}
            name="middleName"
            value={basicinfo.middleName || ''}
            disabled={isHR === false}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.lastName" />}
            onChange={handleChange}
            name="lastName"
            value={basicinfo.lastName || ''}
            validators={['required']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
            disabled={isHR === false}
        />
        <TextValidator
            disabled={isHR === false}
            label={<Translate id="hrms.employeeInfo.myInformation.employeeCode" />}
            onChange={handleChange}
            name="employeeCode"
            value={basicinfo.employeeCode || ''}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.primaryPhoneNumber" />}
            onChange={handleChange}
            name="mobileNo"
            validators={['isNumber']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidMobileNumber" />]}
            value={basicinfo.mobileNo || ""}
        //disabled={basicinfo.roleName === 'Super Admin'}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.alternateMobileNumber" />}
            onChange={handleChange}
            name="altMobileNo"
            validators={['isNumber']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidMobileNumber" />]}
            value={basicinfo.altMobileNo || ""}
        //disabled={basicinfo.roleName !== 'HR Admin'}
        />
        <TextValidator
            disabled={isHR === false}
            label={<Translate id="hrms.employeeInfo.myInformation.emailAddress" />}
            onChange={handleChange}
            name="email"
            validators={['isEmail']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidEmail" />]}
            value={basicinfo.email || ""}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.alternateEmailAddress" />}
            onChange={handleChange}
            name="altEmail"
            validators={['isEmail']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.invalidEmail" />]}
            value={basicinfo.altEmail || ""}
        //disabled={basicinfo.roleName === 'Super Admin'}
        />
        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.gender" />}
            value={basicinfo.gender || ''}
            name="gender"
            onSelect={handleChange}
            data={master.genderList}
            options={{ placeholder: 'Gender' }}
            disabled={isHR === false}
        />
        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.bloodType" />}
            value={basicinfo.bloodType || ''}
            name="bloodType"
            onSelect={handleChange}
            data={master.bloodTypeList}
            options={{ placeholder: 'Blood Type' }}
            disabled={isHR === false}
        />
        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.religion" />}
            value={basicinfo.religion || ''}
            name="religion"
            onSelect={handleChange}
            data={master.religionList}
            options={{ placeholder: 'Religion' }}
            disabled={isHR === false}
        />

        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.placeOfBirth" />}
            onChange={handleChange}
            name="birthPlace"
            value={basicinfo.birthPlace || ''}
            disabled={isHR === false}
        />

        <DatetimePicker
            closeOnSelect
            value={basicinfo.birthDate || ''}
            name="birthDate"
            isValidDate={(current) => { return current.isBefore(moment().subtract(1, 'year')) }}
            viewDate={getCalenderView(basicinfo.birthDate)}
            onChange={(m) => { handleCalendar(m, "birthDate") }}
            inputProps={{
                placeholder: 'DD/MM/YYYY',
                label: <Translate id="hrms.employeeInfo.myInformation.birthDate" />,
                disabled: isHR === false
            }}
            validators={['matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
            errorMessages={['Invalid Date']}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.age" />}
            name="age"
            type="number"
            value={basicinfo.age || ''}
            disabled={isHR === false}
        />
        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.maritalStatus" />}
            value={basicinfo.maritalStatus || ''}
            name="maritalStatus"
            onSelect={handleChange}
            data={master.maritalList}
            options={{ placeholder: "Marital Status" }}
            disabled={isHR === false}
        />
        <DatetimePicker
            closeOnSelect
            value={basicinfo.joinDate || ''}
            name="joinDate"
            viewDate={getCalenderView(basicinfo.joinDate)}
            onChange={(e) => handleCalendar(e, 'joinDate')}
            inputProps={{
                placeholder: 'DD/MM/YYYY',
                label: <Translate id="hrms.employeeInfo.myInformation.joinedDate" />,
                disabled: isHR === false
            }}
            // validators={['matchRegexp:^(0[1-9]|1[0-9]|2[0-9]|3[0-1])/(0[1-9]|1[0-2])/([0-9]{4})$']}
            // errorMessages={['Invalid Date']}
            dateFormat="DD/MM/YYYY"
            timeFormat={false}
        />

        {/* <TextValidator
            disabled
            label="Number of years in service"
            onChange={handleChange}
            name="yearsInService"
            value={10}
         /> */}
        {/* <SelectValidator
        //     defaultValue={basicinfo.positionName}
        //     value={getDropdownUID(master.modulemaster.positionList, basicinfo.positionName)}
        //     label="Current Position"
        //     name="positionName"
        //     onSelect={handleChange}
        //     validators={['required']}
        //     errorMessages={['Position should be selected']}
        //     data={getSelectData('Position')}
        //     options={{ placeholder: "Current Position" }} /> */}

        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.currentPosition" />}
            onChange={handleChange}
            name="positionName"
            value={basicinfo.positionName || ''}
            disabled={isHR === false}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.organisationUnit" />}
            onChange={handleChange}
            name="orgUnitName"
            value={basicinfo.orgUnitName || ''}
            disabled={isHR === false}
        />

        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.division" />}
            onChange={handleChange}
            name="divisionName"
            value={basicinfo.divisionName || ''}
            disabled={isHR === false}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.department" />}
            onChange={handleChange}
            name="departmentName"
            value={basicinfo.departmentName || ''}
            disabled={isHR === false}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.subDepartment" />}
            onChange={handleChange}
            name="subDepartmentName"
            value={basicinfo.subDepartmentName || ''}
            disabled={isHR === false}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.directManager" />}
            onChange={handleChange}
            name="directManagerName"
            value={basicinfo.directManagerName || ''}
            disabled={isHR === false}
        />
        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.indirectManager" />}
            onChange={handleChange}
            name="indirectManagerName"
            value={basicinfo.indirectManagerName || ''}
            disabled={isHR === false}
        />

        <TextValidator
            label={<Translate id="hrms.employeeInfo.myInformation.employmentStatus" />}
            onChange={handleChange}
            name="employeeStatus"
            value={basicinfo.employeeStatus || ''}
            disabled={isHR === false}
        />

        <TextValidator
            label={<Translate id="hrms.allEmployee.contractLength" />}
            onChange={handleChange}
            name="contractLength"
            type="number"
            min={1}
            value={basicinfo.contractLength || ''}
            disabled={pathname.includes('allemployee') && (basicinfo.employeeStatus === 'contract' || basicinfo.employeeStatus === 'Contract') ? false : 
            pathname.includes('myinfo') && isHR === true ? false : true}
            validators={pathname.includes('allemployee') && (basicinfo.employeeStatus === 'contract' || basicinfo.employeeStatus === 'Contract') ? ['required'] :[]}
            errorMessages={pathname.includes('allemployee') && (basicinfo.employeeStatus === 'contract' || basicinfo.employeeStatus === 'Contract') ? [<Translate id="hrms.employeeInfo.myInformation.required" />]:[]}
        />

        <SelectValidator
            label={<Translate id="hrms.employeeInfo.myInformation.nationality" />}
            name="nationality"
            value={basicinfo.nationality || ''}
            onSelect={handleChange}
            data={master.modulemaster.countryList.map(({ text }) => text)}
            options={{ placeholder: "Choose one" }}
            validators={['required']}
            errorMessages={[<Translate id="hrms.employeeInfo.myInformation.required" />]}
            disabled={isHR === false}
        />

        {/* <TextValidator
            label="KTP / PASSPORT NO"
            onChange={handleChange}
            name="ktp"
            value={basicinfo.ktp}
        /> */}

        {/* <DatetimePicker
            value={basicinfo.expiryDate}
            isValidDate={(current) => { return current.isAfter(moment().subtract(1, 'day')) }}
            viewDate={getCalenderView(basicinfo.expiryDate)}
            onChange={(e) => handleCalendar(e, "expiryDate")}
            inputProps={{
                placeholder: 'DD/MM/YYYY',
                label: "Expiry Date"
            }}
            dateFormat="DD/MM/YYYY"
            timeFormat={false} /> */}

        <CheckBox
            label={<Translate id="hrms.employeeInfo.myInformation.allowVirtualLocation" />}
            name="allowVirtualLocation"
            onChange={handleChange}
            checked={basicinfo.allowVirtualLocation}
            disabled={isHR === false}
        />
        <CheckBox
            label={<Translate id="hrms.employeeInfo.myInformation.active" />}
            name="isActive"
            onChange={handleChange}
            checked={basicinfo.isActive}
            disabled={isHR === false}
        />
        <div className="form-group">
            <button id="submit" style={{ display: "none" }} type="submit"></button>
            <button id="resetpassword" style={{ display: "none" }} type="submit"></button>
        </div>
    </ValidatorForm>
);

const mapDispatchToProps = dispatch => bindActionCreators({
    createNotification,
    dataChanged
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(BasicInfo))
import { formDataConsole, devLog, getSignature, DEV_URL, checkAPIError } from '../../../_helpers/helper.methods';
import store from '../../../_helpers/helper.store';
//var ip = require("ip");

export const Add = (formData, user, directory, employee) => {
    let { language: { ipaddress } } = store.getState();
    let signature
    if (employee) {
        signature = employee.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    } else {
        signature = user.empUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    }

    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('EmpUID', employee ? employee.empUID : user.empUID);

    formDataConsole(formData);
    let url = '/api/hrms/employee/address/add';
    return fetch(DEV_URL.concat(url), {
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// address add response', res)
            return res;
        })
        .catch(checkAPIError);
}

export const Update = (formData, user, directory, state) => {
    let signature = state.addressUID.toLowerCase() + user.empUID.toLowerCase() + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('AddressUID', state.addressUID);

    let url='/api/hrms/employee/address/update';
   // return fetch(directory.concat(DEV_URL, ''), {
    return fetch(DEV_URL.concat(url), {   
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
        }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// address update response', res)
            return res;
        })
        .catch(checkAPIError);
}

export const Deactive = (user, AddressUID, directory) => {
    let formData = new FormData();
    let signature = AddressUID + user.empUID + user.token;
    let { language: { ipaddress } } = store.getState();
    devLog("raw sig", signature);

    signature = getSignature(signature);

    formData.append('Signature', signature);
    formData.append('Subscription', user.subscription);
    formData.append("ActionBy", user.empUID);
    formData.append('DeviceIP', ipaddress);
    formData.append('AddressUID', AddressUID);

    formDataConsole(formData);
    let url='/api/hrms/employee/address/deactive';
    //return fetch(directory.concat(DEV_URL, ''), {
    return fetch(DEV_URL.concat(url), { 
        method: "POST",
        headers: new Headers({
            'Authorization': 'Bearer '+ user.token
          }),
        body: formData
    })
        .then((res) => res.json())
        .then((res) => {
            devLog('// address deactive response', res)
            return res;
        })
        .catch(checkAPIError);
}
import React, { Suspense } from 'react'
import { Route } from 'react-router-dom'
import ViewContainer from "../../Components/ViewContainer";

//import Menu from "../../Components/Menu/Menu";
//redux
import {
    storeMaster
} from "../../_reducers/reducer.user";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { withLocalize, Translate } from 'react-localize-redux';
import en from '../../_language/en.json';
import id from '../../_language/id.json';

//api
import API from '../../_apis/Core';
import { devLog } from '../../_helpers/helper.methods';
import EmployeeInfo from "./EmployeeInfo"
import AllEmployee from "./AllEmployee"
//import Leave from "./Leave"
//import Master from "./Master"
//import Absence from "./AbsenceManagement"
//import SecurityManagement from "./SecurityManagement"
//import RMS from "./RMS"
//import organizationchart from "./MyCompanyChart"
//import OrganizationDashboard from "./OrganizationDashboard"
//import AttendanceDashboard from "./AttendanceDashboard"
//import Reports from "./Reports"
//import Person from "./PersoneelManagement"
//import EmailTemplate from "./WorkflowApproval/EmailTemplate"
//import ActionType from "./WorkflowApproval/ActionType"
import Dashboard from "./../Dashboard"

const RMS = React.lazy(() => import('./RMS'))
const SecurityManagement = React.lazy(() => import('./SecurityManagement'))
const Absence = React.lazy(() => import("./AbsenceManagement"))
const OrganizationDashboard = React.lazy(() => import("./OrganizationDashboard"))
const Reports = React.lazy(() => import("./Reports"))
const Person = React.lazy(() => import("./PersoneelManagement"))
const organizationchart = React.lazy(() => import("./MyCompanyChart"))
const EmailTemplate = React.lazy(() => import("./WorkflowApproval/EmailTemplate"))
const ActionType = React.lazy(() => import("./WorkflowApproval/ActionType"))
const Master = React.lazy(() => import("./Master"))
const AttendanceDashboard = React.lazy(() => import("./AttendanceDashboard"))
const Leave = React.lazy(() => import("./Leave"))
const PersonDoc = React.lazy(() => import("./PersonDoc"))
const Reimbursement = React.lazy(() => import("./Reimbursement"))

class HRMS extends React.Component {
    async componentDidMount() {
        let res = await API.Master.List(this.props.user, 'hrms', '');
        if (res.status !== '1') {
            let mlist = {
                ...this.props.master,
                modulemaster: res
            }
            devLog('mliust', mlist)
            this.props.storeMaster(mlist)
        }
    }

    constructor(props) {
        super(props);
        this.props.addTranslationForLanguage(id, "id");
        this.props.addTranslationForLanguage(en, "en");
    }

    state = {
        hasError: false,
        error: null,
        errorInfo: null
    }
    componentDidCatch(err, info) {
        devLog("error", err, "info", info)
        this.setState({ hasError: true, error: err, errorInfo: info })
    }
    render() {
        if (this.state.hasError) {
            //setTimeout(() => {
            //    this.props.history.push('/home');
            //}, 1000)
            return (
                <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <h1><Translate id="hrms.somethingWentWrong" /></h1>
                    <h2><Translate id="hrms.redirectingToHome" /></h2>
                    {this.state.error && this.state.error.toString()}
                    <br />
                    {this.state.errorInfo.componentStack}
                    {/*setTimeout(() => {
                        this.props.history.push('/home');
                    }, 1000)*/}
                </div>
            )
        }
        let { match } = this.props;
        return (
            <ViewContainer id={"viewcontainer"}>
                {/*<Route path={`${match.path}`} component={Menu} />*/}
                <Route path={`${match.path}/myinfo`} component={EmployeeInfo} />
                <Route path={`${match.path}/allemporgunit`} component={AllEmployee} />
                <Suspense fallback={<div><img alt='loading' style={{ height: 100, width: 100 }} src={'/images/loading.gif'} /></div>}>
                    <Route path={`${match.path}/leave`} component={Leave} />
                    <Route path={`${match.path}/master`} component={Master} />
                </Suspense>
                <Route path={`${match.path}/dashboard`} component={Dashboard} />
                <Suspense fallback={<div><img alt='loading' style={{ height: 100, width: 100 }} src={'/images/loading.gif'} /></div>}>
                    <Route path={`${match.path}/absence`} component={Absence} />
                    <Route path={`${match.path}/securitymanage`} component={SecurityManagement} />
                    <Route path={`${match.path}/RMS`} component={RMS} />
                    <Route path={`${match.path}/organizationchart`} component={organizationchart} />
                    <Route path={`${match.path}/organizationdashboard`} component={OrganizationDashboard} />
                    <Route path={`${match.path}/absence/dashboard`} component={AttendanceDashboard} />
                    <Route path={`${match.path}/Reports`} component={Reports} />
                    <Route path={`${match.path}/person`} component={Person} />
                    <Route path={`${match.path}/ws/emailtemplate`} component={EmailTemplate} />
                    <Route path={`${match.path}/ws/acttype`} component={ActionType} />
                    <Route path={`${match.path}/persondocs`} component={PersonDoc} />
                    <Route path={`${match.path}/reimbursement`} component={Reimbursement} />
                </Suspense>
            </ViewContainer>
        );
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    storeMaster
}, dispatch)
const mapStateToProps = state => ({
    user: state.user.user,
    master: state.user.master
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(HRMS))
import React from 'react';
import { withTheme } from 'theming';
const Searchbox = (props) => {
    return (
        <div className="form-group" style={{ marginBottom: 10 }}>
            <div className="form-input __search">
                <input
                    style={props.theme.inputs}
                    value={props.value}
                    placeholder="Search keyword..."
                    onChange={props.onChange} />
                <button onClick={props.clearFilter} className="btn __other">
                    <i className="btn-ico fa fa-remove" title="Clear"></i>
                </button>
            </div>
        </div>
    )
}
export default withTheme(Searchbox);
import React from 'react'
import { Route } from 'react-router-dom'
//components
import Menu from '../../../../../Components/Menu/Menu'
import WorkHistoryList from './List'
import MyWorkHistory from './MyWorkHistory'
import TerminationList from './TerminationList'
import TerminationDetail from './TerminationDetail'

class WorkHistory extends React.Component {
    render() {
        let { match } = this.props;
        return (
            <React.Fragment>
                <Route path={`${match.path}`} component={Menu} />
                <Route path={`${match.path}/current`} component={WorkHistoryList} />
                <Route path={`${match.path}/previous`} component={WorkHistoryList} />
                <Route path={`${match.path}/current/view`} component={MyWorkHistory} />
                <Route path={`${match.path}/current/edit`} component={MyWorkHistory} />               
                <Route path={`${match.path}/current/update`} component={MyWorkHistory} />
                <Route path={`${match.path}/current/termination`} component={TerminationDetail} /> 
                <Route path={`${match.path}/previous/add`} component={MyWorkHistory} />
                <Route path={`${match.path}/previous/edit`} component={MyWorkHistory} />    
                <Route path={`${match.path}/termination`} component={TerminationList} /> 
                <Route path={`${match.path}/termination/add`} component={TerminationDetail} />  
                <Route path={`${match.path}/termination/edit`} component={TerminationDetail} />          
            </React.Fragment>
        );
    }
}

export default WorkHistory;